import { BaseValidator } from "./BaseValidator"

export class MinLengthValidator extends BaseValidator{
    id = "minlength"
    icon =  ""
    name = "Minimum Length"
    config = {
        inputParams: {
            params: ['min', 'customErrorMessage'],
            customErrorMessage: {
                value : "Minimum length criteria failed",
                displayName: "Custom Error Message",
                type: 'string'
            },
            min: {
                value : 0,
                displayName: "Minimum Length",
                type: 'number'
            }
        }
    }

    public validate(val:string, inputParams:any){
        return (val.length >= inputParams.min.value)
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}