import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilePickerDirective } from './file-picker.directive';

/**button | dropzone | both | iconbutton */
declare type filePickerType = "button" | "dropzone" | "both" | "iconbutton"

@Component({
    selector: 'dropzone',
    templateUrl: './dragndropfile.component.html',
    styleUrls: ['./dragndropfile.component.scss'],
    standalone: false
})
export class DragnDropfileComponent implements OnInit {
  isHovered: boolean = false;
  private _imageSrc: string[] = [];
  get imageSrc() { return this._imageSrc }
  set imageSrc(value) { this._imageSrc = value };
  private reader: FileReader = new FileReader();

  _multiple = false;
  get multiple() { return this._multiple; }
  @Input() set multiple(v) {
    // console.log("multiple:", v)
    this._multiple = v;
  }

  isIterable: boolean = true

  @Input() setImage: any
  @Input() disabled: boolean = false;
  @Input() noPreview: boolean;
  @Input() displayText: any
  @Input() displayImage: any

  private _isImageClose: boolean = false;
  get isImageClose() { return this._isImageClose; }
  set isImageClose(v) { this._isImageClose = v; }

  _selectedFiles = [];

  @Output() fileChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() urlListChanged: EventEmitter<any> = new EventEmitter<any>();
  private _height: string = '200px'
  get height() { return this._height; }
  @Input() set height(v) { this._height = v; }

  private _width: string = '100%'
  get width() { return this._width; }
  @Input() set width(v) { this._width = v; }

  private _type: filePickerType = 'both';
  get type() { return this._type; }
  /**Accepts Only 'button' or 'dropzone' or 'iconbutton' or 'both'*/
  @Input() set type(v) { this._type = v }

  /** To specify more than one value, separate the values with a comma (e.g. <input accept="audio/*,video/*,image/*"*/
  @Input() set accept(val: string) {
    this._accept = val
  }
  get accept() { return this._accept }
  private _accept = 'image/*'

  @Input() set maxFileSize(v: number) {
    // console.log("max size provided", v)
    this._maxFileSize = v
   }
  get maxFileSize() { return this._maxFileSize }
  private _maxFileSize = 2097152

  @Input() pathId:any = "";
  @Input() miniPreview: boolean
  @Input() dimensions: any;
  displayImageOption: any = {};
  imageOption: any = {};

  @ViewChild('buttonPicker', { static: true }) _buttonPicker: FilePickerDirective;

  showChangeButton: boolean = false

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes.setImage?.currentValue){
    //   console.log("imageSrc to set:", changes.setImage.currentValue)
    // }
    if(changes.setImage?.currentValue){
      // console.log("onchanges: setImage", changes.setImage.currentValue)
      this.initializeSrc(changes.setImage.currentValue)
    }

    if(changes.displayImage?.currentValue){
      this.displayImage = changes.displayImage?.currentValue;
      if(this.displayImage && !Array.isArray(this.displayImage)) this.displayImage = [this.displayImage];
      if(this.displayImage?.length > 0) this.isImageClose = true;
    }
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  initializeSrc(v){
    if (v) {
      if(this.multiple){
        // console.log("inside multiple")
        if(typeof v == 'string'){
          this._imageSrc[0] = v;
        }else{
          if(!Object.keys(v).length){
            this.imageSrc = []
            return
          }else{
            let i = 0;
            for (const key in v) {
              if(parseInt(key) != i) this.isIterable = false
              i++
            }
            if(this.isIterable){
              for (const i in v) {
                this.imageSrc[i] = v[i]
              }
            }
          }
        }
      }else{
        // console.log("!multiple")
        if(typeof v == 'string'){
          if(v){
            this.imageSrc[0] = v;
          }
        }else if (typeof v == 'object'){
          if(v[0] || v['0']){
            this.imageSrc[0] = v[0] || v['0']
          }
        }else{
          this.imageSrc = []
        }
      }
      console.log("imageSrc initialized", this.imageSrc)
      if (this.imageSrc?.length && typeof this.imageSrc[0] == 'string' && this.imageSrc[0]?.length) {
        this.isImageClose = true;
      }
    }
  }

  displayFileRemoved(displayImages:any, i:number){
    displayImages.splice(i, 1)
  }

  imageMouseenter(i){
    if(!this.imageOption[i]){
      this.imageOption[i] = {};
    }
    this.imageOption[i].mousein = true
  }

  imageMouseleave(i){
    this.imageOption[i].mousein = false
  }

  displayImageMouseenter(i){
    if(!this.displayImageOption[i]){
      this.displayImageOption[i] = {};
    }
    this.displayImageOption[i].mousein = true
  }

  displayImageMouseleave(i){
    this.displayImageOption[i].mousein = false
  }

  fileRemoved(i?: number){
    console.log("file remove hit", i);

    if(i == undefined){
      this.imageSrc = []
      this.fileChange.emit(null);
    }else{
      console.log("remove", i, "from existing images", JSON.parse(JSON.stringify(this.imageSrc)))
      this.imageSrc.splice(i, 1)
      console.log("removed", this.imageSrc)
      this.urlListChanged.emit(this.imageSrc)
    }
    if((!this.displayImage || this.displayImage?.length == 0) && (this.imageSrc?.length == 0)) this.isImageClose = false;
  }
  addOutline() {
    this.isHovered = true;
  }

  // Function to remove outline when not hovering
  removeOutline() {
    this.isHovered = false;
  }
  async _onFilesChanged(files: FileList, showImage: boolean = true, index?: number) {
    console.log("onFilesChanged hit, index", index)
    if(files.length == 0)return

    if(!this.multiple){
      this._selectedFiles = [];
      this.imageSrc = [];
    }

    if(this.noPreview){
      showImage = false;
    }

    let isLoadedAll = []
    for (let j = 0; j < files.length; j++) {
      isLoadedAll.push(false)
    }
    console.log("isLoadedAll initialized", isLoadedAll)

    console.log("image src before image load", this.imageSrc)
    let images: any[] = []    // array of { image: any, index?: number }
    console.log("files", files)

    for (let i = 0; i < files.length; i++) {
      let ext = files[i].name.split('.')?.pop()
      console.log("extension", ext)
      // console.log("files.item(i).size", files.item(i).size)
      // console.log("this._maxFileSize", this._maxFileSize)
      if (files.item(i).size > this._maxFileSize) {
        console.log(`image dimension exceeds limit (max size: ${this._maxFileSize / 1024}KB)`)
        this._snackBar.open(`Please select an image under ${this._maxFileSize / 1024}KB`, "Ok")
        return
      }
      console.log(i, "th image is valid")

      this._selectedFiles.push(files[i]);
      let result = await this.generateFileObject(files[i], ext);
      console.log("before reader onload")
      this.reader.onload = () => {
        console.log("reader loaded")
        let isValidDimensions: boolean = false
        let img = new Image();
        img.onload = () => {
          isValidDimensions = this.validateDimensions(img)
          if (showImage && isValidDimensions) {
            this.isImageClose = true;
            this.showChangeButton = false
            this.imageSrc[index] = this.reader.result as string;
          }
          if(!isValidDimensions) return
          images.push({ image: result, index: index })
          console.log("after pushing", images)
          console.log("imageSrc", this.imageSrc)
          isLoadedAll[i] = true
          if(!isLoadedAll.find(status => status == false)) {
            if(this.multiple) this.fileChange.emit(images);
            else this.fileChange.emit(images[0].image);
          }
        }
        img.src = this.reader.result as string;
        console.log("outside img load")
      }
    }
  }

  validateDimensions(img){
    // console.log("[validateDimensions] dimensions", this.dimensions)
    // console.log("[validateDimensions] img", img)
    if (this.dimensions?.width && this.dimensions?.height) {
      // console.log("if 1")
      if(this.dimensions.width != img.width || this.dimensions.height != img.height){
        this._snackBar.open(`Please select an image with dimensions ${this.dimensions.width} x ${this.dimensions.height}`, "Ok")
        return false
      }
    } else if (this.dimensions?.width) {
      // console.log("if 2")
      if(this.dimensions.width != img.width) {
        this._snackBar.open(`Please select an image with width ${this.dimensions.width} pixels`, "Ok")
        return false
      }
    } else if (this.dimensions?.height) {
      // console.log("if 3")
      if(this.dimensions.height != img.height) {
        this._snackBar.open(`Please select an image with height ${this.dimensions.height} pixels`, "Ok")
        return false
      }
    }
    // console.log("outside")
    // console.log("returning true")
    return true
  }

  async generateFileObject(file: File, extension: string) {
    let result: any = {};
    let name = file.name.replace(/[()%\s]/g, "")
    let fileObj = new File([file], name, {
      type: file.type,
    });
    let buffer = await fileObj.arrayBuffer();
    this.reader.readAsDataURL(file);
    buffer = new Uint8Array(buffer)
    let nodeBuffer = [];
    for (let i = 0; i < buffer.byteLength; i++) {
      nodeBuffer.push(buffer[i])
    }

    let fileName = this.pathId ? `${this.pathId}-${Date.now()}.${extension}` : `test/images/${name}`;
    result.file = {
      filename: fileName,
      size: file.size,
      mimetype: file.type,
      buffer: nodeBuffer
    }
    return result
  }

  _onReset() {
    this._selectedFiles = [];
  }
}
