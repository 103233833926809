import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { StarchService } from 'src/app/shared/services/starch.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { CreateFormPopupComponent } from '../create-form-popup/create-form-popup.component';
import { environment } from 'src/environments/environment';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { SmsService } from 'src/app/shared/services/sms-service.service';
import { QoutaPopupComponent } from 'src/app/shared/qouta-popup/qouta-popup.component';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { FormService } from '../form.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-form-home',
    templateUrl: './form-home.component.html',
    styleUrls: ['./form-home.component.scss'],
    standalone: false
})
export class FormHomeComponent implements OnInit {

  workspaceReady: boolean = false
  spinner:boolean = false;
  forms: any[] = []
  isFormsLoaded: boolean = false
  authError: boolean;
  error = false;
  isNoForm: boolean = false
  selectedConnectionView: string = 'view_list';
  unselectedView: string = 'view_module';
  showTip: string = 'Grid View';
  pageIndex: number = 1
  pageLimit: number = 20
  startIndex: number
  isPaginator: boolean = true

  formsListPanelMeta: any;
  formListTableData: any[] = []

  panelMetaReady: boolean = false
  refreshPanel: boolean;
  currentBaseUrl: string;
  isBrowser: boolean;

  constructor(
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private ngZone: NgZone,
    private themeService: ThemeService,
    private formService: FormService,
    private _snackBar: MatSnackBar,
    private smsService: SmsService,
    private automationService: AutomationService,
    private pageService: PageService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }


  ngOnInit(): void {
    // this.spinnerService.show()
    if(!this.isBrowser) return;
    this.authService.subproduct = "form";
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
    if (!this.connectionService.selectedWorkSpace && !this.authService.loggedIn) {
      this.authService.authCheck();
      console.log("in forms init")
      this.authService.authCheckPositive.subscribe((authStatus) => {
        //if logged in
        if (authStatus) {
          this.spinner = true;
          console.log('logged in');
          this.themeService.loadTheme();
          this.themeService.getExistingTheme(this.authService.profile._id)
          .then((res: any) => {
            if (res?.data != null) {
              console.log(res);
              this.themeService.settings_Id = res?.data[0]._id;
              let theme = res.data[0].themeSetting.theme;
              this.themeService.setTheme(theme);
              this.themeService.currentLocale = res.data[0].localeSetting;
              this.themeService.textDirection =
                res.data[0].themeSetting.direction;
              if (res.data[0].themeSetting.direction == 'rtl') {
                this.themeService.enableDirMode('rtl');
              }
            }
          });
          this.spinner = false;
          console.log("form in auth before", this.connectionService.workSpaceId);
          this.initialize();
        } else {
          this.authError = true;
          this.spinner = false;

          // redirect to login page
          this.ngZone.run(() => {
            this.router.navigate(['../']);
          });

          return;
        }
      });
    } else if (this.connectionService.selectedWorkSpace) {
      // this.getForms();
      this.spinner = false;
      this.initialize()
    }
  }


  async initialize(){
    console.log("initialize hit");
    console.log("[CALLING] getWorkspaceId from workspace component customInit")
    await this.connectionService.getWorkSpaceId();
    this.workspaceReady = true;
    this.createListMeta()
    // await this.getForms();
  }

  refreshList(){
    this.initialize()
  }

  // async getForms() {
  //   this.spinner = true;

  //   this.createListMeta()

  //   let data = await this.formService.getFormsByWorkspace(this.connectionService.selectedWorkSpace, 1, 100, "draft");
  //   console.log('form list received', data, this.connectionService.selectedWorkSpace);
  //   if (Array.isArray(data) && !data.length) {
  //     this.error = true;
  //     this.isNoForm = true;
  //     this.spinner = false;
  //   } else {
  //     this.error = false;
  //     console.log('formList received in form home component:', data);
  //     this.forms = data;
  //     let temp = data.slice(0, 20)
  //     let forms: any[] = []
  //     temp.forEach((bloom, i) => {
  //       forms.push({
  //         "#": i + 1,
  //         "Name": bloom.name,
  //         "logoUrl": bloom.logoUrl
  //       })
  //     })
  //     this.formListTableData = forms
  //     this.spinner = false;
  //   }
  //   this.isFormsLoaded = true
  // }

  createListMeta(){
    this.formsListPanelMeta = {
      listAttributes: [
        {
          "widgetType": "label",
          "columnName": "#",
          "sortEnabled": false,
          "show_hide": false,
          "value": "__serial__",
          "dataType": "NA",
          "isColumnSelected": true,
          "name": "__serial__",
          "__id": "___serial__",
          "fieldType": "label"
        },
        {
          "__id": "logoUrl",
          "name": "Logo",
          "dataType": "string",
          "sortEnabled": false,
          "show_hide": false,
          "fieldType": "attribute",
          "widgetType": "image",
          "isColumnSelected": true
        },
        {
          "__id": "name",
          "name": "name",
          "dataType": "string",
          "sortEnabled": false,
          "show_hide": false,
          "fieldType": "attribute",
          "widgetType": "label",
          "isColumnSelected": true
        },
        {
          columnName: 'Edit',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'edit',
          show_hide: false,
          sortEnabled: false,
          value: 'edit',
          widgetType: 'icon',
          __id: 'edit',
          isColumnSelected: true
        },
        {
          "__id": "publishedUrl",
          "name": "View Responses",
          columnName: 'View',
          dataType: 'NA',
          fieldType: 'attribute',
          show_hide: false,
          sortEnabled: false,
          value: '',
          conditionalConfig: {
            enabled: true,
            attributeRelation: "",
            displayRules: [
              {compareValue: "_TRUE_", result: "list_alt", tooltip: "View response", color: "primary"}
            ]
          },
          widgetType: 'icon',
          isColumnSelected: true
        },
        {
          columnName: 'Clone',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'clone',
          show_hide: false,
          sortEnabled: false,
          value: 'content_copy',
          widgetType: 'icon',
          __id: 'clone',
          isColumnSelected: true
        },
        {
          "__id": "checkPublished",
          "name": "View Published Form",
          columnName: 'View',
          dataType: 'NA',
          fieldType: 'attribute',
          show_hide: false,
          sortEnabled: false,
          value: '__publishedUrl', //used to check value of publishedUrl field
          conditionalConfig: {
            enabled: true,
            attributeRelation: "",
            displayRules: [
              {compareValue: "_TRUE_", result: "preview", tooltip: "View Form", color: "primary"}
            ]
          },
          widgetType: 'icon',
          isColumnSelected: true
        },
        {
          columnName: 'Delete',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'delete',
          show_hide: false,
          sortEnabled: false,
          value: 'delete',
          widgetType: 'icon',
          __id: 'delete',
          isColumnSelected: true,
          eventType: 'action',
          actionConfig: {
            action: 'resource-deletion',
            event: 'click'
          }
        }
      ],
      // dataHeaders: ["#", "Name"],
      defaultListSize: 10,
      hideTitle: true,
      filter: {
        "filterEnabled": true,
        "filterItems": [
          {
            "attribute": "status",
            "operator": "=",
            "value": "draft",
            "dataType": "string",
            "filterType": "static_filter"
          },
          {
            "attribute": "workspace_id",
            "operator": "=",
            "value": this.connectionService.selectedWorkSpace,
            "dataType": "string",
            "filterType": "static_filter"
          }
        ]
      },
      type: 'listpanel',
      viewTypes: {
        "views": [
            "table",
            "card",
            "board"
        ],
        "defaultView": "table",
        "boardStatusColumn": "",
        "userCanChoose": true,
        "table": {
            "name": "table",
            "displayName": "Table",
            "userCanChoose": true,
            "icon": "list"
        },
        "card": {
            "name": "card",
            "displayName": "Card",
            "userCanChoose": true,
            "icon": "grid_on"
        },
        "board": {
            "name": "board",
            "displayName": "Board",
            "userCanChoose": false,
            "icon": "view_kanban"
        }
      },
      userFilterEnabled: false,
      userSortEnabled: false,
      boxId: 'starch',
      baseMap: {
        "box_id": "mongodb",
        "base_id": ""
      },
      connectionId: "",
      boxObjectId: 'forms',
      loadInitialData: true,
      boxConfigToken: environment.DB_BOX_TOKEN,
      pageSize: 10,
      paginationEnabled:true,
      paginationType: "pagebypage",
      id: "formListing",
      noCache: true,
      noDataMessage: "Create your first form now."
    };
    this.formsListPanelMeta["listWidgetSet"] = this.getWidgetSet(this.formsListPanelMeta)

    console.log("Panel meta for bloom listing", this.formsListPanelMeta)
    this.panelMetaReady = true
  }

  getWidgetSet(meta){
    let widgetSet = {}
    for (let i = 0; i < meta.listAttributes.length; i++){
      const attrId = meta.listAttributes[i].__id;
      let widgetType: string = meta.listAttributes[i].widgetType == 'NA' ? meta.listAttributes[i].fieldType : meta.listAttributes[i].widgetType;
      widgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
    }
    return widgetSet
  }

  selectedRowData(data){
    console.log("selectedRowData hit", data)
    if(data.actionId == 'edit'){
      console.log("open form", data.rowDataRaw)
      this.openForm(data.rowDataRaw)
    }if(data.actionId == 'publishedUrl'){
      console.log("open form response", data.rowDataRaw)
      this.openResponse(data.rowDataRaw)
    }else if(data.actionId == 'clone'){
      console.log("clone hit")
      this.cloneForm(data.rowDataRaw);
      console.log("delete form", data.rowDataRaw)
    }else if(data.actionId == 'checkPublished'){
      if(data.rowDataRaw.publishedUrl) {
        window.open(this.currentBaseUrl + data.rowDataRaw.publishedUrl, "_blank")
      }else{
        console.log("Form is not yet published")
        this._snackBar.open("Form is not published yet")
      }
    }
  }

  ngOnDestroy(): void {
    this.authService?.authCheckPositive?.unsubscribe();
  }

  async cloneForm(flow){
    var dialog = this.dialog.open(CreateFormPopupComponent, {
      minWidth: "60%",
      data: {
        title: "Clone Form",
        buttonText: "Clone",
        randomCode: String(Date.now()),
        name: `Clone of ${flow.name}`
      }
    });
    var diologResult = await dialog.afterClosed().toPromise();
    if(!diologResult) return;
    this.spinner = true;
    let clonedFlow = JSON.parse(JSON.stringify(flow));
    clonedFlow.name = diologResult.name;
    clonedFlow.code = diologResult.code;
    clonedFlow.created_at = new Date().toISOString();
    clonedFlow.modified_at = new Date().toISOString();
    if(clonedFlow.publishedUrl){
      delete clonedFlow.publishedUrl;
    }
    delete clonedFlow._id;

    console.log("form to clone", flow, diologResult);
    try {
      let formCreationResponse = await this.formService.create_form(clonedFlow);
      this._snackBar.open('Flow ' + flow.name + ' is succesfully cloned', "", {duration: 3000});
      this.ngZone.run(() => { })
      this.triggerRefresh()
    } catch (error) {
      this._snackBar.open("Could not clone form", "Ok");
    }
    this.spinner = false;


  }

  navigateToSignUp(){
    this.router.navigate(['sign-up']);
  }

  createBlank(){
    console.log("create form from blank")
    this.createNewForm()
  }

  createFromConnection(){
    console.log("create form from connection")
  }

  createFromStarch(){
    console.log("create form from starch")
  }

  async createNewForm() {
    // let isEligible = this.smsService.checkEligibility("FORM");
    // if(!isEligible){
    //   let dialogRef = this.dialog.open(QoutaPopupComponent, {
    //         width: "500px",
    //         data: {
    //           type: "FORM"
    //         },
    //       });
    //   return ;
    // }

    var dialog = this.dialog.open(CreateFormPopupComponent, {
      minWidth: "60%",
      data: { randomCode: String(Date.now()), formName: "New form " + (this.forms.length + 1)}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("do", diologResult)
    if(!diologResult) return;

    this.spinner = true
    let base = await this.createBase()
    let baseMap = base?.baseMap
    let object = base?.object?.__id
    let attributes = base?.attributes
    if(!baseMap || !object || !attributes.length) {
      console.log("base could not be created")
      return
    }
    console.log('base created', base);
    this.spinner = false

    let formNameMap = diologResult;
    this.spinner = true

    let formPanel = this.createFormPanel(baseMap, object)

    // get banner image URL
    // let bannerUrl: string = "https://cdn.ttgtmedia.com/visuals/searchEnterpriseLinux/admin_issues/enterpriselinux_article_003.jpg"

    let bannerUrl = this.getImageUrl('banner')

    // create banner and attach
    let tempPageMeta = await this.pageService.createPanel('image', {panels: []}, null, null, false, bannerUrl)
    let bannerPanel = tempPageMeta.panels[0]
    bannerPanel["noDrag"] = true
    bannerPanel["noInsert"] = true
    let colId = bannerPanel.layoutMap.list[0]
    let rowId = bannerPanel.layoutMap[colId].list[0]
    let bannerWidget = bannerPanel.layoutMap[colId][rowId]['elements'][0]
    bannerWidget.config.value.value = bannerUrl
    bannerWidget.gridX = 12
    bannerWidget.gridY = 4
    console.log("banner widget", bannerWidget)

    // create form title attach
    tempPageMeta = await this.pageService.createPanel('label', {panels: []}, null)
    let titlePanel = tempPageMeta.panels[0]
    titlePanel["noDelete"] = true
    titlePanel["noDrag"] = true
    titlePanel["noInsert"] = true
    colId = titlePanel.layoutMap.list[0]
    rowId = titlePanel.layoutMap[colId].list[0]
    let titleWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
    titleWidget.value = "New form"
    titleWidget.fontSize = 32
    titleWidget.bold = true
    titleWidget.color = "#3869a4"
    titleWidget.gridX = 12
    console.log("title widget", titleWidget)


    // add description
    tempPageMeta = await this.pageService.createPanel('label', tempPageMeta, titlePanel.id)
    console.log("tempPageMeta after desc", tempPageMeta)
    colId = titlePanel.layoutMap.list[0]
    rowId = titlePanel.layoutMap[colId].list[1]
    let descriptionWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
    descriptionWidget.value = "This is the description of the form. Customize it as per your need."
    descriptionWidget.gridX = 12
    descriptionWidget.color = "#444444"
    descriptionWidget.fontSize = 16

    tempPageMeta = await this.pageService.createPanel('separator', tempPageMeta, titlePanel.id)

    // let panelAttributes = this.filterAttributesForForm(JSON.parse(JSON.stringify(boxAttributes)))//JSON.parse(JSON.stringify(listAttributes))
    attributes.forEach(attr => {
      if(attr.__id == '_id') attr['primary'] = true
      attr['enabled'] = true
      attr['editable'] = true
      attr['widgetType'] = 'input'
      if(attr.primary) attr['enabled'] = false
    })
    // panelAttributes = this.addPrimaryAttribute(panelAttributes, primaryAttribute)

    let submitButton:any = this.formPanelButton(baseMap, object);
    console.log("submitButton", submitButton)
    submitButton.id = Date.now();
    console.log("gridX set", submitButton.gridX)
    submitButton['actionConfig']['actions'][0]['actionMap']['mapping'] = [];
    formPanel.submitButtonMeta = submitButton;
    formPanel.name = 'formPanel_1';
    formPanel.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
    formPanel.formAttributes = attributes;
    formPanel = this.automationService.generateFormWidgets(attributes, formPanel, submitButton, submitButton['actionConfig']['actions'][0]['actionMap'].action)
    console.log("panelConfig", formPanel)

    // create submit panel and attach (will only have the decoupled submit button)
    tempPageMeta = await this.pageService.createPanel('button', {panels: []}, null)
    let submitPanel = tempPageMeta.panels[0]
    colId = submitPanel.layoutMap.list[0]
    rowId = submitPanel.layoutMap[colId].list[0]
    submitPanel.layoutMap[colId][rowId]['elements'][0] = submitButton
    submitPanel['noDelete'] = true
    submitPanel['noDrag'] = true
    submitPanel["noInsert"] = true
    submitPanel["isSubmitPanel"] = true

    // create form and attach titlePanel, form panels and submit panel
    let form: any = this.createFormMeta(formNameMap)
    form['panels'] = [bannerPanel, titlePanel, formPanel, submitPanel]
    console.log("form meta", form)

    let formCreationResponse
    try{
      formCreationResponse = await this.formService.create_form(form);
    }catch(e){
      console.log("form creation failed", e)
      this.spinner = false
      this._snackBar.open("could not create form", "Ok");
      return
    }

    console.log('form created', formCreationResponse);
    this.spinner = false
    this.router.navigate(
      ['form/build/' + form.code],
      // { state: { builderMode: true } }
      {fragment: 'edit'}
    );
  }

  /**
   * type: string; 'logo' | 'banner'
   */
  getImageUrl(type: string) {
    let randomCode: any = Math.floor(Math.random() * 6)
    randomCode = randomCode + 1
    // if (randomCode < 6) {
    //   randomCode = '0' + randomCode
    // }

    let imageUrl
    if(type == 'logo'){
      // imageUrl = environment.FORM_LOGO_BASE_URL + randomCode + '.jpg'
    }else if(type == 'banner'){
      imageUrl = environment.FORM_BANNER_BASE_URL + randomCode + '.png'
    }
    return imageUrl
  }

  createFormPanel(baseMap: any, object: string){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": object,
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `${object} - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
      "submitDecoupled": true,
      "hideTitle": true
    }

    basicConfig.boxId = "starch";
    basicConfig.baseMap = {
      "box_id": baseMap.storage_box,
      "base_id": baseMap._id
    }
    basicConfig.baseId = baseMap._id;
    basicConfig.boxConfigToken = baseMap.storage_token;

    return basicConfig;
  }

  formPanelButton(baseMap: any, object: string){
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
        "event": "click",
        "action": "application",
        "actionMap": {
          "action": `${object}/create`,
          "boxId": "starch",
          "actionMode": "save",
          "boxConfigToken": baseMap.storage_token,
          "baseMap": {
            "box_id": baseMap.storage_box,
            "base_id":  baseMap._id
          },
          "mapping": [],
          successMessage: `Form data is successfully submitted!`
        }
      }]
    }

    buttonWidget.gridX = 4;
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.value.value = "Save";
    return buttonWidget;
  }

  async createBase(){
    // let isEligible = this.smsService.checkEligibility("STARCH");
    // console.log("isEligible", isEligible)
    // if(!isEligible){
    //   let dialogRef = this.dialog.open(QoutaPopupComponent, {
    //         width: "500px",
    //         data: {
    //           type: "STARCH"
    //         },
    //       });
    //   return ;
    // }
    let randomCode: string = await this.starchService.checkAndGetRandomCode()
    let baseMap = {
      code: randomCode,
      name: randomCode
    }
    // this.spinnerService.show();
    let base = await this.starchService.createStarchBase(baseMap);
    console.log("base created", base)

    // create base object
    let baseObject = await this.starchService.createBaseObject(base, {object: 'table 1'})
    console.log("base object created", baseObject)
    // debugger;
    // create base object attribute
    let payload = {
      attributes: [
        {
          name: 'Name',
          dataType: 'string',
          defaultValue: ''
        },
        {
          name: 'City',
          dataType: 'string',
          defaultValue: ''
        },
        {
          name: 'Country',
          dataType: 'string',
          defaultValue: ''
        }
      ],
      object: baseObject.__id,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let attrCreateResponse = await this.starchService.createBaseAttributes(base, payload)
    console.log("attribute created", attrCreateResponse)

    // get attributes
    let getAttrPayload = {
      object: baseObject.__id,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let attributes = await this.starchService.getBaseAttributes(base, getAttrPayload)
    console.log("attributes fetched", attributes)

    // this.spinnerService.hide();
    return {
      baseMap: base,
      object: baseObject,
      attributes: attributes
    }
  }

  createFormMeta(formNameMap?: any){
    let randomCode = String(Date.now()).substring(8);

    let logoUrl = this.getLogoUrl()
    console.log("logo url", logoUrl)

    console.log("random code", randomCode)
    let form = {
      code: formNameMap?.code || 'form-' + randomCode,
      name: formNameMap?.name || 'form-' + randomCode,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      created_by: this.authService.profile.email,
      modified_by: this.authService.profile.email,
      workspace_id: this.connectionService.selectedWorkSpace,
      logoUrl: logoUrl,
      status: 'draft',
      access_type:  "private",
      require_login: true,
      title: "New Form",
      tagline: "Customizable form based on starch"
    };
    return form
  }

  // generate random logo url
  getLogoUrl() {
    let randomLogoCode: any = Math.floor(Math.random() * 10)
    randomLogoCode = randomLogoCode + 1
    if (randomLogoCode < 10) {
      randomLogoCode = '0' + randomLogoCode
    }

    let logoUrl = environment.BLOOM_LOGO_BASE_URL + randomLogoCode + '.jpg'
    return logoUrl
  }

  connectionListToggle() {
    if (this.selectedConnectionView == 'view_list') {
      this.selectedConnectionView = 'view_module';
      this.unselectedView = 'view_list';
      this.showTip = 'List View';
    } else {
      this.selectedConnectionView = 'view_list';
      this.unselectedView = 'view_module';
      this.showTip = 'Grid View';
    }
  }

  openForm(form: any, isCollaborated?: boolean) {
    console.log('need to open form:', form.code);
    this.router.navigate([`/form/build/${form.code}`], {
      fragment: 'edit',
      queryParams: { collaborated_bloom: isCollaborated },
    });
  }
  openResponse(form: any, isCollaborated?: boolean) {
    console.log('need to open form:', form.code);
    this.router.navigate([`/form/build/${form.code}`], {
      fragment: 'response',
      queryParams: { collaborated_bloom: isCollaborated
       },
    });
  }

  // async onDelete(form: any) {
  //   console.log('Form to delete', form);
  //   let data = await this.openDeleteDialog(form);
  //   if(data){
  //     let i = this.forms.findIndex(f => f.code == form.code)
  //       if(i != -1) {
  //         this.forms.splice(i, 1)
  //         let temp = this.formListTableData.filter((_, j) => j != i)
  //         this.formListTableData = JSON.parse(JSON.stringify(temp))
  //       }


  //       if (this.forms.length == 0) {
  //         this.error = true;
  //         this.isNoForm = true;
  //       }
  //       this.triggerRefresh()
  //   }
  // }

  triggerRefresh(){
    this.refreshPanel = true;
    let that = this;
    setTimeout(function() {
      that.refreshPanel = false;
    }, 1000);
  }

  onNewFormClicked(){
    // navigate to create form page
    this.router.navigate(['form/new'])
  }

  pageChanged($event) {
    console.log($event);
    this.spinner = true;
    this.pageIndex = $event.pageIndex;
    this.pageLimit = $event.pageSize;
    console.log(this.startIndex);
    // this.formService.getFormsByWorkspace(
    //   this.connectionService.selectedWorkSpace,
    //   'draft',
    //   false,
    //   this.pageIndex + 1,
    //   this.pageLimit
    // ).then((data) => {
    //   this.forms = data;
    //   this.startIndex = $event.pageIndex * $event.pageSize;
    //   this.spinner = false;
    // });
  }


}
