<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onOuterClick()"
  (dblclick)="onDoubleClicked()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover" [ngStyle]="styles"
  fxFlexFill
  style="display: flex; align-items: center;"
  [style.justify-content]="widgetMeta?.config?.alignment?.value">
    <div
      fxLayout="column" fxLayoutAlign="start center"
      (mousedown)="$event.stopPropagation()"
      *ngIf="!textEditMode"
    >
      <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
      <mat-label
        (click)="onClick($event)"
        [ngStyle]="styles" for=""
        class="link"
      >
        {{ widgetMeta.config.linkText.value || "" }}
      </mat-label>
    </div>
  </div>
  <mat-form-field class="textEdit" *ngIf="textEditMode" (mousedown)="$event.stopPropagation()">
    <input matInput #textEditInput (focusout)="onFocusOut($event)" type="text" [(ngModel)]="widgetMeta.config.linkText.value"
      #paraTextArea fxFlexFill cdkTextareaAutosize cdkAutosizeMinRows="2">
  </mat-form-field>
</div>


<div
  *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill
  (click)="onOuterClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
<div class="innerCover" [ngStyle]="styles"
  fxFlexFill
  style="display: flex; align-items: center;"
  [style.justify-content]="widgetMeta?.config?.alignment?.value">
    <div
      fxLayout="column" fxLayoutAlign="start center"
    >
      <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode"></inline-title>
      <mat-label
        (click)="onClick($event)"
        [ngStyle]="styles" for=""
        class="link"
      >
        {{ widgetMeta.config.linkText.value || "" }}
      </mat-label>
    </div>
  </div>
</div>
