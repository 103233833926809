import { CommonModule } from '@angular/common';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PanelModule } from 'src/app/bloom/page/panel/panel.module';
import { PageService } from 'src/app/bloom/services/page-service.service';

@Component({
    selector: 'popup-edit-view',
    templateUrl: './popup-edit-view.component.html',
    styleUrls: ['./popup-edit-view.component.css'],
    imports: [MatDialogModule, MatButtonModule, PanelModule, CommonModule, BrowserModule, MatIconModule, FlexLayoutModule]
})
export class PopupEditViewComponent implements OnInit {

  // popupTitle: string = '';
  showForm: boolean = false;
  showDetails: boolean = false
  formPanel: any;
  detailsPanel: any;
  isViewEdit: boolean = false
  editTitle: string = "Edit row data"
  viewTitle: string = "Row data"

  constructor(
    public dialogRef: MatDialogRef<PopupEditViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pageService: PageService
  ) {
    this.formPanel = data.formPanel
    this.detailsPanel = data.detailsPanel
    if(this.data.detailsPanel) {
      this.showDetails = true
      // this.popupTitle = this.viewTitle
    }
    else if(this.data.formPanel) {
      this.showForm = true
      // this.popupTitle = this.editTitle
    }

    if (this.data.detailsPanel && this.data.formPanel) this.isViewEdit = true
  }

  ngOnInit(): void {
    console.log("dialog data", this.data)
  }

  editData(){
    console.log("edit triggered")
    this.showForm = true
    this.showDetails = false
    // this.popupTitle = this.editTitle
  }

  cancelEdit(){
    this.showForm = false
    this.showDetails = true
    // this.popupTitle = this.viewTitle
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancel(){
    this.dialogRef.close(false);
  }

  userInputHandler(event: any) {
    // console.log('text input received in page', event);
    this.pageService.insertIntoPageModel(event);
  }

}
