import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { WebSocketService } from 'src/app/core/services/SocketService';
import { FlowService } from 'src/app/flow/flow.service';
import { environment } from 'src/environments/environment';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';

@Component({
    selector: 'flow-name-config',
    templateUrl: './flow-name-config.component.html',
    styleUrls: ['./flow-name-config.component.scss'],
    standalone: false
})
export class FlowNameConfigComponent implements OnInit {
  @Input() editMode:boolean;
  @Input() flow: any;
  @Output() 'nameChanged' = new EventEmitter<any>();
  // @Output() 'userChanges' = new EventEmitter<any>();
  isBrowser: boolean;
  step: any;
  index: any;
  sourceFields: any;

  constructor(
    public http: HttpClient,
    private spinner: SpinnerService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    public fs: FlowService,
    private cdr: ChangeDetectorRef
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
   }

  ngOnInit(): void {
    if(!this.isBrowser) return;
  }

  async ngOnChanges(changes: any){
    console.log("changes", changes)
  }

  userMadeChanges(e, i) {
    this.fs.userMadeChanges.next(true);
  }

  ngViewAfterInit(){}

}
