import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import * as Moment from 'moment-timezone';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'step-control',
    templateUrl: './step-control.component.html',
    styleUrls: ['./step-control.component.scss'],
    standalone: false
})
export class StepControlComponent implements OnInit {
  @Input() 'step': any;
  @Input() 'sourceFields': any;
  @Output() 'onConfigure' = new EventEmitter<any>();
  @Output() 'stepSourceFields' = new EventEmitter<any>();
  @Output() 'actionChanged' = new EventEmitter<any>();

  stepOptions: any = {
    connectionOptions: [],
    actionFields: [],
    box: {},
    action: {},
  };

  controlTypes: any = [
    {name: "Branch", _id: "branch", icon: "alt_route"}
  ]

  controlTypeMap: any = {
    branch: {icon: "alt_route", name: "Branch"}
  }

  conditionTypes: any[] = [
    {name: "Custom", icon:"text_fields", value: 'custom', description: ''},
    {name: "Step Results", icon:"find_in_page", value: 'step_result', description: ''},
  ]

  conditionTypeLogoMap: any = {
    "custom": {
      logo: "text_fields",
      name: "Custom"
    } ,
    "step_result": {
      logo: "find_in_page",
      name: "Step Results"
    }
  }

  availableOperators: any[] = [
    { name: '> Greater Than', value: '>'},
    { name: '> Smaller Than', value: '<'},
    { name: '= Equal', value: '='},
    { name: '!= Not Equal', value: '!='},
  ]

  selectFilterAttributeForm = new UntypedFormGroup({});
  attributesForFilter: any;

  selectStepResultsForm = new UntypedFormGroup({});
  stepResultList: any;

  constructor(private ngZone: NgZone) {}


  setControl(control){
    console.log("controll set", control);
    this.step.control_type = control._id;
    this.step.box_name = control.name;
    this.step.box_logo_url = control.icon;
  }

  ngOnInit(): void {
    console.log("sourceFields", this.sourceFields)

    //step field is assumed as empty for now
    if(!this.step.step_options)this.step.step_options = {};
    this.step.step_options.step_fields = [];
    this.stepSourceFields.emit([]);

    if(!this.step.control_type) this.step.control_type = "branch";
    if(!this.step.box_name)this.step.box_name = "Branch";
    if(!this.step.box_logo_url)this.step.box_logo_url = "alt_route";
    if(!this.step.control) this.step.control = {
      conditions: [],
      branch: {
        TRUE: {
          steps: []
        },
        FALSE: {
          steps: []
        }
      }
    }
    if(this.step.control.conditions.length == 0) this.addConditionItem();

    if(this.step.control.conditions.length > 0) {
      for(var i = 0; i < this.step.control.conditions.length; i ++){
        this.selectFilterAttributeForm.addControl("form" + i, new UntypedFormControl());
        let valueMap = this.step.control.conditions[i].field;
        this.selectFilterAttributeForm.patchValue({[`form${i}`]: valueMap});

        if(this.step.control.conditions[i].conditionType == "step_result"){
          this.selectStepResultsForm.addControl("form" + i, new UntypedFormControl());
          let valueMap = this.step.control.conditions[i].value;
          this.selectStepResultsForm.patchValue({[`form${i}`]: valueMap});
        }
      }
    }

  };

  trackByFn(index:number, item:any):any{
    return item || index
  }

  addConditionItem(){
    this.step.control.conditions.push({
      field: '',
      operator: '=',
      conditionType: 'custom'
    })
    this.selectFilterAttributeForm.addControl("form" + (this.step.control.conditions.length - 1), new UntypedFormControl())
    this.selectStepResultsForm.addControl("form" + (this.step.control.conditions.length - 1), new UntypedFormControl())
  }

  onSelectConditionType(condition, obj, i){
    condition.conditionType = obj.value;
    if(condition.conditionType == "step_result"){
      this.selectStepResultsForm.addControl("form" + (this.step.control.conditions.length - 1), new UntypedFormControl())
      condition.value = "";
    }
  }

  setFilterList(i){
    this.attributesForFilter = this.selectFilterAttributeForm.get(`form${i}`).valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._filterAttributesFilter(value))
    );
  }

  setStepResultList(i){
    this.stepResultList = this.selectStepResultsForm.get(`form${i}`).valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._filterAttributesFilter(value))
    );
  }

  onInputChange(value: any, condition, i){
    console.log("value", value)
    condition.field.__id = value;
  }

  filterAttrSelected(event: any, condition, i) {
    condition.field = event.option.value;
  }

  stepResultSelected(event: any, condition, i) {
    condition.value = event.option.value;
  }

  removeFilter(i: number){
    this.step.control.conditions.splice(i, 1)
  }

  displayFn(attr) {
    return attr && attr.__id ? attr.__id : ''
  }


  private _filterAttributesFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filterables: any[] = this.sourceFields.filter((attr: any) => attr) //.filterable
    return filterables.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  // onIntentChange(type){
  //   this.intentChanged.emit(type);
  // }
}
