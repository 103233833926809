import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { debounceTime, map } from 'rxjs/operators';
import { FormService } from '../form.service';
const generateName = require("project-name-generator");
const uuid = require('uuid');


@Component({
    selector: 'app-create-form-detail',
    templateUrl: './create-form-detail.component.html',
    styleUrls: ['./create-form-detail.component.scss'],
    standalone: false
})
export class CreateFormDetailComponent implements OnInit {


  timeout: any = null;
  formMap: any = {};
  suggestFormCode: any;
  isNameExists: boolean = false
  spinner:boolean = false;
  @Output() nameChanged: EventEmitter<any> = new EventEmitter();
  @Input() input: any;
  @Input() hideHeader: boolean = false;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    name: new UntypedFormControl('', Validators.required)
  });
  constructor(
    public formService: FormService
  ) {
   }

  async ngOnInit() {
    this.formGroup.valueChanges.subscribe(value => {
      this.nameChanged.emit(value);
    });
    this.suggestFormCode = await this.checkAndGetRandomCode();
    let name = this.input?.name || `My Form ${this.getRandomCode()}` ;
    this.formGroup.get('name').setValue(name);
  }

  async setName(name) {
    this.suggestFormCode = await this.checkAndGetRandomCode();
    this.formGroup.get('name').setValue(name);
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.executeListing(event.target.value);
      }
    }, 1000);
  }

  async executeListing(value: string) {
    console.log("value", value);
    let code = this.constructPagePath(value);
    this.formGroup.get("code").setValue(code);
    await this.checkFormExists(code);
  }

  getRandomCode(){
    let randomCode = uuid.v4().substring(0, 8);
    if(!randomCode){
      randomCode = Date.now()
    }
    return randomCode;
  }


  async checkAndGetRandomCode(code?:any){
    this.spinner = true;
    let randomCode
    if(!code){
      randomCode = uuid.v4().substring(0, 8);
      if(!randomCode){
        randomCode = Date.now()
      }

      randomCode = `${generateName.generate().dashed}-${randomCode}`;
    } else randomCode = code;


    console.log("randomCode", randomCode)
    let result = await this.formService.getFormByCode(randomCode);
    console.log("result", result);
    if(result) return this.checkAndGetRandomCode();
    else {
      this.spinner = false;
      this.isNameExists = false;
      this.suggestFormCode = randomCode;
      this.formGroup.get('code').setValue(this.suggestFormCode);
      this.nameChanged.emit(this.formGroup.value);
      return randomCode;
    }

  }

  async checkFormExists(code){
    this.spinner = true;
    this.isNameExists = false;
    let result = await this.formService.getFormByCode(code);
    console.log("result", result);
    if(result) this.isNameExists = true;
    this.spinner = false;
  }

  constructPagePath(code){
    //reference https://quickref.me/convert-a-string-to-url-slug;
    let slugify = (string) => string.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    return slugify(code);
  }

  async createForm(){
    var result = null;
    console.log("this.formGroup.", this.formGroup.value)
    // this.dialog.close(this.formGroup.value);
  }

}
