import { ThemeService } from 'src/app/shared/services/theme.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, NgZone, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style } from '@angular/animations';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/translate.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service'
import { PageService } from 'src/app/bloom/services/page-service.service';
import { FlowService } from 'src/app/flow/flow.service';
import { MatDialog } from '@angular/material/dialog';
import { WorkpaceDiologComponent } from 'src/app/modules/home/workpace-diolog/workpace-diolog.component';
import { WorkspaceInvitationDiologComponent } from 'src/app/modules/home//workspace-invitation-diolog/workspace-invitation-diolog.component';
import { ListAllWorkspacesDiologComponent } from 'src/app/modules/home//list-all-workspaces-diolog/list-all-workspaces-diolog.component';//added
import { PermissionService } from 'src/app/core/services/permission.service';
import { ResourcePermissionService } from '../services/resource-permission.service';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
    standalone: false
})

export class WorkspaceComponent implements OnInit, AfterViewInit {
  profile: any;
  sharedWorkSpaces: any[] =[];
  spinner: boolean = false;
  // recentFlows: any = [];
  isBrowser: any;
  isWorkspaceLoaded: boolean = true

  private destroy$ = new Subject<void>();

  get connectionService() { return this._connectionService; }
  @Input() parentPage: any;
  @Output() dataRefresh = new EventEmitter();
  @Output() workspaceSelectionChange = new EventEmitter();
  @Output() workspaceLoaded = new EventEmitter();
  constructor(
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private authService: AuthServiceService,
    private router: Router,
    private http: HttpClient,
    private _connectionService: ConnectionService,
    private themeService: ThemeService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    public permission: PermissionService,
    public resourcePermissionService: ResourcePermissionService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    this.spinnerService.show()
    this.translate.use(this.localeService.currentLocale);
    this.profile = this.authService.profile;
    // this.authService.getOrganizationChange().subscribe((res) => {
    //   this.onOrganizationChanged();
    // });
    this.authService.simulated.subscribe((res) => {
      console.log(res, this.connectionService.workSpaces);
      this.customInit()
    });
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  ngOnInit(): void {
    console.log("callingFrom", this.parentPage)
    console.log("environment ", environment)
    console.log("profile", this.authService.profile)
      if(!this.isBrowser) return;
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(async params => {
      if(params.selected_workspace) this.connectionService.preferredWorkspace = params.selected_workspace;
      console.log("params", params);
    })

    console.log("logged in", this.authService.loggedIn, this.connectionService.workSpaces)
    if (!this.authService.loggedIn) {
      this.authService.authCheck()
    } else {
      if(!this.connectionService.selectedWorkSpace || this.router.url.includes('/home')) this.customInit()
    }

    this.authService.authCheckPositive.pipe(takeUntil(this.destroy$)).subscribe(res => {
      console.log("[HOMEPAGE] auth check result", res)
      if (res) {
        window.dispatchEvent(new Event('resize'));
        this.customInit()
        // this.getSharedWorkspaces(this.authService.profile._id);
      } else {
        // redirect to login page
        // this.snackbar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
        //   horizontalPosition: 'center',
        //   verticalPosition: 'top',
        // });
        this.ngZone.run(() => {
          this.router.navigate(['../'])
        })
      }
    })

    this.localeService.localeChangeEvent.pipe(takeUntil(this.destroy$)).subscribe(lang => this.translate.use(lang));
    this.themeService.loadTheme();
    this.themeService
      .getExistingTheme(this.profile._id)
      .then((res: any) => {
        if (res?.data != null) {
          console.log(res);
          this.themeService.settings_Id = res?.data[0]._id;
          let theme = res.data[0].themeSetting.theme;
          this.themeService.setTheme(theme);
          this.themeService.currentLocale = res.data[0].localeSetting;
          this.themeService.textDirection =
            res.data[0].themeSetting.direction;
          if (res.data[0].themeSetting.direction == 'rtl') {
            this.themeService.enableDirMode('rtl');
          }
        }
      });
  }

  async customInit() {
    this.spinner = true;
    // get workspace id
    console.log("[CALLING] get WorkspaceId from workspace component customInit")
    try{
      let workspaceId = await this.connectionService.getWorkSpaceId()
      console.log("workspace id received", workspaceId)
      if(workspaceId) {
        this.isWorkspaceLoaded = true;
        this.workspaceLoaded.emit(true)
      }
    }catch(er){
      console.log("error in workspace load", er)
      this.isWorkspaceLoaded = false
      this.snackbar.open(er.message, 'ok', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
      return;
    }


    try{
      await this.getSharedWorkspaces(this.authService.profile._id);
      this.connectionService.getPreAuthenticatedToken();
    }catch(e){
      console.log("error in get shared workspace", e)
    }
    this.spinner = false
  }

  async openWorkspace(type?: string, workspaceMap?: any) {
    console.log("open workspace", type)
    let dialogRef = this.dialog.open(WorkpaceDiologComponent, {
      width: "500px",
      panelClass: "workspacediolog",
      data: {
        type: type || 'create',
        workspaceMap: workspaceMap || null
      },
    });
    var diologResult = await dialogRef.afterClosed().toPromise();
    console.log("diologResult", diologResult)
    if(diologResult && type == 'create' && diologResult._id) this.workspaceSelected(diologResult);
  }
  async sendWorkspaceInvitation(workspaceMap) {
    let dialogRef = this.dialog.open(WorkspaceInvitationDiologComponent, {
      width: "60%",
      panelClass: "workspacediolog",
      data: {
        workspaceMap: workspaceMap || null
      },
    });
    var diologResult = await dialogRef.afterClosed().toPromise();
    console.log("diologResult", diologResult)
  }

  async listAllWorkspaces(){
    let dialogRef = this.dialog.open(ListAllWorkspacesDiologComponent, {
      width: "20%",
    });
    await dialogRef.afterClosed().subscribe((res)=>{
      console.log(res);
      this.workspaceSelected(res);
    });
  }

  async getSharedWorkspaces(userId?){
    try{
      this.sharedWorkSpaces = await this.resourcePermissionService.getSharedResources(
        this.authService.profile._id ? this.authService.profile._id : userId,
        'workspace'
      );
      console.log("shared workspaces : ",this.sharedWorkSpaces);
    }catch(e){
      console.log("error", e)
      throw e;
    }
  }

  async workspaceSelected(workspace) {
    this.workspaceLoaded.emit(false)
    console.log("workspace selected", workspace)
    this.workspaceSelectionChange.emit(workspace)
    this.isWorkspaceLoaded = true
    console.log("selected workspace : ", workspace)
    if (this.connectionService.selectedWorkSpace != workspace._id) {

      try{
        this.spinner = true;
        this.authService.userProfile.last_workspace = workspace._id;
        let updatedUser;
        console.log("simulation?", this.authService.simulationStatus)
        if(!this.authService.simulationStatus){
          updatedUser = await this.authService.updateUser(this.authService.userProfile);
        }
        console.log("updatedUser", updatedUser)
        this.connectionService.selectedWorkSpace = workspace._id;
        this.connectionService.selectedWorkSpaceMap = workspace;
        this.connectionService.workSpaceId = this.connectionService.selectedWorkSpace;
        await this.customInit(); // emit change in workspace after this to parent.
        this.dataRefresh.emit();
        this.spinner = false;
        this.workspaceLoaded.emit(true)
        this.isWorkspaceLoaded = true
      }catch(e){
        console.log("error", e)
        this.isWorkspaceLoaded = false
      }
    }
  }

  viewMembers(id) {
    this.router.navigate(['workspace', 'members', id])
  }

  // onOrganizationChanged() {
  //   // this.spinner = false;
  // }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete(); // Ensures cleanup
  }
}
