import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublishService } from '../services/publish.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import confetti from 'canvas-confetti';


@Component({
    selector: 'list-publishing-dialog',
    templateUrl: './list-publishing-dialog.component.html',
    styleUrls: ['./list-publishing-dialog.component.scss'],
    standalone: false
})
export class ListPublishingDialogComponent implements OnInit {
  @ViewChild('errorDiv') errorDiv: ElementRef;
  title: string = "";
  hideTitle: boolean = false
  list: any;
  action: any;
  connection: any;
  errorResponse: any;
  spinner: boolean = false;
  inputParams:any
  enableExecute: boolean = true;
  enablePublish: boolean = false;
  attributesToFetch: any[] = []
  filter: any;
  sort: any;
  limit: any = 100;
  boxObject: string = ""
  // isPublished: boolean = false;
  publishResponse: any;
  fullPublishedUrl: string = ""
  currentBaseUrl: string = ""
  listName: string = ""
  product: string = ""

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any, 
    public dialogRef: MatDialogRef<ListPublishingDialogComponent>,
    private publishService: PublishService,
    private _snackBar: MatSnackBar,
    private connectionService: ConnectionService,
  ) {
    console.log("[ListPublishingDialogModule] dialog data", dialogData);
    var data = dialogData;
    this.action = data.action
    this.connection = data.connection;
    this.boxObject = data.action.__id.split("/")[0]
    this.listName = data.title || (data.action.__id.split("/")[0] + " list");
    this.hideTitle = data.hideTitle || false
    this.product = data.product || ""
  }

  ngOnInit(): void {
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
  }

  inputRecevied(event){
    console.log("[INPUT RECEIVED] event:", event)
    // this.inputParams = JSON.parse(JSON.stringify(event.query));
    this.attributesToFetch = event.attributes?.length ? event.attributes : this.attributesToFetch;
    console.log("attributeToFetch:", this.attributesToFetch);

    let enablePublish = false;
    if(this.attributesToFetch && this.attributesToFetch.length){
      for (let attribute of this.attributesToFetch) {
        if (attribute.isColumnSelected) {
          enablePublish = true;
          break; 
        }
      }
    }
    this.enablePublish = enablePublish; 

    this.filter = event.filter
    this.sort = event.sort
    this.limit = event.limit
  }

  attributeRecevied(event){
    this.attributesToFetch = event
    console.log("attributes to fetch", this.attributesToFetch)
  }

  triggerExecute(event){
    console.log("CHECK TRIGGER VALUE", event);
    this.enableExecute = event;
  }

  copySuccessful(event){
    console.log("URL copied to clipboard")
    this._snackBar.open("URL copied to clipboard", "", {duration: 1000 })
  }

  navigateToNewPublished(){
    window.open(this.fullPublishedUrl, '_blank')
  }

  checkOldPublished(){
    console.log("list all published lists for this connection", this.connection)
  }

  async publish() {
    console.log("node to be published", this.action.__id.split("/")[0])
    let data = {
      boxId: this.connection.box_id,
      boxObjectId: this.action.__id.split("/")[0],
      connection: this.connection,
      attributes: this.attributesToFetch,
      filter: this.filter,
      sort: this.sort,
      hideTitle: this.hideTitle,
      product: this.product
    }
    let listPanelMeta = this.publishService.generateListPanelMeta(data)
    this.spinner = true
    try{
      let res = await this.publishService.publishList(listPanelMeta, this.listName, this.product);

      console.log("list is published", res)

      // update connection
      if(!this.connection.published_views){
        this.connection.published_views = {
          object_ids: []
        }
      }
      if(!this.connection.published_views[data.boxObjectId]){
        this.connection.published_views['object_ids'].push(data.boxObjectId)
        this.connection.published_views[data.boxObjectId] = {}
      }
      if(!this.connection.published_views[data.boxObjectId]["published_lists"]){
        this.connection.published_views[data.boxObjectId]["published_lists"] = {
          list_ids: []
        }
      }
      if(res.data._id){
        this.connection.published_views[data.boxObjectId]["published_lists"].list_ids.push(res.data._id)
        this.connection.published_views[data.boxObjectId]["published_lists"][res.data._id] = {
          id: res.data._id, 
          name: res.data.name,
          url: res.data.publishedUrl,
          logo_url: res.data.logoUrl
        }
      }
      console.log("list attached to connection", this.connection)
      
      let workspaceId = await this.connectionService.getWorkSpaceId()
      let updateResponse = await this.connectionService.updateConnection(this.connection, workspaceId)
      console.log("connection updated", updateResponse)

      this.publishResponse = res
      this.fullPublishedUrl = this.publishResponse?.data?.publishedUrl ? this.currentBaseUrl + this.publishResponse?.data?.publishedUrl : "NULL"
      // this.isPublished = true
      console.log("list is published", this.publishResponse.data)
      this.launchConfetti();
    }catch(e){
      console.log("error occurred in publishing", e)
      this.errorResponse = e;
      this.spinner = false;

      // Scroll to the error section
      // Use setTimeout to wait for the DOM to update
      // 0ms delay ensures it runs in the next cycle
      setTimeout(() => {
        if (this.errorDiv) {
          this.errorDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0); 
      throw e;
    }
    this.spinner = false;
  }

  // Scroll to the top of the dialog content
  private scrollToTop() {
    const dialogContainer = document.querySelector('.mat-dialog-container');
    if (dialogContainer) {
      dialogContainer.scrollTop = 0;  // Scroll to the top of the dialog
    }
  }

  launchConfetti(): void {
    confetti({
      origin: { y: 0.5 },
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
      particleCount: 350,
      zIndex: 1000, // Ensure this value is higher than your dialog screen
    });
  }

  closeDialog() {
    var isListPublished = false;
    if(this.publishResponse) {
      isListPublished = true;
    }
    this.dialogRef.close(isListPublished);
  }

}

