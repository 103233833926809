import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { WidgetAction } from '../../models/Action/WidgetAction';
import { PanelComponent } from '../../page/panel/panel.component';
import { BoxService } from '../../services/box-service.service';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { DetailsPanelDialogComponent } from './details-panel-dialog/details-panel-dialog.component';
import { WidgetUtilityService } from '../../services/widget-utility.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AutomationService } from '../../services/automation.service';
import { WidgetManager } from '../../models/WidgetManager';
import { WidgetService } from '../../services/widget-service.service';
import { UrlParamsService } from 'src/app/shared/services/URLParamService';
import { filter } from 'rxjs';

interface Page {
  number: number,
  size: number,
  total: number,
  nextPageToken?: string,
  previousPageToken?: string
}

@Component({
    selector: 'app-details-panel',
    templateUrl: './details-panel.component.html',
    styleUrls: ['./details-panel.component.scss'],
    standalone: false
})
export class DetailsPanelComponent extends PanelComponent implements OnInit, OnChanges, OnDestroy {

  @Input() panelMeta;
  @Input() builderMode;

  @Output() manageSpinner = new EventEmitter<any>();

  page: Page;
  pageMeta: any;

  runSpinner: boolean = false;
  defaultPageSize: number = 1;
  defaultPageNumber: number = 1;
  currentPageNumber: number = 1;

  // dataSources: any[] = [
  //   // { source: 'searchpanelresult', precedence: 3},
  //   { source: 'navigation', precedence: 2},
  //   { source: 'selfload', precedence: 1}
  // ]
  // dataLoadLockOwner: string = ''    // navigation | searchpanelresult | selfload

  dataLoadError: any
  rawBoxData: any;
  dataLength: number;
  widgets: any[] = []
  submitButtonMeta: any
  currentPageCode: string;
  detailsReady: boolean = false;
  navigationDataSubscription: any
  currentPageCodeSubscription: any
  pageMetaSubscription: any
  routeDataSubscription: any
  navigationEndSub: any

  // navigationDataReceived: boolean = false
  margin: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isNavigationFilterReceived: any;
  currentFilters: any;
  isOnlyStaticFilter: boolean = false

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private boxService: BoxService,
    private metaService: MetaService,
    private pageService: PageService,
    private widgetAction: WidgetAction,
    private themeService: ThemeService,
    private widgetUtilityService: WidgetUtilityService,
    public sps: SpinnerService,
    public route: ActivatedRoute,
    public automationService: AutomationService,
    private myWidgetService: WidgetService,
    private urlService: UrlParamsService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId?: Object,
  ) {
    super(automationService);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    console.log("[DEATILS PANEL] onInit", this.panelMeta)
    if(this.panelMeta?.margin){
      this.margin = this.panelMeta.margin
    }
    if(!this.builderMode) this.clearWidgetAttributesValues();

    if(this.panelMeta.filter?.filterEnabled && this.panelMeta.filter?.filterItems?.length){
      if(!this.panelMeta.filter.filterItems.find(f => f.filterType != 'static_filter')) { // all are static filters
        this.loadBoxData(this.panelMeta.filter?.filterItems || [])
      }
      // this.panelMeta.filter.filterItems.forEach(f => {
      //   if(f.filterType != 'static_filter') this.isOnlyStaticFilter = false
      // })
      // if(this.isOnlyStaticFilter) this.loadBoxData(this.panelMeta.filter?.filterItems || [])
    }


    // this.sps.show();
    // this.generatePrefillActionConfig({})

    this.currentPageCodeSubscription = this.pageService.currentPageCode.subscribe(code => {
      this.currentPageCode = code
    })

    this.pageMetaSubscription = this.metaService.pageMeta.subscribe(meta => {
      if(!meta) return
      if(this.currentPageCode == meta.code && JSON.stringify(this.pageMeta || {}) != JSON.stringify(meta)) {
        this.pageMeta = meta;
      }
    })


    // this.navigationDataSubscription = this.pageService.$navigationData.subscribe(async (data: any) => {
    //   console.log("navigation data", data);
    //   if(!data || Object.keys(data).length == 0) return;
    //   if(Object.keys(data).length > 0) this.navigationDataReceived = true;
    //   if(data["destination-page-code"] !== this.currentPageCode) return
    //   let navFilters = data?.filters ? data.filters : data.dataBindSetup.filters;
    //   if(!navFilters || !navFilters.length) return;
    //   this.currentFilters = this.navigationFilterReplacer(navFilters);
    //   await this.loadBoxData(this.currentFilters)
    // })

    /**
     * triggers when navigating to this page (containing details panel) from another page
     */
    this.routeDataSubscription = this.route.queryParams.subscribe(async params => {
      if(this.metaService.previewMode) return;
      console.log("query params received in details panel: ", params);
      if(!params || Object.keys(params).length == 0){
        params = this.urlService.parseUrlToObj();
      }
      this.handleNavFilter(params);
    })

    //for preview mode take the param values from service and using
    if(this.metaService.previewMode && this.metaService.bloomPreviewMap[this.currentPageCode]) {
      let params = this.urlService.parseUrlToObj(this.metaService.bloomPreviewMap[this.currentPageCode].pathParams);
      this.handleNavFilter(params);
    }

    /**
     * triggers when URL path params changed while in same page
     */
    this.navigationEndSub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(async () => {
      const fullUrl = this.router.url;
      console.log('Full URL:', fullUrl);
      let params = this.urlService.parseUrlToObj();
      this.handleNavFilter(params);
    });
  }



  async handleNavFilter(params){
    let navFilters = [];
    for (const key in params) {
      if(key != "v") {
        navFilters.push({
          attributeId: decodeURIComponent(key),
          dataType: "string",
          value: decodeURIComponent(params[key])
        })
      }
    }
    console.log("nav filters initialized", navFilters)
    if(navFilters.length > 0) {
      navFilters = this.navigationFilterReplacer(navFilters);
      await this.loadBoxData(navFilters);
    }
  }

  navigationFilterReplacer(navFilters: any){
    let filters: any[] = JSON.parse(JSON.stringify(this.panelMeta?.filter?.filterItems || []))
    if(!filters.length){    // automatic filter
      navFilters.forEach(f => f['attribute'] = f.parameter || f.attributeId)
      return navFilters
    }
    console.log("filters", filters)
    for (let i = 0; i < filters.length; i++) {
      if(filters[i].filterType !== 'navigation_filter') continue
      let navFilterObj = navFilters.find(navFilter => (navFilter.parameter || navFilter.attributeId) == filters[i].value)
      console.log(navFilterObj)
      if(navFilterObj?.value){
        if(!filters[i].attribute) filters[i].attribute = navFilters[i].parameter || navFilters[i].attributeId;
        filters[i].value = navFilterObj.value;
        filters[i].dataType = navFilterObj.dataType //TODO change
      } else continue
    }
    return filters;
  }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log("changes", changes)
    // if(changes.panelMeta && changes.panelMeta.currentValue && this.builderMode){
    //   let staticFilters: any[] = changes.panelMeta.currentValue?.filter?.filterItems?.filter(f => f.filterType == 'static_filter')
    //     console.log("static filter", staticFilters)
    //     await this.loadBoxData(staticFilters)
    // }
  }

  ngOnDestroy(): void {
    // this.navigationDataSubscription?.unsubscribe()
    this.currentPageCodeSubscription?.unsubscribe()
    this.pageMetaSubscription?.unsubscribe()
    this.routeDataSubscription?.unsubscribe()
    this.navigationEndSub?.unsubscribe()
    this.pageService.clearPanelFromPageModel(this.panelMeta.id)
  }


  openSettings(){
    let dialogRef = this.dialog.open(DetailsPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        firstHit: false,
        panelMeta: this.panelMeta,

        pageMeta: this.pageMeta
      },
    })

    dialogRef.afterClosed().subscribe(data=>{
      if(!data){
        console.log("details panel configuration dialog closed unexpectedly")
        return
      }
      console.log("details panel config dialog resolved", data)
      this.runSpinner = true
      this.panelMeta = data

      this.automationService.removeBlankRows(this.panelMeta.layoutMap);
    
      this.newPanelMeta.emit(this.panelMeta)

      // scan through pageMeta.panels and find panel matching this.panelMeta.id
      // let i = this.pageMeta.panels.findIndex(panel => panel.id == this.panelMeta.id)
      // if(i > -1){
      //   this.pageMeta.panels[i] = JSON.parse(JSON.stringify(this.panelMeta))
      // }

      // console.log("new pageMeta", this.pageMeta)
      // this.metaService.pageMeta.next(this.pageMeta)
      // this.metaService.userMadeChanges.next(true);
    })
  }

  async loadBoxData(filters: any[] = []){
    // this.runSpinner = true
    if(this.isBrowser) this.manageSpinner.emit({
      panelId: this.panelMeta.id,
      isSpinning: true
    })
    console.log("panelMeta", this.panelMeta)
    console.log("going to load data", filters)
    this.sps.show();

    let detailsAttributeIds = []
    this.panelMeta.detailsAttributes.forEach(attribute => {
      detailsAttributeIds.push(attribute.__id)
    });
    // console.log("details attribute Ids", detailsAttributeIds)

    let dataBindConfig = {
      boxId: this.panelMeta.boxId,
      connectionId: this.panelMeta.connectionId,
      boxObject: this.panelMeta.boxObjectId,
      pageNumber: this.defaultPageNumber,
      baseMap: this.panelMeta.baseMap || {},
      boxConfigToken: this.panelMeta.boxConfigToken,
      pageSize: this.panelMeta.defaultListSize || this.defaultPageSize,
      options: this.panelMeta.getFnOptions,
      attributes: detailsAttributeIds,
      filters: filters
    }

    console.log("details panel dataBindConfig", dataBindConfig)

    //fetch data
    let mode: string;
    if(!this.builderMode){
      mode = 'user_api_key'
    }
    let res: any
    try{
      res = await this.boxService.getAny(dataBindConfig, mode, { clearCache: true })
      console.log("[DETAILS PANEL] boxService getAny result:", res)
      this.page = res.page
      this.dataLength = this.page.total || 1000

      this.rawBoxData = res.data[0] || {}
      this.generatePrefillActionConfig(this.rawBoxData)

      this.updateDataModel()

    }catch(err){
      this.dataLoadError = ''
      console.error("data bind config exec failed for details panel", err)
      this.dataLoadError = `${err.status}  ${err.name}`
    } finally{
      this.runSpinner = false
      this.sps.hide();
      this.manageSpinner.emit({
        panelId: this.panelMeta.id,
        isSpinning: false
      })
    }
  }

  /**
   * generates widget actions for populating details value fields with existing values
   */
   generatePrefillActionConfig(data){
    console.log("data to inject", data, this.panelMeta)
    let actions: any[] = []
    let actionWidgets: any[] = []  // corresponding widget at i'th index for actions[i]
    let widgets = this.automationService.getWidgetsFromPanel(this.panelMeta)
    console.log("detail widgets", widgets)
    this.panelMeta.detailsAttributes.forEach((attr: any, i: number) => {
      // console.log("attr", attr)

      if(attr.isDrillDown){
        for (let j = 0; j < attr.nestedProperties.length; j++) {
          const nestedProp = attr.nestedProperties[j];
          if(typeof nestedProp?.path != 'string' || !nestedProp?.path?.length || !nestedProp?.widgetType) continue;
          let attrStamp = attr.__id + '.' + nestedProp.path   // same strategy used while creating attr stamp in widget id: <attr.__id>.<nestedPath>
          let associatedWidget = widgets.find(wid => wid.id.split('-')[1] == attrStamp)

          let temp = data[attr.__id]
          let resultValue;
          // if(typeof temp == 'object' && !Array.isArray(temp)){
          if(typeof temp == 'object'){
            resultValue = this.widgetUtilityService.getDeepObjectValue(temp, nestedProp.path)
            console.log("nested value retrieved")
          }else{
            console.log("nested value does not exist")
          }
          console.log("nested value is:", resultValue)

          let action = this.myWidgetService.createWidgetAction(nestedProp.widgetType, resultValue, associatedWidget.id, this.panelMeta.id)
          actions.push(action)
          actionWidgets.push(associatedWidget)
        }
      }else{
        // find the index of widget associated with this attribute from attribute stamp in the widget id
        let j = widgets.findIndex(wid => wid.id?.split('-')[1] == attr.__id)

        let resultValue = data[attr.__id]
        let type = attr.widgetType

        let action = this.myWidgetService.createWidgetAction(type, resultValue, widgets[j]?.id, this.panelMeta.id)
        actions.push(action)
        actionWidgets.push(widgets[j])
      }

    })
    console.log("actions", actions)
    actions.forEach((action, i) => this.widgetAction.doAction(action, {type: 'click', widgetMap: actionWidgets[i]}))
    console.log("widgets injected", this.automationService.getWidgetsFromPanel(this.panelMeta))
  }



  updateDataModel(){
    let attributeValues: any[] = []
    this.panelMeta.detailsAttributes.forEach(attr => {
      let attrObj = {
        __id: attr.__id,
        dataType: attr.dataType,
        value: this.rawBoxData[attr.__id]
      }
      attributeValues.push(attrObj)
    });
    this.pageService.injectDataModel(this.panelMeta.connectionId, this.panelMeta.boxObjectId, attributeValues)
  }

  //clearing all ${} notations in published mode while loading as it looks irelevant on load
  clearWidgetAttributesValues() {
    // Extracts an array of __id values from detailsAttributes, if available
    let storedAttributeIds = this.panelMeta.detailsAttributes?.map(obj => obj.__id);
    // Iterate over the column IDs in layoutMap
    this.panelMeta?.layoutMap?.list?.forEach(colId => {
        // Iterate over the row IDs inside each column
        this.panelMeta?.layoutMap?.[colId]?.['list'].forEach(rowId => {
            // Iterate over each widget inside the row
            this.panelMeta.layoutMap[colId]?.[rowId]?.elements.forEach(widget => {
                // Skip clearing if the widget is a submit button OR its type is "button"
                // AND it's not part of attributeWidgetIds list
                let splitAttributeWidgetId = widget?.id?.split("-")?.pop() || null;
                if ((widget?.type !== 'button') && storedAttributeIds.includes(splitAttributeWidgetId)) {
                  // Retrieve a new widget instance based on its type, id, and name
                  let temp = WidgetManager.getWidget(widget.type, widget.id, widget.name);
                  // Copy properties from the existing widget to the new instance
                  Object.keys(widget).forEach(prop => temp[prop] = widget[prop]);
                  temp?.setValue(''); // Clear the widget value
                  widget = temp;
                };
            });
        });
    });
  }
}
