import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy, Injector } from '@angular/core';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WIDGET_OPTIONS, WidgetUtilityService } from '../../../services/widget-utility.service'
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';

@Component({
    selector: 'app-base-widget',
    templateUrl: './base-widget.component.html',
    styleUrls: ['./base-widget.component.css'],
    standalone: false
})
export class BaseWidgetComponent implements OnInit, OnChanges, OnDestroy {

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄► VARIABLE DECLARATION  ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  @Input() widgetMeta: any;
  @Input() pageMeta: any;
  @Input() builderMode: any
  @Input() panelId: any;
  @Input() selectedWidgetId: any;
  @Input() widgetOptions: WIDGET_OPTIONS;
  @Input() contextActions: any
  @Input() isDisabled: boolean

  @Output() raiseContextMenuActions = new EventEmitter<any>();
  @Output() newWidgetMeta = new EventEmitter<any>();
  @Output() widgetDeletion = new EventEmitter<any>();
  @Output() widgetSelection = new EventEmitter<any>();
  @Output() widgetHover = new EventEmitter<any>();
  @Output() userInputReceived: EventEmitter<any> = new EventEmitter();
  @Output() onExecuteAction = new EventEmitter<any>();

  hoveredNow: boolean = false;
  styles: any;
  setValueNotifierSub: any

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►  CONSTRUCTOR  ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄
  constructor(
    public metaService?: MetaService,
    public localPageService?: PageService,
    public resourcePermissionService?: ResourcePermissionService,
    public wmtService?: WidgetMetaTransformationService,
    public injector?: Injector
    ) {
    console.log("base widget constructor")
    if(!this.widgetOptions) this.widgetOptions = {}
    console.log("wmt service", wmtService)
    // console.log("widget options receuved", this.widgetOptions)
  }

  // Method to get ApplicationAction service only when needed
  getApplicationAction(): ActionManager {
    return this.injector.get(ActionManager);
  }

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►   ON INIT   ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  ngOnInit(): void {
    console.log("[BASE WIDGET] onInit: widgetMeta", this.widgetMeta)
    // this.decompressMetaAndAssign(this.widgetMeta)
    this.generateStyles()
  }

  ngOnDestroy(): void {
    this.setValueNotifierSub?.unsubscribe()
  }

  /**
   * given a widget meta in compressed form, it is decompressed and assigned into this.widgetMeta
   * @param meta: maybe in compressed form
   * @returns 
   */
  decompressMetaAndAssign (meta) {
    // console.log("decompress and assign", JSON.parse(JSON.stringify(meta || "")))
    let decompressedMeta = this.decompressMeta(meta)
    this.widgetMeta = decompressedMeta
    return this.widgetMeta
  }

  decompressMeta (meta) {
    if (!meta?.config) {
      let temp = this.wmtService.decompressWidgetMeta(meta)
      return temp
    }
    return meta
  }

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►   ON CHANGES   ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  ngOnChanges(changes: SimpleChanges): void {
    // console.log("[BASE WIDGET] onChanges: changes", changes)
    if(changes.widgetMeta && changes.widgetMeta.currentValue){
      this.decompressMetaAndAssign(changes.widgetMeta.currentValue)
    }
  }

  checkAccess(){
    // return {
    //   show: true,
    //   hide: false
    // }

    // console.log("widgetMeta", this.widgetMeta)
    // console.log("metaService publishedMode", this.metaService.publishedMode);
    if(!this.metaService?.publishedMode || !this.widgetMeta?.securityConfig?.securities || this.widgetMeta.securityConfig.securities.length == 0) {
      return {
        show: true,
        hide: false
      }
    }
    let access = this.resourcePermissionService.checkAccess(this.widgetMeta.securityConfig);
    // console.log("access", access);
    return access;
  }

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►  METHODS  ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  generateStyles() {
    // console.log("generate styles hit----- widgetMeta", this.widgetMeta)
    // console.log("textFormat", this.widgetMeta.textFormat?.props)
    this.styles = {}
    if (this.widgetMeta?.textFormat?.props?.length) {
      if(this.widgetMeta.textFormat.props.find(p => p == 'bold')) this.styles.fontWeight = this.widgetMeta.textFormat.bold?.value ? 'bold' : 'normal'
      if(this.widgetMeta.textFormat.props.find(p => p == 'underline')) this.styles.textDecoration = this.widgetMeta.textFormat.underline?.value ? 'underline' : 'none'
      if(this.widgetMeta.textFormat.props.find(p => p == 'italic')) this.styles.fontStyle = this.widgetMeta.textFormat.italic?.value ? 'italic' : 'normal'
      if(this.widgetMeta.textFormat.props.find(p => p == 'fontSize')) this.styles.fontSize = this.widgetMeta.textFormat.fontSize?.value + 'px'
      if(this.widgetMeta.textFormat.props.find(p => p == 'fontFamily')) this.styles.fontFamily = this.widgetMeta.textFormat.fontFamily?.value
      if(this.widgetMeta.textFormat.props.find(p => p == 'color')) this.styles.color = this.widgetMeta.textFormat.color?.value
      // this.styles.color = 'black'
    }
    //set button color in styles if color picker used.
    if (this.widgetMeta?.config?.buttonColorType?.customValue?.startsWith('#')) {
      this.styles.backgroundColor = this.widgetMeta.config.buttonColorType.customValue;
    }
    // console.log("styles generated", this.styles)
  }

  async onHover(event: any) {
    let res = await this.executeAction(event)
    this.onExecuteAction.emit(res);
  }

  async executeAction(e: any, value?: any) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    if (!this.builderMode){
      return await this.getApplicationAction().executeActions(this.widgetMeta.actionConfig.actions, e, {value: value?.value});
    }
  }

  public async widgetMouseenter(e?: any) {
    if (e) {
      await this.onHover(e);
    }
    // console.log("inside widgetMouseEnter", e)
    if (!this.builderMode || this.localPageService.isDragging() || this.localPageService.panelReorderDragging) {
      return
    }
    this.hoveredNow = true
    // console.log("will emit widget hover")
    this.widgetHover.emit(this.widgetMeta.id)
    // console.log("mouse entered in", this.widgetMeta.type, this.widgetMeta.id)
  }

  public async widgetMouseleave(e?: any) {
    if (e) {
      await this.onHover(e);
    }
    if (!this.builderMode) {
      return
    }
    // console.log("will emit widget unhover")
    this.widgetHover.emit(-1)
    this.hoveredNow = false;
    // console.log("mouse left from", this.widgetMeta.type, this.widgetMeta.id)
  }

}
  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
