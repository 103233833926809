import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';


// interface attributeOptions{
//   id: string,
//   name: string,
//   defaultValue?: string,
//   mandatory?: boolean,
//   dataType?: string,
//   hidden?: boolean
// }

@Component({
    selector: 'app-search-panel-dialog',
    templateUrl: './search-panel-dialog.component.html',
    styleUrls: ['./search-panel-dialog.component.css'],
    standalone: false
})
export class SearchPanelDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  pageMeta: any;;
  panelMeta: any;


  sortAttributeControl = new UntypedFormControl();
  searchAttributeControl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  removable = true;

  filteredSearchAttributes: any
  filteredSortAttributes: any

  // routeParameterSubscription: any
  pageCodeSubscription: any
  pageMetaSubscription: any

  currentPageCode: string;
  selectedBoxName: any;
  selectedConnectionId: any;
  selectedBoxId: any;
  isBoxSelected: boolean = false;
  isBoxObjectSelected: boolean = false;
  boxConfigToken: any;
  selectedBoxObjectId: any;
  boxObjectAttributes: any[];
  isAttributeReady: boolean = false;
  searchAttributes: any = [];
  unselectedForSearch: any = []
  sortAttributes: any = [];
  unselectedForSort: any = []
  isReadyToSave: boolean = false;
  availableListPanels: any = []
  outputListPanel: any = ''
  searchButtonText: string = 'Apply search'

  searchAttributeInfo = "Users will be able to search/filter over the chosen fields(attributes) in the connected app. For instance, if you chose 'name' here, user will be able to search/filter by name"
  sortAttributeInfo = "Users can sort the search result by these selected fields(attributes) before it is shown to the user. For instance, if you select 'age' here, we will show sorted search result based on age"

  isBoxConfigError: boolean = false;
  isBoxObjectConfigError: boolean = false;
  isAttributeError: boolean = false;
  boxConfigError: any = ''
  boxObjectConfigError: any = '';
  attributeError: any = '';
  attributeSpinner: boolean = false;
  gettingObjFunSpinner: boolean = false

  firstHit: boolean = false;
  canGetBoxObjects: boolean = false;
  boxFunctions: any[]

  isOptionsToCollect: boolean = false;
  attributeOptions: any[] = []

  terminationError: boolean = false;
  terminationErrorMessage: string;

  listCreationSpinner: boolean = false;
  isListCreationError: boolean = false;
  listCreationError: any;

  saveDisabled: boolean = false;
  // filteredListPanels: any;
  outputListPanelName: string = '--not selected--';
  selectOutputListControl = new UntypedFormControl();
  filteredLists: any = [];
  isAppSelected: boolean = false

  configChanged: boolean = false;   // this flag tells whether the existing configuration is changed (applies when !firstHit)
  refreshObjects: boolean = false
  disableBoxObjectInput: boolean = false
  isGetOptionsToCollect: boolean = false;

  attributeDisplayName: string;

  form: UntypedFormGroup;
  listPanelControl = new UntypedFormControl(this.outputListPanelName);
  // to render the list attributes configuration table
  // columns = [
    //   {
  //     columnDef: 'serial',
  //     header: 'Sl.',
  //     cell: (element: any) => $
  //   },
  // ]

  getAttrFn: any
  getFn: any
  availableInputParams: any = {
    options: {}
  }
  collectAttrOptions: boolean = false
  getFnOptions: any[] = []
  collectGetOptions: boolean = false
  objectFunctions: any[] = []

  hoveredIndex: number = -1
  widgetMap = {
    list: ['input', 'select', 'autocomplete', 'checkbox', 'numberinput', 'datetime', 'date', 'time', 'chips'],
    'input': {
      name: "Text input",
      icon: "edit_square"
    },
    'select': {
      name: "Dropdown",
      icon: "arrow_drop_down"
    },
    'autocomplete': {
      name: "Autocomplete",
      icon: "manage_search"
    },
    checkbox: { name: 'Check Box', icon: 'check_box' },
    numberinput: { name: 'Number input', icon: '123' },
    datetime: { name: 'Date Time', icon: 'event' },
    date: { name: 'Date', icon: 'calendar_today' },
    time: { name: 'Time', icon: 'more_time' },
    chips: { name: 'Chips', icon: 'edit_attributes', defaultOperator: "%" },
  }

  @ViewChild('closeButton') closeButton;
  @ViewChild('searchAttributeInput') searchAttributeInput: ElementRef<HTMLInputElement>;
  @ViewChild('sortAttributeInput') sortAttributeInput: ElementRef<HTMLInputElement>;
  @ViewChild('listPanelInput') listPanelInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<SearchPanelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private widgetService: WidgetService,
    private boxService: BoxService,
    private pageService: PageService,
    private spinnerService: SpinnerService,
    private WidgetUtilityService: WidgetUtilityService,
    private automationService: AutomationService
  ) {
    this.panelMeta = JSON.parse(JSON.stringify(data.panelMeta))
    this.pageMeta = data.pageMeta

    console.log("page meta initialized", this.pageMeta)

    this.form = new UntypedFormGroup({
      listPanel: this.listPanelControl,
    });

    //reset the data variable, so that if dialog closes unexpectedly, it returns the reset value of data
    data = false
  }

  ngOnInit(): void {

    //check if this is a second hit from existing search panel, in that case, input will contain valid panel meta
    if (this.data.firstHit) {
      this.firstHit = true
      this.saveDisabled = true
      console.log("first hit! building new panel...")
    } else {
      this.saveDisabled = true
      this.firstHit = false;
      console.log("panel meta received in search panel onInit:", this.panelMeta)
      this.searchAttributes = this.panelMeta.searchAttributes
      this.searchAttributes.forEach(a => {
        if(!this.widgetMap.list.includes(a['widgetType'])){
          a['widgetType'] = 'input'  // for backward compatibility
        }
      })

      this.sortAttributes = this.panelMeta.sortAttributes

      this.selectedBoxId = this.panelMeta.boxId
      this.selectedBoxName = this.panelMeta.boxName
      this.selectedConnectionId = this.panelMeta.connectionId
      this.selectedBoxObjectId = this.panelMeta.boxObjectId
      this.boxConfigToken = this.panelMeta.boxConfigToken
      // this.outputListPanelName = this.panelMeta.outputListPanelName
      this.isAppSelected = true
      // set selected value in html

      if(this.panelMeta.attributeOptions && this.panelMeta.attributeOptions.length > 0){
        this.attributeOptions = this.panelMeta.attributeOptions
        this.isOptionsToCollect = true
      }

      if(this.panelMeta.getFnOptions && this.panelMeta.getFnOptions.length > 0){
        this.getFnOptions = this.panelMeta.getFnOptions
        this.isGetOptionsToCollect = true
      }

      this.isBoxSelected = true
      this.isBoxObjectSelected = true
      // this.isAttributeReady = true
      console.log("attributeReady", this.isAttributeReady)
      this.getAttributes()
    }

    this.spinnerService.show()

    this.boxService.setBoxObjectDisplayNames(this.selectedBoxId);
    this.attributeDisplayName = this.boxService.attributeDisplayName;

    //set default values in panel meta
    console.log("title", this.panelMeta.searchPanelTitle);
    console.log("title", this.panelMeta.searchButtonText)
    this.panelMeta.searchPanelTitle = this.panelMeta.searchPanelTitle || 'Search'
    this.panelMeta.searchButtonText = this.panelMeta.searchButtonText || 'Apply search'

    // this.routeParameterSubscription = this.route.params.subscribe((routeData) => {

    // })

    // this.pageCodeSubscription = this.pageService.currentPageCode.subscribe(code => {
    //   this.currentPageCode = code
    //   console.log("current page code", code)
    // })

    // //SUBSCRIBE TO PAGE META
    // this.pageMetaSubscription = this.MetaService.pageMeta.subscribe(meta => {
    //   console.log("searchPanelDialog onInit, [PAGE META]", meta)
    //   if(this.currentPageCode == meta.code){
    //     console.log("page code matched", meta)
    //     this.pageMeta = meta;

    //   }
    // })
    this.getListPanels()

    //FILTER FOR SEARCH ATTRIBUTES AUTOCOMPLETE
    this.filteredSearchAttributes = this.searchAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        //the whole attribute object comes here as value
        return typeof value === 'string' ? value : value.name
      }),
      map(value => this._searchAttributeFilter(value))
    );

    //FILTER FOR SORT ATTRIBUTES AUTOCOMPLETE
    this.filteredSortAttributes = this.sortAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        //the whole attribute object comes here as value
        return typeof value === 'string' ? value : value.name
      }),
      map(value => this._sortAttributeFilter(value))
    );

    //FILTERS FOR BOX AUTOCOMPLETE
    this.filteredLists = this.selectOutputListControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : (value && value.name ? value.name : ''))),
      map(value => this._listPanelFilter(value))
    );
    this.spinnerService.hide()
  }

  ngAfterViewInit(): void {
    // console.log("in view init, native element", this.closeButton._elementRef.nativeElement)
    this.outputListPanelName = this.panelMeta.outputListPanelName
    console.log("outputListPanelName initialized", this.outputListPanelName)
  }

  ngOnDestroy(): void {
      // this.pageCodeSubscription.unsubscribe()
      // this.pageMetaSubscription.unsubscribe()
  }

  //-----------------------------------------FUNCTIONS--------------------------------------

  //filter for box names autocomplete from the connections
  private _listPanelFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.availableListPanels.filter(panel => panel.name.toLowerCase().includes(filterValue));
  }

  displayFn(listPanel) {
    return listPanel && listPanel.name ? listPanel.name : ''
  }

  private _searchAttributeFilter(value: any): string[] {
    if (!this.unselectedForSearch) return []
    const filterValue = value.toLowerCase();
    return this.unselectedForSearch.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _sortAttributeFilter(value: any): string[] {
    if (!this.unselectedForSort) return []
    const filterValue = value.toLowerCase();
    return this.unselectedForSort.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  widgetMenuClosed(event: any){
    console.log("widget menu closed", event)
  }

  widgetTypeSelected(attrIndex: any, widgetType: string){
    this.searchAttributes[attrIndex]['widgetType'] = widgetType;
    //setting default operator as it will be used on search panel filter based on widget types
    this.searchAttributes[attrIndex]['defaultOperator'] = this.widgetMap[widgetType]?.defaultOperator || "=";
    // console.log("modified list attributes", this.searchAttributes)
  }

  removeListAttribute(event: any, i: number) {
    // console.log("list attribute to be removed:", this.listAttributes[i])
    this.searchAttributes.splice(i, 1)
    console.log("list attributes after removal", this.searchAttributes)
    if (!this.searchAttributes.length) {
      this.isReadyToSave = false
    }
  }

  selectAttributeMenuOpened(){
    this.boxObjectAttributes.forEach((e) => {
      e.isColumnSelected = false;
      this.searchAttributes.forEach((list) => {
        if(e.__id == list.__id ){
          e.isColumnSelected = true;
        }
      })
    })
  }

  attributeSelectionChanged(attributes: any[]){
    // this.searchAttributes = attributes
    attributes.forEach(attr => {
      if(!attr.widgetType) attr.widgetType = 'input'
      if(!this.searchAttributes.find(a => a.__id == attr.__id)){
        if(attr.isColumnSelected){
          attr['widgetType'] = 'input'
          this.searchAttributes.push(attr)
        }
      }
    })
  }

  displayFnAttribute(Attribute) {
    return Attribute && Attribute.name ? Attribute.name : ''
  }

  addSearchAttribute(event: MatChipInputEvent): void {
    console.log("add fired", event)
    // if (event.value) {
    //   this.searchAttributes.push(event.value);
    // }

    // Clear the input value
    event.chipInput!.clear();
    this.searchAttributeControl.setValue('');
  }

  addSortAttribute(event: MatChipInputEvent): void {
    console.log("add sort attribute fired", event)
    // if (event.value) {
    //   this.sortAttributes.push(event.value);
    // }
    // Clear the input value
    event.chipInput!.clear();
    this.sortAttributeControl.setValue('');
  }

  removeSearchAttribute(attribute: string): void {
    const index = this.searchAttributes.indexOf(attribute);
    if (index >= 0) {
      this.searchAttributes.splice(index, 1);
    }
    this.excludeSelected('search')
    if (this.searchAttributes.length == 0) {
      this.isReadyToSave = false
    }
  }

  removeSortAttribute(attribute: string): void {
    const index = this.sortAttributes.indexOf(attribute);
    if (index >= 0) {
      this.sortAttributes.splice(index, 1);
    }
    this.excludeSelected('sort')
  }

  selectedSearchAttribute(event: MatAutocompleteSelectedEvent): void {
    console.log("selected fired", event)
    this.searchAttributes.push(event.option.value);
    this.searchAttributeInput.nativeElement.value = '';
    this.searchAttributeControl.setValue('');

    this.excludeSelected('search')

    // this case occurs when list panel was selected before and all search attributes were removed after that
    if (this.panelMeta.outputListPanelId && !this.isReadyToSave) {
      this.isReadyToSave = true
    }
  }

  selectedSortAttribute(event: MatAutocompleteSelectedEvent): void {
    console.log("selected fired", event)
    this.sortAttributes.push(event.option.value);
    this.sortAttributeInput.nativeElement.value = '';
    this.sortAttributeControl.setValue('');

    this.excludeSelected('sort')
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.searchAttributes, event.previousIndex, event.currentIndex);
  }

  dropSortAttribute(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.sortAttributes, event.previousIndex, event.currentIndex);
  }

  hideTitleChanged(event){
    console.log("hide title changed", event)
    this.panelMeta['hideTitle'] = event.checked
  }

  appSelected(e){
    console.log("appselected", e);
    this.selectedBoxId = e.__id;
    if(this.selectedBoxId){
      this.isAppSelected = true
    }
    this.panelMeta.boxId = this.selectedBoxId;
  }

  getSelectedConnectionId(box){
    if(box != 'starch') return this.selectedConnectionId;
    return
  }

  getSelectedBoxId(){
    let box = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    return box;
  }

  async baseSelected(base){
    if('starch' !== this.selectedBoxId){
      this.configChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.saveDisabled = true
      this.refreshObjects = true
      this.disableBoxObjectInput = true
      this.isAttributeReady = false
    }

    this.selectedBoxId = 'starch';
    this.panelMeta.baseMap = {
      box_id: base.storage_box,
      base_id: base._id
    }

    this.isBoxConfigError = false
    this.panelMeta.baseId = base._id;
    this.boxConfigToken = base.storage_token;
    await this.getBoxFunctions(this.panelMeta.baseMap.box_id);
  }

  /** --------------------------------------boxSelected---------------------------
   * fires when a box is selected in box-select component
   * @param box the selected box will come from boxSelect component's output boxInput
   */
   async boxSelected(box: any) {
    console.log("boxSelectedTriggered", box._id)
    console.log("connection", this.selectedConnectionId)
    // check if config changed and keep flag
    if(this.selectedConnectionId != box._id){
      console.log("box id changed")
      this.configChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.saveDisabled = true
      this.refreshObjects = true
      this.disableBoxObjectInput = true
      this.isAttributeReady = false
    }

    this.isBoxConfigError = false
    console.log("selected box", box)
    this.selectedBoxName = box.name
    this.selectedBoxId = box.box_id
    this.boxConfigToken = box.box_token
    this.selectedConnectionId = box._id

    await this.getBoxFunctions()
    this.panelMeta.selectedConnectionId = this.selectedConnectionId;
  }

  async getBoxFunctions(boxId?:any){
    this.gettingObjFunSpinner = true
    let res = await this.boxService.getBoxFunctions(boxId || this.selectedBoxId, this.boxConfigToken)
    this.gettingObjFunSpinner = false
    console.log("box functions received", res)
    this.boxFunctions = res

    // if boxFuntions has getobjects, enable/render boxObject selection component (*ngIf on isBoxSelected)
    if(this.boxFunctions.find(fn => fn.__id == 'getobjects')){
      console.log("can get boxObjects, will enable box object selection component")
      this.canGetBoxObjects = true
      this.disableBoxObjectInput = false
    }else if(this.boxFunctions.find(fn => fn.__id == 'getattributes')){
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.boxObjectConfigError['error'] = {}
      this.boxObjectConfigError['error']['message'] = "'getattributes' not available in box functions"
    }else{
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.boxObjectConfigError['error'] = {}
      this.boxObjectConfigError['error']['message'] = "'getobjects' not available in box functions"
    }
  }

  /**
   * fires when a boxObject is selected from boxObject autocomplete
   * saves the boxObjectId in this.selectedBoxObjectId
   * also calls the getAttributes() once boxObject is selected
   * @param boxObject
   */
  async boxObjectSelected(boxObject: any) {
    this.isBoxObjectConfigError = false
    console.log("box object selected", boxObject.__id)
    this.selectedBoxObjectId = boxObject.__id
    this.isBoxObjectSelected = true;
    this.panelMeta.boxObjectId = boxObject.__id;

    this.gettingObjFunSpinner = true;

    let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    this.objectFunctions = await this.boxService.getBoxObjectFuntions(boxId, this.selectedBoxObjectId, conKey, conType)
    this.gettingObjFunSpinner = false
    console.log("box object functions received", this.objectFunctions)
    let getFn: any = this.objectFunctions.find(fn => fn.__id == 'get')
    if(getFn && getFn.options && !getFn.options.filter){
      this.terminationError = true
      this.terminationErrorMessage = `Search is not supported on ${this.selectedBoxObjectId} of ${this.selectedBoxId}. Use list panel for viewing!`
    } else if(!getFn){
      this.terminationError = true
      this.terminationErrorMessage = `'get' function not found on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
    }
    else{
      this.terminationError = false
      // this.checkOptionsToCollect()
      // if(!this.attributeOptions.length || !this.isOptionsToCollect){
      //   this.getAttributes()
      // }

      this.constructAttrOptions()
    }
    this.getListPanels()
  }

  /**
   * looks for getAttributes in boxFuntions and does necessary steps to collect inputs before calling getAttributes
   */
   checkOptionsToCollect(){
     this.attributeOptions = []
    let fn = this.boxFunctions?.find(fn => fn.__id == 'getattributes')
    if(fn){
      if(fn.input.list.find(input => input == 'options')){
        fn.input['options'].list.forEach((optionItem: string) => {
          fn.input['options'][optionItem]['value'] = ''
          this.attributeOptions.push(fn.input['options'][optionItem])
          this.isOptionsToCollect = true
        });
      }
      console.log("options to collect", this.attributeOptions)
    }else{
      this.attributeError = 'getting attributes not available on this connection and object'
    }
  }

  optionChanged(event, index){
    this.attributeOptions[index]['value'] = event.srcElement.value
  }

  // boxObjectInputChanged(data: boolean) {
  //   if (data) {
  //     this.isAttributeReady = false
  //   }
  // }

  async getAttributes() {
    console.log("inside function", this.isAttributeReady)
    this.attributeSpinner = true

    let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    await this.boxService.getAttributes(conKey, boxId, this.selectedBoxObjectId, this.attributeOptions, null, conType)
      .then((response: any) => {
        this.boxObjectAttributes = response.result
        this.isAttributeReady = true;
        this.attributeSpinner = false
        console.log("attributes received:", this.boxObjectAttributes)

        if(this.firstHit){
          this.searchAttributes = this.WidgetUtilityService.intelliSenseAttribute(this.boxObjectAttributes, 4)
          this.searchAttributes.forEach(attr => attr['widgetType'] = 'input')
        }
        this.excludeSelected('search')
        this.excludeSelected('sort')
        // if(this.searchAttributes.length == 0){}
        // this.sortAttributes = []
        if (this.searchAttributes.length) {
          this.isReadyToSave = true
          if(this.panelMeta.outputListPanelId){
            this.saveDisabled = false
          }
        } else {
          this.isAttributeError = true
          this.attributeError = {
            error: {
              message: 'no attributes found for selected object'
            }
          }
        }
      })
      .catch(err => {
        this.attributeSpinner = false
        this.isAttributeError = true
        this.attributeError = err
        console.error("search-panel-dialog component: getAttributes()", err)
      })
  }

  userSelectSearchAttribute(event: MatCheckboxChange): void {
    console.log("user select search attribute", event.checked)
    this.panelMeta.userSelectSearchAtrribute = event.checked
  }
  userSelectSortAttribute(event: MatCheckboxChange): void {
    console.log("user select sort attribute", event.checked)
    this.panelMeta.userSelectSortAtrribute = event.checked
  }

  /**
   * creates a new attributes list by excluding already selected from all attributes
   * @param type whether excluding for search attributes or sort attributes
   * 'search' | 'sort'
   */
  excludeSelected(type: string) {
    let arr = []
    if (type == 'search') {
      arr = this.searchAttributes
    } else if (type == 'sort') {
      arr = this.sortAttributes
    }

    let unselected: any = []
    this.boxObjectAttributes.forEach(attr => {
      // console.log("dealing ", attr)
      let selected: boolean = false
      arr.forEach(searchAttr => {
        if(searchAttr == attr){
          // console.log("found selected")
          selected = true
        }
      });
      if (!selected) {
        unselected.push(attr)
      }
    })
    if (type == 'search') {
      this.unselectedForSearch = unselected
      // console.log("unselected for search array:", unselected)
    } else if(type == 'sort'){
      this.unselectedForSort = unselected
      // console.log("unselected for sort array:", unselected)
    }
  }

  saveConfig() {
    this.panelMeta = this.createSearchPanelMeta()
    this.panelMeta['widgets'] = this.automationService.generateSearchWidgets(this.panelMeta)
    this.panelMeta = this.widgetService.migrateExistingPanelMeta(this.panelMeta)

    //THIS NEEDS TO BE SAVED IN DATA WHICH WILL BE RETURNED TO THE CALLING COMPONENT ON CLOSE OF DIALOG
    this.data = {
      searchPanelMeta: this.panelMeta
    }
    if(this.outputListPanel){
      this.data['listPanelMeta'] = this.outputListPanel
    }

    console.log("final search panel meta --->", this.data)
    this.dialogRef.close(this.data)
  }

  removeDuplicates(a) {
    return Array.from(new Set(a));
  }

  filterEmptyString(attr) {
    return attr !== ''
  }

  addSearchAttributeField() {
    this.searchAttributes.push('')
    // this.disableSearchAttributeAdd = true
  }

  addSortAttributeField() {
    this.sortAttributes.push('')
    // this.disableSearchAttributeAdd = true
  }

  /**
   * scans through the page meta and gets alll the list panels available
   */
  getListPanels() {
    if(!this.pageMeta || !this.pageMeta.panels.length){
      return
    }
    console.log("panelMeta", this.panelMeta)
    this.availableListPanels = []
    this.pageMeta.panels.forEach(lp => {
    if (lp.type == 'listpanel') console.log("panelMeta lp", lp)
      if (lp.type == 'listpanel' && this.selectedBoxId == lp.boxId && (this.selectedBoxObjectId == lp.boxObjectId)) {
        this.availableListPanels.push(lp)
      }
    });
    console.log("list panels initialized", this.availableListPanels)
  }

  // listPanelSelected(event) {
  //   console.log("event", event)
  //   if(event.option.value == 'auto_create_list'){
  //     console.log("will auto create list panel")
  //     this.createListPanel()
  //   }else{
  //     console.log("list panel selected", event.option.value)
  //     this.panelMeta.outputListPanelId = event.option.value
  //     this.outputListPanelName = this.getPanelNameById(this.panelMeta.outputListPanelId)
  //     this.panelMeta.outputListPanelName = this.outputListPanelName
  //     this.selectOutputListControl.patchValue(this.outputListPanelName)
  //     this.listPanelInput.nativeElement.value = this.outputListPanelName
  //     console.log("input element", this.listPanelInput)
  //     console.log("outputListPanelName", this.outputListPanelName)
  //     this.saveDisabled = false
  //   }
  // }
  listPanelSelected(event) {
    console.log("event", event)
    if(event.value == 'auto_create_list'){
      console.log("will auto create list panel")
      this.createListPanel()
    }else{
      console.log("list panel selected", event.value)
      this.panelMeta.outputListPanelId = event.value
      this.outputListPanelName = this.getPanelNameById(this.panelMeta.outputListPanelId)
      this.panelMeta.outputListPanelName = this.outputListPanelName
      this.saveDisabled = false
    }
  }

  getPanelNameById(id){
    let i = this.pageMeta.panels.findIndex(panel => panel.id == id)
    return this.pageMeta.panels[i].name
  }

  boxSelectionError(err){
    this.isBoxConfigError = true
    this.boxConfigError = err
  }

  boxObjectSelectionError(err){
    this.isBoxObjectConfigError = true
    this.boxObjectConfigError = err
  }

  createSearchPanelMeta(){
    let meta = JSON.parse(JSON.stringify(this.panelMeta))

    meta.connectionId = this.selectedConnectionId
    meta.boxId = this.selectedBoxId
    meta.boxName = this.selectedBoxName
    meta.boxObjectId = this.selectedBoxObjectId
    meta.boxConfigToken = this.boxConfigToken
    meta.attributeOptions = this.attributeOptions
    meta.getFnOptions = this.getFnOptions

    // search attributes: remove duplicates if any
    let searchAttributesUnique = this.removeDuplicates(this.searchAttributes)

    // sort attributes : remove duplicates if any
    let sortAttributesUnique = this.removeDuplicates(this.sortAttributes)
    //save in panelMeta
    meta.searchAttributes = searchAttributesUnique
    meta.sortAttributes = sortAttributesUnique
    return meta
  }

  drillDownToggle(i){
    console.log("drill down toggle for ", this.searchAttributes[i])
    if(!this.searchAttributes[i].isDrillDown) this.searchAttributes[i]['isDrillDown'] = true
    else this.searchAttributes[i].isDrillDown = false
  }
  nestedValueWidgetSelected(widgetType, i, j){
    let attr = this.searchAttributes[i]
    console.log(widgetType, "for", attr.__id, ".", attr['nestedProperties']?.[j]?.['path'])
    attr.nestedProperties[j]['widgetType'] = widgetType
    console.log("attr", attr)
  }

  addNestedproperty(i){
    let attr = this.searchAttributes[i]
    if(!attr.nestedProperties){
      attr['nestedProperties'] = []
    }
    attr.nestedProperties.push({
      path: '',
      widgetType: 'input'
    })
    console.log("attr now", attr)
  }

  deleteNestedProperty(i, j){
    let attr = this.searchAttributes[i]
    attr.nestedProperties.splice(j, 1)
    console.log("attr now", attr)
  }

  /**
   * auto creates the list panel to show search result
   */
  async createListPanel(){
    let panelMeta = this.createSearchPanelMeta()
    this.listCreationSpinner = true

    let listPanel: any
    listPanel = await this.automationService.createListPanel(panelMeta, this.pageMeta)

    // listPanel['defaultView'] = 'table'


    // intellisense attributes
    let intelliSensed = this.WidgetUtilityService.intelliSenseAttribute(JSON.parse(JSON.stringify(this.boxObjectAttributes)), 5)
    intelliSensed.forEach(attr => {
      attr['sortEnabled'] = false
      attr['show_hide'] = false
      attr['fieldType'] = 'attribute'
      attr['widgetType'] = 'label'
      attr['isColumnSelected'] = true
    });
    listPanel.listAttributes = intelliSensed


    //check if at least one list attribute found
    if(!listPanel.listAttributes.length){
      this.listCreationSpinner = false
      this.isListCreationError = true
      this.listCreationError = 'no attribute found for list'
      console.log("list creation failed :", this.listCreationError)
      return
    }
    this.panelMeta.outputListPanelId = listPanel.id
    this.panelMeta.outputListPanelName = listPanel.name
    this.outputListPanelName = listPanel.name

    // this.form.patchValue({
    //   listPanel: this.outputListPanelName
    // })
    this.availableListPanels.push(listPanel)

    this.outputListPanel = listPanel

    this.listCreationSpinner = false
    this.saveDisabled = false
  }

  commonSearchFieldChanged(event){
    console.log("commonSearchFieldChanged", event)
    this.panelMeta['commonSearchField'] = event.checked
  }


  // calculates if input parameters needed for fetching attributes
  async constructAttrOptions(){

    console.log("[CONSTRUCT ATTR OPTIONS]")
    this.getAttrFn = this.boxFunctions.find(fn => fn.__id == 'getattributes')

    if(this.firstHit) {
      console.log("[CONSTRUCT ATTR OPTIONS] - if 1")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      // keep availableInputParams in sync with attribute Options
      this.attributeOptions = this.attributeOptions.filter(op => !op.hidden)
      this.attributeOptions.forEach((op: any) => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        await this.getAttributes();
        this.constructGetOptions()
      }

    } else if(!this.firstHit && (this.configChanged)){  // && (this.boxChanged || this.boxObjectChanged)
      console.log("[CONSTRUCT ATTR OPTIONS] - if 2")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      // keep availableInputParams in sync with attribute Options
      this.attributeOptions.forEach((op: any) => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        await this.getAttributes();
        this.constructGetOptions()
      }

    } else {  // default flow for 2nd hit when options available
      console.log("[CONSTRUCT ATTR OPTIONS] - if 3")
      this.attributeOptions?.forEach((op: any) => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })
      await this.getAttributes();
      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }
      this.constructGetOptions()
    }
  }

   // calculates if input parameters needed for get fn
   constructGetOptions(){

    console.log("[CONSTRUCT GET OPTIONS]")
    this.getFn = this.objectFunctions.find(fn => fn.__id == 'get')

    if(this.firstHit){
      console.log("[CONSTRUCT GET OPTIONS] if (1)")
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      this.getFnOptions = this.getFnOptions.filter(op => !op.hidden)
      if(!this.getFnOptions?.length) {
        this.getAttributes()
        return
      }
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })
      this.collectGetOptions = true
    } else if(!this.firstHit && (this.configChanged)){  // && (this.boxChanged || this.boxObjectChanged)
      console.log("[CONSTRUCT GET OPTIONS] if (2)")
      this.getFn = this.objectFunctions.find(fn => fn.__id == 'get')
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      if(!this.getFnOptions?.length) {
        // this.constructActionOptions()
        this.getAttributes()
        return
      }
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })
      this.collectGetOptions = true

    } else{
      console.log("[CONSTRUCT GET OPTIONS] if (3)", this.getFnOptions)
      if(!this.getFnOptions?.length){
        console.log("no options to collect, calling constructActionOptions() and return")
        // this.constructActionOptions()
        this.getAttributes()
        return
      }
      this.getFnOptions?.forEach(el => {
        // if some option already has value in availableInputParams, use it
        if(this.availableInputParams.options.hasOwnProperty(el.__id)){
          el.value = this.availableInputParams.options[el.__id]
        }else{ // if a new property, keep back in availableInputParams for further use in actionOptions
          this.availableInputParams.options[el.__id] = el.value
        }
      })
      console.log("availableInputParams", this.availableInputParams)

      console.log("making collectGetOptions true")
      this.collectGetOptions = true
      // this.constructActionOptions()
      this.getAttributes()
    }
  }

  /**
   * bound with event emitter to catch input parameters for getAttribute functions
   * @param attrInputParams
   */
  attrOptionInputsRecevied(attrInputParams){
    console.log("attribute input params received", attrInputParams)
    if(!attrInputParams.options) return
    Object.keys(attrInputParams.options).forEach(optionId => {
      this.attributeOptions.forEach(attrOp => {
        if(attrOp.__id == optionId){
          attrOp.value = attrInputParams.options[optionId]
        }
      })
      // update availableInputParams for further use
      this.availableInputParams['options'][optionId] = attrInputParams.options[optionId]
    })
    console.log("availableInputParams updated", this.availableInputParams)
    console.log("attribute options", this.attributeOptions)
  }

  /**
   * bound with event emitter to catch input parameters for other getFn functions
   * @param getFnInputParams
   */
  getOptionInputsRecevied(getFnInputParams){
    console.log("getFn input params received", getFnInputParams)
    if(!getFnInputParams?.options) return
    Object.keys(getFnInputParams.options).forEach(optionId => {
      this.getFnOptions.forEach(getOption => {
        if(getOption.__id == optionId){
          getOption.value = getFnInputParams.options[optionId]
        }
      })
    })
    console.log("get options", this.getFnOptions)
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }
}
