import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { StarchService } from 'src/app/shared/services/starch.service';
import { environment } from 'src/environments/environment';
import { FormService } from '../form.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateFormPopupComponent } from '../create-form-popup/create-form-popup.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppsPopupComponent } from 'src/app/shared/apps-popup/apps-popup.component';
import { FormActionPopupComponent } from '../form-action-popup/form-action-popup.component';
import { FormTemplatePopupComponent } from '../form-template-popup/form-template-popup.component';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { FormContentService } from '../form-content-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CreateFormDetailComponent } from '../create-form-detail/create-form-detail.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';


@Component({
    selector: 'app-new-form',
    templateUrl: './new-form.component.html',
    styleUrls: ['./new-form.component.scss'],
    standalone: false
})
export class NewFormComponent implements OnInit {


  spinner: boolean = false
  error: boolean = false
  authError: boolean = false
  isReady: boolean = false
  disabled: boolean = false
  workspaceReady
  isConnectionSelected: boolean = false;
  appConfig: any = {
    filter:"supports=object"
  };
  templateList: any;
  templateFlowCount: any;
  isTemplateSelected: any;
  templateUnderPreview: any;
  isBlackSelected: boolean;
  templatePanel: any;
  stepperOptions: any = {
    step1: true,
    step2: false,
    step3: false,
    completed1: false,
    completed2: false,
    completed3: false
  }
  linear: boolean = true;
  templateActionMaps: any;
  templateNameMap: any;
  @ViewChild('formtemplateComponent') formtemplateComponent: FormTemplatePopupComponent;
  @ViewChild('stepper') public stepper: MatStepper;
  @ViewChild('formdetailComponent') formdetailComponent: CreateFormDetailComponent;
  templateSearchPanel: any;
  templateCode: any;

  constructor(
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private router: Router,
    private ngZone: NgZone,
    private starchService: StarchService,
    private formService: FormService,
    private dialog: MatDialog,
    private pageService: PageService,
    private automationService: AutomationService,
    private _snackBar: MatSnackBar,
    public formContentService: FormContentService,
    private _formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
   }

  ngOnInit(): void {
    this.authService.subproduct = "form";
    this.route.queryParamMap.subscribe(async (params: any) => {
      console.log("form new route", params);
      if(params?.params?.code) this.templateCode = params?.params?.code;
    })
    if (!this.connectionService.selectedWorkSpace && !this.authService.loggedIn) {
      this.spinner = true;
      this.authService.authCheck()
      this.authService.authCheckPositive.subscribe(async (authStatus) => {
        //if logged in
        if (authStatus) {
          console.log("logged in")
          if (!this.connectionService.selectedWorkSpace) {
            await this.connectionService.getWorkSpaceId();
          };
          this.initialize()
          this.spinner = false;
        } else {
          this.error = true;
          this.authError = true
          this.spinner = false;
          // this._snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top',
          // });
          // redirect to login page
          this.ngZone.run(() => {
            this.router.navigate(['../'])
          })

          return
        }
      })
    } else if (this.connectionService.selectedWorkSpace) {
      this.initialize()
    }
  }

  ngAfterViewInit(): void {
  }



  async initialize(){
    this.isReady = true;
    console.log("template", this.templateCode)
    if(this.templateCode){
      this.spinner = true;
      let filterStr = `code=${this.templateCode},template_status=published`;
      let template = await this.formService.getFormByFilter(filterStr);
      this.spinner = false;
      if(!template) return;
      this.createFromTemplate();
      this.stepperOptions.step1 = false;
      this.selectedRowData({rowDataRaw: template});
      this.stepperOptions.step2 = true;
      // this.cdr.detectChanges();
      setTimeout(() => {
        console.log("this.stepper", this.stepper);
        this.stepper.selectedIndex = 1;
      }, 0);
    }
  }

  createBlank(){
    this.templateUnderPreview = null;
    this.isBlackSelected = true;
    this.isConnectionSelected = false;
    this.isTemplateSelected = false;
    console.log("create form from blank")
    this.createNewForm()
  }

  createFromConnection(){
    console.log("create form from connection");
    this.isConnectionSelected = !this.isConnectionSelected;
    this.isBlackSelected = false;
    this.isTemplateSelected = false;
    this.templateUnderPreview = null;
  }

  async newAppSelected(boxMap){
    let formMeta = await this.createNewFormMeta();
    console.log("createNewFormMeta", formMeta);
    let widgetMap = this.formService.getSubmitButton(formMeta);
    let widget = widgetMap.widget;
    console.log("this widget", widget);
    var dialog = this.dialog.open(FormActionPopupComponent, {
      // maxWidth: '500px',
      maxHeight: '500px',
      data: {widget: widget, selectedBox: boxMap, origin: "new"}
    });
    var dialogResult = await dialog.afterClosed().toPromise();
    console.log("diologResult in new", dialogResult);
    console.log("this widget", widget);
    let appAction = dialogResult.action;
    let appSelectedAttributes = dialogResult.attributes;

    var nameDiolog = this.dialog.open(CreateFormPopupComponent, {
      minWidth: "60vw",
      data: { randomCode: String(Date.now()), formName: "New form"}
    });
    var nameDiologResult = await nameDiolog.afterClosed().toPromise();
    if(!nameDiologResult) return;

    console.log("nameDiologResult", nameDiologResult)
    this.spinner = true;
    formMeta.name = nameDiologResult.name;
    formMeta.code = nameDiologResult.code;
    let base = await this.prepareBase(nameDiologResult.code, appSelectedAttributes);
    if(!base) return;
    let baseMap = base?.baseMap
    let object = base?.object?.__id
    let baseAttributes = base?.attributes;
    console.log("baseAttributes", baseAttributes)
    baseAttributes.forEach(attr => {
      appSelectedAttributes.forEach(element => {
        if(attr.__id == element.__id) Object.assign(attr, element)
      });
      if(attr['primary'])  attr['enabled'] = false
      else attr['enabled'] = true
    })
    let submitButton:any = this.formService.formPanelButton(baseMap, object);
    let formPanel = this.formService.createFormPanel(baseMap, object);

    submitButton.id = Date.now();
    // submitButton['actionConfig']['actions'][0]['actionMap']['mapping'] = [];

    formPanel.name = 'formPanel_1';
    formPanel.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
    formPanel.formAttributes = baseAttributes;
    formPanel = this.automationService.generateFormWidgets(baseAttributes, formPanel, submitButton, submitButton['actionConfig']['actions'][0]['actionMap'].action)

    console.log("submitButton", submitButton)
    console.log("formPanel", formPanel);

    let tempPageMeta = await this.pageService.createPanel('button', {panels: []}, null)
    let submitPanel = tempPageMeta.panels[0];
    let colId = submitPanel.layoutMap.list[0];
    let rowId = submitPanel.layoutMap[colId].list[0];
    submitPanel.layoutMap[colId][rowId]['elements'][0] = submitButton
    submitPanel['noDelete'] = true
    submitPanel['noDrag'] = true
    submitPanel['noClone'] = true;
    submitPanel["noInsert"] = true
    submitPanel["isSubmitPanel"] = true;

    console.log("submitPanel", submitPanel)

    let appActionMapping = submitButton['actionConfig']?.['actions']?.[0]?.['actionMap']?.['mapping'] || [];


    appAction['actionMap']['mapping'] = appActionMapping;


    submitButton['actionConfig']['actions'].push(appAction);

    submitButton['actionConfig']['actions'].push(this.formService.createNavAction()); //navigation action
    formPanel.submitButtonMeta = submitButton;
    submitButton["noDrag"] = true;
    submitButton["noDelete"] = true;
    submitButton['noClone'] = true;

    console.log("submitPanel", submitPanel);
    console.log("submitButton", submitButton)

    // create form and attach titlePanel, form panels and submit panel
    formMeta['panels'] = [formMeta['panels'][0], formMeta['panels'][1], formPanel, submitPanel]
    console.log("formMeta", formMeta)
    formMeta.successPageConfig = {
      actionType: 'thankyou',
      richTextContentHtml: '<h1 class="ql-align-center">🙌</h1><h1 class="ql-align-center"><strong>Thank you for submitting the form.</strong></h1><p class="ql-align-center">Your submission has been received successfully.</p>',
      externalUrl: '',
      isCelebrate: true
    }
    await this.createFormAndRoute(formMeta);
    this.spinner = false;
  }


  createFromStarch(){
    console.log("create form from starch")
  }

  async createNewFormMeta() {
    let attributes = [];//base?.attributes
    this.resetDragInputs();
    let formPanel = this.formService.createEmptyFormPanel()
    let bannerUrl = this.getImageUrl('banner')

    // create banner and attach
    let tempPageMeta = await this.pageService.createPanel('image', {panels: []}, null, null, false, bannerUrl)
    let bannerPanel = tempPageMeta.panels[0]

    let colId = bannerPanel.layoutMap.list[0]
    let rowId = bannerPanel.layoutMap[colId].list[0]
    let bannerWidget = bannerPanel.layoutMap[colId][rowId]['elements'][0]
    bannerWidget.value = bannerUrl
    bannerWidget.gridX = 12
    bannerWidget.gridY = 4
    console.log("banner widget", bannerWidget)

    // create form title attach
    tempPageMeta = await this.pageService.createPanel('label', {panels: []}, null)
    let titlePanel = tempPageMeta.panels[0]

    colId = titlePanel.layoutMap.list[0]
    rowId = titlePanel.layoutMap[colId].list[0]
    let titleWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
    titleWidget.value = "New form"
    titleWidget.fontSize = 32
    titleWidget.bold = true
    titleWidget.color = "#3869a4"
    titleWidget.gridX = 12
    console.log("title widget", titleWidget)

    // add description
    tempPageMeta = await this.pageService.createPanel('label', tempPageMeta, titlePanel.id)
    console.log("tempPageMeta after desc", tempPageMeta)
    colId = titlePanel.layoutMap.list[0]
    rowId = titlePanel.layoutMap[colId].list[1]
    let descriptionWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
    descriptionWidget.value = "This is the description of the form. Customize it as per your need."
    descriptionWidget.gridX = 12
    descriptionWidget.color = "#444444"
    descriptionWidget.fontSize = 16

    tempPageMeta = await this.pageService.createPanel('separator', tempPageMeta, titlePanel.id)


    let submitButton:any = this.formService.createEmptyFormPanelButton();
    console.log("submitButton", submitButton)
    submitButton.id = Date.now();
    console.log("gridX set", submitButton.gridX)
    submitButton['actionConfig']['actions'][0]['actionMap']['mapping'] = [];
    formPanel.submitButtonMeta = submitButton;
    formPanel.name = 'formPanel_1';
    formPanel.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
    formPanel.formAttributes = attributes;
    formPanel = this.automationService.generateFormWidgets(attributes, formPanel, submitButton, submitButton['actionConfig']['actions'][0]['actionMap'].action)
    console.log("panelConfig", formPanel)

    // create submit panel and attach (will only have the decoupled submit button)
    tempPageMeta = await this.pageService.createPanel('button', {panels: []}, null)
    let submitPanel = tempPageMeta.panels[0]
    colId = submitPanel.layoutMap.list[0]
    rowId = submitPanel.layoutMap[colId].list[0]
    submitPanel.layoutMap[colId][rowId]['elements'][0] = submitButton
    submitPanel['noDelete'] = true
    submitPanel['noDrag'] = true
    submitPanel['noClone'] = true;
    submitPanel["noInsert"] = true
    submitPanel["isSubmitPanel"] = true

    // create form and attach titlePanel, form panels and submit panel
    let form: any = this.formService.createFormMeta();
    form['panels'] = [bannerPanel, titlePanel, formPanel, submitPanel]
    console.log("form meta", form)

    return form;
  }

  async resetDragInputs(){
    this.pageService.widgetReadyForDrag = false;
    this.pageService.selectedRow = null;
    this.pageService.selectedLayout = null;
  }

  async createNewForm() {

    var dialog = this.dialog.open(CreateFormPopupComponent, {
      minWidth: "60%",
      data: { randomCode: String(Date.now()), formName: "New form"}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("do", diologResult)
    if(!diologResult) return;

    this.spinner = true

    try {
      let base = await this.prepareBase(diologResult.code)
      if(!base) return;
      let baseMap = base?.baseMap
      let object = base?.object?.__id
      let attributes = base?.attributes

      // let baseMap = {code
      //   :
      //   "mist-3854"}
      // let object = "obj"
      // let attributes:any = [{
      //   __id: "name",
      //   name: "name"
      // }]
      if(!baseMap || !object || !attributes.length) {
        console.log("base could not be created");
        throw "Base could not be created"
      }
      // console.log('base created', base);
      this.spinner = false

      let formNameMap = diologResult;
      this.spinner = true
      this.resetDragInputs();
      let formPanel = this.formService.createFormPanel(baseMap, object)

      // get banner image URL
      // let bannerUrl: string = "https://cdn.ttgtmedia.com/visuals/searchEnterpriseLinux/admin_issues/enterpriselinux_article_003.jpg"

      let bannerUrl = this.getImageUrl('banner')

      // create banner and attach
      let tempPageMeta = await this.pageService.createPanel('image', {panels: []}, null, null, false, bannerUrl)
      let bannerPanel = tempPageMeta.panels[0]

      let colId = bannerPanel.layoutMap.list[0]
      let rowId = bannerPanel.layoutMap[colId].list[0]
      let bannerWidget = bannerPanel.layoutMap[colId][rowId]['elements'][0]
      bannerWidget.value = bannerUrl
      bannerWidget.gridX = 12
      bannerWidget.gridY = 4

      // create form title attach
      tempPageMeta = await this.pageService.createPanel('label', {panels: []}, null)
      let titlePanel = tempPageMeta.panels[0]

      colId = titlePanel.layoutMap.list[0]
      rowId = titlePanel.layoutMap[colId].list[0]
      let titleWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
      titleWidget.value = "New form"
      titleWidget.fontSize = 32
      titleWidget.bold = true
      titleWidget.color = "#3869a4"
      titleWidget.gridX = 12

      // add description
      tempPageMeta = await this.pageService.createPanel('label', tempPageMeta, titlePanel.id)
      colId = titlePanel.layoutMap.list[0]
      rowId = titlePanel.layoutMap[colId].list[1]

      let descriptionWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
      descriptionWidget.value = "This is the description of the form. Customize it as per your need."
      descriptionWidget.gridX = 12
      descriptionWidget.color = "#444444"
      descriptionWidget.fontSize = 16

      tempPageMeta = await this.pageService.createPanel('separator', tempPageMeta, titlePanel.id)

      // let panelAttributes = this.filterAttributesForForm(JSON.parse(JSON.stringify(boxAttributes)))//JSON.parse(JSON.stringify(listAttributes))
      attributes.forEach(attr => {
        if(attr.__id == '_id') attr['primary'] = true
        attr['enabled'] = true
        attr['editable'] = true
        attr['widgetType'] = 'input'
        if(attr.primary) attr['enabled'] = false
      })
      // panelAttributes = this.addPrimaryAttribute(panelAttributes, primaryAttribute)

      let submitButton:any = this.formService.formPanelButton(baseMap, object);
      submitButton.id = Date.now();
      submitButton['actionConfig']['actions'][0]['actionMap']['mapping'] = [];
      formPanel.submitButtonMeta = submitButton;
      formPanel.name = 'formPanel_1';
      formPanel.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
      formPanel.formAttributes = attributes;
      formPanel = this.automationService.generateFormWidgets(attributes, formPanel, submitButton, submitButton['actionConfig']['actions'][0]['actionMap'].action)

      submitButton['actionConfig']['actions'].push(this.formService.createNavAction());
      submitButton['checkValidation'] = true;
      submitButton["noDrag"] = true;
      submitButton["noClone"] = true;
      submitButton["noDelete"] = true;
      // create submit panel and attach (will only have the decoupled submit button)
      tempPageMeta = await this.pageService.createPanel('button', {panels: []}, null)
      let submitPanel = tempPageMeta.panels[0]
      colId = submitPanel.layoutMap.list[0]
      rowId = submitPanel.layoutMap[colId].list[0]
      submitPanel.layoutMap[colId][rowId]['elements'][0] = submitButton
      submitPanel['noDelete'] = true;
      submitPanel["noClone"] = true;
      submitPanel['noDrag'] = true
      submitPanel["noInsert"] = true
      submitPanel["isSubmitPanel"] = true

      // create form and attach titlePanel, form panels and submit panel
      var form: any = this.formService.createFormMeta(formNameMap)
      form['panels'] = [bannerPanel, titlePanel, formPanel, submitPanel]
      form.attribute_name_map = {};
      console.log("form meta", form)

      let widgets = this.automationService.getWidgetsFromPanel(formPanel);
      widgets.forEach(element => {
        form.attribute_name_map[element.name.toLocaleLowerCase()] = {
          wId: element.id,
          wName: element.name
        }
      });
      form.successPageConfig = {
        actionType: 'thankyou',
        richTextContentHtml: '<h1 class="ql-align-center">🙌</h1><h1 class="ql-align-center"><strong>Thank you for submitting the form.</strong></h1><p class="ql-align-center">Your submission has been received successfully.</p>',
        externalUrl: ''
      }
      await this.createFormAndRoute(form);
    } catch(e){
      console.error("Error on creating new form", e);
      this._snackBar.open("Unable to create the form at this time. Please reload the page and try again. If the issue persists, contact support for assistance.", "Ok");
    }

  }

  async createFormAndRoute(form){
    let formCreationResponse
    try{
      formCreationResponse = await this.formService.create_form(form);
      this.formService.formMeta.next(formCreationResponse);
    }catch(e){
      console.log("form creation failed", e)
      this.spinner = false
      this._snackBar.open("could not create form", "Ok");
      return
    }

    console.log('form created', formCreationResponse);
    this.spinner = false
    this.router.navigate(
      ['form/build/' + form.code],
      // { state: { builderMode: true } }
      {fragment: 'edit'}
    );
  }

   /**
   * type: string; 'logo' | 'banner'
   */
   getImageUrl(type: string) {
    let randomCode: any = Math.floor(Math.random() * 6)
    randomCode = randomCode + 1
    // if (randomCode < 6) {
    //   randomCode = '0' + randomCode
    // }

    let imageUrl
    if(type == 'logo'){
      // imageUrl = environment.FORM_LOGO_BASE_URL + randomCode + '.jpg'
    }else if(type == 'banner'){
      imageUrl = environment.FORM_BANNER_BASE_URL + randomCode + '.png'
    }
    return imageUrl
  }

  async selectedRowData(e){
    console.log("selected row data", e);
    this.stepperOptions.step2 = false;
    this.templateActionMaps = null;
    let template = e.rowDataRaw;
    this.templateUnderPreview = template;
    let submitSection = template;
    if(template.sections?.length > 0 ) {
      submitSection = template.sections[template.sections.length - 1];
    }
    let widgetMap = this.formService.getSubmitButton(submitSection, template).widget;
    this.templateActionMaps = widgetMap.actionConfig?.actions;
    this.formdetailComponent?.setName(template.name);
    this.stepperOptions.step2 = true;
    this.stepperOptions.step3 = false;
    this.stepperOptions.completed1 = true;
    this.stepperOptions.completed2 = false;
    this.stepper.next();
    this.cdr.detectChanges();
  }

  async createFromTemplate(){
    this.spinner = true;
    // await this.getTemplateFlows();
    this.resetDragInputs();
    this.templatePanel = this.formContentService.getTemplatesPanel();
    this.templateSearchPanel = this.formContentService.createSearchMeta();
    this.isTemplateSelected = !this.isTemplateSelected;
    this.isConnectionSelected = false;
    this.isBlackSelected = false;
    this.stepperOptions.step2 = false;
    this.stepperOptions.step3 = false;
    this.spinner = false;
  }

  async actionMapped(e){
    console.log("actionMapped", e)
    this.templateActionMaps = e;
    this.ngZone.run(() => {
      this.stepperOptions.step3 = true;
      this.stepperOptions.completed2 = true;
      this.cdr.detectChanges();
    })
    console.log("stepperOptions", this.stepperOptions)

  }

  async templateNameChanged(e){
    console.log("name changes", e)
    this.templateNameMap = e;
  }

  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex === 1) {
      console.log("step event", event);
      this.formtemplateComponent?.initActions(this.templateActionMaps);
    } else if(event.selectedIndex === 0){
      this.stepperOptions.step2 = false;
      this.stepperOptions.step3 = false;
      this.stepperOptions.completed1 = false;
      this.stepperOptions.completed2 = false;
    }
  }

  async createTemplateForm(){
    await this.templateSelected(this.templateUnderPreview, this.templateNameMap);
  }

  async templateSelected(template, nameMap){
    this.templateUnderPreview = template

    let submitSection = template;
    let isSection = false;
    if(template.sections?.length > 0 ) {
      submitSection = template.sections[template.sections.length - 1];
      isSection = true;
    }

    let widgetMap = this.formService.getSubmitButton(submitSection, template).widget;
    this.spinner = true;
    let newActions = this.templateActionMaps;

    let starchActionMapping = newActions[0].actionMap?.mapping || [];
    let starchAttributes = [];
    starchActionMapping.forEach(element => {
      let obj = {
        name: element?.appField?.__id || element?.appField?.name,
        dataType: element?.appField?.dataType || "string",
        defaultValue: ''
      }
      starchAttributes.push(obj);
    });

    console.log("nameMap", nameMap)
    let form: any = this.formService.createFormMeta(nameMap);

    form["title"] = template["title"];
    form["tagline"] = template["tagline"];

    let submitButtonWid = JSON.parse(JSON.stringify(widgetMap));

    let base:any = await this.prepareBase(nameMap.code, starchAttributes);
    if(!base) return;
    console.log("nameMap", nameMap)
    let baseMap = base?.baseMap
    let object = base?.object?.__id;
    newActions[0]["actionMap"]["action"] = `${object}/create`;
    newActions[0]["actionMap"]["boxConfigToken"] = baseMap?.storage_token;
    newActions[0]["actionMap"]["baseMap"] = {
        "box_id": baseMap?.storage_box,
        "base_id":  baseMap?._id
      }

    submitButtonWid.actionConfig.actions = newActions;

    //panel action
    let submitPanel = template?.panels[3];
    let colId = submitPanel.layoutMap.list[0];
    let rowId = submitPanel.layoutMap[colId].list[0];
    submitPanel.layoutMap[colId][rowId]['elements'][0] = submitButtonWid;
    form.panels = template.panels;
    form.panels[2]["submitButtonMeta"] = submitButtonWid;
    form.panels[2]["boxObjectId"] = object;
    form.panels[2]["baseMap"] = {
      "box_id": baseMap?.storage_box,
      "base_id":  baseMap?._id
    };
    form.panels[2]["baseId"] = baseMap?._id;
    form.panels[2]["boxConfigToken"] = baseMap?.storage_token;
    form.successPageConfig = template.successPageConfig;
    form.theme = template.theme;
    form.attribute_name_map = template.attribute_name_map;

    if(isSection){
      let sectionSubmitPanel = submitSection?.panels[submitSection?.panels?.length - 1];
      let colId = sectionSubmitPanel.layoutMap.list[0];
      let rowId = sectionSubmitPanel.layoutMap[colId].list[0];
      sectionSubmitPanel.layoutMap[colId][rowId]['elements'][sectionSubmitPanel.layoutMap[colId][rowId]['elements']?.length - 1] = submitButtonWid;
      form.sections = template.sections;
    }

    console.log("form", form)
    await this.createFormAndRoute(form);
    this.spinner = false;
  }

  async getTemplateFlows(pageNumber: number = 1, pageSize: number = 100){
    try{
      if(!this.templateList || this.templateList?.length){
        let response: any
        response = await this.formService.getFormsTemplate(pageNumber, pageSize);
        this.templateList = response.data
        this.templateFlowCount = response.totalCount
      }

    }catch(err){
      console.error("template blooms could not be fetched", err)
    }
    return this.templateList
  }

  async prepareBase(code: string, defaultAttributes?: any){
    // let isEligible = this.smsService.checkEligibility("STARCH");
    // console.log("isEligible", isEligible)
    // if(!isEligible){
    //   let dialogRef = this.dialog.open(QoutaPopupComponent, {
    //         width: "500px",
    //         data: {
    //           type: "STARCH"
    //         },
    //       });
    //   return ;
    // }

    let result;
    try{
      result = await this.formService.prepareBase(code, defaultAttributes);
    } catch(e){
      console.error("error", e)
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          "message": "Error on creating Base",
          title: 'Error on creating Base',
          description:JSON.stringify(e?.error?.error || e?.error?.message || e),
          recommendation: "Please reload the page and try again. If the issue persists, contact support for assistance.",
          isError: true
        },
      });
      this.spinner = false;
    }

    return result;
  }




}
