<spinner
  [center]="true"
  size="la-2x"
  color="grey"
  type="ball-triangle-path"
></spinner>

<h1 mat-dialog-title>Add New Attribute</h1>
<div mat-dialog-content >
  <p>Create a new field in your selected application.</p>
  <div fxLayout="column">
      <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Attribute Name</mat-label>
        <input matInput (input)="onChangeOfAttributeName($event.target.value)" [(ngModel)]="data.attribute.name" placeholder="Enter the attribute name." required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Attribute Type</mat-label>
        <mat-select [(ngModel)]="data.attribute.dataType">
          <mat-option *ngFor="let type of availableBoxTypes; trackBy: trackByFn" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</div>
<div mat-dialog-actions fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button color="primary" [disabled]="!data.attribute.name" (click)="createFieldMap()" cdkFocusInitial>Apply</button>
    <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
  </div>
  <div fxLayout="row" *ngIf="statusMessage" fxLayoutAlign="center center" [ngClass]="isError ? 'warning' : 'success'" style="width: 300px;" >
    <mat-icon>{{ isError ? 'close' : 'done' }}</mat-icon>
    <b>{{ statusMessage }}</b>
  </div>
</div>

