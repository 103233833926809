import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })

export class ActionServiceGUIUtility {

    actionObject: any = {}
    actionOptions: any [] = [];
    openedActionIndex:any = 0;
    constructor(
    ){}

    setActionOutputFields(i, fields) {

    }

    addActionObject(obj) {
      this.actionOptions.push({actionType: obj?.action});
    }

    spliceActionObject(object){
      this.actionOptions.splice(this.actionOptions.length - 1, 0, object);
    }

    initActionObjectOptions(actions: any) {
      actions.forEach(element => {
        this.actionOptions.push({actionType: element?.action});
      });
    }

    deleteActionOptions(i){
      this.actionOptions.splice(i, 1);
    }

    setActionSourceFields(i, fields){
      let index = i || i ==0 ? i : this.openedActionIndex;
      this.actionOptions[index].sourceFields = [];
      let sourcefields = this.actionOptions[index].sourceFields;
      fields.forEach(element => {
        element.__id = `action_${index + 1}` + "." + element.__id;
        element.name = `Action ${index  + 1}` + " " + element.name || element.__id;
        sourcefields.push(element);
      });
      console.log("setActionSourceFields", this.actionOptions)
    }


    //add previous actions's output field into fields
    addPreviousActionsSourceFields(index, sourceFields){
      index = index || index == 0 ? index : this.openedActionIndex;
      if(!sourceFields) sourceFields = [];
      for (let i = 0; i < index; i++) {
        const element = this.actionOptions[i];
        if(element?.sourceFields?.length) sourceFields = sourceFields.concat(element?.sourceFields);
      }

      console.log("addPreviousActionsSourcFields", index, sourceFields)
      return sourceFields;
    }


    moveItemInArray(fromIndex: number, toIndex: number): void {
      const from = this.clamp(fromIndex, this.actionOptions.length - 1);
      const to = this.clamp(toIndex, this.actionOptions.length - 1);
      if (from === to) {
        return;
      }

      const target = this.actionOptions[from];
      const delta = to < from ? -1 : 1;
      for (let i = from; i !== to; i += delta) {
        this.actionOptions[i] = this.actionOptions[i + delta];
      }
      this.actionOptions[to] = target;
    }

    /**
     * Clamps a number between a minimum and a maximum value.
     *
     * @param value Number to be clamped.
     * @param max Maximum value.
     * @param min Minimum value (defaults to 0).
     */
    clamp(value: number, max: number, min = 0): number {
      return Math.max(min, Math.min(max, value));
    }



}
