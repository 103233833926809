import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, NgZone, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';

declare const google: any

@Component({
    selector: 'app-bloom-toolbar',
    templateUrl: './bloom-toolbar.component.html',
    styleUrls: ['./bloom-toolbar.component.scss'],
    standalone: false
})
export class BloomToolbarComponent implements OnInit {

  @Input() isLoggedIn: boolean = false;
  @Input() currentBloomCode: any = "";
  @Input() pages: any = {};
  @Input() bloomMeta: any = {};
  @Input() version: any;
  @Output() bloomLogout = new EventEmitter<any>()
  @Output() bloomLogin = new EventEmitter<any>()
  @Output() pageChange = new EventEmitter<any>()
  @Output() previewPageChange = new EventEmitter<any>()

  constructor(

    private tokenUtil: TokenUtil,
    private ngZone: NgZone,
    public metaService: MetaService,
    private authService: AuthServiceService,
    public themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
    }

  async ngOnInit() {

  }

  logout(){
    this.bloomLogout.emit()
  }

  login(){
    this.bloomLogin.emit()
  }

  trackByFn(index, item) {
    return index;
  }

  handlePageClick(code){
    this.pageChange.emit(true)
    this.previewPageChange.emit(code)
  }

  pageChangeDetected(event){
    this.pageChange.emit(true)
  }

}
