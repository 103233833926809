import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Duration extends Widget {
  type = 'duration';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      // "placeholder",
      // 'viewOnly'
    ],
    // placeholder: {
    //   displayName: "Duration Placeholder",
    //   value: "Enter duration",
    //   type: 'text'
    // },
    
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['title'].value = 'Enter duration';
    this.config['value'].value = {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0
    };
    this.config['defaultValue'].value = {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0
    };
    this.config['defaultValue'].valueType = 'duration';

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);
    return map;
  }
}
