import { Injectable, signal, Injector } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { WidgetManager } from '../models/WidgetManager';
import { FormService } from 'src/app/form/form.service';
import { WidgetMetaTransformationService } from './widget-meta-transformation.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  widgetCreationRequest = new Subject<any>();
  widgetNotification = this.widgetCreationRequest.asObservable()

  widgetDragCreationRequest = new Subject<any>();
  widgetDragNotification = this.widgetDragCreationRequest.asObservable()

  widgetCloneRequest = new Subject<any>();
  widgetCloneNotification = this.widgetCloneRequest.asObservable()

  widgetDragStart = new Subject<any>();
  $widgetDragStarted = this.widgetDragStart.asObservable();

  // widgetDragEnd = new Subject<any>();
  // $widgetDragEnded = this.widgetDragEnd.asObservable();

  starchtDragCreationRequest = new Subject<any>();
  starchtDragNotification = this.starchtDragCreationRequest.asObservable();

  selectedPanelId = new BehaviorSubject<string>('');
  panelSelectionAlert = this.selectedPanelId.asObservable()

  selectedWidgetId = new BehaviorSubject<string>('');
  widgetSelectionAlert = this.selectedWidgetId.asObservable()

  widgetUpdationRequest = new Subject<any>();
  widgetUpdation = this.widgetUpdationRequest.asObservable()

  actionPanelRequest = new Subject<any>();
  starchtPanelNotification = this.actionPanelRequest.asObservable();

  widgetMetaSignal = signal<any>({})
  // updatedWidgetMeta = new Subject<any>();
  // $widgetUpdate = this.updatedWidgetMeta.asObservable();

  widgetNotifier(widget: string){
    this.widgetCreationRequest.next(widget)
    this.widgetCreationRequest.subscribe((widgetType, options?) => {
    })
  }

  constructor(
    private authService: AuthServiceService,
    private formService: FormService,
    public wmtService: WidgetMetaTransformationService
  ) {}

  openWidgetSidebarSettings = new BehaviorSubject<any>(null);
  $openWidgetSidebarSettings = this.openWidgetSidebarSettings.asObservable();

  widgetDeselected = new Subject<any>();
  $widgetDeselected = this.widgetDeselected.asObservable();

  widgetDragNotifier(widget: string){
    this.widgetDragCreationRequest.next(widget)
  }

  widgetClone(event: any){
    this.widgetCloneRequest.next(event)
  }

  starchDragNotifier(starchMap: any){
    this.starchtDragCreationRequest.next(starchMap)
  }

  actionPanelNotifier(starchMap: any){
    this.actionPanelRequest.next(starchMap)
  }


  /**
   * 
   * @param widgetMeta : can be in compressed or decompressed form 
   * @returns new widget in decompressed form, as a class instance of the widget
   */
  createNewWidgetInstance(widgetMeta: any){
    let newWidget: any = WidgetManager.getWidget(widgetMeta.type)
    let decompressedWid = this.wmtService.decompressWidgetMeta(widgetMeta)
    Object.keys(decompressedWid).forEach(k => newWidget[k] = decompressedWid[k])
    return newWidget
  }

  /**
 * checks if a given event is bubbled up from child or a direct click on parent
 * @param event
 */
  checkIfEventBubbling(event: any): boolean {
    if(!event) return false;
    // TEST IF CLICK IS BUBBLED UP FROM CHILD OR DIRECTLY ON THIS ROW. IF DIRECT CLICK, DESELECT CHILDREN
    const currentTarget = event.currentTarget as HTMLElement;
    const target = event.target as HTMLElement;

    if (target === currentTarget) {
      console.log("direct click, deselect children")
      return false
    } else {
      // console.log("event bubbled up")
      return true
    }
  }
  // checkIfEventBubbling(event: any, bubbledUpFromClass?: string): boolean {
  //   if (!event) return false;

  //   const currentTarget = event.currentTarget as HTMLElement;
  //   const target = event.target as HTMLElement;

  //   // Check if it's a direct click on the current target
  //   if (target === currentTarget) {
  //     console.log("direct click, deselect children");
  //     return false;
  //   }

  //   // If bubbledUpFromClass is provided, check if the event originated from an element with that class or its descendants
  //   if (bubbledUpFromClass) {
  //     let bubbledUpFromElement = target.closest(`.${bubbledUpFromClass}`);
  //     if (bubbledUpFromElement) {
  //       console.log(`event bubbled up from element with class: ${bubbledUpFromClass} or descendants`);
  //       return true;
  //     } else {
  //       console.log(`event did not originate from an element with class: ${bubbledUpFromClass} or descendants`);
  //       return false;
  //     }
  //   }

  //   // If no class is provided, check if the event bubbled up in general
  //   console.log("event bubbled up");
  //   return true;
  // }


  // getRowIdFromWidget

  checkForFormSection(item?: any, widgetMeta?: any){
    if( this.authService?.subproduct == "form" && widgetMeta?.isValueBasedFormSection) {
      console.log("item", item);
      let currentSection = this.formService.pageSelectionRequest?.value;
      console.log("currentSection", currentSection);
      this.formService.generateConditionalButtons(currentSection, item.formSection);
    }
  }

  getWidgetsFromPanel(panelMeta){
    let layoutMap = panelMeta.layoutMap;
    let widgets = [];
    if(!layoutMap) return widgets;
    layoutMap?.list?.forEach(layout => {
      layoutMap[layout]?.list?.forEach(row => {
        let rowMap = layoutMap[layout][row];
        if(rowMap?.type == 'elements' && rowMap?.elements?.length > 0) {
          widgets = widgets.concat(rowMap.elements);
        }
      });
    });

    return widgets;
  }

  /**
   * this was originally written for older panels having widgets inside 'widgets' array prior to introduction of panel layout
   * this is also used for search panel, because search panel widget generation process returns in plain array, which needs to be transformed into layout structure
   * @param panelMeta
   * @returns
   */
  migrateExistingPanelMeta(panelMeta){
    let newLayout = { list: [], "gridX": 12};
    let id = new Date().valueOf();
    panelMeta?.widgets?.forEach((element, i) => {
      let rowId = new Date().setSeconds(new Date().getSeconds() + i).valueOf();
      newLayout.list.push(rowId)
      newLayout[rowId] = {
          type: "elements",
          elements: [element]
        }
    });
    let layout = {
      "list": [id],
      [id] : newLayout
    }
    panelMeta['layoutMap'] = layout;
    panelMeta.widgets = [];
    console.log("layout", layout)
    return panelMeta
  }

  createWidgetAction(type: any, resultValue: any, widgetId: string, panelId: string){
    let valueToFill: any;
    switch (type) {
      case 'label':
      case 'link':
      case 'richtext':
        valueToFill = resultValue || '${reset}'
        break;
      case 'image':
        valueToFill = resultValue || ''
        break;
      case 'embed':
        valueToFill = resultValue || '${reset}'
        break;

      case 'date':
      case 'time':
      case 'datetime':
        valueToFill = resultValue || ''
        break

      case 'object':
        valueToFill = resultValue || '${reset}'
        valueToFill = JSON.stringify(valueToFill, null, 4);
        break;

      case 'array':
        valueToFill = resultValue || '${reset}'
        valueToFill = JSON.stringify(valueToFill, null, 2);
        // format like an array
        break;
      case 'chips':
        valueToFill = resultValue || '${reset}'
        // value = JSON.stringify(value, null, '2');
        // format like an array
        break;
      case 'tags':
        valueToFill = resultValue || '${reset}'
        // value = JSON.stringify(value, null, '2');
        // format like an array
        break;
      case 'select':
        valueToFill = resultValue || '${reset}'
        break;
      case 'autocomplete':
        valueToFill = resultValue || '${reset}'
        break;
      case 'star':
      case 'slider':
        valueToFill = resultValue || '${reset}'
        break;
      case 'duration':
        valueToFill = resultValue || '${reset}'
        break;
      case 'period':
        valueToFill = resultValue || '${reset}'
        break;
      case 'connection':
        valueToFill = resultValue || ''
        break;
      default:
        break;
    }

    let action: any = {
      action: 'widget',
      actionMap: {
        effectStyles: [],
        mapping: [],
        value: valueToFill,
        widget: panelId + '.' + widgetId,
        widgetActionType: "set_value"
      },
      event: 'click'
    }
    return action
  }

  /**
   * sync method for setting the widget value, this method will set the signal directly.
   * @param widget - Widget meta
   * @param value
   * @param panelId
   * @returns void
   */
  setValue(widget: any, value: any, panelId?: string) {
    widget.setValue(value, false, panelId);
    this.widgetMetaSignal.set(JSON.parse(JSON.stringify(widget)))
    // this.updatedWidgetMeta.next(JSON.parse(JSON.stringify(widget)))
  }

  /**
   * async method for setting the widget value, this method will set the signal once the child widgets initialized.
   * @param widget - Widget meta
   * @param value
   * @param panelId
   * @returns void
   */
  asyncSetValue(widget: any, value: any, panelId?: string) {
    widget.setValue(value, false, panelId);

    setTimeout(() => {
      this.widgetMetaSignal.set(JSON.parse(JSON.stringify(widget)))
      // this.updatedWidgetMeta.next(JSON.parse(JSON.stringify(widget)))
    }, 1);
  }

  /**
   * method to add new property(linkConfig) under widget config(if not present) and store link url value in it
   * @param widgetMeta
   * @returns widgetMeta
   */
  setLinkConfig(widgetMeta) {
    console.log("actionConfig for link widget", JSON.parse(JSON.stringify(widgetMeta)))
        //if linkConfig not present, add
        if(!widgetMeta.config.linkConfig){
          widgetMeta.config.linkConfig = {
            action : "navigation",
            actionMap : {
              boxId: '', 
              mapping: [], 
              parameters: [],
              page: "", 
              pageType: ''},
            active : true,
            event : "click"
          }
        }
          // populate value in linkConfig from config.value
          if(widgetMeta.config.value.pageType){
            widgetMeta.config.linkConfig.actionMap.pageType = widgetMeta.config.value.pageType
            if(widgetMeta.config.value.pageType == 'link'){
              widgetMeta.config.linkConfig.actionMap.link = widgetMeta.config.value.value
            }else if(widgetMeta.config.value.pageType == 'prev_page' || widgetMeta.config.value.pageType == 'bloom_page'){
              widgetMeta.config.linkConfig.actionMap.page = widgetMeta.config.value.value
            }
          }else if(widgetMeta.config.value.value && !widgetMeta.config.value.pageType && this.isValidUrl(widgetMeta.config.value.value)){
            //checking for backward compatibility
            widgetMeta.config.linkConfig.actionMap.link = widgetMeta.config.value.value
          }
          widgetMeta.config.linkConfig.actionMap.parameters = JSON.parse(JSON.stringify(widgetMeta.config.value.parameters)) || []
          console.log("actionConfig for link widget", JSON.parse(JSON.stringify(widgetMeta)))
          return widgetMeta;
  }

  isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }


}
