import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { WidgetManager } from '../../../WidgetManager';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { SystemFieldsService } from 'src/app/shared/services/system-fields.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-widget-action',
    templateUrl: './widget-action.component.html',
    styleUrls: ['./widget-action.component.scss'],
    standalone: false
})
export class WidgetActionComponent implements OnInit {
  private destroy$ = new Subject<void>();
  @Input() "actionConfig": any;
  @Input() "widget": any;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  config: any;
  widgets: any[];

  availableStyleMap: any = {}
  systemFields: any;

  constructor(
    public metaService: MetaService,
    public widgetService: WidgetService,
    public systemFieldsService: SystemFieldsService

  ) { }

  widgetActions:any = [
    {
      id: "set_value",
      name: "Set value"
    },
    {
      id: "effects",
      name: "Effects"
    }
  ]

  events: any = [
    {
      id: "hover",
      name: "Hover"
    },
    {
      id: "click",
      name: "Click"
    }
  ]

  widgetManager: any = WidgetManager;

  ngOnInit(): void {

    if(this.actionConfig.action && !this.actionConfig.actionMap) {
      this.actionConfig.actionMap = { };
    } 

    let widgetConfig = WidgetManager.getWidget(this.widget.type).getWidgetConfig();
    this.availableStyleMap = widgetConfig?.effect || {};
    console.log("widgetConfig", widgetConfig)

    if(!this.actionConfig.actionMap.effectStyles) {
      this.actionConfig.actionMap.effectStyles = [];
    } 

    let te = new TemplateEngine();
    this.metaService.pageMeta.pipe(takeUntil(this.destroy$)).subscribe(pageMeta => {
      this.widgets = [];
      // this.pageMeta = pageMeta
      console.log("---> pagemeta", pageMeta)
      pageMeta.panels.forEach(panelMeta => {
        let panel = panelMeta;
        let widgets = this.widgetService.getWidgetsFromPanel(panel);
        widgets.forEach((widget ) => {
          if(WidgetManager.getWidget(widget.type)?.getWidgetConfig()){
            let config = {
                __id: "${panel.id}.${widget.id}",
                name: "${panel.name} ${widget.name}"
            }//WidgetManager.getWidget(widget.type)?.getMappingConfig();
            // if(config.__id.includes(".value")) config.__id = config.__id.replace(".value", '');
            let data = {
              panel: panel,
              widget: widget
            }
            this.widgets.push(te.fillTemplateObject(config, data))
          }
        })
      })

      console.log("this.widgets", this.widgets);
    })
  }

  onWidgetSelected(){
    console.log("this.actionConfig", this.actionConfig)
  }

  onWidgetActionSelected(){
    console.log("this.actionConfig", this.actionConfig)
  }

  insertFilterCursor(event){
    console.log("insertFilterCursor", event)
  }

  async getSystemFields(){
    let res = await this.systemFieldsService.getSystemFields();
    for (let index = 0; index < res?.fields?.length; index++) {
      const field = res?.fields[index];
      this.systemFields.push(res[field])
    }
    console.log("SYSTEM FIELDS: ",this.systemFields);
  }

  changeDetected(){
    console.log('Change detected...')
    this.metaService.userChangeDetected()
    this.selectionChanged.emit();
  }

  onEffectChanged(){
    if(!this.actionConfig.actionMap.effectStyles) {
      this.actionConfig.actionMap.effectStyles = [];
    } 
    
  }

  addFontStyle(event) {
    // Get the toggled style attribute
    const toggledStyle = event.value;
    
    // Check if the button was toggled on or off
    const isSelected = event.source.checked;
  
    if (isSelected) {
      // Button was toggled ON - Add the style if it doesn't exist
      if (!this.actionConfig.actionMap.effectStyles.some(style => style.attribute === toggledStyle)) {
        this.actionConfig.actionMap.effectStyles.push({
          attribute: toggledStyle,
          value: this.availableStyleMap[toggledStyle]?.value
        });
      }
    } else {
      // Button was toggled OFF - Remove the style
      const index = this.actionConfig.actionMap.effectStyles.findIndex(
        style => style.attribute === toggledStyle
      );
      if (index !== -1) {
        this.actionConfig.actionMap.effectStyles.splice(index, 1);
      }
    }
  
    this.changeDetected();
  }
  
  isFontStyleChecked(attr: string) {
    return this.actionConfig.actionMap.effectStyles.some((s) => s.attribute === attr)
  }

  addNewAttribute(){
    this.actionConfig.actionMap.effectStyles.push({
      attribute: "",
      value: ""
    })
    this.changeDetected();
  }

  removeAttribute(i){
    this.actionConfig.actionMap.effectStyles.splice(i, 1);
    this.changeDetected();
  }

  onAttributeSelected(style){
    let styleMap = this.availableStyleMap[style?.attribute];
    style.value = '';

    if(!styleMap?.inputType) {
      style.value = styleMap.value;
    }
    this.changeDetected();
  }

  setAttributeId(style){

  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete(); // Ensures cleanup
  }
}
