import { CommonConfig } from "./commonWidgetProps";
import { ValidatorConfig } from "./commonWidgetProps";

export class Widget {
  id: number;
  name: string;

  // public pageService?: PageService

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  public getWidgetConfig() {
    return null;
  }

  public getWidgetMeta() {
    return null;
  }

  public getMappingConfig(widget?: any) {
    return null;
  }

  public isOptionBasedWidget() {
    return null;
  }

  public setOptions(values) {
    return null;
  }

  public getEventSubscribe(options?) {
    return null;
  }

  public getEvents() {
    let events = [
      {
        id: 'click',
        name: 'Click',
        function: 'click',
      },
      {
        id: 'hover',
        name: 'Hover',
        function: 'hover',
      },
    ];

    return events;
  }

  public getSecurityEffects() {
    let effects = [
      {
        name: 'Show',
        code: 'show',
      },
      {
        name: 'Hide',
        code: 'hide',
      },
    ];
    return effects;
  }

  effectStyleMap = {
    list: ['color', 'font-size', 'font-weight', 'font-style', 'text-decoration'],
    color: {
      id: 'color',
      name: 'Color',
      inputType: 'color-palette',
    },
    'font-size': {
      id: 'font-size',
      name: 'Font Size',
      inputType: 'string',
    },
    'font-weight': {
      id: 'font-weight',
      name: 'Bold',
      value: 'bold',
      icon: 'format_bold'
    },
    'font-style': {
      id: 'font-style',
      name: 'Italic',
      value: 'italic',
      icon: 'format_italic'
    },
    'text-decoration': {
      id: 'text-decoration',
      name: 'Underline',
      value: 'underline',
      icon: 'format_underlined'
    }
  };

  protected attachCommonConfig(widgetInstance: any) {
    let commonConfig = JSON.parse(JSON.stringify(CommonConfig));
    let validatorConfig = JSON.parse(JSON.stringify(ValidatorConfig));
    if (Array.isArray(commonConfig.properties)) {
      commonConfig.properties
        .slice()
        .reverse()
        .forEach((prop) => {
          if (
            !widgetInstance.config.props.includes(prop) &&
            commonConfig[prop]?.supportedWidgets?.includes(widgetInstance.type)
          ) {
            widgetInstance.config.props.unshift(prop);
            widgetInstance.config[prop] = commonConfig[prop].config;
          }
        });
    }
    if (validatorConfig.supportedWidgets.includes(widgetInstance.type)) {
      widgetInstance['validatorConfig'] = validatorConfig.config;
    }
  }

  /**
   *
   * @param value : value to set
   * @param isOptionConfigValue : flag indicating if the value provided is not regular value, rather it's options configuration array for option based widgets
   */
  public setValue(
    value: any,
    isOptionConfigValue: boolean = false,
    panelId?: any
  ) {
    // console.log("setValue for wid", this, "value", value)
    let widgetConfig = this.getWidgetConfig();
    if (!widgetConfig) return;
    // console.log("widget config", widgetConfig)
    let valuePath = widgetConfig.valuePath;
    // console.log("value path", valuePath)
    // if value reset requested
    if (value == '${reset}') {
      let resetValuePath: string = '';
      let resetValuepathParts: string[];

      resetValuepathParts = valuePath.split('.');
      if (resetValuepathParts.length > 1) {
        resetValuepathParts[resetValuepathParts.length - 1] = 'resetValue';
        resetValuePath = resetValuepathParts.join('.');
      } else if (resetValuepathParts.length == 1) {
        resetValuepathParts[0] = 'resetValue';
        resetValuePath = 'resetValue';
      }

      value = this.getDeepObjectValue(this, resetValuePath) || '';
      // console.log("deepObjectValue set:", value)
    }

    // console.log("THIS:", this)
    // console.log("VALUE:", value)
    // console.log("VALUE PATH:", valuePath)

    if (this.isOptionBasedWidget()) {
      //isOptionConfigValue &&
      // valuePath = 'config.availableOptions.staticOptions'
      // this.setDeepObjectValue(this, valuePath, JSON.parse(JSON.stringify(value)), true)
      this.setOptions(value);
    } else if (
      ['date', 'datetime', 'time'].includes(this.getWidgetMeta()?.type)
    ) {
      this.setDeepObjectValue(
        this,
        valuePath,
        JSON.parse(JSON.stringify(value)),
        true
      );

      // } else if(this.isOptionBasedWidget()){
      //   console.log("vlaue to set in option based widget", JSON.parse(JSON.stringify(value)))
      //   this.setDeepObjectValue(this, valuePath, JSON.parse(JSON.stringify(value)), true)
    } else {
      //!isOptionConfigValue && !this.isOptionBasedWidget()
      this.setDeepObjectValue(this, valuePath, value);
    }

    if (this.getEventSubscribe()) {
      this.getEventSubscribe(this);
    }

    // if(!panelId) return

    // let that:any = this;

    // let dataInput: any = {
    //     dataBindConfig: that.dataBindConfig,
    //     widgetId: that.id,
    //     value: value,
    //     panelId: panelId
    //   }

    //   console.log("actionMap dataInput", dataInput)

    //   this.pageService.updatePageModel(dataInput);
  }

  public getValue() {
    let path = this?.getWidgetConfig()?.valuePath;
    let val = path ? this.getDeepObjectValue(this, path) : null;
    return val;
  }

  private getDeepObjectValue(obj, path) {
    let parts = path.split('.');
    let objTemp: any = obj;
    let len = parts.length;
    for (let j = 0; j < len; j++) {
      objTemp = objTemp[parts[j]];

      if (objTemp == undefined) break;
      if (
        j !== len - 1 &&
        (typeof objTemp !== 'object' || Array.isArray(objTemp))
      ) {
        objTemp = undefined;
        break;
      }
    }
    return objTemp;
  }

  // private setDeepObjectValueNew(obj, path, value){
  //   let parts = path.split('.')
  //   let objTemp: any = obj
  //   let len = parts.length
  //   for(let j = 0; j < len - 1; j++){
  //     objTemp = objTemp[parts[j]]

  //     if(objTemp == undefined) objTemp = {}
  //   }
  //   objTemp[parts[len - 1]] = value

  //   console.log("value injected", obj)
  //   return obj
  // }

  private setDeepObjectValue(
    obj,
    path,
    value,
    isCustomSet?,
    setrecursively: boolean = false
  ) {
    // console.log(`obj: ${JSON.parse(JSON.stringify(obj, null, 2))}, path: ${path}, value: ${JSON.parse(JSON.stringify(value, null, 2))}`)
    // console.log("widget name and id", this.name, this.id)
    // console.log("obj", JSON.parse(JSON.stringify(obj, null, 2)))
    // console.log("path", path)
    // console.log("value", JSON.parse(JSON.stringify(value, null, 2)))
    // console.log("set deep object value ", value, "in widget", JSON.parse(JSON.stringify(this)), "at", path)

    let effectiveValue;
    if (Array.isArray(value)) {
      effectiveValue = JSON.parse(JSON.stringify(value));
    } else {
      effectiveValue = value;
    }

    var pList = path.split('.');
    pList.reduce((a, b, level) => {
      if (
        setrecursively &&
        typeof a[b] === 'undefined' &&
        level !== pList.length
      ) {
        a[b] = {};
        return a[b];
      }
      if (level === pList.length - 1) {
        // console.log("injection position found")
        // console.log("a", JSON.parse(JSON.stringify(a)))
        // console.log("b", b)
        // console.log("value to inject", JSON.parse(JSON.stringify(effectiveValue)))

        if (Array.isArray(a[b]) && !isCustomSet) {
          delete a[b];
          a[b] = [];
          a[b] = a[b].concat(effectiveValue);
        } else if (isCustomSet) {
          if (
            ['date', 'datetime', 'time'].includes(obj?.getWidgetMeta()?.type)
          ) {
            let val = obj.createFormattedValue(effectiveValue);
            a[b] = val;
          } else {
            let val = obj.setOptions(effectiveValue);
            a[b] = val;
          }
        } else {
          a[b] = effectiveValue;
        }

        // console.log("injected", JSON.parse(JSON.stringify(a)))
        return effectiveValue;
      }
      return a[b];
    }, obj);
    return obj;
  }

  mergeEffectStyle(widgetConfig) {
    widgetConfig.effect.styles = widgetConfig.effect.styles.concat(
      this.effectStyleMap.list
    );
    widgetConfig.effect = Object.assign(
      widgetConfig.effect,
      this.effectStyleMap
    );
    return widgetConfig;
  }
};

