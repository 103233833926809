import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.css'],
    standalone: false
})
export class IconComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  textEditMode: boolean = false;
  hoveredNow: boolean = false;
  iconCode: string = ""
  private destroy:any = new Subject();

  @ViewChild('menuTrigger') iconMenuTrigger: MatMenuTrigger

  constructor(
    // public actionManager: ActionManager,
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    public wmtService: WidgetMetaTransformationService,
    public injector: Injector
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService, injector)
  }

  private getActionManager(): ActionManager {
    return this.injector.get(ActionManager);
  }

  ngOnInit(): void {
    // console.log('icon oninit', this.widgetMeta)
    this.destroy = this.metaService?.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
    super.ngOnInit()

    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      // console.log("set value notifier in icon", widgetMeta)
      this.decompressMetaAndAssign(widgetMeta)
      this.initialize()
    })
    // this.initialize()
  }

  ngOnDestroy(): void {
    this.destroy?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes)
    // console.log("iconOnChanges", changes)
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
      this.initialize()
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    super.generateStyles()
  }

  // calculates the icon code
  initialize(){
    if (!this.widgetMeta?.config?.conditionalConfig?.enabled) {
      this.iconCode = this.widgetMeta?.config?.value?.value
    } else if (this.widgetMeta?.config?.conditionalConfig?.enabled && this.widgetMeta?.config?.conditionalConfig?.displayRules?.length) {
      console.log("conditional", this.widgetMeta.config.conditionalConfig)
      this.widgetMeta?.config?.conditionalConfig?.displayRules.forEach(rule => {
        let res = this.checkRule(rule)
        if (res) {
          this.iconCode = res
          if (rule.color) this.widgetMeta.textFormat.color.value = rule.color
          if (rule.tooltip) this.widgetMeta.config.tooltip.value = rule.tooltip
        }
      })
    }
    // console.log("iconCode initialized", this.iconCode)
  }

  checkRule(rule){
    let res = null
    if(rule.compareValue == "_TRUE_" && this.widgetMeta.config.value?.value && this.widgetMeta.config.value?.value != ""){
      res = rule.result;
    }
    if (rule.operator == "=" && rule.compareValue == this.widgetMeta.config.value?.value) {
      res = rule.result
    }
    return res
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        'code',
        'tooltip',
        'fontSize',
        'color',
        'backgroundColor',
      ],
      code: {
        type: this.widgetMeta?.config.value.type
      }
    }
    // if(this.widgetOptions.deleteEnabled !== false) this.contextMenuActions.actions.push('delete')
    if(this.widgetOptions?.configurationEnabled !== false) this.contextMenuActions.actions.push('edit')
    // this.contextMenuActions.actions.push('copy')
    // console.log("contextMenuAction", this.contextMenuActions)
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
    else if (event.actionType == "changeIcon") {
      this.widgetMeta.config.value.value = event.returnData

      console.log("widget meta changed", this.widgetMeta)
      // this.newWidgetMeta.emit(this.widgetMeta)
    }else if(event.actionType == 'updateStyles'){
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        console.log("localMeta changed", this.widgetMeta)

      }
      super.generateStyles();
      // this.newWidgetMeta.emit(this.widgetMeta)
      if (!event.noEmit) this.newWidgetMeta.emit(this.widgetMeta)
    }
  }

  onClick(event: any) {
    // event.stopPropagation();
    console.log("click event detected")
    if(!this.builderMode){
      this.executeAction(event)
    }
  }

  // executeAction(e) {
  //   if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
  //   if (!this.builderMode) this.getActionManager().executeActions(this.widgetMeta.actionConfig.actions, e);
  //   //  this.actionManager.getAction(this.widgetMeta.actionConfig.action).doAction(this.widgetMeta.actionConfig, e)
  // }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.iconMenuTrigger.closeMenu();
  }
}
