import { BaseValidator } from "./BaseValidator"

export class MaxLengthValidator extends BaseValidator{
    id = "maxlength"
    icon =  ""
    name = "Maximum Length"
    config = {
        inputParams: {
            params: ['max', 'customErrorMessage'],
            customErrorMessage: {
                value : "Maximum length criteria failed",
                displayName: "Custom Error Message",
                type: 'string'
            },
            max: {
                value : 255,
                displayName: "Maximum Length",
                type: 'number'
            }
        }
    }

    public validate(val:string, inputParams:any){
        return (val.length <= inputParams.max.value)
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}