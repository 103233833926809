<div>
  <br />
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="90" fxLayoutAlign="center center">
      <h2><b>Flow Sharing and collaboration</b></h2>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </div>
  <div *ngIf="flow" fxLayout="column" fxLayoutAlign="space-around">
    <div fxLayout="column" fxLayoutAlign="space-around center">
      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        style="padding: 20px; text-align: center"
      >
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="22">
          <div fxLayout="row" style="padding: 5px" class="logo-container">
            <img
              *ngIf="flow.trigger.box_logo_url"
              [src]="flow.trigger.box_logo_url"
              class="image-size"
            />
            <mat-icon
              *ngIf="flow.trigger_type == 'schedule'"
              class="icon-size-list"
              >schedule</mat-icon
            >
            <mat-icon
              *ngIf="flow.trigger_type == 'webhook'"
              class="icon-size-list"
              >webhook</mat-icon
            >
            <ng-container *ngFor="let step of flow.steps; let i = index; trackBy: trackByFn">
              <!-- Display logos based on the showMoreLogos state -->
              <ng-container *ngIf="showMoreLogos || i < 4">
                <mat-icon
                  *ngIf="
                    step.box_logo_url && !step.box_logo_url.includes('https://')
                  "
                  class="icon-size-list"
                  style="margin-right: 5px"
                >
                  {{ step.box_logo_url }}
                </mat-icon>
                <img
                  *ngIf="step?.box_logo_url?.includes('https://')"
                  [src]="step.box_logo_url"
                  class="image-size"
                  style="margin-right: 5px"
                />
              </ng-container>
              <!-- Display toggle icon after showing all logos -->
              <ng-container *ngIf="i === flow.steps.length - 1">
                <!-- <div class="dots">...</div> -->
                <mat-icon
                  *ngIf="flow.steps.length > 4"
                  class="icon-show-more"
                  matTooltip="{{ showMoreLogos ? 'Show Less' : 'Show More' }}"
                  matTooltipPosition="above"
                  (click)="toggleLogos()"
                  style="cursor: pointer"
                >
                  {{ showMoreLogos ? "remove" : "add" }}
                </mat-icon>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <mat-card-title style="margin: 5px">{{ flow.name }}</mat-card-title>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        style="padding: 15px; text-align: center"
      >
        <div style="margin-top: 4%">
          <mat-card-subtitle>Flow : {{ flow.name }}</mat-card-subtitle>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<mat-tab-group style="max-height: 50vh">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">admin_panel_settings</mat-icon>
      Flow Members
    </ng-template>
    <app-common-member
      [restoreInput]="restoreInput"
      [resourceType]="dialogData.resourceType"
      [resourceMap]="dialogData.resource"
    ></app-common-member>
  </mat-tab>
</mat-tab-group>
<br />
<div fxLayout="row" fxLayoutAlign="stretch" *ngIf="!isShowInvite">
  <button
    mat-stroked-button
    color="primary"
    (click)="isShowInvite = true"
    type="button"
  >
    Invite Members
  </button>
</div>
<div fxLayout="row" class="mat-h4" *ngIf="isShowInvite">
  <h3 style="margin-bottom: 0px"><b>Invite Members</b></h3>
</div>
<div *ngIf="isShowInvite">
  <app-resource-collaboration
    (cancel)="isShowInvite = false"
    (emailSent)="isShowInvite = false; resetLoading()"
    [resourceMap]="dialogData.resource"
    [resourceType]="dialogData.resourceType"
    [workspaceMap]="dialogData.workspaceMap"
  >
  </app-resource-collaboration>
</div>
