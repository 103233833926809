import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'step-title-bar',
    templateUrl: './step-title-bar.component.html',
    styleUrls: ['./step-title-bar.component.scss'],
    standalone: false
})
export class StepTitleBarComponent implements OnInit {

  @Input() steps: any;
  @Input() index: any;
  @Input() editMode: any = true;
  @Input() supportDrag:any;
  @Output() "stepDeleted" = new EventEmitter<any>();
  @Output() "dragged" = new EventEmitter<any>();
  @Output() "editStep" = new EventEmitter<any>();

  step:any;
  stepTypeDisplay: any = { "action": "Action", control: "Control", processor: "Processor" }

  constructor(
   private http:  HttpClient,
  ) { }


  ngOnInit(): void {
    this.step = this.steps[this.index]
  }

  drop(e){
    console.log("draged", e);
    this.dragged.emit(e);
  }

  deleteStep(){
    this.stepDeleted.emit(this.index);
  }

  editStepIndex(){
    this.editStep.emit(this.index);
  }


}

