import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatRadioModule} from '@angular/material/radio';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlowPublishDialogComponent } from './flow-publish-dialog.component';
import { SpinnerModule } from "../../../shared/spinner/spinner.module";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TemplateDataCollectionModule } from 'src/app/bloom/builder/publish-config/collect-template-data/template-data-collection.module';


@NgModule({
    declarations: [
        FlowPublishDialogComponent
    ],
    exports: [
        FlowPublishDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatTabsModule,
        RouterModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        DragDropModule,
        SpinnerModule,
        MatSlideToggleModule,
        TemplateDataCollectionModule
    ]
})
export class FlowPublishDialogModule { }
