<div fxLayout fxLayout="center center">
  <spinner *ngIf="!isReceivedConnections" [center]="true"></spinner>
</div>
<form *ngIf="isReceivedConnections" class="box_select_ac example-form example-full-width" fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="start center">
  <div fxLayout="column" fxFlex="60" fxLayoutAlign="start start">
    <mat-form-field class="example-full-width" fxFlex="50%">
      <mat-label>Choose a connection</mat-label>
      <input
        #boxInput
        type="text"
        aria-label="connection"
        matInput
        [formControl]="selectBoxControl"
        [matAutocomplete]="auto"
      >
      <button *ngIf="boxInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectBoxControl.patchValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnBox" (optionSelected)="boxSelected($event)">
        <mat-option *ngFor="let box of filteredBoxes | async; trackBy: trackByFn" [value]="box">
          <img class="example-option-img" matTooltip="{{ box.box_id }}" [src]="box.options.box_logo_url ? box.options.box_logo_url : ''" height="25" width="25">
          <span>{{box.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- <span *ngIf="oldValue">
      <span style="color: #333; font-size: .9rem;">Existing selection : </span>
      <span style="margin-left: 0 1rem; font-size: 1rem;"><b>{{ oldValue }}</b></span>
    </span> -->
  </div>

  <button
    class="sidebuttons"
    mat-icon-button
    matTooltip="Refresh"
    fxFlex="8%"
    (click)="refreshConnections()"
  >
    <mat-icon>refresh</mat-icon>
  </button>

  <a
    mat-icon-button
    fxFlex="8%"
    matTooltip="New connection"
    fxLayout fxLayoutAlign="center center" fxFlexFill
    style="text-decoration: none; height: 100%; width: 100%;"
    [routerLink]="['../../connection']"
    [queryParams]="{workspaceid: workSpaceId, authorization: preAuthenticatedToken}"
    target="_blank"
  >
    <mat-icon>add</mat-icon>
  </a>
  <spinner *ngIf="!isReceivedConnections" fxFlex="10%" [color]="'#555'"></spinner>

</form>
