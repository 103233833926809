import { BaseValidator } from "./BaseValidator"

export class RegexValidator extends BaseValidator {
    id = "regex"
    icon =  ""
    name = "Regular Expression"
    config = {
        inputParams: {
            params: ['regex', 'customErrorMessage'],
            customErrorMessage: {
                value : "Enter a valid Input",
                displayName: "Custom Error Message",
                type: 'string'
            },
            regex: {
                value : "",
                displayName: "Regular Expression",
                type: 'string'
            }
        }
    }

    public validate(val:string, inputParams:any){
        const regex = new RegExp(inputParams.regex.value); // Convert the string to a RegExp object
        return regex.test(val); // Use .test() on the RegExp object
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}