<!-- about -->
<div fxLayout="column" fxLayoutGap="2%">
  <diV fxLayout="row"> Predefined filters </diV>
  <diV fxLayout="row">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="90">

      <div fxLayout="row" style="cursor: pointer">
        <mat-radio-group [(ngModel)]="filterType" aria-label="Select an option" (change)="onTypeChange()">
          <mat-radio-button value="any">Any app</mat-radio-button> &nbsp;
          <mat-radio-button value="categories">Specific categories</mat-radio-button> &nbsp;
          <mat-radio-button value="apps">Specific apps</mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxLayout="row" *ngIf="filterType != 'any'">
        <mat-form-field class="example-chip-list" appearance="outline">
          <mat-label>Choose {{filterType == 'apps' ? 'Apps' : 'Categories' }}</mat-label>
          <mat-chip-grid #chipList aria-label="Selection">
            <mat-chip-row *ngFor="let obj of lists; trackBy: trackByFn">
              {{obj.name || obj.__id}}
              <mat-icon style="cursor: pointer; opacity: 50%;" (click)="removeList(obj)">cancel</mat-icon>
            </mat-chip-row>
            <input
              placeholder="New {{filterType == 'apps' ? 'App' : 'Category' }}..."
              #fruitInput
              [formControl]="listCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addList($event)">
          </mat-chip-grid>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedList($event)">
            <mat-option *ngFor="let obj of filteredList | async; trackBy: trackByFn" [value]="obj">
              {{obj.name || obj.__id}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        
      </div>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </diV>
</div>