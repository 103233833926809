import { BaseValidator } from "./BaseValidator"

export class MaxValueValidator extends BaseValidator{
    id = "maxvalue"
    icon =  ""
    name = "Maximum Value"
    config = {
        inputParams: {
            params: ['min', 'customErrorMessage'],
            customErrorMessage: {
                value : "Maximum value criteria failed",
                displayName: "Custom Error Message",
                type: 'string'
            },
            min: {
                value : 100,
                displayName: "Maximum Value",
                type: 'number'
            }
        }
    }

    public validate(val:number, inputParams:any){
        return (val <= inputParams.min.value)
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}