import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { StarchService } from 'src/app/shared/services/starch.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
    selector: 'starch-attibute-side-panel',
    templateUrl: './starch-attibute-side-panel.component.html',
    styleUrls: ['./starch-attibute-side-panel.component.scss'],
    standalone: false
})
export class StarchAtrributesSidePanelComponent implements OnInit {

  attributeMap:any = {
    __id: ""
  }

  isRelation: boolean = false;
  objects: any = [];
  relationObjectAttributes:any = [];
  relationMap:any = {};
  exisitngRelationMap:any = {}
  rowData:any

  cardinalities: any = [
    {name: "One-to-one", __id: "1:1"},
    {name: "Many-to-one", __id: "N:1"},
    {name: "One-to-many", __id: "1:N"},
    {name: "Many-to-many", __id: "N:N"},
  ]
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<StarchAtrributesSidePanelComponent>,
    private spinnerService: SpinnerService,
    public starchService: StarchService,
  ) {  
    
  }
  


  async ngOnInit() {
    console.log("dialogData", this.dialogData);

    this.rowData = this.dialogData?.row?.data;
    console.log("rowData", this.rowData);
    this.attributeMap.__id = JSON.parse(JSON.stringify(this.rowData?.__id?.value));

    if(this.rowData?.relation?.value ){
      this.spinnerService.show();
      let relation:any = this.rowData?.relation?.value || {};
      await this.getObjects();
      this.exisitngRelationMap.object = this.objects.filter((e:any) => e?.__id == relation.object)[0];
      this.relationObjectAttributes = await this.getBaseObjectAttributes(relation.object);
      this.exisitngRelationMap['attribute'] = this.relationObjectAttributes.filter((e:any) => e?.__id == relation.attribute)[0];
      this.exisitngRelationMap['name'] = this.relationObjectAttributes.filter((e:any) => e?.__id == relation.name)[0];
      this.exisitngRelationMap['cardinality'] = this.cardinalities.filter((e:any) => e?.__id == relation.cardinality)[0];
      this.relationMap = await this.starchService.getStarchRelation(this.dialogData?.object?.baseMap, relation.relationship_rec_id);
      console.log("relationMap", this.relationMap)
      this.isRelation = true;
      this.spinnerService.hide();
    }
  }

  async getObjects(){
    console.log("dialogData -->", this.dialogData);
    this.objects = await this.starchService.getBaseObjects(this.dialogData?.object?.baseMap);
    console.log("objects", this.objects)
  }

  async relationshipChanged(){
    this.spinnerService.show();
    this.relationObjectAttributes = [];
    if(this.isRelation){
      await this.getObjects();
      this.relationMap = {
        object: this.dialogData?.object?.__id,
        attribute: this.rowData?.__id?.value,
        name: this.rowData?.name?.value || null,
        relation: {}
      }
    }
    this.spinnerService.hide();
  }

  async selectedObject(event){
    console.log("event", event);
    this.relationMap.relation['object'] = event.__id;
    this.relationObjectAttributes = await this.getBaseObjectAttributes(event?.__id)
  }

  async selectedAttribute(event){
    console.log("event", event);
    this.relationMap.relation['attribute'] = event.__id;
  }

  async selectedName(event){
    this.relationMap.relation['name'] = event.__id;
  }

  async selectedCardinality(event){
    console.log("event", event);
    this.relationMap.relation['cardinality'] = event.__id;
  }

  async getBaseObjectAttributes(object){
    this.spinnerService.show();
    let payload = {
      object: object
    }

    console.log("payload", payload)
    let attributes = await this.starchService.getBaseAttributes(this.dialogData?.object?.baseMap, payload);
    console.log("attributes", attributes)
    this.spinnerService.hide();
    return attributes;
  }

  async updateAttribute(){
    console.log("dialogData", this.dialogData);
    console.log("attributeMap", this.attributeMap)
    let baseMap = this.dialogData?.object?.baseMap;
    if(this.isRelation){
      console.log("this.relationMap", this.relationMap);
      if(this.relationMap._id){
        await this.starchService.updateStarchRelation(baseMap, this.relationMap);
      } else await this.starchService.createStarchRelation(baseMap, this.relationMap);
    } else{
      if(this.relationMap._id) await this.starchService.deleteStarchRelation(baseMap, this.relationMap._id);
    }
    
    this.spinnerService.show();
    let payload = {
    object: this.dialogData?.object?.__id,
      attributes: [
        { field: this.rowData?.__id?.value, updateName: this.attributeMap?.__id }
      ]
    }

    if(this.rowData?.__id?.value != this.attributeMap?.__id)await this.starchService.updateBaseAttributes(baseMap, payload);
    this.spinnerService.hide();
    this.dialogRef.close(true);
  }

  close(){
     this.dialogRef.close();
  }

}
