import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { MetaService } from './meta-service';
import { WidgetManager } from '../models/WidgetManager';
import { BoxService } from './box-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { WorkspaceService } from 'src/app/shared/services/workspace.service';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

// by default all are enabled. a property has to be explicitly false to disable the feature inside widget
export interface WIDGET_OPTIONS {
  dragDrop?: boolean;
  textEdit?: boolean;
  deleteEnabled?: boolean;
  configurationEnabled?: boolean;
  widgetSelectionEnabled?: boolean;
  hideChartIfNoData?: boolean;  // applies to chart widgets; if no data is fetched, dont show charts
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class WidgetUtilityService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private themeService: ThemeService,
    private authService: AuthServiceService,
    private metaService: MetaService,
    private boxService: BoxService,
    public resourcePermissions: ResourcePermissionService,
    public workspaceService: WorkspaceService,
    private _snackBar: MatSnackBar
  ) {
  }

  inputTypeWidgets:any = ['input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext',
    'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star']

  optionBasedWidgets:any = ['checkbox', 'select', 'choice', "chips", "autocomplete"]




  isInputWidget(widgetType: any){
    return this.inputTypeWidgets.includes(widgetType)
  }

  isOptionBasedWidget(widgetType: any) {
    return this.optionBasedWidgets.includes(widgetType)
  }

  /**
   *
   * @param attributes
   * @param count
   * @param handleNestedFields : zoho returns derived fields as separate attributes.
   *                             if this flag is true, derived field ids if matched, are put into 'children' array inside parent attribute
   * @returns
   */
  intelliSenseAttribute(attributes: any[], count: number, handleNestedFields: boolean = false) : any[]{
    let regex = /email|phone|id|name|category|status|type|amount|address/i
    let newArray = []
    let i = 0
    let len = attributes.length
    let matched = 0
    let notMatched = []   //to keep the indices that didnt match, (to be accessed and added sequentially if not got 4 intelligent matches)
    while(i < len){
      if(attributes[i]['__id'] == '_id') {
        i++;
        continue;
      }

      if (handleNestedFields && attributes[i].__id.includes('.')) {
        let childPath = attributes[i]?.__id?.split('.')[1]
        let parentPath = attributes[i]?.__id?.split('.')[0]

        if(regex.test(childPath)) {
          let parentAttr = attributes.find(attr => attr.__id == parentPath)
          if (!parentAttr.children?.length) {
            parentAttr['children'] = [childPath]
          } else {
            parentAttr['children'].push(childPath)
          }
          console.log("child matched", childPath)
          console.log("new array", JSON.parse(JSON.stringify(newArray)))
          if (!newArray.find(a => a.__id == parentAttr.__id)) {
            console.log("not found, adding", parentAttr)
            newArray.push(parentAttr)
            matched++
          }
        }
      } else {
        if(regex.test(attributes[i].__id)){
          if (!newArray.find(a => a.__id == attributes[i].__id)) {
            newArray.push(attributes[i])
            matched++
          }
        }else{
          notMatched.push(i)
        }
      }

      i++;

      if(matched == count) break
    }

    // if not got matches == count, add from unmantched list to make total 4
    let j = 0;
    while(matched < count && j < notMatched.length){
      newArray.push(attributes[notMatched[j]]);
      j++;
      matched++;
    }
    console.log("intellisensed array: ", newArray)
    return newArray
  }

  /**
   * this will format date/time/datetime strings to required format as saved in user's settings
   * @param datetimestring [string]
   * @param type [string] : 'datetime' | 'date' | 'time'
   */
  formatDateTime(datetimestring: string, type: string){
    let dateStyle: string = this.getDateStyle()
    let timeStyle: string = this.getTimeStyle()

    let value: any = new Date(datetimestring)
    // console.log("date/time/datetime value:", value)
    console.log("formatDateTime", this.themeService.currentLocale)
    let timeZone: string = this.themeService?.currentLocale?.timeZone
    let gmtOffset = timeZone?.substring(1, 10)

    let formattedDate: string = ''
    if(dateStyle == 'short')
    formattedDate = formatDate(value, 'M/d/yy', this.locale, gmtOffset)
    else if(dateStyle == 'medium')
    formattedDate = formatDate(value, 'MMM d, y', this.locale, gmtOffset)
    else if(dateStyle == 'long')
    formattedDate = formatDate(value, 'MMMM d, y', this.locale, gmtOffset)
    else if(dateStyle == 'full')
    formattedDate = formatDate(value, 'EEEE, MMMM d, y', this.locale, gmtOffset)

    // console.log("formatted date", formattedDate)

    console.log("timeStyle", timeStyle)
    let formattedTime: string = ''
    if(timeStyle == 'short')
      formattedTime = formatDate(value, 'shortTime', this.locale, gmtOffset)
    else if(timeStyle == 'medium')
      formattedTime = formatDate(value, 'mediumTime', this.locale, gmtOffset)
    else if(timeStyle == 'long')
      formattedTime = formatDate(value, 'longTime', this.locale, gmtOffset)
    else if(timeStyle == 'full')
      formattedTime = formatDate(value, 'fullTime', this.locale, gmtOffset)

    // console.log("formatted time", formattedTime)

    if(type == 'date')
      value = formattedDate
    else if(type == 'datetime')
      value = formattedDate + ' ' + formattedTime
    else if(type == 'time')
      value = formattedTime

    return value
  }

  /**
   * this custom function does not ignore the timezone.
   * defualt js toISOString() produces output in default timezone, so the time value gets altered
   * SOURCE: https://stackoverflow.com/a/17415677
   */
  static toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
        return (num < 10 ? '0' : '') + num;
    };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  private getDateStyle(){
    let dateStyle: string
    let dateFormat: string = this.themeService.currentLocale.dateFormat || 'short'
    dateFormat = dateFormat.split(' ')[0].toLowerCase()
    if(dateFormat == 'full' || dateFormat == 'long' || dateFormat == 'medium' || dateFormat == 'short'){
      dateStyle = dateFormat
    }else{
      dateStyle = 'short'
    }
    return dateStyle
  }

  private getTimeStyle(){
    let timeStyle: string
    let timeFormat: string = this.themeService.currentLocale.timeFormat || 'short'
    timeFormat = timeFormat.split(' ')[0].toLowerCase()
    if(timeFormat == 'full' || timeFormat == 'long' || timeFormat == 'medium' || timeFormat == 'short'){
      timeStyle = timeFormat
    }else{
      timeStyle = 'short'
    }
    return timeStyle
  }

  // getDynamicValue(code: string){
  //   let value: any
  //   let d: Date
  //   switch (code) {
  //     case '__CURRENTTIME__':
  //       d = new Date()
  //       value = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
  //       console.log("returning time", value)
  //       break;
  //     case '__CURRENTDATE__':
  //       d = new Date()
  //       value = d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear()
  //       console.log("returning date", value)
  //       break
  //     case '__CURRENTDATETIME__':
  //       value = new Date().toISOString()
  //       console.log("returning datetime", value)
  //       break
  //     case '__CURRENTUSEREMAIL__':
  //       value = this.authService.profile.email
  //       break
  //     case '__PUBLISHEREMAIL__':
  //       this.metaService.get_bloomMeta.subscribe(bloomMeta => {
  //         value = bloomMeta.publisher_email
  //       })
  //       break
  //     case '__CURRENTUSERNAME__':
  //       value = this.authService.profile.first_name + ' ' + this.authService.profile.last_name
  //       break
  //     default:
  //       value = ''
  //       break;
  //   }
  //   return value
  // }

  /**
   * checks and injects default value in the widgetMeta if necessary
   * @param widgetMeta
   * @returns new widgetMeta with default value injected
   */
  injectDefaultValue(widgetMeta: any){
    // console.log("handle default value hit", widgetMeta)

    let value: any
    // check if default value enabled
    let defaultValueObj = widgetMeta?.config?.defaultValue
    if(!defaultValueObj || !defaultValueObj['enabled']) return widgetMeta

    // determine default value
    if(!defaultValueObj.dynamic){
      // console.log("setting static default value", defaultValueObj.value)
      value = defaultValueObj.value
    }else{

      // TODO
      // instead of calculating the dynamic value, assign the dynamic code
      // when pageModel is getting used, replace any code if exists in page model
      let dynamicValue = defaultValueObj.dynamicCode


      // let dynamicValue = this.getDynamicValue(defaultValueObj.dynamicCode)
      // console.log("setting dynamic default value", dynamicValue)
      value = dynamicValue
    }

    // make the regular widgetMeta object as widget class instance (we need to use setValue())
    let newWidget: any = WidgetManager.getWidget(widgetMeta.type)
    Object.keys(widgetMeta).forEach(k => newWidget[k] = widgetMeta[k])
    newWidget.setValue(value)

    // console.log("new widget with injected value", newWidget)

    return newWidget
  }


  /**
   * fetches dynamic options based on box binding inside widgetMeta.config.availableOptions.dynamicOptions
   * @param widgetMeta
   * @returns
   */
  async fetchDynamicOptions(widgetMeta: any, builderMode: boolean){
    // console.log("handleDynamicOptions hit")
    let dynamicOptions = widgetMeta.config.availableOptions.dynamicOptions
    if(!dynamicOptions || !dynamicOptions.enabled || !(dynamicOptions.connectionId || dynamicOptions.boxConfigToken) || !dynamicOptions.boxObjectId) {
      console.log("dynamic options not enabled or invalid")
      return []
    }

    let dataBindSetup: any = {
      boxId: dynamicOptions.boxId,
      boxObject: dynamicOptions.boxObjectId,
      connectionId: dynamicOptions.connectionId,
      boxConfigToken: dynamicOptions.boxConfigToken, //for starch
      baseMap: dynamicOptions.baseMap || {},
      pageNumber: 1,
      pageSize: 20,
      getFnOptions: dynamicOptions.getFnOptions || [],
      attributes: [dynamicOptions.nameAttribute.__id, dynamicOptions.valueAttribute.__id]
    }

    if(dynamicOptions?.filter?.filterEnabled){
      dataBindSetup.filters = dynamicOptions?.filter?.filterItems
    }
    console.log("dataBindSetup", dataBindSetup)
    let mode: string = builderMode ? 'preauthenticated_token' : 'user_api_key'
    let res: any
    try{
      res = await this.boxService.getAny(dataBindSetup, mode)
    }catch(e){
      console.log("could not fetch dynamic options", e)
      throw e
    }
    console.log("dynamic options fetched", res)
    return res.data
  }

  bloomUserData:any = [];
  async getBloomUsers(){
    if(this.bloomUserData.length > 0 || !this.metaService?.bloomMeta) return this.bloomUserData;
    let permissions = await this.resourcePermissions.getPermissions(this.metaService.bloomMeta.code);
    var userIds = [];
    permissions.forEach((e) => {
      userIds.push(e.user_id);
    });
    var users = await this.workspaceService.getUserbyIds(userIds);
    this.bloomUserData = users;

    return users;
  }


  processDynamicOptions(dynamicOptionItems: any[], widgetMeta: any){
    let options = []
    let valuePath: string = widgetMeta.config.availableOptions.dynamicOptions?.valueAttribute?.__id || "" + (widgetMeta.config.availableOptions.dynamicOptions.valuePath ? `/${widgetMeta.config.availableOptions.dynamicOptions.valuePath}` : '')
    let namePath: string = widgetMeta.config.availableOptions.dynamicOptions?.nameAttribute?.__id || "" + (widgetMeta.config.availableOptions.dynamicOptions.namePath ? `/${widgetMeta.config.availableOptions.dynamicOptions.namePath}` : '')

    dynamicOptionItems.forEach((opt: any) => {
      let optionObject = {
        name: this.getDeepObjectValue(opt, namePath),
        value: this.getDeepObjectValue(opt, valuePath),
        default: false
      }
      // console.log("optionObject prepared", optionObject)
      options.push(optionObject)
    })
    // console.log("prepared dynamic options", options)
    return options
  }

  /**
   * fetch and return available options for options lsiting type widget
   * @param widgetMeta : widgetMeta
   * @returns options array
   */
  async getAvailableOptions(widgetMeta: any): Promise<any[]> {
    // Extract static options from widget metadata
    const staticOptions = widgetMeta.config?.availableOptions?.staticOptions || [];
    let rawDynamicOptions: any[] = [];
    let dynamicOptionItems: any[] = [];

    // Check if dynamic options are enabled
    if (widgetMeta.config?.availableOptions?.dynamicOptions?.enabled) {
      try {
        if (!widgetMeta.config.availableOptions.dynamicOptions.userData) {
          // Fetch dynamic options based on userData
          rawDynamicOptions = await this.fetchDynamicOptions(widgetMeta, false);
        } else {
          // Fetch bloom users if userData is not set
          rawDynamicOptions = await this.getBloomUsers();
        }
      } catch (error) {
        console.error("Error fetching dynamic options:", error);
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: {
            "message": "Error fetching dynamic options:",
            title: 'Error fetching dynamic options:',
            description:JSON.stringify(error?.error?.error || error?.error?.message || error),
            recommendation: "Please reload the page and try again. If the issue persists, contact support for assistance.",
            isError: true
          },
        });
      }
      // Process dynamic options
      dynamicOptionItems = this.processDynamicOptions(rawDynamicOptions, widgetMeta);
    }

    // Combine static and dynamic options using spread syntax
    return [...staticOptions, ...dynamicOptionItems];
  }


  getDeepObjectValue(obj, path){
    // console.log("getDeepObjectValue hit", obj, path)
    if(typeof obj !== 'object' || Array.isArray(obj) || !path) return obj
    let parts = path.split('.')
    let objTemp: any = obj
    let len = parts.length
    for(let j = 0; j < len; j++){
      objTemp = objTemp?.[parts[j]]

      if(objTemp == undefined) break
      // if(j !== (len - 1) && (typeof objTemp !== 'object' || Array.isArray(objTemp))){
      if(j !== (len - 1) && (typeof objTemp !== 'object')){
        console.log("broken path")
        objTemp = undefined
        break
      }
    }
    // console.log("returning", objTemp)
    return objTemp
  }

  /**
   * Checks whether a given data is iterable or not
   * array are considered iterable
   * objects which can be directly transformed into array, i.e. having numeric keys with consecutive values e.g. {0: 'a', 1: 'b', 2: 'c'} is considered iterable
   * @param value : any
   * @returns boolean (true if value is iterable, false otherwise)
   */
  static isIterable(value) {
    // Check if value is not null and is of type object or function
    if (value != null && value != undefined && (typeof value === 'object' || typeof value === 'function')) {
      // Check if value is an array or has a Symbol.iterator property
      if (Array.isArray(value) || typeof value[Symbol.iterator] === 'function') {
        return true;
      }
      // Check if value is an object with consecutive integer keys
      if (typeof value === 'object') {
        const keys = Object.keys(value).map(Number).sort((a, b) => a - b);
        return keys.length > 0 && keys.every((key, index) => key === index);
      }
    }
    return false;
  }


  /**
   * FOR BACKWARD COMPATIBILITY (OLD WIDGET METAS MAY NOT HAVE TITLE FIELD)
   * Adds the title and showTitle field to the widget meta if not already exists.
   * @param {any} widgetMeta - The widgetMeta to add the title field to.
   * @returns {any} The modified widgetMeta object.
   */
  addTitleIfNotExists (widgetMeta: any) : any {
    // commenting the following line because non-input widgets can also have title e.g. link and tags
    // if (!this.isInputWidget(widgetMeta.type)) return widgetMeta
    let newBlueprint = WidgetManager.getWidget(widgetMeta.type) // new widget with latest updated properties

    if (!newBlueprint.config.props.includes('title')) {
      newBlueprint = WidgetManager.getWidget('input')
    }

    if (!widgetMeta.config.props.includes('title') && !widgetMeta.config.title) {
      widgetMeta.config.props.push('title')
      widgetMeta.config['title'] = newBlueprint.config.title
      widgetMeta.config.title.value = ""
    }
    return widgetMeta
  }

  /**
   * FOR BACKWARD COMPATIBILITY (OLD WIDGET METAS MAY NOT HAVE DESCRIPTION FIELD)
   * THIS APPLIES TO INPUT WIDGETS FOR NOW
   * Adds the description field to the widget meta if not already exists.
   * @param {any} widgetMeta - The widgetMeta to add the title field to.
   * @returns {any} The modified widgetMeta object.
   */
  addDescriptionIfNotExists (widgetMeta: any) : any {
    // console.log("addDescriptionIfNotExists", JSON.parse(JSON.stringify(widgetMeta)))
    if (!this.isInputWidget(widgetMeta.type)) return widgetMeta
    let newBlueprint = WidgetManager.getWidget(widgetMeta.type) // new widget with latest updated properties
    if (!widgetMeta.config.props.includes('description') && !widgetMeta.config.description) {
      widgetMeta.config.props.push('description')
      widgetMeta.config['description'] = newBlueprint.config.description
    }
    return widgetMeta
  }

  addValidationIfNotExists (widgetMeta: any) : any {
    // console.log("addValidationIfNotExists", JSON.parse(JSON.stringify(widgetMeta)))
    if(['input', 'numberinput'].includes(widgetMeta.type)){
      if(!widgetMeta?.validatorConfig || !widgetMeta.config?.supportedValidators){
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        widgetMeta.config['supportedValidators'] = newBlueprint?.config?.supportedValidators
        widgetMeta['validatorConfig'] = {isEnabled : false, validators: []}
      }
    }
    return widgetMeta
  }

  /**
   * rename dateValue to value in date input widget
   * applies to date and datetime widget
   */
  renameDateValueToValue (widgetMeta) : any {
    if (widgetMeta.type == 'date') {
      if (widgetMeta.config.props.includes('dateValue') || widgetMeta.config.dateValue) {
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('dateValue'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.dateValue.value
        delete widgetMeta.config.dateValue
      }
    } else if (widgetMeta.type == 'datetime') {
      if (widgetMeta.config.props.includes('dateTimeValue') || widgetMeta.config.dateTimeValue) {
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)

        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('dateTimeValue'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.dateTimeValue.value
        delete widgetMeta.config.dateTimeValue
      }
    }
    return widgetMeta
  }

  /**
   * rename textContent to value in text input widget
   * applies to input and numberinput widget
   */
  renameTextContentToValue (widgetMeta) : any {
    // console.log("rename text content hit", widgetMeta, "type", widgetMeta.type)
    if (['input', 'numberinput', 'textarea', 'select'].includes(widgetMeta.type)) {
      // console.log("widget type will be processed", widgetMeta.type)
      if (widgetMeta.config.props.includes('textContent') || widgetMeta.config.textContent) {
        // console.log("textContent prop found")
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        // console.log("newBlueprint", newBlueprint)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('textContent'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        // console.log("value prop added in props", widgetMeta.config.props)
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.textContent.value
        // console.log("value injected", widgetMeta.config.value)
        delete widgetMeta.config.textContent
      }
    }
    return widgetMeta
  }

  /**
   * rename labelText to value in text widget
   */
  renameLabelTextToValue (widgetMeta) : any {
    // console.log("rename labelText hit", widgetMeta, "type", widgetMeta.type)
    if (['label'].includes(widgetMeta.type) && widgetMeta.config.labelText) {
      // console.log("widget type will be processed", widgetMeta.type)
      if (widgetMeta.config.props.includes('labelText') || widgetMeta.config.labelText) {
        // console.log("labelText prop found")
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        // console.log("newBlueprint", newBlueprint)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('labelText'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        // console.log("value prop added in props", widgetMeta.config.props)
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.labelText.value
        // console.log("value injected", widgetMeta.config.value)
        delete widgetMeta.config.labelText
      }
    }
    return widgetMeta
  }

  /**
   * rename linkUrl to value in link widget
   */
  renameLinkUrlToValue (widgetMeta) : any {
    // console.log("rename linkUrl hit", widgetMeta, "type", widgetMeta.type)
    if (['link'].includes(widgetMeta.type) && widgetMeta.config.linkUrl) {
      // console.log("widget type will be processed", widgetMeta.type)
      if (widgetMeta.config.props.includes('linkUrl') || widgetMeta.config.linkUrl) {
        // console.log("linkUrl prop found")
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        // console.log("newBlueprint", newBlueprint)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('linkUrl'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        // console.log("value prop added in props", widgetMeta.config.props)
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.linkUrl.value
        // console.log("value injected", widgetMeta.config.value)
        delete widgetMeta.config.linkUrl
      }
    }
    return widgetMeta
  }

  /**
   * rename buttonText to value in button widget
   */
  renameButtonTextToValue (widgetMeta) : any {
    // console.log("rename buttonText hit", widgetMeta, "type", widgetMeta.type)
    if (['button'].includes(widgetMeta.type) && widgetMeta.config.buttonText) {
      // console.log("widget type will be processed", widgetMeta.type)
      if (widgetMeta.config.props.includes('buttonText') || widgetMeta.config.buttonText) {
        // console.log("buttonText prop found")
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        // console.log("newBlueprint", newBlueprint)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('buttonText'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        // console.log("value prop added in props", widgetMeta.config.props)
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.buttonText.value
        // console.log("value injected", widgetMeta.config.value)
        delete widgetMeta.config.buttonText
      }
    }
    return widgetMeta
  }

  /**
   * rename code to value in icon widget
   */
  renameCodeToValue (widgetMeta) : any {
    // console.log("rename code hit", widgetMeta, "type", widgetMeta.type)
    if (['icon'].includes(widgetMeta.type) && widgetMeta.config.code) {
      // console.log("widget type will be processed", widgetMeta.type)
      if (widgetMeta.config.props.includes('code') || widgetMeta.config.code) {
        // console.log("code prop found")
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        // console.log("newBlueprint", newBlueprint)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('code'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        // console.log("value prop added in props", widgetMeta.config.props)
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.code.value
        // console.log("value injected", widgetMeta.config.value)
        delete widgetMeta.config.code
      }
    }
    return widgetMeta
  }

  /**
   * rename url to value in embed widget
   */
  renameUrlToValue (widgetMeta) : any {
    // console.log("rename url hit", widgetMeta, "type", widgetMeta.type)
    if (['embed'].includes(widgetMeta.type) && widgetMeta.config.url) {
      // console.log("widget type will be processed", widgetMeta.type)
      if (widgetMeta.config.props.includes('url') || widgetMeta.config.url) {
        // console.log("url prop found")
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        // console.log("newBlueprint", newBlueprint)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('url'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        // console.log("value prop added in props", widgetMeta.config.props)
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.url.value
        // console.log("value injected", widgetMeta.config.value)
        delete widgetMeta.config.url
      }
    }
    return widgetMeta
  }

  /**
   * add default value if not exists into autocomplete and dropdown widget
   */
  addDefaultValueConfig(widgetMeta){
    if (['select', 'autocomplete'].includes(widgetMeta.type)){
      let newBlueprint = WidgetManager.getWidget(widgetMeta.type) //get widget config
      //inject default value if not exists
      if(!widgetMeta.config?.['defaultValue']) {
        if (!widgetMeta.config.props.includes('defaultValue')) widgetMeta.config.props.push('defaultValue');
        widgetMeta.config['defaultValue'] = newBlueprint.config.defaultValue;
      }
    }
    return widgetMeta;
  }

  /**
   * rename contentHtml to value in richtext widget
   * applies to richtext widget
   */
  renameContentHtmlToValue(widgetMeta){
    if (['richtext'].includes(widgetMeta.type) && widgetMeta.config.contentHtml){
      let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
      widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('contentHtml'), 1);
      if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value');
      widgetMeta.config['value'] = newBlueprint.config.value;
      widgetMeta.config['value'].value = widgetMeta.config.contentHtml.value;

      //inject default value if not exists
      if(!widgetMeta.config?.['defaultValue']) {
        if (!widgetMeta.config.props.includes('defaultValue')) widgetMeta.config.props.push('defaultValue');
        widgetMeta.config['defaultValue'] = newBlueprint.config.defaultValue;
        widgetMeta.config['defaultValue'].value = widgetMeta.config.contentHtml.value;
      }
      delete widgetMeta.config.contentHtml
    }
    return widgetMeta;
  }

   /**
   * rename src to value in image & imageInput widget
   */
   renameSrcToValue (widgetMeta) : any {
    // console.log("rename image input hit", widgetMeta, "type", widgetMeta.type)
    if (['imageinput','image'].includes(widgetMeta.type) && widgetMeta.config.src) {
      // console.log("widget type will be processed", widgetMeta.type)
      if (widgetMeta.config.props.includes('src') || widgetMeta.config.src) {
        let newBlueprint = WidgetManager.getWidget(widgetMeta.type)
        widgetMeta.config.props.splice(widgetMeta.config.props.indexOf('src'), 1)
        if (!widgetMeta.config.props.includes('value')) widgetMeta.config.props.push('value')
        widgetMeta.config['value'] = newBlueprint.config.value
        widgetMeta.config['value'].value = widgetMeta.config.src.value
        delete widgetMeta.config.src
      }
    }
    return widgetMeta
  }

/**
 * if old widget has showTitle property, it's value needs to be mapped into new title.show
 * @param widgetMeta - The widget meta object to update.
 */
  handleShowTitle (widgetMeta) {
    let showTitleIndex = widgetMeta.config.props.findIndex(p => p == 'showTitle')
    if (showTitleIndex > -1 && widgetMeta.config.showTitle) {
      widgetMeta.config.props.splice(showTitleIndex, 1)

      let newBlueprint = WidgetManager.getWidget(widgetMeta.type)

      if (newBlueprint.config.props.includes('title')) {
        if (!widgetMeta.config.props.includes('title')) {
          widgetMeta.config.props.push('title')
        }
        if (!widgetMeta.config.title) {
          widgetMeta.config['title'] = newBlueprint.config.title
        }

        widgetMeta.config.title.show = widgetMeta.config.showTitle.value
      }
      delete widgetMeta.config.showTitle
    }
    return widgetMeta
  }
}
