import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { DateService } from '../../date/date.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-period-type',
    templateUrl: './period-type.component.html',
    styleUrl: './period-type.component.css',
    standalone: false
})
export class PeriodTypeComponent implements OnInit, DoCheck, OnDestroy {
  @Input() widgetMeta;
  @Input() builderMode: boolean = true;
  @Output() rangeValue = new EventEmitter<any>();

  @ViewChild('timeStartInput', { static: false }) timeStartInput: NgModel;
  @ViewChild('timeEndInput', { static: false }) timeEndInput: NgModel;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  oldMeta: any;
  validationSubscription: Subscription;

  get appearance() {
    return this.widgetMeta.config?.appearance?.value || 'standard'
  }

  get isRequired() {
    return this.widgetMeta?.config?.required?.value || false;
  }

  constructor(private dateService: DateService, private validationService: ValidationService) {}

  ngOnInit(): void {
    this.setFormattedDate();

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        if (this.widgetMeta?.config?.value?.periodType == "time") {
          this.timeStartInput.control.markAsTouched();
          this.timeEndInput.control.markAsTouched();
        } else {
          this.range.controls.start.markAsTouched()
          this.range.controls.end.markAsTouched()
        }  
      }
    })
  }

  ngDoCheck(): void {
    if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
      this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
    }
  }

  ngOnDestroy(): void {
    this.validationSubscription.unsubscribe();
  }

  setFormattedDate() {
    if ((this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') && this.widgetMeta.config.defaultValue.value) {
      this.widgetMeta.config.value.value = JSON.parse(JSON.stringify(this.widgetMeta.config.defaultValue.value));
    }
    if (!this.checkForEmptyValue(this.widgetMeta.config.value.value)) {
      const formattedValue = this.dateService.getFormattedDateTimeValue(this.widgetMeta.config.value.value, this.widgetMeta.config.value.periodType); 
      console.log('FORMATTED VALUE', formattedValue)
      this.range.setValue(formattedValue)
    }
    
    this.rangeInputDetected();
  }

  checkForEmptyValue(rangeValue: any) {
   return Object.values(rangeValue).some((val) => val == null || val == '')
  }

  rangeInputDetected() {
    let rangeValue = null;
    let validity = true;
    const periodType = this.widgetMeta.config.value.periodType;

    switch (periodType) {
      case 'date':
      case 'datetime':
        const clonedRangeValue = JSON.parse(JSON.stringify(this.range.value));
        validity = !this.checkForEmptyValue(clonedRangeValue);
        Object.keys(clonedRangeValue).forEach((key) => {
          clonedRangeValue[key] = (clonedRangeValue[key] !== null) ? `${new Date(clonedRangeValue[key]).toISOString()}|date` : ''
        });
        rangeValue = clonedRangeValue;
        break;
      case 'time':
        rangeValue = JSON.parse(JSON.stringify(this.widgetMeta.config.value.value));
        validity = !this.checkForEmptyValue(rangeValue);
        break;
      default:
        break;
    }
    console.log('validity', validity, 'rangeValue', rangeValue)
    this.rangeValue.emit({value: rangeValue, validity});
  }
}
