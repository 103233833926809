import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  standalone: false,
  selector: 'app-magnifier-dialog',
  template: `
    <div class="magnifier-dialog">
      <div class="toolbar">
        <button mat-icon-button matTooltip="Zoom Out" (click)="zoomOut()">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Zoom In" (click)="zoomIn()">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Full Screen" (click)="toggleFullScreen()">
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Close" (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="content" [style.transform]="'scale(' + scale + ')'">
        <ng-container *ngComponentOutlet="data.content; inputs: data.inputs"></ng-container>
      </div>
    </div>
  `,
  styles: [`
    .magnifier-dialog {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .toolbar {
      display: flex;
      gap: 8px;
      padding: 8px;
      border-bottom: 1px solid #eee;
      z-index: 100;
      position: sticky;
      top: 0;
      background-color: white;
    }

    .content {
      flex: 1;
      overflow: auto;
      transform-origin: center;
      transition: transform 0.2s ease;
      width: 100%;
      height: 100%;
    }
  `]
})
export class MagnifierDialogComponent implements OnInit {
  scale = 1;
  isFullScreenEnabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MagnifierDialogComponent>
  ) {}

  ngOnInit(): void {
    // Detect backdrop click
    this.dialogRef.backdropClick().subscribe(() => {
      this.zoomOut();
    });
  }

  zoomIn() {
    this.scale *= 1.2;
  }

  zoomOut() {
    this.scale /= 1.2;
  }

  toggleFullScreen() {
    this.isFullScreenEnabled = !this.isFullScreenEnabled;

    if (this.isFullScreenEnabled) {
      this.dialogRef.updateSize('100%', '100vh');
      this.zoomIn();
    } else {
      this.dialogRef.updateSize('90vw', '90vh')
      this.zoomOut();
    }
  }

  close() {
    this.dialogRef.close();
  }
} 