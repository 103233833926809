import { Subject } from "rxjs";
import { Widget } from "./baseWidget";
import { WidgetUtilityService } from "../../services/widget-utility.service";
import { TextFormatting } from "./textFormat";

export class DateTime extends Widget {
  type = 'datetime';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      // "customOutputFormat",
      "appearance",
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    customOutputFormat: {
      displayName: "Custom output date-time format",
      // value: 'M/d/yy, h:mm a',
      type: 'date-time-format-input',
      enabled: false,
      customTime: true,
      customDate: true,
      customDateFormat: 'M/d/yy',
      customTimeFormat: 'h:mm a',
      toPersist: ['customTime', 'customDate', 'customDateFormat', 'customTimeFormat', 'enabled']
    }
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['title'].value = 'Choose date & time';
    this.config['value'].type = 'datetime';
    this.config['defaultValue'].valueType = 'datetime';
    this.config['placeholder'].value = 'Enter date and time';

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  static dateTimeEvent = new Subject<any>();
  static dateTimeEvent$ = DateTime.dateTimeEvent.asObservable();

  public getEventSubscribe(data?:any){
    if(!data) return 1
    DateTime.dateTimeEvent.next(data);
    // DateTime.dateTimeEvent.complete();
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "datetime"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }

  public getWidgetMeta() {
    return this
  }

  createFormattedValue(rawDateTimeValue){
    if(!rawDateTimeValue) return ''

    const rawDateTime = (rawDateTimeValue !== '__currentdatetime__') ? new Date(rawDateTimeValue) : new Date()
    // console.log("raw value", rawDateTimeValue)
    // console.log("in local time", WidgetUtilityService.toIsoString(new Date(rawDateTimeValue)))

    let val = WidgetUtilityService.toIsoString(rawDateTime)
    return val || ''
  }

}
