<div class="wrapper" fxLayout="column" (mouseover)="mouseover()" (mouseout)="mouseout()">
  <div style="position: relative" [ngClass]="{ 'blurred': pageService.footerInFocus.value }">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <div *ngIf="pageService.footerInFocus.value" class="overlay" (click)="defocusFooter()"></div>
  </div>
  
  <div class="pre-divider" (mouseenter)="clearPlaceholders()"></div>
  
  <div 
    #footerHandleWrapper class="footer-handle-wrapper"
    (mouseenter)="mouseenterFooterHandle($event)" (mouseleave)="mouseleaveFooterHandle($event)" 
    *ngIf="builderMode && showFooter"
  >
    <div class="footer-handle" (click)="pageService.footerInFocus.value ? defocusFooter() : focusFooter($event)" fxLayoutAlign="space-between center">
      <div class="divider" fxFlex="27"></div>
      <div fxFlex="40" fxLayoutAlign="center center">
        <button mat-flat-button color="basic" class="thin-button" fxFlex="100">
          <mat-icon>add_box</mat-icon>
          {{ pageService.footerInFocus.value ? "Save footer" : "Edit footer" }}
        </button>
      </div>
      <div class="divider" fxFlex="27"></div>
    </div>
  </div>

  <div class="post-divider" (mouseenter)="clearPlaceholders()"></div>

  <div class="footer-wrapper" *ngIf="showFooter" [ngClass]="{ 'blurred': !pageService.footerInFocus.value }">
    <app-page [footerConfig]="{ 'isFooterPage': true, 'changeContext': pageService.footerInFocus.value }" [_builderMode]="true"></app-page>
    <div *ngIf="!pageService.footerInFocus.value" class="overlay" (click)="pageService.footerInFocus.value ? defocusFooter() : focusFooter($event)"></div>
  </div>
</div>
