import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { AES } from 'crypto-js';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';

//Do not change!!
const DEFAULT_KEY = 'DuolcMroftalpSurvij';

class AdminLength {
  group: number = 0;
  user: number = 0;
  role: number = 0;
  privilege: number = 0;
}


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private _usersTableSource: MatTableDataSource<any> = new MatTableDataSource([]);
  get usersTableSource() { return this._usersTableSource; }
  set usersTableSource(v) { this._usersTableSource = v };

  private _groupsTableSource: MatTableDataSource<any> = new MatTableDataSource([]);
  get groupsTableSource() { return this._groupsTableSource; }
  set groupsTableSource(v) { this._groupsTableSource = v };

  private _privilegeTableSource: MatTableDataSource<any> = new MatTableDataSource([]);
  get privilegeTableSource() { return this._privilegeTableSource; }
  set privilegeTableSource(v) { this._privilegeTableSource = v };


  //length
  adminLength: AdminLength = {
    group: 0,
    role: 0,
    privilege: 0,
    user: 0
  }

  get email() {
    if (!this._email) { return this.authService.profile.email }
    return this._email
  }
  set email(v: string) {
    this._email = v;
  }
  private _email: string

  get preAuthenticatedToken() { return this.getPreAuthenticatedTokenForEmail(this.email) }

  constructor(
    private http: HttpClient,
    private tokenUtil: TokenUtil,
    private authService: AuthServiceService,
    private httpCs: HttpCacheService,
  ) { }

  getPreAuthenticatedTokenForEmail(email: string) {
    console.log('email', email);
    //use subproduct creator email if not passed
    if(!email) email = this.authService.subProductCreatorEmail;
    return AES.encrypt(email, DEFAULT_KEY).toString();
  }

  async createGroup(preAuthenticatedToken: string, workspaceId: string, payload: any) {
    const collection = 'group';
    var result: any = {};
    result = await this._createAdminApi(collection, preAuthenticatedToken, workspaceId, payload);
    return result;
  }

  async updateGroup(preAuthenticatedToken: string, payload: any) {
    const collection = 'group';
    var result: any = {};
    result = await this._updateAdminApi(collection, preAuthenticatedToken, payload);
    return result;
  }

  async deleteGroup(preAuthenticatedToken: string, _id: string) {
    const collection = 'group';
    var result: any = {};
    result = await this._deleteAdminApi(collection, preAuthenticatedToken, _id);
    return result;
  }

  async getByIdGroup(preAuthenticatedToken: string, _id: string) {
    const collection = 'group';
    var result: any = {};
    result = await this._getbyIdAdminApi(collection, preAuthenticatedToken, _id);
    return result;
  }

  async listGroup(page: number = 1, limit: number = 5, preAuthenticatedToken: string, workspaceId: string) {
    const collection = 'group';
    var result: any = {};
    result = await this._listAdminApi(collection, page, limit, preAuthenticatedToken, workspaceId);
    console.log(result)
    this._groupsTableSource.data = result[collection];
    return result;
  }

  async createUser(preAuthenticatedToken: string, workspaceId: string, payload: any) {
    const collection = 'user';
    var result: any = {};
    result = await this._createAdminApi(collection, preAuthenticatedToken, workspaceId, payload);
    return result;
  }

  async updateUser(preAuthenticatedToken: string, payload: any) {
    const collection = 'user';
    var result: any = {};
    result = await this._updateAdminApi(collection, preAuthenticatedToken, payload);
    return result;
  }

  async deleteUser(preAuthenticatedToken: string, _id: string) {
    const collection = 'user';
    var result: any = {};
    result = await this._deleteAdminApi(collection, preAuthenticatedToken, _id);
    return result;
  }

  async getByIdUser(preAuthenticatedToken: string, _id: string) {
    const collection = 'user';
    var result: any = {};
    result = await this._getbyIdAdminApi(collection, preAuthenticatedToken, _id);
    return result;
  }

  async listUser(page: number = 1, limit: number = 5, preAuthenticatedToken: string, workspaceId: string) {
    const collection = 'user';
    var result: any = {};
    result = await this._listAdminApi(collection, page, limit, preAuthenticatedToken, workspaceId);
    this._usersTableSource.data = result[collection];
    return result;
  }

  async createRole(preAuthenticatedToken: string, payload: any) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/role`;
    result = await this.execute(url, payload, 'post', preAuthenticatedToken);
    return result;
  }

  async updateRole(preAuthenticatedToken: string, payload: any) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/role`;
    result = await this.execute(url, payload, 'put', preAuthenticatedToken);
    return result;
  }

  async deleteRole(preAuthenticatedToken: string, _id: string) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/role/${_id}`;
    result = await this.execute(url, null, 'delete', preAuthenticatedToken);
    return result;
  }

  async getByIdRole(preAuthenticatedToken: string, _id: string) {
    const collection = 'role';
    var result: any = {};
    result = await this._getbyIdAdminApi(collection, preAuthenticatedToken, _id);
    return result;
  }

  async listRole(page: number = 1, limit: number = 100, preAuthenticatedToken: string, payload?: any) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/role/getall?page=${page}&limit=${limit}`;
    console.log("payload list role", payload);
    let res = await this.execute(url, payload, 'post', preAuthenticatedToken)
    result = res;
    console.log("result list role", result);
    return result;
  }

  async getRolesbyIds(ids: any, preAuthenticatedToken?: any) {
    let url = `${environment.SERVER_BASE_URL}/admin/role/getbyids`;
    var result: any = null;
    let token = preAuthenticatedToken ? preAuthenticatedToken : this.preAuthenticatedToken;
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + token
      }
      var response: any = await this.http.post(url, { "ids": ids }, { headers: headers }).toPromise();
      console.log("[WORKSPACE-PERMISSION] getRolesbyIds", response);
      result = response?.data;
    } catch (e) {
      console.log("Error while getting roles", e)
    }
    return result;
  }


  async getOwnerRole(preAuthenticatedToken?: any) {
    let url = `${environment.SERVER_BASE_URL}/admin/role/get/owner`;
    var result: any = null;
    try {
      let token = preAuthenticatedToken ? preAuthenticatedToken : this.preAuthenticatedToken
      var response: any = await this.execute(url, null, 'get', token);
      console.log("getOwnerRole response", response);
      result = response?.data;
    } catch (e) {
      console.log("Error while getting owner role", e)
    }
    return result;
  }

  async createPrivilege(preAuthenticatedToken: string, payload: any) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/privilege`;
    result = await this.execute(url, payload, 'post', preAuthenticatedToken);
    return result;
  }

  async updatePrivilege(preAuthenticatedToken: string, payload: any) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/privilege`;
    result = await this.execute(url, payload, 'put', preAuthenticatedToken);
    return result;
  }

  async deletePrivilege(preAuthenticatedToken: string, _id: string) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/privilege/${_id}`;
    result = await this.execute(url, null, 'delete', preAuthenticatedToken);
    return result;
  }

  async getByIdPrivilege(preAuthenticatedToken: string, _id: string) {
    const collection = 'privilege';
    var result: any = {};
    result = await this._getbyIdAdminApi(collection, preAuthenticatedToken, _id);
    return result;
  }

  async getByCode(preAuthenticatedToken: string, code: string, collection: any) {
    let url = `${environment.SERVER_BASE_URL}/admin/${collection}/fetchbycode/${code}`;
    console.log("fetchbycode url",url)
    var result: any = {};
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + preAuthenticatedToken
    );
    console.log("header", headers)
    try {
      let res: any = await this.http.get(url, { headers }).toPromise();
      console.log(res);
      result[collection] = res?.data?.[0] || null;
      result.status = "SUCCESS";
      console.log("[ADMIN] Get By ID: ", result)
    } catch (e) {
      console.log(`Error while Getting By Code admin Privilege `, e)
    }
    return result;
  }

  async listPrivilege(page: number = 1, limit: number = 5, preAuthenticatedToken: string, payload?: any) {
    var result: any = {};
    let url = `${environment.SERVER_BASE_URL}/admin/privilege/getall?page=${page}&limit=${limit}`;
    let res = await this.execute(url, payload, 'post', preAuthenticatedToken)
    result = res;
    return result;
  }

  getAutorizationTokenHeader() {
    var result = { Authorization: '' };
    var token = this.tokenUtil.getStatelessToken();
    result.Authorization = `Bearer ${token}`;
    return result;
  }

  async execute(url: string, payload?: any, method?: string, preAuthenticatedToken?: any) {
    var options: any = {
      headers: {
        Authorization: `PreAuthenticatedToken ${preAuthenticatedToken}`
      }
    }

    console.log("execute url", url, JSON.stringify(payload), JSON.stringify(options));

    let response: any;
    try {
      if (method == "get") {
        response = await this.http.get(url, options).toPromise();
      } else if (method == "post") {
        response = await this.http.post(url, payload, options).toPromise();
      } else if (method == "put") {
        response = await this.http.put(url, payload, options).toPromise();
      } else if (method == "delete") {
        response = await this.http.delete(url, options).toPromise();
      }
      console.log("[BOX-HTTP] admin execution response: ", response);
      return response
    } catch (e) {
      console.log("[BOX-HTTP] admin execution failed: ", e)
      throw e;
    }
  }

  private async _createAdminApi(collection: string, preAuthenticatedToken: string, workspaceId: string, payload: any) {
    let url = `${environment.SERVER_BASE_URL}/admin/${collection}/${workspaceId}`;
    var result: any = {};
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + preAuthenticatedToken
    );
    try {
      var res: any = await this.http.post(url, payload, { headers: headers }).toPromise();
      console.log(res);
      result[collection] = res?.data?.[0] || null;
      result.status = "SUCCESS";
      console.log("[ADMIN] create: ", result)
    } catch (e) {
      console.log(`Error while creating admin ${collection}`, e)
    }
    return result;
  }

  private async _updateAdminApi(collection: string, preAuthenticatedToken: string, payload: any) {
    let url = `${environment.SERVER_BASE_URL}/admin/${collection}/update`;
    var result: any = {};
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + preAuthenticatedToken
    );
    try {
      var res: any = await this.http.put(url, payload, { headers: headers }).toPromise();
      console.log(res)
      result[collection] = res?.data?.[0] || null;
      result.status = "SUCCESS";
      console.log("[ADMIN] update: ", result)
    } catch (e) {
      console.log(`Error while updating admin ${collection}`, e)
    }
    return result;
  }

  private async _deleteAdminApi(collection: string, preAuthenticatedToken: string, _id: string) {
    let url = `${environment.SERVER_BASE_URL}/admin/${collection}/${_id}`;
    var result: any = {};
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + preAuthenticatedToken
    );
    try {
      var res: any = await this.http.delete(url, { headers: headers }).toPromise();
      console.log(res)
      result[collection] = res?.data?.[0] || null;
      result.status = "SUCCESS";
      console.log("[ADMIN] delete: ", result)
    } catch (e) {
      console.log(`Error while deleting admin ${collection}`, e)
    }
    return result;
  }

  private async _listAdminApi(collection: string, page: number = 1, limit: number = 5, preAuthenticatedToken: string, workspaceId: string) {
    let url = `${environment.SERVER_BASE_URL}/admin/${collection}/${workspaceId}?page=${page}&limit=${limit}`;
    var result: any = {};
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + preAuthenticatedToken
    );
    try {
      let res: any = await this.http.get(url, { headers }).toPromise();
      console.log(res);
      result[collection] = res?.data || null;
      this.adminLength[collection] = result.totalCount = res?.totalCount || 0;
      result.status = "SUCCESS";
      console.log("[ADMIN] List: ", result)
    } catch (e) {
      console.log(`Error while Listing admin ${collection}`, e)
    }
    return result;
  }

  private async _getbyIdAdminApi(collection: string, preAuthenticatedToken: string, _id: string) {
    let url = `${environment.SERVER_BASE_URL}/admin/${collection}/${_id}`;
    var result: any = {};
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + preAuthenticatedToken
    );
    try {
      // let res: any = await this.http.get(url, { headers }).toPromise();
      let chOptions: any = {keyPrefix: `${collection}_`};
      let res:any = await this.httpCs.get(url, { headers }, chOptions);
      console.log(res);
      result[collection] = res?.data?.[0] || null;
      result.status = "SUCCESS";
      console.log("[ADMIN] Get By ID: ", result)
    } catch (e) {
      console.log(`Error while Getting By ID admin ${collection}`, e)
    }
    return result;
  }


  // async getObjects(workSpaceId: string, preAuthenticatedToken: string, pageNumber: number = 1, pageSize: number = 100) {
  //   let collection = 'bloom';
  //   var result: any = [];
  //   var options = { headers: { boxconfigToken: this.BOX_TOKEN } };

  //   var tokenMap = this.getAutorizationTokenHeader();
  //   options.headers = Object.assign(options.headers, tokenMap);

  //   var boxUrl = `${environment.BOX_URL}/mongodb/${collection}/get`;
  //   let payload = {
  //     parameters: {
  //       query: {
  //         attributes: '',
  //         filter:
  //           'workspace_id=' + workSpaceId + '|string',
  //         sort: 'id=ASC',
  //         page: `${pageNumber}|${pageSize}|1`,
  //       },
  //     },
  //   };
  //   try {
  //     console.log('inside try');
  //     let response: any = await this.http.post(boxUrl, payload, options).toPromise();
  //     result[0] = response?.result?.data || null;
  //     console.log(response)
  //     result[0].name = 'Bloom'
  //     result[1] = await this.connectionService.getTotalConnection(pageNumber, pageSize, preAuthenticatedToken, workSpaceId).then((res) => {
  //       console.log(res); return res?.data || null
  //     });

  //     result[1].name = 'Connection'

  //   } catch (e) {
  //     console.error('[BOX-HTTP] Error on get objects by workspace:', e);
  //   }
  //   return result
  // }

  async getGCSBoxConfig() {
    console.log('GCSBox', this.preAuthenticatedToken);
    const headers = new HttpHeaders()
      .set(
        'Authorization',
        'PreAuthenticatedToken ' + this.preAuthenticatedToken
      );
    let url = environment.SERVER_BASE_URL + '/connection/id/' + environment.GCS_CONNECTION_ID
    let res: any;
    try {
      res = await this.http.get(url, { headers }).toPromise();
      return res.data.box_token
    } catch (err) {
      console.log("cant fetch conn", err)
      throw err;
    }
  }

  async generateFileObject(file: File, pathid?:any) {

    console.log("file", file)
    let result: any = {};
    let fileObj = new File([file], file.name, {
      type: file.type,
    });
    let buffer = await fileObj.arrayBuffer();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    buffer = new Uint8Array(buffer)
    let nodeBuffer = [];
    for (let i = 0; i < buffer.byteLength; i++) {
      nodeBuffer.push(buffer[i])
    }

    let fileName = pathid ? `${pathid}-${file.name}` : `test/images/${file.name}`;
    result.file = {
      filename: fileName,
      size: file.size,
      mimetype: file.type,
      buffer: nodeBuffer
    }

    return result;
  }

  async fileUpload(event: any, email?: string) {
    console.log("file selected:", event.file)

    // console.log('GCS box config.', JSON.stringify(event.file.buffer))
    if (email) { this.email = email; }
    let that = this;
    let boxConfigToken: string;

    boxConfigToken = await that.getGCSBoxConfig();
    // console.log("connection received", boxConfigToken);
    if (!boxConfigToken) {
      console.log('GCS box config undefined.')
      return
    }
    let payload = {
      parameters: {
        attachments: [event.file],
        bucket: environment.GCS_BUCKET
      }
    }

    let uploadUrl = environment.BOX_URL + '/googlecloudstorage/uploadFile'
    var options = { headers: { boxconfigToken: boxConfigToken } }

    var tokenMap = that.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)

    console.log("payload prepared:", payload)
    console.log("headers", options)
    let response: any;
    try {
      response = await that.http.post(uploadUrl, payload, options).toPromise();
      console.log(`upload response ---->>>`, response);

    } catch (e) {
      console.log(e)
      throw e;
    }
    return response.result.data[0].mediaLink
  }
}
