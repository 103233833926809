<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger" -->
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover" (click)="onClick($event)" (mousedown)="$event.stopPropagation()">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>

    <mat-form-field style="width: 100%;">
      <mat-chip-grid #chipList multiple="true">
        <mat-chip-row
          *ngFor="let chip of availableItems; let i = index; trackBy: trackByFn"
          [removable]="true"
          (removed)="remove(chip, i)"
        >
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="builderMode && chip.type == 'static'">cancel</mat-icon>
        </mat-chip-row>
        <input
          matInput
          [placeholder]="widgetMeta.config.placeholder ? widgetMeta.config.placeholder.value : 'Type and press enter to add...'"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)"
        >
      </mat-chip-grid>
    </mat-form-field>

    <!-- <div style="height: 1rem;"></div> -->
  </div>
</div>


<div
  *ngIf="!builderMode && ((!widgetMeta.config.hidden) || (widgetMeta.config.hidden && !widgetMeta.config.hidden?.value))" fxLayout fxLayoutAlign="flex-start center" class="outerCover" (click)="onClick($event)"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" (click)="onClick($event)" (mousedown)="$event.stopPropagation()">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <ng-container *ngIf="!widgetMeta.config.viewOnly.value; else nonInputChipsInView">
      <mat-form-field style="width: 100%;">
        <mat-chip-grid #chipListView multiple="true" (mousedown)="$event.stopPropagation()">
          <mat-chip-row
            *ngFor="let chip of availableItems; let i = index; trackBy: trackByFn"
            [removable]="widgetMeta.config.viewOnly.value ? false : true"
            (removed)="!widgetMeta.config.viewOnly.value ? remove(chip, i) : false"
          >
            {{chip.name}}
            <mat-icon matChipRemove *ngIf="!widgetMeta.config.viewOnly.value">cancel</mat-icon>
          </mat-chip-row>
          <input
            *ngIf="!widgetMeta.config.viewOnly.value"
            matInput
            [formControl]="fc"
            [placeholder]="widgetMeta.config.placeholder ? widgetMeta.config.placeholder.value : 'Type and press enter to add...'"
            [matChipInputFor]="chipListView"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
          >
          <mat-error *ngIf="fc.touched && fc.hasError('required')">This field is required</mat-error>
        </mat-chip-grid>
      </mat-form-field>
    </ng-container>
    <ng-template #nonInputChipsInView>
      <mat-chip-grid #chipListView multiple="true" (mousedown)="$event.stopPropagation()">
        <mat-chip-row *ngFor="let chip of availableItems; let i = index; trackBy: trackByFn">
          {{chip.name}}
        </mat-chip-row>
      </mat-chip-grid>
    </ng-template>

  </div>
</div>
