import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadersInputsComponent } from './headers-inputs.component';
import { SharedModule } from '../../shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { FieldPickerModule } from '../../field-picker/field-picker.module';

@NgModule({
  declarations: [HeadersInputsComponent],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    FieldPickerModule
  ],
  exports: [HeadersInputsComponent]
})
export class HeadersInputsModule { }
