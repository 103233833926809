<div>
    <div class="ribbon-event" *ngIf="!hideRibbon">
        Trigger
    </div>
    <div fxLayout="column" class="mat-h2" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon fxLayoutAlign="center center">schedule</mat-icon> &nbsp;
          <span class="large-text" fxLayoutAlign="center center">{{ schedulerInfo?.title || 'Schedule'}}</span>
        </div>
        @if(schedulerInfo?.tagline) {
          <span class="tagline">{{ schedulerInfo.tagline }}</span>
        }
    </div>

    <div fxLayout="row"  class="mat-h1" fxLayoutAlign="start center">

    </div>

    <div *ngFor="let schedule of flowMap.trigger.schedulers;; trackBy: trackByFn">
        <div *ngIf="!isOnlyInterval" fxLayout="row" fxLayoutAlign="start center">
          <mat-radio-group color="primary" style="padding-bottom: 2px"
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            (change)="onSelectSchedule(schedule)"
            [(ngModel)]="schedule.schedule_type"
          >
            <mat-radio-button style="padding-right: 10px" class="example-radio-button" value="interval">
              <strong>At interval</strong>
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" value="specific_time">
              <strong>At specific time</strong>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div fxLayout="row" style="height: 15px"></div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field style="width: 100%;" class="example-full-width">
            <mat-label>Timezone</mat-label>
            <input type="text" placeholder="Select Timezone" aria-label="Number" matInput
              [formControl]="myControl" [(ngModel)]='schedule.timezone' (focus)="setTimeZoneOptions()"
              [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredTimeZones | async; trackBy: trackByFn" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-icon matTooltip="Guess my timezone" color="primary" style="text-decoration: none; cursor: pointer;"
            (click)='getMyTimeZone(schedule)'>
            add_location
          </mat-icon>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" >
            <mat-form-field style="width: 100%;" class="custom_select example-full-width" *ngIf="schedule.schedule_type == 'interval'">
                <mat-label>Interval Type</mat-label>
                <mat-select (selectionChange)="onSelectSchedule(schedule)" [(ngModel)] = 'schedule.interval_type'
                name="interval_type" >
                    <mat-option *ngFor="let intervalType of intervalTypes; trackBy: trackByFn" [value]="intervalType.value">
                      {{intervalType.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="schedule.schedule_type === 'interval'">
            <div fxLayout="row" style="height: 10px">
          </div>




          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="schedule.interval && ['hourly','daily'].includes(schedule.interval_type)">
            <span><strong>Days:</strong>&nbsp;</span>
            <mat-button-toggle-group
                (change)="onSelectSchedule(schedule)"
                [(ngModel)]="schedule.interval.days_of_week"
                name="ingredients"
                multiple>
                <!-- [hideMultipleSelectionIndicator]="true" -->
                <mat-button-toggle *ngFor="let day of intervalTypeMap[schedule.interval_type].days; trackBy: trackByFn" [value]="day.id"
                  [matTooltip]="'Click to ' + (schedule.interval.days_of_week.includes(day.id) ? 'unselect' : 'select') + ' ' + day.name">
                  <span>{{ day.name }}</span>
                </mat-button-toggle>
              </mat-button-toggle-group>

          </div>


      <!-- <div fxLayout="row" fxLayoutAlign="start center"  style="height:5px"
      >

      </div> -->

      <div fxLayout="row" fxLayoutAlign="start center"
      *ngIf="schedule.interval && ['hourly'].includes(schedule.interval_type)">
      <br>
      <br>
      <br>
      <br> <br>
      <br> <br>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column" fxLayoutAlign="center center">
            <span><strong>Hours between:</strong>&nbsp;</span>
          </div>
          <div>
            <mat-range-slider
              class="example-margin"
              [min]="0"
              [max]="23"
              [minValue] = "schedule.interval.hours_between.min"
              [maxValue] = "schedule.interval.hours_between.max"
              (output)="onChangeHourRange($event, schedule); onSelectSchedule(schedule)"
            > </mat-range-slider>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="schedule.interval && ['minutely'].includes(schedule.interval_type)">
        <mat-form-field style="width: 100%;" class="custom_select example-full-width">
          <mat-label>Every</mat-label>
          <mat-select required (selectionChange)="onSelectSchedule(schedule)" [(ngModel)]="schedule.interval.minute" name="Minute">
            <mat-option *ngFor="let min of intervalTypeMap[schedule.interval_type].minutes; trackBy: trackByFn" [value]="min.id" matTooltip="{{ min.name }}">
              <span>{{ min.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="schedule.interval && ['weekly'].includes(schedule.interval_type)">
        <mat-form-field style="width: 100%;" class="custom_select example-full-width">
          <mat-label>Day of the week</mat-label>
          <mat-select required (selectionChange)="onSelectSchedule(schedule)" [(ngModel)]="schedule.interval.day_of_week" name="Day of week">
            <mat-option *ngFor="let day of intervalTypeMap[schedule.interval_type].days; trackBy: trackByFn" [value]="day.id" matTooltip="{{ day.name }}">
              <span>{{ day.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <!-- <div fxLayout="row" fxLayoutAlign="start center" *ngIf="schedule.interval && ['monthly'].includes(schedule.interval_type)">
        <mat-form-field style="width: 100%;" class="custom_select example-full-width" >
            <mat-label>Day of the Month</mat-label>
            <mat-select required (selectionChange)="onSelectSchedule(schedule)"
            [(ngModel)] = 'schedule.interval.day_of_month.type' name="Day of month" >
              <mat-option value="first"><span> First </span></mat-option>
              <mat-option value="last"><span> Last </span></mat-option>
              <mat-option value="day"><span> Day </span></mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 100%;" class="custom_select example-full-width" >
          <mat-label>Day of the Month</mat-label>
          <mat-select *ngIf="schedule.interval.day_of_month.type" required (selectionChange)="onSelectSchedule(schedule)"
          [(ngModel)] = 'schedule.interval.day_of_month.day' name="Day of month" >
            <mat-option *ngFor="let day of intervalTypeMap[schedule.interval_type][schedule.interval.day_of_month.type]; trackBy: trackByFn"
            [value]="day.id">
              <span>{{day.name}}</span>
            </mat-option>
          </mat-select>
      </mat-form-field>
    </div> -->

    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="schedule.interval && ['monthly'].includes(schedule.interval_type)">
      <mat-form-field style="width: 100%;" class="custom_select example-full-width">
        <mat-label>Day of the Month</mat-label>
        <mat-select required (selectionChange)="onSelectSchedule(schedule)" [(ngModel)]="schedule.interval.day_of_month" name="Day of month">
          <mat-option *ngFor="let day of intervalTypeMap[schedule.interval_type].days; trackBy: trackByFn" [value]="day.id" matTooltip="Click to select/unselect {{ day.name }}">
            <span>{{ day.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div fxLayout="row" fxLayoutAlign="start center"
      *ngIf="schedule.interval && ['daily','weekly', 'monthly'].includes(schedule.interval_type)">
      <span><strong>Time:</strong>&nbsp;</span>
        <mat-form-field style="width:150px">
        <!-- <mat-label>Time</mat-label> -->
        <input (change)="onSelectSchedule(schedule)" [(ngModel)] = 'schedule.interval.time'
          matInput type="time">
      </mat-form-field>
      </div>


        <!-- <div fxLayout="row" fxLayoutAlign="start center"
        *ngIf="schedule.interval && ['hourly','daily'].includes(schedule.interval_type)" >
            <span> Trigger on weekends: &nbsp; </span>
            <mat-checkbox (change)="onSelectSchedule(schedule)" class="example-margin" [(ngModel)]="schedule.interval.include_weekend"></mat-checkbox>
        </div> -->

        <!-- <div fxLayout="row" fxLayoutAlign="start center" *ngIf="schedule.interval && ['monthly'].includes(schedule.interval_type)">
            <mat-form-field style="width: 100%;" class="custom_select example-full-width" >
                <mat-label>Time of day</mat-label>
                <mat-select required (selectionChange)="onSelectSchedule(schedule)"  [(ngModel)] = 'schedule.interval.time_of_day' name="Time of day" >
                  <mat-option *ngFor="let time of intervalTypeMap[schedule.interval_type].times; trackBy: trackByFn"  [value]="time.id">
                    <span>{{time.name}}</span>
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        </div>

        <div *ngIf="schedule.schedule_type == 'specific_time'">
            <div fxLayout="row" style="height: 10px">
            </div>
            <div *ngIf="schedule.specific_time" fxLayout="row" fxLayoutAlign="start center" >
                <mat-form-field style="padding-right: 15px;">
                    <mat-label>Date</mat-label>
                    <input (dateChange)="onSelectSchedule(schedule)" matInput [(ngModel)] = 'schedule.specific_time.date'
                    [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker  #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="width:150px">
                    <mat-label>Time</mat-label>
                    <input (change)="onSelectSchedule(schedule)" [(ngModel)] = 'schedule.specific_time.time'
                    matInput type="time">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<!-- <pre>
  {{flowMap.trigger| json}}
</pre> -->
