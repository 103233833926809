<spinner *ngIf="publishSpinner || spinner" [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div class="header" [class.disabled] = "disabled" fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title>Publish Form</div>
  <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </button>
</div>



<mat-dialog-content >

  <div fxLayoutAlign="center center" id="publish-confetti">
    <div  fxLayoutAlign="center center" style="color: black;">
      <h1> {{form?.name}}</h1>
    </div>
  </div>

  <div *ngIf="!publishSuccess && !oldPublishedUrl" fxLayoutAlign="center center" style="padding: 1rem">
    <div fxLayoutAlign="center center" style="color: green;">Form is ready to be published</div>
  </div>

  <div class="publish-success-container" *ngIf="oldPublishedUrl || publishSuccess">

    <div class="subSection" fxLayout fxLayoutAlign="center center">
      <div *ngIf="!publishSuccess && oldPublishedUrl" class="publishedUrlText">
        Form is already published; you can publish your update.
      </div>
    </div>
    <div class="subSection" fxLayout fxLayoutAlign="center center" *ngIf="publishSuccess">
      <div  fxLayoutAlign="center center" >
        <mat-icon  class="successIcon">verified</mat-icon>
      </div>
      <div fxFlex="3"></div>
      <div  class="successHeading">{{ !publishSuccess && !oldPublishedUrl ? "Form is successfully published." : "Updates are successfully published" }}</div>
    </div>

    <div>
      <mat-card fxFlex="100%">
          <mat-card-content>
          <mat-tab-group fitInkBarToContent mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab>
              <ng-template mat-tab-label>
                      <mat-icon class="primary">link</mat-icon> &nbsp; Form link
              </ng-template>

              <div fxFlex="100%" fxLayout="column" fxLayoutGap="4%" style="overflow: hidden;">
              <div fxFlex="100%" style="width:60vw;padding-top: 18px;">
                  <a fxLayout="row wrap" target="_blank" href="{{getPublishedUrl()}}">{{getPublishedUrl()}}</a>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center">
                   <button mat-raised-button color="primary" (click)="copied()"
                   [cdkCopyToClipboard]="getPublishedUrl()">Copy link</button>
                   <div fxFlex="5"></div>
                   <button mat-button color="primary"(click)="navigateToNewPublished()">Open link</button>
              </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                      <mat-icon class="primary">mail</mat-icon>  &nbsp; Email
              </ng-template>

              <div fxFlex="100%" fxLayout="column" style="overflow: hidden;">

                <div>
                  <mat-form-field style="width:60vw" class="example-full-width form-field">
                    <mat-label>Subject</mat-label>
                    <input matInput [(ngModel)]="publishEmail.inviteSubject" placeholder="Type your subject">
                  </mat-form-field>
                </div>

                <div fxLayout="row">
                  <mat-form-field class="example-full-width form-field" style="width:60vw">
                    <mat-label>To</mat-label>
                    <mat-chip-grid #chipList aria-label="email">
                      <mat-chip-row
                        *ngFor="let email of publishEmail.validEmails; trackBy: trackByFn"
                        [removable]
                        (removed)="removeChipValue(email, publishEmail.validEmails)"
                      >
                        {{ email }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip-row>
                      <input
                        [(ngModel)]="publishEmail.inputValue"
                        placeholder="Type email and press enter"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addChipValue($event, publishEmail.validEmails)"
                        (paste)="pasteChipValue($event, publishEmail.validEmails)"
                      />
                    </mat-chip-grid>
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field style="width:60vw" class="example-full-width form-field">
                    <mat-label>Message</mat-label>
                    <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"
                      [(ngModel)]="publishEmail.inviteMessage" matInput placeholder="Type your message"></textarea>
                  </mat-form-field>
                </div>

                <div fxFlex="100%" fxLayout="row">
                  <button mat-raised-button color="primary" (click)="sendEmail()">Send</button>
              </div>
              </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                      <mat-icon class="primary">code</mat-icon>  &nbsp; Embed
              </ng-template>
              <div fxFlex="100%" fxLayout="column" style="overflow: hidden;">
                <div fxFlex="100%" fxLayout="row">
                  <mat-form-field  style="width:60vw" class="example-full-width form-field">
                  <mat-label>Embed HTML</mat-label>
                  <textarea style="width:60vw" matInput
                  cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"
                  placeholder="Embed" [(ngModel)]="embedHtml">
                </textarea>
                 </mat-form-field>
                </div>
                <div fxFlex="60" fxLayout="row">
                  <mat-form-field fxFlex="20%" class="example-full-width form-field">
                    <mat-label>Height </mat-label>
                    <input matInput  type="text" placeholder="Height" (change)="createEmbedHtml()"  [(ngModel)]="embedHeight">
                  </mat-form-field>
                  <div fxFlex="5"></div>
                  <mat-form-field fxFlex="20%" class="example-full-width form-field">
                    <mat-label>Width </mat-label>
                    <input matInput type="text" placeholder="Width" (change)="createEmbedHtml()"  [(ngModel)]="embedWidth">
                  </mat-form-field>
                  <div fxFlex="55" ></div>
                </div>
                <div fxFlex="100%" fxLayout="row">
                  <button mat-raised-button color="primary" (click)="copyEmbedUrlToClipboard()">Copy code</button>
                </div>

              </div>
            </mat-tab>
            </mat-tab-group>

          </mat-card-content>
      </mat-card>

  </div>

  </div>

  <div style="height: 1rem;"></div>
  <div class="templateStatusBlock" fxLayout="column" fxLayoutAlign="space-between start">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="100">
        <mat-slide-toggle [checked]="form.template"
        (change)="templatePublishCheckedEvent($event)" color="primary">
        <b>Publish as template</b>
        </mat-slide-toggle>
      </div>
    </div>
    <div *ngIf="form.template" style="width: 100%; box-sizing: border-box;">
      <mat-card style="padding: 5px;">
        <app-collect-template-data
        [form]="form"
        [screenshots]= "templateScreenshots"
        [banner]= "templateBanner"
        (emitTemplateData)="templateDataReceived($event)"
        (renewScreenshots)="captureScreenshots($event, assignScreenshots.bind(this))"
        (renewBanner)="captureScreenshots($event, assignBanner.bind(this))"
      ></app-collect-template-data>
      </mat-card>
    </div>
  </div>
  <!-- <div #buildercanvas style="visibility: hidden;position: absolute; top: -9999px; left: -9999px;">
    <app-form-viewer-canvas [type]="'internal'"></app-form-viewer-canvas>
  </div> -->
</mat-dialog-content>

<mat-dialog-actions align="end" [class.disabled] = "disabled">
  <button mat-raised-button mat-dialog-close>{{ publishSuccess ? 'Close' : 'Cancel' }}</button>
  <button mat-raised-button color="primary" (click)="publishClicked()" [disabled]="publishDisabled || publishSuccess" cdkFocusInitial>{{ publishSuccess || oldPublishedUrl ? 'Publish Updates' : 'Publish Now' }}</button>
</mat-dialog-actions>
