import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
const uuid = require('uuid');


@Component({
    selector: 'app-create-flow-detail',
    templateUrl: './create-flow-detail.component.html',
    styleUrls: ['./create-flow-detail.component.scss'],
    standalone: false
})
export class CreateFlowDetailComponent implements OnInit {


  isNameExists: boolean = false
  spinner:boolean = false;
  @Output() nameChanged: EventEmitter<any> = new EventEmitter();
  @Input() input: any;
  @Input() hideHeader: boolean = false;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required)
  });
  constructor(
  ) {
   }

  async ngOnInit() {
    this.formGroup.valueChanges.subscribe(value => {
      this.nameChanged.emit(value);
    });
    let name = this.input?.name || `My Form ${this.getRandomCode()}` ;
    this.formGroup.get('name').setValue(name);
  }
  async setName(name) {
    this.formGroup.get('name').setValue(name);
  }
  getRandomCode(){
    let randomCode = uuid.v4().substring(0, 8);
    if(!randomCode){
      randomCode = Date.now()
    }
    return randomCode;
  }

}
