import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'mt-input',
    templateUrl: './mat-input.component.html',
    styleUrls: ['./mat-input.component.scss'],
    standalone: false
})
export class MatInputComponent implements OnInit {



  @Input() placeholder: string = '';
  @Input() selectedValue: any = null;
  @Input() options: any = {};

  @Output() inputReceived = new EventEmitter<any>();

  constructor() {
    // this.getFilteredOptions()
  }

  ngOnInit(): void {

  }

  async ngAfterViewInit(){
  }


  ngOnChanges(){
  }

  userInputDetected(){
    this.inputReceived.emit({value: this.selectedValue})
  }


}
