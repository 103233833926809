import { NgModule } from "@angular/core";
import { PeriodTypeComponent } from "./period-type.component";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
    declarations: [PeriodTypeComponent],
    imports: [SharedModule],
    exports: [PeriodTypeComponent]
})
export class PeriodTypeModule {

}