export const CommonConfig: any = {

  properties: ['title', 'label', 'placeholder', 'value', 'defaultValue', 'required', 'backgroundColor', 'description', 'hidden', 'tooltip', 'viewOnly'],

  backgroundColor: {
    supportedWidgets: [
      'input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext',
      'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star',
      'label', 'button', 'chart', 'embed', 'icon', 'image', 'link', 'tags', 'space'
    ],
    config: {
      displayName: 'Background Color',
      type: 'color-picker',
      value: 'transparent',
      defaultValue: 'transparent'
    }
  },

  title: {
    // supportedWidgets: ['input', 'textarea',  'checkbox','select', 'choice', "chips", "autocomplete"],
    supportedWidgets: [
      'input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext', 'link',
      'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star', 'tags'
    ],
    config: {
      displayName: "Title",
      value: '',
      type: 'title',
      show: true,
      defaultValue: '',
      toPersist: ['show', 'value']
    },
  },

  label: {
    supportedWidgets: [
      'input', 'textarea', 'select', "autocomplete", 'numberinput'
    ],
    config: {
      displayName: "Label",
      value: 'Type your input',
      type: 'text',
      defaultValue: 'Type your input'
    },
  },

  tooltip: {
    supportedWidgets: [
      'label', 'input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'image', 'button', 'connection-list', 'connection',
        'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star', 'tags', 'icon', 'link',
      ],
    config: {
      displayName: "Tooltip",
      value: '',
      type: 'text',
      defaultValue: '',
      enabled: true,
      toPersist: ['value', 'enabled']
    },
  },

  placeholder: {
    supportedWidgets: [
      'input', 'textarea', "autocomplete", 'numberinput', 'chips', 'date', 'datetime', 'imageinput', 'period', 'richtext', 'time'
    ],
    config: {
      displayName: "Placeholder",
      value: 'Type your input',
      type: 'text',
      defaultValue: 'Type your input'
    },
  },

  description: {
    // supportedWidgets: ['input', 'textarea',  'checkbox','select', 'choice', "chips", "autocomplete"],
    supportedWidgets: ['input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext',
      'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star', 'tags'],
    config: {
      displayName: "Description",
      value: "",
      type: 'text',
      defaultValue: ""
    },
  },

  value: {
    supportedWidgets: ['duration', 'imageinput', 'star', 'slider', 'date', 'datetime', 'time', 'autocomplete', 'checkbox', 'period', 'label', 'link', 'image', 'button', 'icon', 'embed', 'input'],
    config: {
      displayName: "Value",
      value: "",
      type: 'text',
    }
  },
  defaultValue: {
    supportedWidgets: ['slider', 'star', 'numberinput', 'autocomplete', 'input', 'textarea', 'checkbox', 'select', 'choice', 'chips', 'date', 'datetime', 'time', 'duration', 'richtext', 'period', 'label', 'link', 'autocomplete'],
    config: {
      displayName: "Default Value",
      value: "",
      type: 'defaultValue',
      enabled: false,
      dynamic: false,
      toPersist: ['enabled', 'dynamic', 'value']
    }
  },
  hidden: {
    supportedWidgets: ['input', 'checkbox', 'select', 'choice', "chips", "autocomplete", 'imageinput', 'richtext', 'tags', 'textarea', 'numberinput',  'star', 'slider', 'date', 'datetime', 'time', 'duration', 'period', 'label', 'link', 'image', 'button', 'icon', 'embed', 'separator', 'space'],
    config: {
      displayName: 'Hidden',
      type: 'boolean',
      value: false,
    }
  },
  required: {
    supportedWidgets: ['input', 'textarea', 'checkbox', 'select', 'choice', "chips", "autocomplete", 'numberinput', 'star', 'date', 'datetime', 'time', 'imageinput', 'richtext', 'period'],
    config: {
      displayName: "Required",
      value: false,
      type: 'boolean'
    }
  },
  viewOnly: {
    supportedWidgets: ['input', 'textarea', 'checkbox', 'select', 'choice', "chips", "autocomplete", 'numberinput', 'star', 'slider', 'date', 'datetime', 'duration', 'time', 'richtext', 'period', 'connection-list', 'connection'],
    config: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can NOT change the value',
      value: false,
      type: 'boolean',
      resetValue: false,
      toPersist: ['value', 'resetValue']
    }
  }
}

export const ValidatorConfig = {
  supportedWidgets: [
    'input','numberinput'
  ],
  config: {
    isEnabled: false,
    validators: []
  }
}

export const AvailableOptions: any = {
  appearance: {
    widgetsSupported: ["input", "numberinput", "select", 'autocomplete', "textarea", "connection", "connection-list", "date", "datetime", "period", "time"],
    availableOptions: [
      "standard",
      "outline"
    ]
  },
  orientation: {
    widgetsSupported: ["checkbox", "choice"],
    availableOptions: [
      "vertical",
      "horizontal"
    ]
  },
  buttonType: {
    widgetsSupported: ["button"],
    availableOptions: [
      "Basic",
      "Raised",
      "Stroked",
      "Flat",
      // "Icon",
      "Fab",
      // "Mini-fab"
    ]
  },
  buttonColorType: {
    widgetsSupported: ["button"],
    availableOptions: [
      "primary",
      "accent",
      "warn",
      "none",
      "custom"
    ]
  },
  tagType: {
    widgetsSupported: ["tags"],
    availableOptions: [
      "Basic",
      "Raised",
      "Stroked",
      "Flat"
    ]
  },
  tagColorType: {
    widgetsSupported: ["tags"],
    availableOptions: [
      "primary",
      "accent",
      "warn"
    ]
  },
  width: {
    widgetsSupported: ["image"],
    availableOptions: [
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "80%",
      "90%",
      "100%",
    ]
  },
  imageAlignment: {
    widgetsSupported: ["image"],
    availableOptions: [
      "left",
      "center",
      "right"
    ]
  },
}
