<spinner *ngIf="spinner" [center]="true" [selfSpin]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div fxLayout="row">
</div>

<div *ngIf="show">
        <mat-slide-toggle *ngIf="isSupportFilter" [checked]="filter.filterEnabled" (change)="filterToggleChanged($event)"
    color="primary"> Apply Filters
    </mat-slide-toggle>

    <div class="" fxLayout="column" *ngIf="isSupportFilter && filter.filterEnabled" >
        <div *ngFor="let filter of filter.filterItems; let i = index; trackBy: trackByFn" >
            <mat-card appearance="outlined" fxLayout="column" style="padding-bottom: 1px;">
                <div fxLayout="row" fxFlex="90" ></div>

                <div fxLayout="row" fxFlex="5" fxLayoutAlign="end end" style="margin: -25px -20px -19px 0px;padding-bottom: 12px;">
                    <button fxLayout="column" fxFlex="5" fxLayoutAlign="end end" mat-icon-button color="warn" (click)="removeFilter(i)">
                    <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div style="padding-bottom: 5px;" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxFlex="100%">
                    <div fxLayout.xs="row" *ngIf="selectFilterAttributeForm.get('form0')" fxFlex="35" fxLayout="column" fxLayoutAlign="center center">
                        <form [formGroup]="selectFilterAttributeForm" class="full-width">
                            <mat-form-field class="full-width paddingForm" style="margin-bottom: -19px;">
                                <mat-label>Attribute</mat-label>
                                <input
                                    #boxInput
                                    type="text"
                                    aria-label="attribute autocomplete"
                                    matInput
                                    (focus)="setFilterList(i)"
                                    formControlName="{{'form' + i}}"
                                    [matAutocomplete]="auto2"
                                >
                                <button *ngIf="boxInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectFilterAttributeForm.get('form0').patchValue('')">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFn"
                                (optionSelected)="filterAttrSelected($event, filter); ">
                                    <mat-option *ngFor="let attr of attributesForFilter | async; trackBy: trackByFn" [value]="attr">
                                    <span>{{attr.__id}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </form>
                    </div>

                    <div fxLayout.xs="row" fxFlex="30" fxLayout="column" fxLayoutAlign="center center">
                        <mat-form-field class="full-width"  style="margin-bottom: -24px;">
                            <mat-label>Operator</mat-label>
                            <mat-select [(value)]="filter.operator">
                                <mat-option [value]="op.value" *ngFor="let op of availableOperators; trackBy: trackByFn">{{ op.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div fxLayout.xs="row" fxFlex="35" fxLayout="column" fxLayoutAlign="center center"  style="margin-bottom: -24px;">
                        <div>
                            <div>
                                <mat-form-field class="full-width">
                                    <mat-label>Value</mat-label>
                                    <input matInput #filterValueInput [(ngModel)]="filter.value" (change)="addFilterInQuery()">
                                </mat-form-field>
                                <app-field-picker
                                *ngIf="fieldMap?.list?.length"
                               [inputFieldMap]="fieldMap"
                              (selectedFields)="insertFilterCursor($event, filter)"
                           ></app-field-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card >
            <div fxHide.lt-md style="height: 5px;"></div>
        </div>
    </div>

    <div *ngIf="isSupportFilter && filter.filterEnabled"  fxLayout="row" fxLayoutAlign="start center">
        <button  mat-stroked-button color="primary" (click)="addFilterItem()">
        <mat-icon>add</mat-icon> Add Filter
        </button>
    </div>

    <div class="" fxLayout="column" *ngIf="isSupportCountLimit">
        <mat-form-field class="full-width">
            <mat-label>Limit</mat-label>
            <input matInput #filterValueInput [(ngModel)]="inputMap['__limit']" (change)="inputParameter.emit(this.inputMap);">
        </mat-form-field>
    </div>



    <div *ngIf="isSupportGroup">
        <mat-slide-toggle [checked]="group.groupEnabled" (change)="groupToggleChanged($event)"
        color="primary"> Apply Group
        </mat-slide-toggle>

        <form *ngIf="group.groupEnabled">
            <mat-form-field class="example-chip-list" appearance="outline">
              <mat-label>Group Attributes</mat-label>
              <mat-chip-grid #chipList multiple="true">
                <mat-chip-row *ngFor="let attr of group.attributes; trackBy: trackByFn" (removed)="removeGroup(attr)">
                  {{attr.name}}
                  <button matChipRemove [attr.aria-label]="'remove '">
                    <mat-icon style="margin: -6px -13px;">cancel</mat-icon>
                  </button>
                </mat-chip-row>
              <input placeholder="Choose Attribute"  (focus)="setGroupList()" #fruitInput [formControl]="groupAttriCtrl"
                [matChipInputFor]="chipList" [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addGroup($event)"/>
              <mat-autocomplete [displayWith]="displayFn" #auto="matAutocomplete" (optionSelected)="selectedGroup($event)">
                <mat-option *ngFor="let att of attributesForGroup | async; trackBy: trackByFn" [value]="att">
                  {{att.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-chip-grid>
            </mat-form-field>
          </form>

    </div>
</div>


<div *ngIf="attributeHide" fxLayout="column">
    <div *ngFor="let param of attributesParameterWidgets; let i = index; trackBy: trackByFn" fxLayout="row" fxLayoutAlign="center enter">
        <!-- <app-widget-wrapper
            fxFlex="100"
            [widgetMeta]="param.widget"
            [builderMode]="false"
            (userInputReceived)="attributeInputReceived($event, param)"
        ></app-widget-wrapper> -->


<ng-container *ngIf="param.widget && param.widget.type">
    <!--  && !sps.spinner -->
    <ng-container [ngSwitch]="param.widget.type"
    >
      <ng-container *ngSwitchCase="'input'">
        <app-textinput
          style="width: 100%" #widgets
          [widgetMeta]="param.widget"
          [builderMode]="false"
          (userInputReceived)="attributeInputReceived($event, param)"
        >
        </app-textinput>
      </ng-container>

      <ng-container *ngSwitchCase="'textarea'">
        <app-textarea
          style="width: 100%" #widgets
          [widgetMeta]="param.widget"
          [builderMode]="false"
          (userInputReceived)="attributeInputReceived($event, param)">
        </app-textarea>
      </ng-container>

      <ng-container *ngSwitchCase="'date'">
        <app-date
          style="width: 100%" #widgets
          [widgetMeta]="param.widget"
          [builderMode]="false"
          (userInputReceived)="attributeInputReceived($event, param)">
        </app-date>
      </ng-container>

      <ng-container *ngSwitchCase="'datetime'">
        <app-datetime
          style="width: 100%" #widgets
          [widgetMeta]="param.widget"
          [builderMode]="false"
          (userInputReceived)="attributeInputReceived($event, param)">
        </app-datetime>
      </ng-container>

      <ng-container *ngSwitchCase="'autocomplete'">
        <app-autocomplete
          style="width: 100%" #widgets
          [widgetMeta]="param.widget"
          [builderMode]="false"
          (userInputReceived)="attributeInputReceived($event, param)">
        </app-autocomplete>
      </ng-container>

    </ng-container>
</ng-container>

    </div>
    <div *ngIf="attributesParameterWidgets.length > 0">
        <button mat-raised-button color="primary" (click)="getAttribute()">Get Attributes</button>
    </div>
    <div style="text-align:left;">
        <div *ngIf="boxObjectAttributes && boxObjectAttributes.error;">
            <h2>Result</h2>
            {{boxObjectAttributes.error.error | json}}
        </div>
    </div>
</div>

<div *ngIf="show">
    <div *ngFor="let param of parameterWidgets; let i = index; trackBy: trackByFn" fxLayoutAlign="start center">
      <ng-container *ngIf="param.widget?.type == 'query'; else elseTemplate">
        <query-inputs-component *ngIf="queryInputs"
          fxFlex="100"
          [componentsToShow]="queryInputs.subComponentsToShow"
          [boxObjectAttributes]="boxObjectAttributes"
          [retainSubComponentOutput]="queryInputs.retainSubComponentOutput"
          [existingConfig]="param.widget.queryInputs"
          [recommendAttributes]="queryInputs.recommendAttributes"
          [supportApplyButton]="false"
          [fieldListingMap]="fieldListingMap"
          (newConfig)="valueReceived({value: $event.query, subComponentOutput: $event.subComponentOutput}, param)"
        ></query-inputs-component>
      </ng-container>

      <ng-template #elseTemplate>
        <!-- <app-widget-wrapper
          fxFlex="100"
          [widgetMeta]="param.widget"
          [builderMode]="false"
          (userInputReceived)="valueReceived($event, param)"
        ></app-widget-wrapper> -->
        <ng-container *ngIf="param.widget && param.widget.type; else arrTemplate">
            <!--  && !sps.spinner -->
            <ng-container [ngSwitch]="param.widget.type"
            >
              <ng-container *ngSwitchCase="'input'">
                <app-textinput
                  style="width: 100%" #widgets
                  [widgetMeta]="param.widget"
                  [builderMode]="false"
                  (userInputReceived)="valueReceived($event, param)"
                >
                </app-textinput>
              </ng-container>

              <ng-container *ngSwitchCase="'textarea'">
                <app-textarea
                  style="width: 100%" #widgets
                  [widgetMeta]="param.widget"
                  [builderMode]="false"
                  (userInputReceived)="valueReceived($event, param)">
                </app-textarea>
              </ng-container>

              <ng-container *ngSwitchCase="'date'">
                <app-date
                  style="width: 100%" #widgets
                  [widgetMeta]="param.widget"
                  [builderMode]="false"
                  (userInputReceived)="valueReceived($event, param)">
                </app-date>
              </ng-container>

              <ng-container *ngSwitchCase="'datetime'">
                <app-datetime
                  style="width: 100%" #widgets
                  [widgetMeta]="param.widget"
                  [builderMode]="false"
                  (userInputReceived)="valueReceived($event, param)">
                </app-datetime>
              </ng-container>

              <ng-container *ngSwitchCase="'autocomplete'" fxLayout="column">
                <div fxLayout="column" style="width: 100%;">
                  <app-autocomplete
                    style="width: 100%;" #widgets
                    [widgetMeta]="param.widget"
                    [builderMode]="false"
                    (userInputReceived)="valueReceived($event, param)">
                  </app-autocomplete>
                  <br/>
                </div>
              </ng-container>

            </ng-container>
            <app-field-picker
            *ngIf="param.widget.type == 'input' && fieldMap?.list?.length"
            [inputFieldMap]="fieldMap"
              (selectedFields)="insertCursor($event, param)"
            ></app-field-picker>
        </ng-container>
      </ng-template>

      <ng-template #arrTemplate>
        <mat-card appearance="outlined" fxLayout="column" style="margin: 10px 0; width: 100%;" >
          <div *ngIf="arrayParamWidgets.length" style="width: 100%;" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <ng-container style="margin-top: 5%;" *ngFor="let widget of arrayParamWidgets; let i = index; trackBy: trackByFn" >
              <div style="margin-bottom: 5px; border: 0.5px solid rgb(182, 182, 182); border-radius: 5px;" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign=" center" cdkDrag >
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                <span class="drag-handle" cdkDragHandle>
                  <mat-icon matTooltip ="Hold and Drag">drag_indicator</mat-icon>
                </span>
                <app-parameter-widgets
                  [paramKey]="widget.key"
                  [parameters]="widget.widget || widget.widgets"
                  [index]="i"
                  [inputMap]="inputMap"
                  [fieldListingMap]="fieldListingMap"
                  (inputParameterWidget)="inputParameterwidgetsRecevied($event);"
                ></app-parameter-widgets>
                <button mat-icon-button matTooltip ="Delete" [ngStyle]="{'color': 'red'}" (click)="removeArrayInputs(widget, i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
          <div fxLayout="row" fxLayoutGap="5px" style="margin-top: 10px;">
            <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="5px" *ngFor="let paramWidget of param?.widgets; let i = index; trackBy: trackByFn">
                <button mat-stroked-button color="primary" class="smallButton" mat-icon-button matTooltip = "add {{paramWidget.name||paramWidget.key}}"
                (click)="addArrayInputs(paramWidget,null,param.key)">
                  <div fxLayout="row" fxLayoutAlign="start center">
                      <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center">add_circle_outline</mat-icon></span>
                      <span>{{paramWidget.name||paramWidget.key}}</span>
                  </div>
                </button>
            </div>
          </div>
        </mat-card>
      </ng-template>
    </div>
    <div *ngIf="valueOptionsObj?.found === false">
      <span style="font-size: small; margin-left: 10px; margin-bottom:10px; color: rgb(231, 0, 0);" *ngIf="valueOptionsObj?.message != ''">
        Note: {{valueOptionsObj?.message || ''}}
      </span>
    </div>
</div>


<div *ngIf="options?.supportSemanticType">
  <headers-inputs *ngIf="action?.input?.headers"
    [isDynamicData]="action?.input?.headers?.semanticType == 'headers'"
    [headers]=""
    [existingInputs]=""
    (inputParameter)="inputHeadersRecevied($event)"
    [sourceFields]="fieldListingMap?.sourceFields"
    [systemFields]="fieldListingMap?.systemFields"
  >
  </headers-inputs>

  <query-param-inputs *ngIf="action?.input?.queryParams"
    [isDynamicData]="action?.input?.queryParams?.semanticType == 'queryParams'"
    [queryData]=""
    [existingInputs]=""
    (inputParameter)="inputQueryParamsRecevied($event)"
    [sourceFields]="fieldListingMap?.sourceFields"
    [systemFields]="fieldListingMap?.systemFields"
  >
  </query-param-inputs>
</div>

