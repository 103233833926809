import { Injectable } from '@angular/core';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  dateFormat: string;
  timeFormat: string;

  constructor(private themeService: ThemeService) { }

  getFormattedDateTimeValue(value: any, periodType: string) {
    let formattedValue: any;

    const { dateFormat, timeFormat } = this.themeService.currentLocale || {};
    //set date format
    this.dateFormat = dateFormat || "MMM-dd-yyyy";
    //set time format
    this.timeFormat = timeFormat || "hh:mm a";

    if (value) {
      const configValue = JSON.parse(JSON.stringify(value || ""));

      if (typeof configValue === "object") {
        Object.keys(configValue).forEach((key) => {
          if(configValue[key]?.endsWith("|date")) configValue[key] = configValue[key].slice(0, -5); // discard the '|date' if exists
          // configValue[key] = configValue[key].substring(0, 16)  // discard the seconds and miliseconds from iso date string
          configValue[key] = this._formatDateByType(configValue[key], periodType);
        })
        formattedValue = configValue;
      }
      console.log("formattedValue", formattedValue)
    } else {
      formattedValue = this._formatDateByType(new Date().toISOString(), periodType)
    }

    return formattedValue;
  }

  /**
   * to show the existing value in html input of type = datetime-local or time
   * @param inputDate 
   * @returns formatted date or time string
   */
  private _formatDateByType(inputDate: string, periodType: string): string {
    // Create a new Date object from the ISO string
    const date = new Date(inputDate);

    // Get the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Get the time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date and time in the required format
    if (periodType == "date") {
      return `${year}-${month}-${day}`;
    } else if(periodType == "datetime") {
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    } else if(periodType == "time") {
      return `${hours}:${minutes}`;
    }
  }
}
