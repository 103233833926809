import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from '../form.service';
import { AnySrvRecord } from 'dns';


@Component({
    selector: 'app-form-attribute-selection',
    templateUrl: './form-attribute-selection.component.html',
    styleUrls: ['./form-attribute-selection.component.scss'],
    standalone: false
})
export class FormAttributeSelectionComponent implements OnInit {

  @Input() widgetMeta: any;
  @Input() attributes:any;
  @Output() attibutesSelected: EventEmitter<any> = new EventEmitter();

  spinner:boolean = false;
  // widgetMeta:AnySrvRecord
  action: { event: string; action: string; actionMap: { boxId: any; }; };
  widgetMap: any = {
    list: ['input', 'textarea', 'image', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext', 'select', "chips", "autocomplete", 'numberinput', 'connection'],
    input: { name: 'Text Input', icon: 'text_fields' },
    textarea: { name: 'Text Area', icon: 'edit_note' },
    image: { name: 'Image', icon: 'image' },
    checkbox: { name: 'Check Box', icon: 'check_box' },
    numberinput: { name: 'Number input', icon: '123' },
    datetime: { name: 'Date + Time', icon: 'event' },
    date: { name: 'Date', icon: 'calendar_month' },
    time: { name: 'Time', icon: 'schedule' },
    imageinput: { name: 'Image Input', icon: 'file_upload' },
    richtext: { name: 'Rich Text', icon: 'wysiwyg' },
    select: { name: 'Dropdown', icon: 'arrow_drop_down' },
    chips: { name: 'Chips', icon: 'developer_board' },
    autocomplete: { name: 'Autocomplete', icon: 'manage_search' },
    connection: { name: 'Connection', icon: 'cable' }
  }
  // attributes: any;
  boxObjectAttributes: any;

  constructor(
    // @Inject(MAT_DIALOG_DATA)
    // public dataInput: any,
    // public dialogRef: MatDialogRef<FormAttributeSelectionComponent>,
    public formService: FormService
  ) {


   }

  async ngOnInit() {
    this.widgetMeta = this.widgetMeta;
    this.boxObjectAttributes = this.attributes;
    this.boxObjectAttributes.forEach(element => {
      element['fieldType'] = 'attribute';
      element['widgetType'] = 'input';
    });
    console.log("this.action", this.action, this.attributes)
    this.attributes = JSON.parse(JSON.stringify(this.boxObjectAttributes));
    this.attibutesSelected.emit(this.attributes);
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

  ngOnChanges(e){
    console.log("e", e)
    if(JSON.stringify(e.attributes.currentValue) !=   JSON.stringify(e.attributes.previousValue )){
      this.boxObjectAttributes = this.attributes;
      this.boxObjectAttributes.forEach(element => {
        element['fieldType'] = 'attribute';
        element['widgetType'] = 'input';
      });
      console.log("this.action", this.action, this.attributes)
      this.attributes = JSON.parse(JSON.stringify(this.boxObjectAttributes));
      this.attibutesSelected.emit(this.attributes);
    }
  }

  onSave(){
    // this.dialogRef.close({attributes: this.attributes});
  }

  onNoClick(): void {
    // this.dialogRef.close();
  }

  widgetMenuClosed(event: any){
    console.log("widget menu closed", event)
  }

  widgetTypeSelected(attrIndex: any, widgetType: string){
      this.attributes[attrIndex]['widgetType'] = widgetType;
  }

  removeListAttribute(event: any, i: number) {
    this.attributes.splice(i, 1);
    this.attibutesSelected.emit(this.attributes);
  }

  selectAttributeMenuOpened(){

  }

  attributeSelectionChanged(attributes: any[]){
    this.attributes = attributes;
    console.log("attributes", attributes);
    this.attibutesSelected.emit(attributes);
  }

}
