import { ENTER, COMMA, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../services/auth-service.service';
import { MailService } from '../services/mail.service';
import { WorkspaceService } from '../services/workspace.service';
import { SpinnerService } from '../spinner/spinner.service';
import { ResourcePermissionService } from '../services/resource-permission.service';

@Component({
    selector: 'app-resource-collaboration',
    templateUrl: './resource-collaboration.component.html',
    styleUrls: ['./resource-collaboration.component.scss'],
    standalone: false
})
export class ResourceCollaborationComponent implements OnInit {
  invitationMap: any = [
    {
      validEmails: [],
      role: ''
    }
  ]

  inputValue:any;

  failedChipPasteInput: string;
  isValidEmail: any;
  baseUrl: string= environment.BASE_URL;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, SPACE];
  availableRoles: any = [];

  @Input() resourceMap: any;
  @Input() workspaceMap: any;
  @Input() resourceType: any;
  workspace: any;
  @Output() "emailSent" = new EventEmitter<any>();
  @Output() "cancel" = new EventEmitter<any>();

  constructor(
    public spinner: SpinnerService,
    public connectionService: ConnectionService,
    public authService: AuthServiceService,
    public mailService: MailService,
    public workspaceService: WorkspaceService,
    public snackBar: MatSnackBar,
    public adminService: AdminService,
    public resourcePermissionService: ResourcePermissionService
  ) { }

  async ngOnInit() {
    console.log("Resource Map: ",this.resourceMap)
    console.log("Workspace Map: ",this.workspaceMap)
    if(this.connectionService.selectedWorkSpaceMap) this.workspace = this.connectionService.selectedWorkSpaceMap;
    if(this.resourceType == "bloom") {
      var payload = {
        filter: `resource_id=default|string;resource_id=${this.resourceMap.code}|string`
      }
    }
    var roles = await this.adminService.listRole(1, 500, this.connectionService.preAuthenticatedToken, payload)
    this.availableRoles = roles.data;
  }

  removeInvitation(invitationMap, i){
    invitationMap.splice(i, 1)
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  async sendWorkspaceInvitation(){
    this.addInputValueInEmail();
    this.spinner.show();
    let userProfile = this.authService.profile;

    let data = {
      profile: userProfile,
      workspace: this.workspace,
      resource: this.resourceMap,
      acceptUrl: "",
      denyUrl: ""
    }
    let mailOptions = {
      fromEmail: "${profile.email}",
      toEmail: "",
      body: this.mailService.template("resource_invitation"),
      subject: "Invitation to collaborate on ${resource.name} - "+this.resourceType
    }

    for(var j = 0; j < this.invitationMap.length; j++) {
      for(var i = 0; i < this.invitationMap[j].validEmails.length; i++){
        mailOptions.toEmail = this.invitationMap[j].validEmails[i];
        var userMap = await this.authService.getWorkspaceUser(mailOptions.toEmail);
        if(!userMap) userMap = await this.authService.createNewUserWithEmail(mailOptions.toEmail);

        let permissionMap = {
          user_id: userMap._id,
          workspace_id: this.workspace._id,
          resource_type: this.resourceType,
          resource_id: this.resourceType == "bloom" ? this.resourceMap.code : this.resourceMap._id,
          status: "Invited",
          created_by: this.authService.profile.email,
          modified_by: this.authService.profile.email,
          roles: this.invitationMap[j]?.role ? [ this.invitationMap[j]?.role ] : []
        }
        var permission = await this.resourcePermissionService.checkAndCreatePermission(permissionMap);
        var url = `${this.baseUrl}/workspace/approval?p=${permission.permission._id}&u=${this.authService.profile._id}&e=${this.resourceType}`
        data.acceptUrl = `${url}&a=accept`;
        data.denyUrl = `${url}&a=reject`
        await this.mailService.sendSesEmail(mailOptions, data);
      }
    }

    this.snackBar.open("Invitations sent successfully")._dismissAfter(3000);
    this.invitationMap= [{
        validEmails: [],
        role: ''
      }]
    this.emailSent.emit(true);
    this.spinner.hide();
  }

  addInputValueInEmail(){
    if(this.invitationMap?.[this.invitationMap.length - 1]?.validEmails?.length == 0){
      let inputValue = this.invitationMap[this.invitationMap.length - 1].inputValue;
      var isValid = CheckIsValidEmail(inputValue)
      if(isValid) this.invitationMap[this.invitationMap.length - 1].validEmails.push(inputValue);
      this.invitationMap[this.invitationMap.length - 1].inputValue = null;
    }
  }

  addInvitation(){
    this.addInputValueInEmail();
    this.invitationMap.push(
      {
        validEmails: [],
        role: ''
      }
    )
  }

  onCancel(){
    this.cancel.emit(true)
  }

    /*paste values for domain and email
  splits the values by seperator and valid
  refer https://medium.com/@sriram_in/angular-material-chips-add-chip-on-paste-event-ebce64fcfabd
  @param event evet
  @param type access type
  */
  pasteChipValue(event: ClipboardEvent, validEmails: any): void {
    console.log("event", event)
    this.failedChipPasteInput = "";
    var failedInput = [];
    event.preventDefault();
    event.clipboardData
    .getData('Text')
    .split(/[\s,;:\n\t]+/) // seprates with comma,colon,semicolon,space,tab,new line
    .forEach(value => {
      if(value.trim()){
        let inputValue = value.trim();
          var isValid = CheckIsValidEmail(inputValue)
          if(!isValid) this.isValidEmail = isValid;
          if (isValid) {
            inputValue = inputValue.toLowerCase();
            validEmails.push(inputValue);
          } else {
            failedInput.push(inputValue)
          }
        }
    })
    if(failedInput.length > 0) this.failedChipPasteInput = failedInput.join(",")
  }

  addChipValue(event: MatChipInputEvent, validEmails: any): void {
    this.failedChipPasteInput = "";
    const input = event.input;
    const value = event.value;
    console.log("event", event)
    // Add our value
    if ((value || '').trim()) {
      var inputValue = value.trim();
        var isValid = CheckIsValidEmail(inputValue)
        this.isValidEmail = isValid;
        if (isValid) {
          inputValue = inputValue.toLowerCase();
          validEmails.push(inputValue);
          inputValue = "";
        }
    }
    // set input value
    if (input) {
      input.value = inputValue;
    }
  }

  removeChipValue(input: any, validEmails: any): void {
      const index = this.isValidEmail.indexOf(input);
      if (index >= 0) {
        validEmails.splice(index, 1);
      }
  }

}

function CheckIsValidEmail(email) {
  var mailformat = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  return email.match(mailformat);
}
