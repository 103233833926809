<div class="cover" style="width: 100%">
  <div class="section sectionHeading">Chart data source</div>
  <!-- <div class="gap" style="height: 1rem; border: 1px solid green"></div> -->

  <div class="section">
    <mat-radio-group
      aria-label="Data source type"
      color="primary"
      (change)="dataSourceTypeChanged($event)"
    >
      <div class="radioGroup">
        <mat-radio-button class="dataSourceTypeRadio" [checked]="config.dataSourceType === 'dynamic'" value="dynamic">Dynamic data</mat-radio-button>
        <mat-radio-button class="dataSourceTypeRadio" [checked]="config.dataSourceType === 'static'" value="static">Static data</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>

  <div class="section">
    <fieldset *ngIf="config.dataSourceType === 'dynamic'" class="dataPointsFieldset">

      <widget-data-source-box-config
        [dataSource]="widgetMeta.config.dataSource"
        (newDataSource)="newChartDataSourceRceived($event)"
      >
      </widget-data-source-box-config>

      <div *ngIf="isAttributesReady" fxLayout="column" fxLayoutAlign="center stretch">
        <chart-dynamic-attribute-config
          [widgetMeta]="widgetMeta"
          [attributes]="attributes"
          (newWidgetMeta)="attributeConfigReceived($event)"
        >
        </chart-dynamic-attribute-config>
      </div>
      <mat-checkbox *ngIf="attributes.length" [checked]="widgetMeta?.config?.dataSource?.filter?.userFilterEnabled" (change)="onUserFilterChanged($event)">Allow user filter</mat-checkbox>
    </fieldset>


    <fieldset *ngIf="config.dataSourceType === 'static'" class="dataPointsFieldset">

      <div fxLayoutAlign="flex-start center" fxLayoutGap="1rem">

        <!-- DIMENSION -->
        <div *ngIf="config.dimensions && config.dimensions.length">
          <div style="height: 1rem"></div>
          <div class="subHeading">Dimension</div>
          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem" style="width: 100%">
            <mat-form-field class="noPaddingBottom" appearance="outline">
              <input
                #staticDimensionInputBox
                [value]="config.dimensions[0].attribute.__id || ''"
                (input)="staticDimensionInput(staticDimensionInputBox.value)"
                type="text"
                aria-label="attribute"
                matInput
              >
            </mat-form-field>
          </div>
        </div>

        <!-- METRIC -->
        <div *ngIf="config && config.metric && widgetMeta.config.chartType.value !== 'scorecard'">
          <div style="height: 1rem"></div>
          <div class="subHeading">Metric</div>
          <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%">
            <mat-form-field class="noPaddingBottom" appearance="outline" style="margin-right: 1rem">
              <input
                #staticMetricInput
                type="text"
                [value]="config.metric.attribute.__id || ''"
                (input)="staticMetricAttrInput(staticMetricInput.value)"
                aria-label="attribute autocomplete"
                matInput
              >
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- DATA -->
      <div style="height: 1rem"></div>
      <div class="sectionBody" style="width: 100%;">
        <div class="subHeading">Static Data</div>
        <div style="height: .5rem"></div>

        <!-- NON SCORE CARD -->
        <div *ngIf="widgetMeta.config.chartType.value !== 'scorecard'; else elseTemplate">
          <div fxLayoutAlign="flex-start center" fxLayoutGap=".5rem" style="width: 100%;" style="background-color: #efefef; height: 2rem">
            <div fxFlex="5" fxLayoutAlign="center center" class="dataRowsTableHeader">#</div>
            <div fxFlex="70" fxLayout="row" fxLayoutAlign="flex-start center">
              <div fxFlex="45" class="dataRowsTableHeader">{{ config.dimensions[0].attribute.__id }}</div>
              <div fxFlex="45" class="dataRowsTableHeader">{{ config.metric.attribute.__id }}</div>
              <div></div>
            </div>
          </div>

          <ng-container *ngIf="staticData.length; else elseTemplate">
            <div
              style="width: 100%; cursor: default; margin: 0;"
              *ngFor="let dataRow of staticData; let i = index; trackBy: trackByFn"
              fxLayout="row wrap" fxLayoutAlign="flex-start center" fxLayoutGap=".5rem"
            >
              <div fxLayoutAlign="center center" fxFlex="5">{{ i + 1 }}</div>

              <div fxLayoutAlign="flex-start center" fxFlex="70">
                <div
                  fxFlex="100"
                  *ngIf="editModeIndex !== i"
                  fxLayout fxLayoutAlign="flex-start center"
                  style="box-sizing: border-box;"
                >
                  <div class="name" fxFlex="45" fxLayoutAlign="flex-start center">{{ dataRow.name }}</div>
                  <div class="value" fxFlex="45" fxLayoutAlign="flex-start center">{{ dataRow.value }}</div>
                </div>

                <div
                  fxFlex="100"
                  *ngIf="editModeIndex == i"
                  fxLayout fxLayoutAlign="space-around center"
                  style="box-sizing: border-box;"
                >
                  <div fxLayoutAlign="flex-start center" fxFlex="45">
                    <mat-form-field class="noPaddingBottom" fxFlex="100">
                      <input matInput type="text" [value]="dataRow.name" (input)="dimValueChanged($event, i)" style="width: 100%">
                    </mat-form-field>
                  </div>
                  <div fxLayoutAlign="flex-start center" fxFlex="45">
                    <mat-form-field class="noPaddingBottom" fxFlex="100">
                      <input matInput type="number" [value]="dataRow.value" (input)="metricValueChanged($event, i)" style="width: 100%">
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div fxLayoutAlign="center center" fxFlex="5">
                <ng-container *ngIf="editModeIndex !== i; else editModeIcon">
                  <button mat-icon-button (click)="editDataRow(i)" matTooltip="Edit data row"><mat-icon class="icon">edit</mat-icon></button>
                </ng-container>
                <ng-template #editModeIcon>
                  <button mat-icon-button (click)="saveEditedRow()" matTooltip="Done"><mat-icon class="icon">done</mat-icon></button>
                </ng-template>
              </div>

              <div fxLayoutAlign="center center" fxFlex="5" matTooltip="Delete data row">
                <mat-icon class="icon" (click)="deleteDataRow(i)">delete</mat-icon>
              </div>
            </div>

            <div style="height: .5rem"></div>
            <div fxLayout fxLayoutAlign="flex-start center">
              <button mat-stroked-button color="primary" (click)="addNewStaticRow()">
                <div fxLayoutAlign="space-around center">
                  <mat-icon>add</mat-icon>
                  <span>New Data</span>
                </div>
              </button>
            </div>
          </ng-container>

          <ng-template #elseTemplate>
            <div fxLayout fxLayoutAlign="center center" class="noDataRowMessage">
              <button mat-stroked-button color="primary" (click)="addNewStaticRow()" style="margin: 1rem 0">
                <div fxLayoutAlign="space-around center">
                  <mat-icon>add</mat-icon>
                  <span>Add Data Now</span>
                </div>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- SCORECARD -->
        <ng-template #elseTemplate>
          <div fxLayoutAlign="flex-start center" fxLayoutGap="1rem">

            <!-- SCORE CARD LABEL -->
            <div>
              <div style="height: 1rem"></div>
              <div class="subHeading">{{ widgetMeta.config.scorecardLabel.displayName }}</div>
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem" style="width: 100%">
                <mat-form-field class="noPaddingBottom" appearance="outline">
                  <input
                    #scoreLabel
                    [value]="widgetMeta.config.scorecardLabel.value || 'Score'"
                    (input)="staticScorecardLabelInput(scoreLabel.value)"
                    type="text"
                    matInput
                  >
                </mat-form-field>
              </div>
            </div>

            <!-- SCORE CARD VALUE -->
            <div>
              <div style="height: 1rem"></div>
              <div class="subHeading">Score value</div>
              <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%">
                <mat-form-field class="noPaddingBottom" appearance="outline" style="margin-right: 1rem">
                  <input
                    #scoreValue
                    type="text"
                    [value]="config.staticData[1] || 0"
                    (input)="staticScorecardValueInput(scoreValue.value)"
                    aria-label="attribute autocomplete"
                    matInput
                  >
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-template>


        <div style="height: .5rem;"></div>
      </div>
    </fieldset>
  </div>

  <div style="height: 1rem;"></div>
</div>
