import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { FlowContentService } from 'src/app/flow/flow-content.service';

@Component({
    selector: 'step-processor',
    templateUrl: './step-processor.component.html',
    styleUrls: ['./step-processor.component.scss'],
    standalone: false
})
export class StepProcessorComponent implements OnInit {
  @Input() 'step': any;
  @Input() 'sourceFields': any;
  @Input() 'editMode': boolean = true;
  @Output() 'onConfigure' = new EventEmitter<any>();
  @Output() 'stepSourceFields' = new EventEmitter<any>();
  @Output() 'actionChanged' = new EventEmitter<any>();
  @Output() 'userChanges' = new EventEmitter<any>();


  stepOptions: any = {
    connectionOptions: [],
    actionFields: [],
    box: {},
    action: {},
  };

  parameterOptions:any = [];
  fieldMap: any;



  constructor(private ngZone: NgZone, public flowContentService: FlowContentService) {}

  ngOnInit(): void {
    console.log("===> STEP", this.step)
    console.log("sourceFields--->",this.step.step_code, "--->", this.sourceFields);
    this.iniziate();
  };

  ngOnChanges(changes:any){
    console.log("[changes]", changes)
  }

  iniziate(){
    if(!this.step.processor_map) this.step.processor_map = { type: ""};
    if(this.step?.processor_map?.type) {
      this.onProcessorTypeSelected()
      this.checkForRequiredParams()
    } else if(!this.step?.processor_map?.type){
      if(!this.step.processor_map) this.step.processor_map = {};
      this.step.processor_map = { type: ""};
    }
    this.constructFieldMap();
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  onProcessorSelected(){
    console.log("===> STEP", this.step)
    this.userMadeChanges();
  }

  onProcessorTypeSelected(){
    console.log("===> STEP", this.step);

    let pType = this.step.processor_type;
    let type = this.step.processor_map.type;
    let inputFields = this.flowContentService.processorTypes[pType].map[type]?.input;
    console.log("===> inputFields", inputFields);

    let actionFields = [];
    this.flowContentService.processorTypes[pType].map[type]?.output?.list?.forEach(element => {
      let obj = this.flowContentService.processorTypes[pType].map[type]?.output?.[element];
      actionFields.push(obj);
    });
    this.stepOptions.actionFields = actionFields;
    this.parameterOptions = inputFields;
    console.log("===> this.parameterOptions", this.parameterOptions);
    this.emitStepSourceFields()
    this.userMadeChanges();
  }

  inputOptionsRecevied(e){
    console.log("inputOptionsRecevied", e)
    this.step.processor_map.input = e;
    this.userMadeChanges()
  }

  inputReceived(e, field){
    console.log("inputReceived", e, field)
    if(!this.step.processor_map.input) this.step.processor_map.input = {};
    this.step.processor_map.input[field] = e.value;
    this.checkForRequiredParams()
  }

  checkForRequiredParams(){
    this.parameterOptions?.list.forEach(element => {

      let paramElemt = this.parameterOptions[element];
      console.log("element", element)
      if(paramElemt.requiredParams?.length > 0){
        let hasValue = false;
        paramElemt.requiredParams.forEach(ele => {
          if(this.step.processor_map.input[ele]) hasValue = true
        });

        if(hasValue && paramElemt.optionFun){
          let options = paramElemt.optionFun(this.step.processor_map.input[paramElemt.param]);
          paramElemt.options = options;
        }
      }
    });
  }


  emitStepSourceFields(){

    let stepSourceFields = [];
    let actionFields = JSON.parse(JSON.stringify(this.stepOptions?.actionFields));
    actionFields.forEach(element => {
      element.__id = this.step.step_code + "." + element.__id || element.name;
      stepSourceFields.push(element);
    });
    console.log("[emitStepSourceFields]", stepSourceFields);
    if(!this.step.step_options)this.step.step_options = {};
    this.step.step_options.step_fields = stepSourceFields;
    this.stepSourceFields.emit(stepSourceFields);
  }

  insertCursor(e, key){
    console.log("e, key", e, key);
    if(!this.step.processor_map.input) this.step.processor_map.input = {};
    let value = e.__id;
    let cursorValue = '${' + value + '}';
    this.step.processor_map.input[key] = cursorValue;
    this.checkForRequiredParams()
  }

  userMadeChanges() {

    this.userChanges.emit(true);
  }

  constructFieldMap(){
    let fieldObj:any = {};
    let list = [];

    if(this.sourceFields?.length > 0) {
      list.push('sourceFields');
      let fieldObjsourceFields = this.getFieldMapObject('sourceFields','SOURCE FIELDS',this.sourceFields)
      fieldObj[fieldObjsourceFields.id] = fieldObjsourceFields
    }
    if(this.flowContentService?.systemFields?.length > 0) {
      list.push('systemFields');
      var fieldObjsystemFields = this.getFieldMapObject('systemFields','SYSTEM FIELDS',this.flowContentService.systemFields)
      fieldObj[fieldObjsystemFields.id] = fieldObjsystemFields
    }
    fieldObj.list = list;
    this.fieldMap = fieldObj;
    console.log("FIELD MAP PREPARED : ",this.fieldMap)
  }

  getFieldMapObject(_id:string, displayName:string, fields:any, option?:any){
    var obj = {
      id : _id,
      displayName : displayName,
      fields : fields,
      options : option ? option : {}
    }
    return obj
  }

}
