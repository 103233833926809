import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { SystemDataService } from '../services/system-data.service';

@Component({
    selector: 'app-system-objects-selection',
    templateUrl: './system-objects-selection.component.html',
    styleUrls: ['./system-objects-selection.component.scss'],
    standalone: false
})
export class SystemObjectsSelectionComponent implements OnInit {

  constructor(
    public systemDataService: SystemDataService,
  ) { }
  spinner: boolean = false;
  systemObjectsListingACFrom = new UntypedFormControl();
  systemObjectsOptions: any;
  systemObjects: any;

  @Input() selected: any;
  @Output() "selectedObject" = new EventEmitter<any>();

  async ngOnInit() {
    let systemObjectMap = this.systemDataService.getSystemObjectMap();
    this.systemObjects = Object.keys(systemObjectMap)
      .filter(key => key !== 'fields') // Exclude `fields` property
      .map(key => systemObjectMap[key]); // Convert to array

    if(this.selected){
      this.systemObjectsListingACFrom.setValue(systemObjectMap[this.selected]);
    }
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  async ngOnChanges(changes: SimpleChanges) {

  }

  systemObjectSelected(e){
    this.selectedObject.emit(e);
  }


  async setStepFilterOptions() {
    this.systemObjectsOptions = this.systemObjectsListingACFrom.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value, this.systemObjects))
    );
  }

  private _filter(value: string, array?:any): string[] {
    value = value ? value : ""
    if(typeof value != "string") return;
    const filterValue = value.toLowerCase();
    return array.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFunction(subject) {
    if(!subject) return undefined;
    return subject.name;
  }

}
