import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-connection-explorer-delete-record-dialog',
    templateUrl: './connection-explorer-delete-record-dialog.component.html',
    styleUrls: ['./connection-explorer-delete-record-dialog.component.scss'],
    standalone: false
})
export class ConnectionExplorerDeleteRecordDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConnectionExplorerDeleteRecordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  delete(){
    this.dialogRef.close(true);
  }

  dontDelete(){
    this.dialogRef.close(false);
  }

}
