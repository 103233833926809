<!-- <div class="header" style="width: 100%; padding: 1.5rem;">
  <button mat-stroked-button color="primary" style="width: 6rem" (click)="goBack()" fxLayoutAlign="center center">
    Back
  </button>
</div> -->
<spinner
  [center]="true"
  size="la-2x"
  color="grey"
  type="ball-triangle-path"
></spinner>
<div fxLayoutAlign="center center" style="width: 100%; overflow-x: scroll;">
  <app-list-panel 
    style="width: 100%" 
    *ngIf="isReady" 
    [panelMeta]="listPanel" 
    [builderMode]="false"
    [isDynamicWidth]="true"
  >
  </app-list-panel>
</div>
