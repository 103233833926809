import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-connection-list-box-popup',
    templateUrl: './connection-list-box-popup.component.html',
    styleUrls: ['./connection-list-box-popup.component.scss'],
    standalone: false
})
export class ConnectionListBoxPopupComponent implements OnInit {

  boxConfig:any;
  boxSelected: boolean;
  connectionConfig: any = {}
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public dialogRef: any,
    public dialog: MatDialogRef<ConnectionListBoxPopupComponent>, 
  ) {
    this.boxConfig = this.dialogRef;
   }

  ngOnInit(): void {
    if(this.dialogRef?.edit && this.dialogRef?.connection){
      this.connectionConfig.connection = this.dialogRef.connection;
      this.boxSelected = true;
    }

    if(this.boxConfig?.filterType != 'any' && this.boxConfig.filterList.length > 0){
      let filter = '';
      switch (this.boxConfig.filterType) {
        case "categories":
          this.boxConfig?.filterList?.forEach((element, i) => {
            if(i != 0) filter = filter + ";";
            filter = filter + `categories=${element}`
          });
          break;
        case "apps":
          this.boxConfig?.filterList?.forEach((element, i) => {
            if(i != 0) filter = filter + ";";
            filter = filter + `__id=${element}`
          });
          break;
        default:
          break;
      }
      this.boxConfig.filter = filter;
    }
    console.log("this.boxConfig", this.boxConfig);
  }

  newAppSelected(e){
    console.log("box selected", e)
    this.connectionConfig.box = e.__id;
    this.boxSelected = true;
  }

  connectionChange(e){
    console.log("e", e)
    this.dialog.close(e);
  }

}
