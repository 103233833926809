<div class="header" fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title>What form do you want to prepare?</div>
  <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <div class="subheading">Choose Form Action</div>
  <div style="height: 1rem;"></div>

  <app-app-selection
    [existingBox]="actionConfig.actions[0].actionMap.boxId"
    [config]="{isSupportStarch: true}"
    (selectedBox)="appSelected($event)"
  >
  </app-app-selection>

  <app-connection-selection
    *ngIf="actionConfig.actions[0].actionMap.boxId && selectedBoxId !== 'starch'"
    [existingCon]="actionConfig.actions[0].actionMap.connection"
    [config]="{ box_id: actionConfig.actions[0].actionMap.boxId, focus: true }"
    (selectedConnection)="boxSelected($event)"
  ></app-connection-selection>

  <base-selection *ngIf="selectedBoxId === 'starch'" [existingBase]="panelMeta.baseId"
   (selectedBase)="baseSelected($event)"></base-selection>

  <app-select-box-object
    *ngIf="actionConfig.actions[0].actionMap.boxId && (actionConfig.actions[0].actionMap.connection || actionConfig.actions[0].actionMap.boxConfigToken)"
    [boxId]="getSelectedBoxId()"
    [connectionId]="getSelectedConnectionId(selectedBoxId)"
    [boxConfigToken]="boxConfigToken"
    [oldValue]="panelMeta.boxObjectId"
    [disableBoxObjectInput]="disableBoxObjectInput"
    (boxObjectInput)="sourceBoxObjectSelected($event)"
    (boxObjectSelectionError)="boxObjectSelectionError($event)"
  >
  </app-select-box-object>

  <small *ngIf="isBoxObjectConfigError" style="color: red;">{{ errorMessage }}</small>

  <app-action-selection
    *ngIf="actionConfig.actions[0].actionMap.boxId && selectedBoxObjectId"
    [config]="{
      box_id: actionConfig.actions[0].actionMap.boxId,
      base_box_id: this.panelMeta?.baseMap?.box_id,
      connection: getSelectedConnectionId(selectedBoxId),
      boxConfigToken: actionConfig.actions[0].actionMap.boxConfigToken,
      box_object_id: selectedBoxObjectId
    }"
    [collectExtraOptions]="false"
    [existingActionId]="actionConfig.actions[0].actionMap.action"
    (selectedAction)="actionSelected($event)"
  ></app-action-selection>
    <!-- (actionFields)="actionSelected($event)" -->

  <div *ngIf="collectAttrOptions">
    <div style="height: 1rem;"></div>
    <div class="subheading">Input params to fetch attributes</div>
    <!-- <div style="height: .5rem;"></div> -->
    <shared-parameter-inputs
      [action]="getAttrFn"
      [existingInputs]="availableInputParams"
      [isBoxObject]="canGetBoxObjects"
      [isOnlyOptions]="true"
      (inputParameter)="attrOptionInputsRecevied($event)"
    ></shared-parameter-inputs>

    <button
      mat-stroked-button
      color="primary"
      (click)="attributeOptionsCollected()"
    ><mat-icon>east</mat-icon>
    </button>
  </div>


  <div *ngIf="collectGetOptions">
    <div style="height: 1rem;"></div>
    <div class="subheading">Input params to get record</div>
    <!-- <div style="height: .5rem;"></div> -->
    <shared-parameter-inputs
      [action]="getFn"
      [existingInputs]="availableInputParams"
      [isOnlyOptions]="true"
      (inputParameter)="getOptionInputsRecevied($event)"
    ></shared-parameter-inputs>

    <button
      mat-stroked-button
      color="primary"
      (click)="constructActionOptions()"
    ><mat-icon>east</mat-icon>
    </button>
  </div>

  <div *ngIf="collectActionOptions">
    <div style="height: 1rem;"></div>
    <div class="subheading">Input params to execute action</div>
    <!-- <div style="height: .5rem;"></div> -->
    <shared-parameter-inputs
      [action]="selectedAction"
      [connection]="{_id: this.selectedConnectionId, box_id: this.selectedBoxId}"
      [existingInputs]="availableInputParams"
      [optionalInputs]="availableInputParams"
      [attributesFetched]="boxObjectAttributes"
      [isOnlyOptions]="true"
      (inputParameter)="actionOptionInputsRecevied($event)"
    ></shared-parameter-inputs>

    <button
      mat-stroked-button
      color="primary"
      (click)="inputParamsCollected()"
    ><mat-icon>east</mat-icon>
    </button>
  </div>

  <div style="height: 1rem;"></div>

  <!----------------------------------- FORM SETTING --------------------------------->
  <div class="attribute-error-cover">
    <small *ngIf="isAttributeError" class="attribute-error">{{ errorMessage }}</small>
  </div>
  <div *ngIf="attributeSpinner || gettingObjFunSpinner" fxLayout fxLayoutAlign="center center">
    <spinner></spinner>
  </div>

  <div class="attributeSelection" *ngIf="isAttributesReady">
    <div fxLayout style="min-width: 100%;">
      <mat-form-field class="full-width" fxFlex="60">
        <mat-label>Title</mat-label>
        <input matInput [(ngModel)]="formPanelTitle">
      </mat-form-field>
    </div>

    <div class="subheading">Form {{attributeDisplayName | titlecase }}s</div>

    <div class="attributes-table">
      <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">

        <div class="table-header" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="th" fxFlex="3"></span>
          <span class="th" fxFlex="5">#</span>
          <span class="th" fxFlex="35" fxLayout="row" fxLayoutAlign="start center">{{attributeDisplayName | titlecase}}</span>
          <span class="th" fxFlex="35">Widget Type</span>
          <span class="th" fxFlex="7">Enabled</span>
          <span class="th" fxFlex="7">Editable</span>
          <span class="th" fxFlex="6"></span>
          <span class="th" fxFlex="2"></span> <!--padding-->
          <!-- <span class="th" fxFlex="10">Hide</span> -->
        </div>

        <div
          class="example-box smallText"
          style="cursor: default;"
          *ngFor="let attr of formAttributes;
          let i = index"
          cdkDrag
          (mouseenter)="hoveredIndex = i"
          (mouseleave)="hoveredIndex = -1"
          fxLayout="row wrap" fxLayoutAlign="space-between center"
        >
          <!-- DRAG INDICATOR -->
          <div class="reorder td" fxFlex="3">
            <mat-icon cdkDragHandle style="cursor: move; opacity: 60%;" [style.visibility]="hoveredIndex == i ? 'visible' : 'hidden'">drag_indicator</mat-icon>
          </div>

          <!-- SERIAL NUMBER -->
          <div class="serial-number td" fxFlex="5">{{ i + 1 }}</div>

          <!-- ATTRIBUTE NAME -->
          <div class="attribute-id td" fxFlex="35" fxLayout fxLayoutAlign="start center">{{attr.name}}</div>

          <!-- WIDGET TYPE -->
          <div class="fieldType td" fxFlex="35" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="attr.enabled" fxLayout="row" fxLayoutAlign="center center">
              <button
                matTooltip="choose input type"
                mat-button
                fxFlexFill
                class="widgetSelectionButton"
                [matMenuTriggerFor]="menu"
                aria-label="Widget selection menu"
              >
                <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                  <mat-icon style="opacity: 60%;" *ngIf="widgetTypeMap[attr.widgetType].icon">{{ widgetTypeMap[attr.widgetType].icon || '' }}</mat-icon>
                  <span class="selectedWidget">{{ widgetTypeMap[attr.widgetType].name }}</span>
                  <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
                </div>
              </button>

              <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
                <ng-container *ngFor="let widgetType of widgetTypeMap.widgets; trackBy: trackByFn">
                  <button mat-menu-item (click)="widgetTypeSelected(i, widgetType)">
                    <mat-icon>{{ widgetTypeMap[widgetType].icon }}</mat-icon>
                    <span>{{ widgetTypeMap[widgetType].name }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>

          <!-- ENABLED -->
          <div class="allow-hide td" fxFlex="7">
            <mat-slide-toggle [checked]="attr.enabled" (change)="enabledChanged($event.checked, i)" color="primary"></mat-slide-toggle>
          </div>

          <div fxFlex="7">
            <mat-checkbox class="example-margin"
              [checked]="attr.editable"
              [disabled]="!attr.enabled"
              [color]="'primary'"
              (change)="editableChanged($event.checked, i)"
            >
            </mat-checkbox>
          </div>

          <!-- MENU ICON -->
          <div fxFlex="7" class="td">
            <button mat-icon-button #attributeMenu="matMenuTrigger" [matMenuTriggerFor]="menu2">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu2="matMenu">
              <button mat-menu-item (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
            </mat-menu>
          </div>

          <div fxFlex="5"></div>


          <mat-card appearance="outlined" *ngIf="attr.isDrillDown && attr.enabled" fxFlex=100 fxLayout="column" fxLayoutAlign="start start">
            <div fxLayoutAlign="end center" style="width: 100%;">
              <button mat-flat-button (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
            </div>
            <div fxLayoutAlign="flex-start center" style="width: 100%;">
              <div fxFlex="10"></div>
              <div fxFlex="90" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap=".5rem">
                <div style="height: 1rem" fxFlex="100"></div>
                <div fxFlex="100" fxLayoutAlign="start center">
                  <div fxLayoutAlign="start center">
                    <span style="color: #444">Nested property access for</span>
                    <span style="color: black">&nbsp;{{attr.__id}}</span></div>
                  </div>
                  <div style="font-size: .8rem; color: #777; font-style: italic; ">path may be separated by dot for deep nesting</div>
                <div style="height: .5rem" fxFlex="100"></div>

                <ng-container *ngFor="let nestedProperty of attr.nestedProperties; let j = index; trackBy: trackByFn">
                  <div fxFlex="100" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="45" appearance="outline" style="margin-bottom: -1.25em">
                        <input matInput placeholder="Nested value path" [(ngModel)]="nestedProperty.path">
                      </mat-form-field>

                      <div class="fieldType td" fxFlex="35" fxLayout="row" fxLayoutAlign="center center">
                        <button
                          matTooltip="choose input type"
                          mat-button
                          fxFlexFill
                          class="widgetSelectionButton"
                          [matMenuTriggerFor]="menu"
                          aria-label="Widget selection menu"
                        >
                          <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                            <mat-icon style="opacity: 60%;" *ngIf="widgetTypeMap[attr.widgetType].icon">{{ widgetTypeMap[attr.widgetType].icon || '' }}</mat-icon>
                            <span class="selectedWidget">{{ widgetTypeMap[attr.widgetType].name }}</span>
                            <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
                          </div>
                        </button>

                        <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
                          <ng-container *ngFor="let widgetType of widgetTypeMap.widgets; trackBy: trackByFn">
                            <button mat-menu-item (click)="nestedValueWidgetSelected(widgetType, i, j)">
                              <mat-icon>{{ widgetTypeMap[widgetType].icon }}</mat-icon>
                              <span>{{ widgetTypeMap[widgetType].name }}</span>
                            </button>
                          </ng-container>
                        </mat-menu>
                      </div>

                      <mat-icon fxFlex="10" (click)="deleteNestedProperty(i, j)" style="color: #444">delete</mat-icon>
                  </div>
                </ng-container>

                <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                  <button mat-stroked-button (click)="addNestedproperty(i)">
                    <mat-icon>add</mat-icon>
                    Access another property
                  </button>
                </div>

              </div>
            </div>
          </mat-card>

          <div fxFlex="2"></div>
        </div>
      </div>
    </div>
    <div style="height: 1rem;"></div>
    <!-- <app-filter-config
      [filter]="filter"
      [boxObjectAttributes]="boxObjectAttributes"
      [enableSecurity]="false"
      [maskOperator]="true"
      (selectedFilter)="filterChanged($event)"
    >
    </app-filter-config> -->
    <app-filter-component
      [filter]="filter"
      [boxObjectAttributes]="boxObjectAttributes"
      [enableSecurity]="false"
      [maskOperator]="true"
      (selectedFilter)="filterChanged($event)"
    >
    </app-filter-component>

    <div style="height: 1rem;"></div>
    <div fxFlexFill>
      <mat-form-field fxFlex="50">
        <mat-label>Submit Button Title</mat-label>
        <input matInput [(ngModel)]="submitButtonTitle">
      </mat-form-field>
    </div>
    <div style="height: 1rem;"></div>

    <div fxFlexFill>
      <mat-form-field fxFlex="50">
        <mat-label>Success Message</mat-label>
        <input matInput [(ngModel)]="panelMeta.successMessage">
      </mat-form-field>
    </div>
    <div style="height: 1rem;"></div>
  </div>

</mat-dialog-content>

<div>
  <spinner *ngIf="spinner"></spinner>
</div>

<div class="sticky" fxLayout fxLayoutAlign="center center" fxFlexFill>
  <button
    *ngIf="isReadyToSave && isAttributesReady"
    [disabled]="saveDisabled"
    fxLayout
    fxLayoutAlign="center center"
    mat-raised-button
    color="primary"
    (click)="saveConfig()"
  >
    {{ firstHit? 'Create Form Panel' : 'Save Configuration' }}
  </button>
</div>
