<div>
  <div *ngIf="widgetMeta">
    <div style="height: 1rem"></div>
    <mat-slide-toggle
      [checked]="isEnabled"
      (change)="isEnabledChanged($event.checked)"
      color="primary"
    >{{ widgetMeta.config.customOutputFormat.displayName }}</mat-slide-toggle>
    <br>
  </div>

  <ng-container *ngIf="isEnabled && isCustomDate">
    <div style="height: 1rem;"></div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" style="width: 100%;">
      <mat-form-field style="width: 100%;">
        <mat-label>Date Format</mat-label>
        <mat-select [ngModel]="selectedDateFormat">
          <mat-option *ngFor="let format of dateFormats.availableFormats; trackBy: trackByFn" [value]="dateFormats[format].value" (click)="dateformatSelected(dateFormats[format].value)">
            {{ dateFormats[format].name }}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="isSeparatePreview && selectedDateFormat != 'custom'" style="color: #1B51B5; font-size: larger;">Preview Date : {{ displayDate }}</mat-hint>
      </mat-form-field>
      <!-- <div style="width: 5%;"></div> -->
    </div>
    <!-- Custom Date Format  -->
    <div *ngIf="selectedDateFormat == 'custom'" fxLayoutAlign="center center" fxLayoutGap="5%" style="width: 100%;">
      <div style="width: 5%;"></div>
      <!-- <span style="width: 10%;" fxLayoutAlign="start center"></span> -->
      <mat-form-field style="width: 95%;">
        <input type="text" #customdateinput placeholder="Enter a custom date format" aria-label="Number" matInput [(ngModel)]="selectedCustomDateFormat"
        (input)="customDateformatSelected($event)">
        <mat-hint *ngIf="isSeparatePreview" style="color: #1B51B5; font-size: larger;">Preview Date : {{ customDisplayDate }}</mat-hint>
        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearCustomDate()"><mat-icon>close</mat-icon></button>
      </mat-form-field>
      <!-- <div style="width: 5%;"></div> -->
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="isEnabled && isCustomDate && isSeparatePreview">
    <div style="height: .5rem"></div>
    <div style="color: #1B51B5;">Preview: {{ (displayDate || customDisplayDate) }}</div>
  </ng-container> -->

  <ng-container *ngIf="isEnabled && isCustomTime">
    <div style="height: 1rem;"></div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" style="width: 100%;">
      <mat-form-field style="width: 100%;">
        <mat-label>Time Format</mat-label>
        <mat-select [(ngModel)]="selectedTimeFormat">
          <mat-option *ngFor="let format of timeFormats.availableFormats; trackBy: trackByFn" [value]="timeFormats[format].value" (click)="timeformatSelected(timeFormats[format].value)">
            {{ timeFormats[format].name }}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="isSeparatePreview && selectedTimeFormat != 'custom'" style="color: #1B51B5; font-size: larger;">Preview : {{ displayTime }}</mat-hint>
      </mat-form-field>
      <!-- <div style="width: 5%;"></div> -->
    </div>
    <!-- Custom Time Format  -->
    <div *ngIf="selectedTimeFormat == 'custom'" fxLayoutAlign="center center" fxLayoutGap="5%" style="width: 100%;">
      <div style="width: 5%;"></div>
      <!-- <span style="width: 10%;" fxLayoutAlign="start center"></span> -->
      <mat-form-field style="width: 95%;">
        <input type="text" #customtimeinput placeholder="Enter a custom time format" aria-label="Number" matInput [(ngModel)]="selectedCustomTimeFormat"
        (input)="customTimeformatSelected($event)">
        <mat-hint *ngIf="isSeparatePreview" style="color: #1B51B5; font-size: larger;">Preview : {{ customDisplayTime }}</mat-hint>
        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearCustomTime()"><mat-icon>close</mat-icon></button>
      </mat-form-field>
      <!-- <div style="width: 5%;"></div> -->
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="isEnabled && isCustomTime && isSeparatePreview">
    <div style="height: .5rem"></div>
    <div style="color: #1B51B5;">Preview: {{ (displayTime || customDisplayTime) }}</div>
  </ng-container> -->

  <ng-container *ngIf="isEnabled && isCustomTime && isCustomDate && !isSeparatePreview">
    <div style="height: .5rem"></div>
    <div style="color: #1B51B5;">Preview: {{ (displayDate || customDisplayDate) + " " + (displayTime || customDisplayTime) }}</div>
  </ng-container>

</div>
