import { Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StarchService } from 'src/app/shared/services/starch.service';
import { AuthServiceService } from '../shared/services/auth-service.service';
import { ConnectionService } from '../modules/organization/connection.service';
import { ThemeService } from '../shared/services/theme.service';
import { WidgetManager } from '../bloom/models/WidgetManager';
import { environment } from 'src/environments/environment';
import { DeleteDialogComponent } from '../shared/delete-dialog/delete-dialog.component';
import { StarchCollaborationComponent } from './starch-collaboration/starch-collaboration.component';


@Component({
    selector: 'starch',
    templateUrl: './starch.component.html',
    styleUrls: ['./starch.component.scss'],
    standalone: false
})

export class StarchComponent implements OnInit, OnDestroy {

  workspaceReady: boolean = false
  spinner:boolean = false;
  authError: boolean;
  starchListPanelMeta: {
    listAttributes: ({ widgetType: string; columnName: string; sortEnabled: boolean; show_hide: boolean; value: string; dataType: string; isColumnSelected: boolean; name: string; __id: string; fieldType: string; [key: string]: any; } | { __id: string; name: string; dataType: string; sortEnabled: boolean; show_hide: boolean; fieldType: string; widgetType: string; isColumnSelected: boolean; columnName?: undefined; value?: undefined; } | { columnName: string; dataType: string; fieldType: string; name: string; show_hide: boolean; sortEnabled: boolean; value: string; widgetType: string; __id: string; isColumnSelected?: undefined; })[];
    // dataHeaders: ["#", "Name"],
    defaultListSize: number; hideTitle: boolean; filter: { filterEnabled: boolean; filterItems: { attribute: string; operator: string; value: string; dataType: string; filterType: string; }[]; }; type: string; viewTypes: { views: string[]; defaultView: string; boardStatusColumn: string; userCanChoose: boolean; table: { name: string; displayName: string; userCanChoose: boolean; icon: string; }; card: { name: string; displayName: string; userCanChoose: boolean; icon: string; }; board: { name: string; displayName: string; userCanChoose: boolean; icon: string; }; }; userFilterEnabled: boolean; userSortEnabled: boolean; boxId: string; connectionId: string; baseMap: { box_id: string; base_id: string; }; boxObjectId: string; loadInitialData: boolean; boxConfigToken: any; pageSize: number; paginationEnabled: boolean; paginationType: string; id: string; noCache: boolean; noDataMessage: string;
  };
  addNewBase:boolean = false;
  refreshPanel:boolean = false
  isBrowser: boolean;

  constructor(
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private ngZone: NgZone,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object

  ) {
  }

  async ngOnInit() {
    // this.spinnerService.hide()
    if (!this.connectionService.selectedWorkSpace || !this.authService.loggedIn) {
      console.log("in starch init")
      this.authService.authCheck();
      let that = this;
      this.authService.authCheckPositive.subscribe((authStatus) => {
        console.log('authStatus logged in', authStatus);
        //if logged in
        if (authStatus) {
          that.initialize();
        } else {
          that.authError = true;
          that.spinner = false;
          // this.snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top',
          // });
          // redirect to login page
          that.ngZone.run(() => {
            that.router.navigate(['../']);
          });
          return;
        }
      });
    } else if (this.connectionService.selectedWorkSpace) {
      this.spinner = false;
      this.initialize();
    }
  }

  async initialize(){
    if(!this.connectionService.workSpaceId) await this.connectionService.getWorkSpaceId();
    this.createListMeta();
    this.workspaceReady = true;
  }

  refreshList(){
    this.initialize();
  }


  async shareStarchBase(base){
    console.log("Starch to be shared : ",base);
    let dialogRef = this.dialog.open(StarchCollaborationComponent, {
      width: '90vw',
      height: '90vh',
      panelClass: "collabdiolog",
      data: {
        resource: base,
        resourceType: 'starch'
      },
    });
    var diologResult = await dialogRef.afterClosed().toPromise();
  }

  triggerRefresh(){
    this.refreshPanel = true;
    let that = this;
    setTimeout(function() {
      that.refreshPanel = false;
    }, 1000);
  }

  async selectedRowData(data){
    console.log("selectedRowData hit", data)
    if(data.actionId == 'explore'){
      console.log("open starch", data.rowDataRaw)
      // let starch = await this.starchService.getStarchBase(data.rowDataRaw._id);
      this.router.navigate(['starch', 'explorer'],
      {
        queryParams: {
          base: data.rowDataRaw._id
        },
        state: {
          starch: data.rowDataRaw,
          workspaceId: this.connectionService.workSpaceId
        }
      })
    }else if(data.actionId == 'edit'){
      console.log("open bloom", data.rowDataRaw)
      this.router.navigate(['starch', 'edit'],
      {
        queryParams: {
          base: data.rowDataRaw._id
        }
      })
    }else if(data.actionId == 'share'){
      console.log("view published hit")
      this.shareStarchBase(data.rowDataRaw);
    }
  }

  createListMeta(){
    this.starchListPanelMeta = {
      listAttributes: [
        {
          "widgetType": "label",
          "columnName": "#",
          "sortEnabled": false,
          "show_hide": false,
          "value": "__serial__",
          "dataType": "NA",
          "isColumnSelected": true,
          "name": "__serial__",
          "__id": "___serial__",
          "fieldType": "label"
        },
        {
          "__id": "logo",
          "name": "Logo",
          "dataType": "string",
          "sortEnabled": false,
          "show_hide": false,
          "fieldType": "attribute",
          "widgetType": "image",
          "isColumnSelected": true
        },
        {
          "__id": "name",
          "name": "Starch Name",
          "dataType": "string",
          "sortEnabled": false,
          "show_hide": false,
          "fieldType": "attribute",
          "widgetType": "label",
          "isColumnSelected": true
        },
        {
          columnName: 'Explore',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'explore',
          show_hide: false,
          sortEnabled: false,
          value: 'visibility',
          widgetType: 'icon',
          __id: 'explore',
          isColumnSelected: true
        },
        {
          columnName: 'Edit',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'edit',
          show_hide: false,
          sortEnabled: false,
          value: 'edit',
          widgetType: 'icon',
          __id: 'edit',
          isColumnSelected: true
        },
        {
          columnName: 'Share',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'Share',
          show_hide: false,
          sortEnabled: false,
          value: 'share',
          widgetType: 'icon',
          __id: 'share',
          isColumnSelected: true
        },
        {
          columnName: 'Delete',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'delete',
          show_hide: false,
          sortEnabled: false,
          value: 'delete',
          widgetType: 'icon',
          __id: 'delete',
          isColumnSelected: true,
          eventType: 'action',
          actionConfig: {
            action: 'resource-deletion',
            event: 'click'
          }
        }
      ],
      // dataHeaders: ["#", "Name"],
      defaultListSize: 10,
      hideTitle: true,
      filter: {
        "filterEnabled": true,
        "filterItems": [
          {
            "attribute": "workspace_id",
            "operator": "=",
            "value": this.connectionService.selectedWorkSpace,
            "dataType": "string",
            "filterType": "static_filter"
          }
        ]
      },
      type: 'listpanel',
      viewTypes: {
        "views": [
            "table",
            "card",
            "board"
        ],
        "defaultView": "table",
        "boardStatusColumn": "",
        "userCanChoose": true,
        "table": {
            "name": "table",
            "displayName": "Table",
            "userCanChoose": true,
            "icon": "list"
        },
        "card": {
            "name": "card",
            "displayName": "Card",
            "userCanChoose": true,
            "icon": "grid_on"
        },
        "board": {
            "name": "board",
            "displayName": "Board",
            "userCanChoose": false,
            "icon": "view_kanban"
        }
      },
      userFilterEnabled: false,
      userSortEnabled: false,
      boxId: 'starch',
      connectionId: "",
      baseMap: {
        "box_id": "mongodb",
        "base_id": ""
      },
      boxObjectId: 'starch',
      loadInitialData: true,
      boxConfigToken: environment.DB_BOX_TOKEN,
      pageSize: 10,
      paginationEnabled:true,
      paginationType: "pagebypage",
      id: "starchListing",
      noCache: true,
      noDataMessage: "Create your first starch now."
    };

    this.starchListPanelMeta["listWidgetSet"] = this.getWidgetSet(this.starchListPanelMeta)
    this.starchListPanelMeta['listPanelTitle'] = "List of Starch Bases",

    console.log("Panel meta for starch listing", this.starchListPanelMeta)
  }

  ngOnDestroy(): void {
    this.authService?.authCheckPositive?.unsubscribe();
  }

  getWidgetSet(meta){
    let widgetSet = {}
    for (let i = 0; i < meta.listAttributes.length; i++){
      const attrId = meta.listAttributes[i].__id;
      let widgetType: string = meta.listAttributes[i].widgetType == 'NA' ? meta.listAttributes[i].fieldType : meta.listAttributes[i].widgetType;
      widgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
    }
    return widgetSet
  }

  navigateToSignUp(){
    this.router.navigate(['sign-up']);
  }
}


