import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { FormService } from '../form.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';

@Component({
    selector: 'app-form-viewer-canvas',
    templateUrl: './form-viewer-canvas.component.html',
    styleUrls: ['./form-viewer-canvas.component.scss'],
    standalone: false
})


export class FormViewerCanvasComponent implements OnInit {
  form: any;
  isSuccessPage: boolean;
  routerSubscription: any;
  style:any = {};
  @Input() type: any;

  constructor(
    public formService: FormService,
    public router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    public authService: AuthServiceService
  ) { }

  ngOnInit(): void {
    this.authService.subproduct = "form";
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkIfSuccess();
      });

    this.formService.formMeta.subscribe(data => {
      this.form = data;
    })
    this.checkIfSuccess();
  }

  getMargin(margin){
    let marginCss = {};
    // let marginLeft = margin?.left || 15;
    // let marginRight = margin?.right || 85;
    // let marginTop = margin?.top || 0;
    // let marginBottom = margin?.bottom || 100;
    let marginLeft = margin?.left || margin?.left === 0 ? margin.left : 15;
    let marginRight = margin?.right || margin?.right === 0 ? margin.right : 85;
    let marginTop = margin?.top || margin?.top === 0 ? margin.top : 2;
    let marginBottom = margin?.bottom || margin?.bottom === 0 ? margin.bottom : 98;
    marginCss = {
      "margin-left": (marginLeft) + "%",
      "margin-right": (100 - marginRight) + "%",
      "margin-top": (marginTop) + "%",
      "margin-bottom": (100 - marginBottom) + "%",
    }
    return marginCss;
  }

  getMergedStyles(): { [key: string]: string } {
    const marginStyles = this.getMargin(this.form?.theme?.selectedTheme?.frameconfig?.margin);
    const frameStyles = this.form?.theme?.selectedTheme?.frameconfig?.style ? this.form?.theme?.selectedTheme?.frameconfig?.style
                         : {};

    let custom = {width: "100%"}
    return {
      ...marginStyles,
      ...frameStyles,
      ...custom
    };
  }


  checkIfSuccess() {
    let bool =  false;
    const urlSegments = this.router.url.split('/');
    console.log("urlSegments", urlSegments)
    if (urlSegments[urlSegments.length - 1] === 'success') {
      bool = true;
    }
    this.isSuccessPage = bool;
    if(this.isSuccessPage) this.applyStyles({"align-self": "center"});
    else this.applyStyles({"align-self": "start"});
    return bool;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  applyStyles(addStyleMap) {
    let style = {};
    Object.assign(this.style, addStyleMap);
    const element = this.el.nativeElement.querySelector('.form-canvas');
    if (element && this.style) {
      Object.keys(this.style).forEach(style => {
        this.renderer.setStyle(element, style, this.style[style]);
      });
    }
  }

}
