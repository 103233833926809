import { Widget } from "./baseWidget";
import { AvailableOptions } from "./optionsConfig";
import { TextFormatting } from "./textFormat";

interface item{
  content: string,
  disabled?: boolean,
  selected?: boolean
}

export class Choice extends Widget{
  type = 'choice';
  gridX = 12
  minGridX = 3
  selectedContent = ''   //will hold the content of selected item
  selectedIndex = -1   //will hold the index of selected item in items array

  config = {
    props: [
      'availableOptions',
      'alignment',
      'orientation',
      'value'
    ],
    availableOptions: AvailableOptions,
    orientation: {
      displayName: 'Orientation',
      value: 'horizontal',   //or vertical
      type: 'select'
    },
    value: {
      displayName: "Value",
      value: '',
      type: 'text',
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
  };

  //text formatting properties
   textFormat;

  constructor(id: number, name: string){
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = "Choose an option"

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    return map;
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return true
  }

  public setOptions(value){
    console.log("choice: setOptions()", this.config, "values", value)
    if(!value){
      this.config.value.value = '';
      // this.config.availableOptions.staticOptions.forEach(opt => {
      //   if(opt.default) opt.default = false
      // });
    } else {
      this.config.value.value = value
    }
    return value;
  }
}
