<div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon color="primary" > rebase_edit </mat-icon>
    <mat-form-field style="width: 100%;">
      <mat-label>Processor</mat-label>
      <mat-select (selectionChange)="onProcessorSelected()"
      [(ngModel)] = "step.processor_type">
        <mat-option [value]="flowContentService.processorTypes[type].__id"
        *ngFor="let type of flowContentService.processorTypes.list; trackBy: trackByFn">
          {{ flowContentService.processorTypes[type].name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="step.processor_type">
    <mat-icon color="primary" > memory </mat-icon>
    <mat-form-field style="width: 100%;">
      <mat-label>{{flowContentService.processorTypes[step.processor_type].name}}</mat-label>
      <mat-select (selectionChange)="onProcessorTypeSelected()"
      [(ngModel)] = "step.processor_map.type">
        <mat-option [value]="flowContentService.processorTypes[step.processor_type].map[type].__id"
        *ngFor="let type of flowContentService.processorTypes[step.processor_type].map.list; trackBy: trackByFn">
          {{ flowContentService.processorTypes[step.processor_type].map[type].name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <shared-parameter-inputs
    *ngIf="parameterOptions?.list?.length && flowContentService?.processorTypes?.[step?.processor_type]?.map[step?.processor_map?.type]?.parameterInput"
      [existingInputs]="{options: step.processor_map.input}"
      [parameters]="parameterOptions"
      (inputParameter)="inputOptionsRecevied($event)"
      [fieldListingMap]="{list: ['sourceFields', 'systemFields'], sourceFields: sourceFields, systemFields: flowContentService.systemFields}"
      [isOnlyOptions]="true">
    </shared-parameter-inputs>
  </div>
  <div>
    <div *ngFor="let parameter of parameterOptions?.list; let i = index; trackBy: trackByFn">

        <div *ngIf="parameterOptions[parameter].widget == 'select'" style="width: 100%;" fxLayout="row">
          <mt-select fxFlex="90"
            [placeholder]="parameterOptions[parameter].name"
            [list]="parameterOptions?.[parameter]?.options"
            [selectedValue]="step.processor_map?.input?.[parameter]"
            (inputReceived)="inputReceived($event, parameterOptions[parameter].__id)"
          ></mt-select>
        </div>
        <div *ngIf="parameterOptions[parameter].widget == 'input'" style="width: 100%;" fxLayout="row">
          <mt-input fxFlex="90"
            [placeholder]="parameterOptions[parameter].name"
            [selectedValue]="step.processor_map?.input?.[parameter]"
            (inputReceived)="inputReceived($event, parameterOptions[parameter].__id)"
          ></mt-input>
          <app-field-picker fxFlex="10"
              *ngIf="fieldMap?.list?.length"
              [inputFieldMap]="fieldMap"
              (selectedFields)="insertCursor($event, parameterOptions[parameter].__id)"
          ></app-field-picker>
        </div>
    </div>
  </div>

</div>
