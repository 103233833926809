import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BloomContentService {
  currentTheme: any;
  selectedPage: any;

  constructor(
  ) { }


  createSearchMeta(){
    return {
      searchAttributes: [
        {
          "dataType": "string",
          "filterable": true,
          "isColumnSelected": true,
          "name": "Search by name",
          "sortable": true,
          "widgetType": "input",
          "__id": "name"
        }
      ],
      pageSize: 10,
      hideTitle: true,
      searchButtonText: "Search",
      alignment: "flex-start",
      baseId: "",
      boxObjectId: "bloom",
      name: "searchpanel_1",
      outputListPanelId: "blooms-templates-listing",
      type: "searchpanel",
    }
  }

  getTemplatesPanel(){
    let result =
      {
        "type": "listpanel",
        "alignment": "flex-start",
        "layoutCount": "1",
        "widgets": [],
        "id": "blooms-templates-listing",
        "name": "listPanel_1",
        boxId: 'starch',
        boxObjectId: 'bloom',
        connectionId: "",
        baseMap: {
          "box_id": "mongodb",
          "base_id": ""
        } ,
        "listAttributes": [
          {
            "__id": "name",
            "name": "name",
            "dataType": "string",
            "filterable": true,
            "sortable": true,
            "fieldType": "attribute",
            "widgetType": "label",
            "isColumnSelected": true
          },
          {
            "__id": "templateData",
            "name": "templateData",
            "dataType": "object",
            "filterable": false,
            "sortable": false,
            "fieldType": "attribute",
            "widgetType": "label",
            "isColumnSelected": true,
            "isDrillDown": true,
            "nestedProperties": [
              {
                "path": "name",
                "widgetType": "label",
                "fieldType": "attribute"
              },
              {
                "path": "bannerImage",
                "widgetType": "image",
                "fieldType": "attribute"
              },
              {
                "path": "tagline",
                "widgetType": "label",
                "fieldType": "attribute"
              },
              {
                "path": "description",
                "widgetType": "label",
                "fieldType": "attribute"
              }
            ]
          },
          {
            "dataType": "string",
            "fieldType": "attribute",
            "filterable": true,
            "isColumnSelected": false,
            "name": "Page Structure Id",
            "sortable": true,
            "widgetType": "label",
            "__id": "page_structure_id"
          },
          {
            "dataType": "string",
            "fieldType": "attribute",
            "filterable": true,
            "imageDimension": 5,
            "isColumnSelected": false,
            "name": "logoUrl",
            "sortable": true,
            "widgetType": "image",
            "__id": "logoUrl"
          }
        ],
        "aditionalAttributesAllowed": false,
        "pageSize": 20,
        "loadInitialData": true,
        "dataSource": "app",
        "paginationEnabled": true,
        "paginationType": "pagebypage",
        "defaultListSize": 10,
        "noDataMessage": "There is no data to be shown here",
        "navigationSettings": {
          "enabled": true,
          "navFilterAttributes": [],
          "pageCode": "",
          "tooltip": "",
          "type": ""
        },
        "boxConfigToken": environment.DB_BOX_TOKEN,
        "attributeOptions": [],
        "getFnOptions": [],
        "listPanelTitle": "",
        hideTitle: true,
        "viewTypes": {
          "views": [
            "table",
            "card",
            "board"
          ],
          "defaultView": "card",
          "boardStatusColumn": "",
          "userCanChoose": false,
          "table": {
            "name": "table",
            "displayName": "Table",
            "userCanChoose": true,
            "icon": "list"
          },
          "card": {
            "name": "card",
            "displayName": "Card",
            "userCanChoose": true,
            "icon": "grid_on"
          },
          "board": {
            "name": "board",
            "displayName": "Board",
            "userCanChoose": false,
            "icon": "view_kanban"
          }
        },
        "primaryAttribute": {
          "__id": "_id",
          "name": "_id",
          "dataType": "string",
          "filterable": true,
          "sortable": true,
          "primary": true,
          "writable": false,
          "fieldType": "attribute",
          "widgetType": "label",
          "isColumnSelected": false
        },
        "filter": {
          "filterEnabled": true,
          "filterItems": [
            {
              "attribute": "template",
              "operator": "=",
              "value": "true",
              "dataType": "boolean",
              "filterType": "static_filter"
            },
            {
              "attribute": "templateStatus",
              "operator": "=",
              "value": "published",
              "dataType": "string",
              "filterType": "static_filter"
            }
          ]
        },
        "sort": {
          "sortEnabled": false,
          "sortAttributes": []
        },
        "listWidgetSet": {
          "name": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824762e067",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "value",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "value": {
                "displayName": "Value",
                "value": "",
                "type": 'text'
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 20
              },
              "bold": {
                "displayName": "Bold",
                "value": true
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          },
          "templateData.name": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824762e067",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "labelText",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor",
                "value"
              ],
              "value": {
                "displayName": "Value",
                "value": "",
                "type": 'text'
              },
              "labelText": {
                "displayName": "Label Text",
                "value": "Default label text. Double-click to change it.",
                "resetValue": "",
                "type": "longtext"
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          },
          "templateData.bannerImage": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824d6a2f7b",
            "name": "image_0",
            "type": "image",
            "gridX": 6,
            "gridY": 0,
            "minGridX": 3,
            "minGridY": 3,
            "initialPixelHeight": 0,
            "config": {
              "props": [
                "value",
                "tooltip",
                "width",
                "backgroundColor"
              ],
              "value": {
                "displayName": "Source URL",
                "value": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                "resetValue": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                "type": "image-picker"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "width": {
                "displayName": "Width",
                "value": "100%",
                "type": "select"
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            }
          },
          "templateData.tagline": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824e00b7ec",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "value",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "value": {
                "displayName": "Value",
                "value": "",
                "type": 'text'
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          },
          "templateData.description": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "17216347618240a8681b",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "value",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "value": {
                "displayName": "Value",
                "value": "",
                "type": 'text'
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          }
        },
        "getFn": {
          "__id": "get",
          "name": "Get",
          "dataFlow": "pull",
          "crudType": "R",
          "functionName": "get",
          "options": {
            "filter": true,
            "sort": true,
            "maxPageSize": 1000
          },
          "input": {
            "list": [
              "query",
              "options"
            ],
            "options": {
              "name": "options",
              "dataType": "object",
              "list": [
                "relationObject"
              ],
              "relationObject": {
                "dataType": "string",
                "name": "Relation Object",
                "__id": "relationObject",
                "hidden": true,
                "value": ""
              }
            },
            "query": {
              "name": "query",
              "dataType": "query",
              "required": true
            }
          }
        }
      }

    return result;
  }
}