import { Component, Input, OnInit, Injector, EventEmitter, ViewChild, SimpleChanges, OnChanges, AfterViewInit, ElementRef, DoCheck } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-textinput',
    templateUrl: './textinput.component.html',
    styleUrls: ['./textinput.component.css'],
    standalone: false
})
export class TextinputComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit, DoCheck {

  contextMenuActions: any = {};

  hoveredNow: boolean = false;


  // @ViewChild('menuTrigger') inputMenuTrigger: MatMenuTrigger;
  @ViewChild('input') input: ElementRef;
  destroy: any;
  oldValue: any;
  fc = new FormControl({ value: '', disabled: true })

  validationSubscription: any
  customErrorMessage: any;
  widgetValidationObj: any;

  constructor(
    // public expressionUtility: ExpressionUtility,
    public metaService: MetaService,
    public validationService: ValidationService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    public wmtService: WidgetMetaTransformationService,
    public injector: Injector
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService, injector)
  }

  private getExpressionUtility(): ExpressionUtility {
    return this.injector.get(ExpressionUtility);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if (contextActions && this.widgetMeta.id == contextActions?.widgetId) {
        this.action(contextActions);
      }
    });

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      // console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })
    super.generateStyles()
    if (this.isDisabled) {
      this.fc.disable();  // Disable the form control
    } else {
        this.fc.enable();   // Enable the form control
    }

    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      this.decompressMetaAndAssign(widgetMeta)
      this.initForm()
      setTimeout(() => {
        this.userInputDetected()
      }, 200);
    })
  }

  ngOnDestroy(): void {
    this.destroy?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.widgetMeta?.config?.focus?.value) {
      this.input?.nativeElement?.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    if (changes.widgetMeta && changes.widgetMeta.currentValue) {
      // this.widgetMeta = changes.widgetMeta.currentValue;
      if (this.widgetMeta.config?.expressionConfig?.id) {
        let value = this.getExpressionUtility().resolveExpression(this.widgetMeta.config?.expressionConfig);
        this.widgetMeta.config.value.value = value;
      }
      this.initForm()
      // this.emitUserInput();
      this.userInputDetected();
      this.setContextActions();
    }

    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      // if (this.inputMenuTrigger && this.inputMenuTrigger.menuOpen) {
      //   this.inputMenuTrigger.closeMenu();
      // }
    }
    if (changes.contextActions?.currentValue) {
      this.action(changes.contextActions.currentValue);
    }
  }

  ngDoCheck(): void {
    // if (!this.widgetMeta.config) this.decompressAndAssignMeta(this.widgetMeta)
    // if(this.oldValue != this.widgetMeta?.config?.value?.value){
    //   this.oldValue = this.widgetMeta?.config?.value?.value || ""
    //   // console.log("SELECT doCheck", this.oldValue)
    //   this.initForm()
    //   // console.log("calling from doCheck", this.oldValue)
    //   setTimeout(() => {
    //     this.userInputDetected()
    //   }, 200);
    // }
  }

  initForm(){
    // if (!this.widgetMeta.config) this.decompressAndAssignMeta(this.widgetMeta)
    // console.log("init form : widget meta", this.widgetMeta)
    if(this.widgetMeta.config?.required?.value){
      this.fc.addValidators(Validators.required);
    }

    if (!this.widgetMeta.config?.value?.value?.length) {
      this.widgetMeta.config.value.value = this.widgetMeta.config?.defaultValue?.value;
    }

    this.fc.patchValue(this.widgetMeta.config?.value?.value || '')
    // this.fc.markAsTouched()
    this.fc.updateValueAndValidity()
  }

  setContextActions() {
    this.contextMenuActions = {
      actions: [
        "appearance",
        "nofloat",
        "edit",
      ],
      appearance: {
        value: this.widgetMeta?.config?.appearance?.value,
        // availableTypes: this.widgetMeta?.config.appearance.availableTypes,
        type: this.widgetMeta?.config?.appearance?.type
      },
      nofloat: {
        value: this.widgetMeta?.config.nofloat?.value
      }
    };
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions);
  }

  action(event: any) {
    if (event.actionType == "delete") {
      this.onDelete();
    }
    if(event.actionType == "updateStyles"){
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        console.log("localMeta changed", this.widgetMeta)
        if (!event.noEmit) this.newWidgetMeta.emit(this.widgetMeta)
        // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        super.generateStyles();
        // console.log("styles re-generated", this.styles)
      }
    }
    if (event.actionType == "customPropertyUpdate" && event.propertyName === "appearance") {
      this.updateAppearance(event.data);
      if (!event.noEmit) this.newWidgetMeta.emit(this.widgetMeta)
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }
    this.widgetSelection.emit(this.widgetMeta.id);
  }

  insertTextAtCursor(text: string) {
    if (!this.input || !this.input.nativeElement) {
      console.error("Textarea reference not found!");
      return;
    }

    const textinput: HTMLTextAreaElement = this.input.nativeElement;
    let currentValue = textinput.value;
    let start = textinput.selectionStart;
    let end = textinput.selectionEnd;

    // Insert the text at the cursor position
    let updatedValue = currentValue.substring(0, start) + text + currentValue.substring(end);

    // Update the ngModel value to reflect changes in UI
    this.widgetMeta.config.value.value = updatedValue;

    // Restore cursor position after inserted text
    setTimeout(() => {
      textinput.selectionStart = textinput.selectionEnd = start + text.length;
    }, 0);
  }

  onDelete() {
    this.widgetDeletion.emit(this.widgetMeta.id);
    // this.inputMenuTrigger.closeMenu();
  }

  userInputDetected() {
    this.widgetValidationObj = this.validationService.checkWidgetValidation(this.widgetMeta)
    if(this.widgetValidationObj){
      if (!this.widgetValidationObj?.isValid) {
        this.fc.setErrors({ invalid: true }); // Mark the form control as invalid
        this.fc.setErrors({ valid: false }); // Mark the form control as invalid
        this.customErrorMessage = this.widgetValidationObj?.customErrorMessage ? this.widgetValidationObj.customErrorMessage : "";
      } else {
        this.fc.setErrors(null); // Clear any existing errors, marking it as valid
      }
    }
    setTimeout(() => {
      this.emitUserInput();
    }, 200);
  }

  emitUserInput() {
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config?.value?.value,
      validity: this.fc.valid
    }
    if(this.widgetValidationObj){
      userInput['widgetValidationObj'] = this.widgetValidationObj
    }

    if (this.widgetMeta.hasOwnProperty('propName')) {
      userInput.propName = this.widgetMeta.propName;
    }

    // console.log("emitting output", userInput)
    this.userInputReceived.emit(userInput);
  }

  getTextInputAppearance(): string {
    if (this.widgetMeta?.config?.appearance?.value) {
      if (this.contextMenuActions.nofloat.value) {
        return ''; // Empty string to disable floating label
      } else {
        return this.widgetMeta?.config?.appearance?.value;
      }
    }
    return '';
  }

  updateAppearance(appearanceValue: any) {
    console.log("appearance value", JSON.parse(JSON.stringify(appearanceValue)))
    this.widgetMeta.config.appearance.value = appearanceValue.config?.appearance?.value || this.widgetMeta.config.appearance.value;
    // this.setContextActions();
  }

  getFormFieldClass(): string {
    if (this.widgetMeta.config?.appearance?.value) {
      switch (this.widgetMeta.config.appearance.value) {
        case 'default':
          return 'default-appearance-class';
        case 'rounded':
          return 'rounded-appearance-class';
        case 'outlined':
          return 'outlined-appearance-class';
      }
    }
    return '';
  }
}
