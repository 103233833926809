<div class="wrapper" [class.disabled] = "blur">

  <div class="templateDataItem">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div style="font-size: 1.2rem; color: #444">Banner Image</div>
      <div><button *ngIf="form"
        class="sidebuttons"
        mat-icon-button
        matTooltip="Generate the latest banner"
        (click)="refreshBanner()"
        ><mat-icon>refresh</mat-icon>
      </button>
    </div>
    </div>

    <div style="height: .5rem;"></div>
    <dropzone #dragnDropfileComponent
      [maxFileSize]="1024000" style="width: 100%;" [pathId]="storePath"
      (mousedown)="$event.stopPropagation()"
      [width]="'95%'"
      (fileChange)="bannerImageInput($event)"
      [miniPreview]="true"
      [displayImage]="banner"
      [dimensions]="{ width: 900, height: 600 }"
      [setImage]="templateData.bannerImage"
    ></dropzone>
  </div>

  <div style="height: 1rem;"></div>
  <div class="templateDataItem">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div style="font-size: 1.2rem; color: #000000">Screenshots</div>
      <div><button *ngIf="form"
          class="sidebuttons"
          mat-icon-button
          matTooltip="Generate the latest screenshots"
          (click)="refreshScreenshots()"
          ><mat-icon>refresh</mat-icon>
        </button>
      </div>
      <spinner *ngIf="formService.capturedScreenshots?.inProgress" [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
    </div>

    <div style="height: .5rem;"></div>
    <dropzone
      style="width: 100%;"
      [maxFileSize]="1024000"
      [pathId]="storePath"
      [width]="'95%'"
      [miniPreview]="true"
      [dimensions]="{ width: 1280, height: 720 }"
      [multiple]="true"
      [displayImage]="screenshots"
      [setImage]="templateData.screenshots"
      (mousedown)="$event.stopPropagation()"
      (fileChange)="screenshotsImageInput($event)"
      (urlListChanged)="templateData.screenshots = $event"
    ></dropzone>
  </div>

  <div style="height: 1.5rem;"></div>
  <mat-form-field class="templateDataItem">
    <mat-label>Tagline</mat-label>
    <input matInput placeholder="Tagline" required [(ngModel)]="templateData.tagline" (input)="templateDataCollected()">
  </mat-form-field>

  <mat-form-field class="templateDataItem">
    <mat-label>Description</mat-label>
    <textarea matInput placeholder="Description" rows="3" required [(ngModel)]="templateData.description" (input)="templateDataCollected()"></textarea>
  </mat-form-field>

  <div class="templateDataItem keyFeatures">
    <div style="font-size: 1.2rem; color: #444">Key Features</div>
    <div style="height: .5rem;"></div>

    <app-widget-wrapper
      *ngIf="keyFeaturesWidgetMeta"
      [widgetMeta]="keyFeaturesWidgetMeta"
      [builderMode]="false"
      (userInputReceived)="keyFeaturesReceived($event)"
    ></app-widget-wrapper>

    <!-- <div class="addedFeatures" *ngIf="templateData.keyFeatures.length">
      <div class="addedFeatureItem" *ngFor="let f of templateData.keyFeatures; let i = index; trackBy: trackByFn" fxLayout="row" fxLayoutAlign="start center">
        <div class="addedFeatureItemSub" fxFlex="10" fxLayoutAlign="center center">{{ i + 1 + '.' }}</div>
        <span class="addedFeatureItemSub" fxFlex="70" fxLayoutAlign="start center" style="font-style: italic;">{{ f }}</span>
        <button class="addedFeatureItemSub" mat-icon-button fxFlex="10"  fxLayoutAlign="center center" (click)="removeFeature(i)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>

    <div class="newFeature" *ngIf="newFeatureActive" fxLayoutAlign="start center">
      <mat-form-field appearance="standard" fxFlex="85">
        <input matInput placeholder="New feature..." (keyup.enter)="saveNewFeature()" [(ngModel)]="newFeature">
      </mat-form-field>
      <div fxFlex="10">
        <button mat-icon-button (click)="saveNewFeature()"><mat-icon>done</mat-icon></button>
      </div>
    </div>

    <div class="addFeatureButton" *ngIf="!newFeatureActive">
      <button mat-stroked-button matTooltip="Add new feature" (click)="newFeatureActive = true"><mat-icon>add</mat-icon>New feature</button>
    </div> -->
  </div>

  <div style="height: 1.5rem"></div>
  <div class="templateDataItem">
    <div style="font-size: 1.2rem; color: #444">Categories</div>
    <mat-form-field style="width: 100%;">
      <mat-chip-grid #chipList multiple="true">
        <mat-chip-row
          *ngFor="let category of categories; let i = index; trackBy: trackByFn"
          [removable]="true"
          (removed)="remove(category, i)"
        >
          {{category}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          matInput
          [placeholder]="'Type and press enter to add...'"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)"
        >
      </mat-chip-grid>
    </mat-form-field>
  </div>
</div>

<!-- <div fxLayoutAlign="end center">
  <button matflat-button (click)="templateDataCollected('cancel')">Cancel</button>
  <button mat-flat-button (click)="templateDataCollected('save')">Save</button>
</div> -->
