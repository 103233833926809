import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';


@Component({
    selector: 'app-security-config',
    templateUrl: './security-config.component.html',
    styleUrls: ['./security-config.component.scss'],
    standalone: false
})
export class SecurityConfigComponent implements OnInit {

  filteredCtrl = new UntypedFormControl('');

  constructor(
    public adminService: AdminService,
    public connectionService: ConnectionService,
    public metaService: MetaService
  ) { }

  @Input() meta;
  @Input() isNoEffect;
  @Input() effects;

  privilegeOptions:any;
  privileges: any = []

  conditions= [
    {
      name: "has",
      code: "contain"
    },
    {
      name: "doesn't have",
      code: "not-contain"
    },
  ]

  async ngOnInit() {
    if(!this.meta.securityConfig) this.meta.securityConfig = {};
    if(!this.meta.securityConfig.securities) this.meta.securityConfig.securities = [];

    if(!this.metaService.bloomMeta) return;
    let defaultPayload = {filter: `resource_type=default|string,resource_id=default|string`};
    let bloomPayload = {filter: `resource_type=bloom|string,resource_id=${this.metaService.bloomMeta.code}|string`};
    var defaultRes:any = await this.adminService.listPrivilege(1, 500, this.connectionService.preAuthenticatedToken, defaultPayload)
    var bloomRes:any = await this.adminService.listPrivilege(1, 500, this.connectionService.preAuthenticatedToken, bloomPayload)
    let defaultPrivliages = defaultRes?.data || [];
    let bloomPrivliages = bloomRes?.data || [];
    this.privileges = defaultPrivliages.concat(bloomPrivliages);
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  addNewSecurity() {
    if (!this.meta.securityConfig.securities) this.meta.securityConfig.securities = [];
    let securityObj = {
      "effect": "show",
      "condition": "contain",
      "privileges": []
    }
    this.meta.securityConfig.securities.push(securityObj);
  }

  removeSecurity(security, i) {
    security.splice(i, 1);
  }

  setFilterOptions(){
    this.privilegeOptions = this.filteredCtrl.valueChanges.pipe(startWith(""),
      map((value) => this._filter(value, this.privileges))
    );
  }

  removePrivilege(privilege: string, array:any): void {
    const index = array.indexOf(privilege);
    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  selectChange(event, array:any){
    var code = event.code;
    if(!array.includes(code)){
      array.push(event.code);
    }
  }

  displayFunction(subject) {
    if(!subject) return undefined;
    return subject.name;
  }

  private _filter(value: string, array?:any): string[] {
    console.log("value", value)
    value = value ? value : ""
    if(typeof value != "string") return;
    const filterValue = value.toLowerCase();
    return array.filter(option => option.code.toLowerCase().includes(filterValue));
  }

}
