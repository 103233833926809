import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-connection-popup',
    templateUrl: './connection-popup.component.html',
    styleUrls: ['./connection-popup.component.scss'],
    standalone: false
})
export class ConnectionPopupComponent implements OnInit {

  config:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public dialogRef: any,
    public dialog: MatDialogRef<ConnectionPopupComponent>, 
  ) {
    this.config = this.dialogRef;
   }

  ngOnInit(): void {

  }

  connectionChange(e){
    console.log("connectionChange", e)
    if(e.isBack){
      this.dialog.close(null);
    } else if(e.isConnectionCreated){
      this.dialog.close(e?.connection);
    }
  }

}
