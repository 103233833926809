import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, timer, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';

@Injectable()
export class NetworkTrackingInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private isServer: boolean;
  private timerSubscription: Subscription | null = null;
  private isPrerendered = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isServer = isPlatformServer(this.platformId);

    // Only start the timer logic if we're in the browser
    if (!this.isServer) {
      // Initial wait of 10 seconds before starting the check cycle
      this.timerSubscription = timer(10000).subscribe(() => {
        this.checkAndUpdatePrerenderedStatus();
      });
    }
  }

  /**
   * Check if there are active network calls and update isPrerendered status accordingly
   * If active calls exist, wait 10 seconds and try again
   * If no active calls, wait 5 seconds to confirm and then set isPrerendered to false
   */
  private checkAndUpdatePrerenderedStatus(): void {
    if (this.activeRequests > 0) {
      // Active requests exist, wait 10 seconds and check again
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.timerSubscription = timer(10000).subscribe(() => {
        this.checkAndUpdatePrerenderedStatus();
      });
    } else {
      // No active requests, wait 5 seconds to confirm
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.timerSubscription = timer(5000).subscribe(() => {
        if (this.activeRequests === 0) {
          // Still no active requests after 5 seconds, set isPrerendered to false
          this.isPrerendered = false;
          console.log("PRERENDERDFALSE")
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
          }
        } else {
          // Active requests appeared during the 5 second wait, restart the check cycle
          this.checkAndUpdatePrerenderedStatus();
        }
      });
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip tracking if we're on the server
    if (this.isServer) {
      return next.handle(req);
    }

    this.activeRequests++;

    return next.handle(req).pipe(
      finalize(() => {
        this.activeRequests--;
      })
    );
  }

  /**
   * Returns whether the application is still in prerendered state
   */
  public getIsPrerendered(): boolean {
    return this.isPrerendered;
  }

  /**
   * Cleanup method to prevent memory leaks
   */
  public ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }
}