<div>
    <div *ngIf="actionConfig.action">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="primary" > widgets </mat-icon>
          <mat-form-field style="width: 100%;">
            <mat-select (selectionChange)="onWidgetSelected()" [(ngModel)] = "actionConfig.actionMap.widget">
              <mat-option [value]="option.__id" *ngFor="let option of widgets; trackBy: trackByFn">
                {{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="actionConfig.actionMap.widget">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="primary" > widgets </mat-icon>
          <mat-form-field style="width: 100%;">
            <mat-select (selectionChange)="onWidgetActionSelected()" [(ngModel)] = "actionConfig.actionMap.widgetActionType">
              <mat-option [value]="option.id" *ngFor="let option of widgetActions; trackBy: trackByFn">
                {{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="actionConfig.actionMap && actionConfig.actionMap.widgetActionType === 'set_value'">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary" > title </mat-icon>
            <mat-form-field style="width: 100%;">
                <mat-label>Value</mat-label>
                <input matInput type="text"  [(ngModel)] = "actionConfig.actionMap.value"
                  placeholder="Provide Value">
            </mat-form-field>
            <app-field-picker *ngIf="systemFields"
                               [inputFieldMap]="{
                                  list:['systemFields'],
                                  systemFields: {
                                    id:'systemFields', 
                                    displayName: 'SYSTEM FIELDS', 
                                    fields: systemFields, 
                                    options:{}
                                  }}"
                              (selectedFields)="insertFilterCursor($event)"
                           ></app-field-picker>
        </div>
      </div>

</div>

<!-- <div *ngIf="actionConfig.actionMap && actionConfig.actionMap.widgetActionType == 'effects'">
  <div >
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="primary" > gradient </mat-icon>
      <mat-form-field style="width: 58vw;">
        <mat-select (selectionChange)="onEffectChanged()" [(ngModel)] = "actionConfig.actionMap.effect_type">
          <mat-option [value]="option.id" *ngFor="let option of events; trackBy: trackByFn">
            {{ option.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div> -->



<div *ngIf="actionConfig.actionMap && actionConfig.actionMap.widgetActionType === 'effects'" fxLayout="row" style="margin-bottom: 0px" class="mat-h2">Style Effects</div> 
<mat-card appearance="outlined" *ngIf="actionConfig.actionMap && actionConfig.actionMap.widgetActionType === 'effects'">
  <mat-card-content>
    <div fxLayout="row" *ngFor="let style of actionConfig.actionMap.effectStyles;let i = index; trackBy: trackByFn"  fxLayoutAlign="start center"> 
      <div *ngIf="!style.attribute || (style.attribute && availableStyleMap[style.attribute].inputType)" fxLayoutAlign="start center" style="width: 100%;">
        <div fxLayout="column" [fxFlex]="(style.attribute && availableStyleMap[style.attribute].inputType) ? 45 : 90">
            <mat-form-field style="width: 100%;" class="example-full-width" >
              <mat-label>Attribute</mat-label> 
              <mat-select [(ngModel)] = 'style.attribute'  (selectionChange)="onAttributeSelected(style)"
              name="" >
                <ng-container *ngFor="let att of availableStyleMap.styles; trackBy: trackByFn">
                  <mat-option *ngIf="availableStyleMap[att]?.inputType" [value]="att">
                      <span>{{availableStyleMap[att].name}}</span>
                  </mat-option>
              </ng-container>
              </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" *ngIf="style.attribute && availableStyleMap[style.attribute].inputType" fxFlex="45">
          <!-- custom string -->
          <mat-form-field class="example-full-width" *ngIf="availableStyleMap[style.attribute].inputType === 'string'">
            <input  matInput placeholder="Value" [(ngModel)] = 'style.value' (change)="changeDetected()">
          </mat-form-field>

          <!-- color palette -->
          <mat-form-field *ngIf="availableStyleMap[style.attribute].inputType === 'color-palette'">
            <input #colorInput class="colorInput" matInput type="color" name="color" [(ngModel)] = 'style.value'(change)="changeDetected()">
          </mat-form-field>

        </div>
        <div fxLayout="column" fxFlex="5" >
          <mat-icon matTooltip="remove" color="warn" (click)="removeAttribute(i)" style="cursor: pointer"> delete </mat-icon>
        </div>
      </div>
    </div>
    <div>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple>
        <ng-container *ngFor="let att of availableStyleMap.styles; trackBy: trackByFn">
          <mat-button-toggle 
            *ngIf="availableStyleMap[att]?.icon"
            [value]="att"
            [checked]="isFontStyleChecked(att)"
            (change)="addFontStyle($event)">
            <mat-icon>{{availableStyleMap[att].icon}}</mat-icon>
          </mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </div>
    <div *ngIf="actionConfig?.actionMap?.effectStyles && actionConfig.actionMap.effectStyles.length !== availableStyleMap.styles.length" fxLayout="row" fxLayoutAlign="start center"> 
        <button style="cursor: pointer;" (click)="addNewAttribute()"  color="primary" mat-stroked-button matTooltip="Add new attribute">
            <mat-icon >
                add_circle_outline
              </mat-icon>
              Add style attribute
        </button>
    </div>
  </mat-card-content>
</mat-card>
