import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-sort-config',
    templateUrl: './sort-config.component.html',
    styleUrls: ['./sort-config.component.scss'],
    standalone: false
})
export class SortConfigComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @Input() sort;
  @Input() boxObjectAttributes;
  @Input() enableSecurity;
  @Input() hideToggle: boolean;
  @Input() supportApplyButton: boolean;
  @Output() selectedSort = new EventEmitter<any>();
  @Output() cancelSort = new EventEmitter<any>();

  newSortOpened: boolean = false;
  sortSettingsError: boolean = false
  attributesForSort: any;
  unselectedListAttributes: any = [];
  maskOperator: boolean = false;

  newSortAttribute: any = {
    attribute: undefined,
    order: 'ASC'
  }

  constructor() { }

  selectSortAttributeForm = new UntypedFormGroup({});

  ngOnInit(): void {
    console.log("box obj attr", this.boxObjectAttributes)
    // console.log("sort in panel sort config", this.sort)
    // console.log("showToggle", this.hideToggle)
    if(this.sort.sortAttributes && this.sort.sortAttributes.length > 0) {
      for(var i = 0; i < this.sort.sortAttributes.length; i ++){
        this.selectSortAttributeForm.addControl("form" + i, new UntypedFormControl());
        let valueMap = {
          __id: this.sort.sortAttributes[i].attribute,
          dataType: this.sort.sortAttributes[i].dataType || "string"
        }
        this.selectSortAttributeForm.patchValue({[`form${i}`]: valueMap});
      }
    }
    if(this.sort.sortEnabled && !this.sort.sortAttributes.length){
      this.addSortItem()
    }

  }

  isAttributeDisabled(attribute: any): boolean {
    if(attribute?.sortable && attribute.sortable === true){
      return false;
    } else {
      return true;
    }
  }
  
  hasDisabledAttributes(): boolean {
    return this.boxObjectAttributes?.some(attr => this.isAttributeDisabled(attr));
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  sortToggleChanged(event){
    console.log("sort toggle changed", event.checked)
    this.sort.sortEnabled = event.checked
    if(!this.sort.sortAttributes || this.sort.sortAttributes.length == 0){
      this.addSortItem()
    }
    if(this.sort.sortEnabled) this.newSortOpened = true
    else this.newSortOpened = false

    this.emitSortOnInput()
  }

  sortOrderChanged(event, sort, i){
    console.log("sort order changed", event,"\n Sort",sort)
    sort.order = event
    this.sort.sortAttributes[i] = sort
    console.log("sort", sort)
    this.emitSortOnInput()
    this.menuTrigger.closeMenu();
  }

  private _sortAttributesFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let sortables: any[] = this.boxObjectAttributes.filter((attr: any) => attr)
    return sortables.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  displayFn(attr) {
    return attr && attr.name ? attr.name : attr?.__id ? attr.__id : '';
  }

  addSortItem(){
    // console.log("addSortItem hit", this.sort)
    this.sort.sortAttributes.push({
      attribute: '',
      order: 'ASC'
    })
    console.log("sort attr added", JSON.parse(JSON.stringify(this.sort)))
    this.selectSortAttributeForm.addControl("form" + (this.sort.sortAttributes.length - 1), new UntypedFormControl())
    this.emitSortOnInput()
  }

  removeSort(i: number){
    this.sort.sortAttributes.splice(i, 1)
    console.log("this.sort", this.sort)
    this.emitSortOnInput()
  }

  setSortList(i){
    this.attributesForSort = this.selectSortAttributeForm.get(`form${i}`).valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._sortAttributesFilter(value))
    );
  }

  sortAttrSelected(event: any, sort, i) {
    console.log("new sort event:", event, "sort", sort, "index", i)
    sort.attribute = event.option.value.__id;
    sort.dataType = event.option.value.dataType;
    this.emitSortOnInput();

    console.log("this.sort", this.sort)
  }

  emitSortOnInput(){
    if (this.supportApplyButton) return
    this.selectedSort.emit(this.sort)
  }

  applySort(){
    this.selectedSort.emit(this.sort)
  }

  cancelSorts(){
    // this.sort.sortAttributes = []
    // this.sort.sortAttributes.push({
    //   attribute: '',
    //   order: 'ASC'
    // })
    this.cancelSort.emit(true)
  }

}
