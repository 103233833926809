
<mat-accordion *ngIf="widgetMeta">

  <!-- display -->
  <mat-expansion-panel expanded (opened)="displayPanelOpened()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Display
      </mat-panel-title>
      <mat-panel-description>
        Display and general
      </mat-panel-description>
    </mat-expansion-panel-header>

    <app-widget-display-config [widgetMeta]="widgetMeta" [panelId]="panelId"
      (settingsChanged)="widgetMetaChange($event)"></app-widget-display-config>

    <!-- <mat-action-row>
    <button mat-raised-button>Save</button>
  </mat-action-row> -->
  </mat-expansion-panel>



  <!-- data -->
  <mat-expansion-panel (opened)="dataPanelOpened(true)" (closed)="dataPanelOpened(false); showSave = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Data
      </mat-panel-title>
      <mat-panel-description>
        Data source
      </mat-panel-description>
    </mat-expansion-panel-header>

    <app-widget-data-config *ngIf="isOpenDataPanel" [widgetMeta]="widgetMeta" [panelId]="panelId" (settingsChanged)="widgetMetaChange($event)">
    </app-widget-data-config>
  </mat-expansion-panel>



  <mat-expansion-panel (opened)="actionPanelOpened()" (closed)="showSave = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Actions
      </mat-panel-title>
      <mat-panel-description>
        Widget actions
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-widget-action-config [widgetMeta]="widgetMeta" [panelId]="panelId" [isSmallScreen]="true" (settingsChanged)="widgetMetaChange($event)">
    </app-widget-action-config>
  </mat-expansion-panel>

  <mat-expansion-panel (opened)="securityPanelOpened()" (closed)="showSave = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Security
      </mat-panel-title>
      <mat-panel-description>
        Widget security and access
      </mat-panel-description>
    </mat-expansion-panel-header>

    <app-security-config [meta]="widgetMeta" [effects]="widgetSecurityEffects">
    </app-security-config>
  </mat-expansion-panel>
</mat-accordion>

<!-- <div *ngIf="showSave" fxLayoutAlign="center center" style="margin: 1rem 0;">
  <button mat-raised-button color="primary" style="z-index: 3;" (click)="saveWidgetSpecifics()">
    Save
  </button>
</div> -->

