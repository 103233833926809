import { BaseValidator } from "./BaseValidator";

export class ContainsValidator extends BaseValidator{
    id = "contains"
    icon =  ""
    name = "Contains"
    config = {
        inputParams: {
            params: ['contains', 'customErrorMessage'],
            customErrorMessage: {
                value : "Enter a valid Input",
                displayName: "Custom Error Message",
                type: 'string'
            },
            contains: {
                value : "",
                displayName: "Contains",
                type: 'string'
            }
        }
    }

    public validate(val:string, inputParams:any){
        const contains = inputParams.contains.value;
        return val.toLowerCase().includes(contains.toLowerCase());
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}