import { BaseValidator } from "./BaseValidator"

export class EmailValidator extends BaseValidator{
    id = "email"
    icon =  ""
    name = "Email"
    config = {
        inputParams: {
            params: ['customErrorMessage'],
            customErrorMessage: {
                value : "Please Enter a Valid Email",
                displayName: "Custom Error Message",
                type: 'string'
            }
        }
    }

    public validate(val:string){
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(val);
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}