<div class="header" fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title>Details panel configuration</div>
  <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <div class="subheading">Choose Data Source</div>
  <div style="height: 1rem;"></div>

  <app-app-selection
    [existingBox]="selectedBoxId"
    [config]="{isSupportStarch: true}"
    (selectedBox)="appSelected($event)"
  >
  </app-app-selection>

  <app-connection-selection
    *ngIf="isAppSelected && selectedBoxId !== 'starch'"
    [existingCon]="selectedConnectionId"
    [config]="{ box_id: selectedBoxId, focus: true }"
    (selectedConnection)="boxSelected($event)"
  ></app-connection-selection>

  <base-selection *ngIf="isAppSelected && selectedBoxId === 'starch'" [existingBase]="panelMeta.baseId"
   (selectedBase)="baseSelected($event)"></base-selection>

  <app-select-box-object
    *ngIf="canGetBoxObjects || !firstHit"
    [boxId]="getSelectedBoxId()"
    [connectionId]="getSelectedConnectionId(selectedBoxId)"
    [boxConfigToken]="boxConfigToken"
    [oldValue]="panelMeta.boxObjectId"
    [disableBoxObjectInput]="disableBoxObjectInput"
    (boxObjectInput)="boxObjectSelected($event)"
    (boxObjectSelectionError)="boxObjectSelectionError($event)"
  >
  </app-select-box-object>
  <small *ngIf="isBoxObjectConfigError" style="color: rgb(63, 45, 45);">{{ boxObjectConfigError.error.message | json }}</small>


  <!-- <div *ngIf="isOptionsToCollect" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem">
    <ng-container *ngFor="let option of attributeOptions; let i = index">
      <mat-form-field appearance="standard">
        <mat-label>{{ option.name }}</mat-label>
        <input matInput (input)="attributeOptionChanged($event, i)" [required]="option.required" [(ngModel)]="option.value">
        <mat-hint style="color: #3f51b5;">Type: {{ option.dataType }}</mat-hint>
      </mat-form-field>
    </ng-container>

    <button mat-stroked-button color="primary" (click)="getAttributes()"><mat-icon>east</mat-icon></button>
  </div> -->

  <div *ngIf="collectAttrOptions">
    <div style="height: 1rem;"></div>
    <div class="subheading">Input params to fetch attributes</div>
    <!-- <div style="height: .5rem;"></div> -->
    <shared-parameter-inputs
      [action]="getAttrFn"
      [existingInputs]="availableInputParams"
      [isBoxObject]="canGetBoxObjects"
      [isOnlyOptions]="true"
      (inputParameter)="attrOptionInputsRecevied($event)"
    ></shared-parameter-inputs>

    <button
      mat-stroked-button
      color="primary"
      (click)="constructGetOptions()"
    ><mat-icon>east</mat-icon>
    </button>
  </div>


  <div *ngIf="collectGetOptions">
    <div style="height: 1rem;"></div>
    <div class="subheading">Input params to get record</div>
    <!-- <div style="height: .5rem;"></div> -->
    <shared-parameter-inputs
      [action]="getFn"
      [existingInputs]="availableInputParams"
      [isOnlyOptions]="true"
      (inputParameter)="getOptionInputsRecevied($event)"
    ></shared-parameter-inputs>

    <button
      mat-stroked-button
      color="primary"
      (click)="getAttributes()"
    ><mat-icon>east</mat-icon>
    </button>
  </div>

  <!----------------------------------- DETAILS SETTING --------------------------------->
  <div class="attribute-error-cover">
    <small *ngIf="isAttributeError" class="attribute-error">{{ attributeError | json }}</small>
  </div>
  <div *ngIf="attributeSpinner || gettingObjFunSpinner" fxLayout fxLayoutAlign="center center">
    <spinner></spinner>
  </div>

  <div class="attributeSelection" *ngIf="isAttributeReady">
    <div class="subheading">Details {{attributeDisplayName | titlecase}}s</div>

    <div class="attributes-table">
      <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">

        <div class="table-header" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="th" fxFlex="3"></span>
          <span class="th" fxFlex="5">#</span>
          <span class="th" fxFlex="40" fxLayout="row" fxLayoutAlign="start center">{{attributeDisplayName | titlecase}}</span>
          <span class="th" fxFlex="15">Enabled</span>
          <span class="th" fxFlex="25">Widget Type</span>
          <span class="th" fxFlex="7"></span>
          <span class="th" fxFlex="5"></span>
        </div>

        <div
          class="example-box smallText"
          style="cursor: default;"
          *ngFor="let attr of detailsAttributes;
          let i = index; trackBy: trackByFn"
          cdkDrag
          (mouseenter)="hoveredIndex = i"
          (mouseleave)="hoveredIndex = -1"
          fxLayout="row wrap" fxLayoutAlign="space-between center"
        >
          <!-- DRAG INDICATOR -->
          <div class="reorder td" fxFlex="3">
            <mat-icon cdkDragHandle style="cursor: move; opacity: 60%;" [style.visibility]="hoveredIndex == i ? 'visible' : 'hidden'">drag_indicator</mat-icon>
          </div>

          <!-- SERIAL NUMBER -->
          <div class="serial-number td" fxFlex="5">{{ i + 1 }}</div>

          <!-- ATTRIBUTE NAME -->
          <div class="attribute-id td" fxFlex="40" fxLayout fxLayoutAlign="start center">{{attr.name}}</div>

          <!-- ENABLED -->
          <div class="allow-hide td" fxFlex="15">
            <mat-slide-toggle [checked]="attr.enabled" (change)="enabledChanged($event.checked, i)" color="primary"></mat-slide-toggle>
          </div>

          <!-- WIDGET TYPE -->
          <div class="fieldType td" fxFlex="25" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="attr.enabled" fxLayout="row" fxLayoutAlign="center center">
              <button
                [disabled]="attr.isDrillDown"
                matTooltip="choose input type"
                mat-button
                fxFlexFill
                class="widgetSelectionButton"
                [matMenuTriggerFor]="menu"
                aria-label="Widget selection menu"
              >
                <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                  <mat-icon style="opacity: 60%;" *ngIf="widgetTypeMap[attr.widgetType] && widgetTypeMap[attr.widgetType].icon">{{ widgetTypeMap[attr.widgetType].icon || '' }}</mat-icon>
                  <span class="selectedWidget">{{ widgetTypeMap[attr.widgetType].name }}</span>
                  <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
                </div>
              </button>

              <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
                <ng-container *ngFor="let widgetType of widgetTypeMap.widgets; trackBy: trackByFn">
                  <button mat-menu-item (click)="widgetTypeSelected(i, widgetType)">
                    <mat-icon>{{ widgetTypeMap[widgetType].icon }}</mat-icon>
                    <span>{{ widgetTypeMap[widgetType].name }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>

          <!-- MENU ICON -->
          <div fxFlex="7" class="td">
            <button mat-icon-button #attributeMenu="matMenuTrigger" [matMenuTriggerFor]="menu2">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu2="matMenu">
              <button mat-menu-item (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
            </mat-menu>
          </div>

          <div fxFlex="5"></div>


          <mat-card appearance="outlined" *ngIf="attr.isDrillDown && attr.enabled" fxFlex=100 fxLayout="column" fxLayoutAlign="start start">
            <div fxLayoutAlign="end center" style="width: 100%;">
              <button mat-flat-button (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
            </div>
            <div fxLayoutAlign="flex-start center" style="width: 100%;">
              <div fxFlex="10"></div>
              <div fxFlex="90" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap=".5rem">
                <div style="height: 1rem" fxFlex="100"></div>
                <div fxFlex="100" fxLayoutAlign="start center">
                  <div fxLayoutAlign="start center">
                    <span style="color: #444">Nested property access for</span>
                    <span style="color: black">&nbsp;{{attr.__id}}</span></div>
                  </div>
                  <div style="font-size: .8rem; color: #777; font-style: italic; ">path may be separated by dot for deep nesting</div>
                <div style="height: .5rem" fxFlex="100"></div>

                <ng-container *ngFor="let nestedProperty of attr.nestedProperties; let j = index; trackBy: trackByFn">
                  <div fxFlex="100" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="45" appearance="outline" style="margin-bottom: -1.25em">
                        <input matInput placeholder="Nested value path" [(ngModel)]="nestedProperty.path">
                      </mat-form-field>

                      <div fxFlex="35" fxLayout="row" fxLayoutAlign="center center">
                        <button
                          matTooltip="choose input type"
                          mat-button
                          fxFlexFill
                          class="widgetSelectionButton"
                          [matMenuTriggerFor]="menu3"
                          aria-label="Widget selection menu"
                        >
                          <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                            <mat-icon style="opacity: 60%;" *ngIf="widgetTypeMap[nestedProperty.widgetType] && widgetTypeMap[nestedProperty.widgetType].icon">{{ widgetTypeMap[nestedProperty.widgetType].icon || '' }}</mat-icon>
                            <span class="selectedWidget">{{ widgetTypeMap[nestedProperty.widgetType].name }}</span>
                            <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
                          </div>
                        </button>

                        <mat-menu #menu3="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
                          <ng-container *ngFor="let widgetType of widgetTypeMap.widgets; trackBy: trackByFn">
                            <button mat-menu-item (click)="nestedValueWidgetSelected(widgetType, i, j)">
                              <mat-icon>{{ widgetTypeMap[widgetType].icon }}</mat-icon>
                              <span>{{ widgetTypeMap[widgetType].name }}</span>
                            </button>
                          </ng-container>
                        </mat-menu>
                      </div>

                      <mat-icon fxFlex="10" (click)="deleteNestedProperty(i, j)" style="color: #444">delete</mat-icon>
                  </div>
                </ng-container>

                <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                  <button mat-stroked-button (click)="addNestedproperty(i)">
                    <mat-icon>add</mat-icon>
                    Access another property
                  </button>
                </div>

              </div>
            </div>


          </mat-card>
        </div>
      </div>
    </div>

    <div style="height: 1.5rem;"></div>

    <div>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="filter.filterEnabled">
        <mat-radio-button [value]="false" (click)="getFilterInfo(false)">Automatic Filter</mat-radio-button> &nbsp;
        <mat-radio-button [value]="true" (click)="getFilterInfo(true)">Custom Filter</mat-radio-button>
      </mat-radio-group>
      <div style="height: .5rem"></div>
      <div style="color: rgb(91, 87, 87)">({{filterInfo}})</div>
    </div>

    <div style="height: 1.5rem;"></div>

    <!-- <app-filter-config *ngIf="filter.filterEnabled"
      [filter]="filter"
      [boxObjectAttributes]="boxObjectAttributes"
      [enableSecurity]="false"
      [hideToggle]="true"
      [maskOperator]="true"
      (selectedFilter)="filterChanged($event)"
    >
    </app-filter-config> -->
    <app-filter-component *ngIf="filter.filterEnabled"
      [filter]="filter"
      [boxObjectAttributes]="boxObjectAttributes"
      [enableSecurity]="false"
      [hideToggle]="true"
      [maskOperator]="true"
      (selectedFilter)="filterChanged($event)"
    >
    </app-filter-component>
    <div *ngIf="noFilterData" style="color: red">Please apply one Filter</div>

    <div style="height: 1.5rem;"></div>

    <div fxLayout style="min-width: 100%;">
      <mat-form-field class="full-width" fxFlex="60">
        <mat-label>Details Panel Title</mat-label>
        <input matInput [(ngModel)]="detailsPanelTitle">
      </mat-form-field>
      <div fxFlex="15">
        <mat-checkbox color="warn" [checked]="panelMeta.hideTitle"
        (change)="hideTitleChanged($event)">Hide title</mat-checkbox>
      </div>
    </div>

    <div style="height: 1.5rem;"></div>

    <div fxLayout style="min-width: 100%;">
      <mat-slide-toggle [checked]="showLabels" (change)="showLabelChanged($event)" color="primary">Show Labels</mat-slide-toggle>
    </div>
    <div style="height: 1.5rem;"></div>

    <div *ngIf="isAttributeReady && isGetOptionsToCollect" id="getFnOptions" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem">
      <div class="subheading">Please provide the following values</div>
      <ng-container *ngFor="let option of getFnOptions; let i = index; trackBy: trackByFn">
        <mat-form-field>
          <mat-label>{{ option.name }}</mat-label>
          <input matInput (input)="getOptionChanged($event, i)" [required]="option.required" [value]="option.value">
          <mat-hint style="color: #3f51b5;">Input type: {{ option.dataType }}</mat-hint>
        </mat-form-field>
      </ng-container>
    </div>
    <div style="height: 1rem;"></div>

  </div>
</mat-dialog-content>

<div *ngIf="attributeOptionsError || getOptionsError">
  <div style="color: red;">Please provide values for the option(s)</div>
  <span *ngFor="let option of faultyAttrOptions; trackBy: trackByFn">
    {{option.name + ", "}}
  </span>
  <span *ngFor="let option of faultyGetOptions; trackBy: trackByFn">
    {{option.name + ", "}}
  </span>
</div>

<div class="sticky" fxLayout fxLayoutAlign="center center" fxFlexFill>
  <button
    *ngIf="isReadyToSave"
    [disabled]="saveDisabled"
    fxLayout
    fxLayoutAlign="center center"
    mat-raised-button
    color="primary"
    (click)="saveConfig()"
  >
    {{ firstHit? 'Create Details Panel' : 'Save Configuration' }}
  </button>
</div>
