<div  class="fullWidth" fxLayout="column" fxLayoutAlign="start start">
  <!-- <div class="collapseIcon" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
    <mat-icon (click)="collapsePanel()">expand_less</mat-icon>
  </div> -->
  <div style="width: 100%;">
    <div fxLayout="row" fxLayoutAlign="start center" class="header">Navigate to</div>

    <div class="section" style="width: 95%;">

      <div class="fullWidth" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex="95" style="margin-bottom: -24px;">
          <mat-label>Select from available pages or write external URL</mat-label>
          <input
            class="fullWidth"
            #linkInput
            type="text"
            aria-label="page autocomplete"
            matInput
            [formControl]="selectPageControl"
            [matAutocomplete]="auto"
            (input)="linkInputReceived(linkInput.value)"
            (click)="cursorPlaced($event)"
            (keyup)="cursorPlaced($event)"
            [disabled]="pageCreatingSpinner"
          >
          <button *ngIf="linkInput.value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="resetPageSelection()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="linkPageSelected($event);">
            <mat-option [value]="'Auto create details page'">
              <span class="createPage">Auto Create Details Page</span>
            </mat-option>
            <mat-option [value]="'Auto create form page'">
              <span class="createPage">Auto Create Edit Form</span>
            </mat-option>

            <mat-option *ngFor="let pageName of filteredPages | async; trackBy: trackByFn" [value]="pageName">
              <span>{{ pageName }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <app-field-picker fxFlex="5" class="fieldPicker"
          [inputFieldMap]="listAttributesMap"
          (selectedFields)="templateValueSelected($event)"
        ></app-field-picker>

        <div *ngIf="linkError" class="linkError" fxLayout fxLayoutAlign="start center">{{ linkErrorMessage }}</div>
      </div>
    </div>

    <div class="section" style="width: 95%;">
      <div class="fullWidth" fxLayoutAlign="center center" *ngIf="attr['navigationSettings']">
        <mat-form-field class="fullWidth" style="margin-bottom: -24px;">
          <mat-label>Tooltip</mat-label>
          <input matInput (input)="tooltipChanged()" [(ngModel)]="attr['navigationSettings'].tooltip">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="pageCreatingSpinner" fxLayout fxLayoutAlign="center center">
    <spinner></spinner>
  </div>

  <div class="section" style="width: 95%;">

  <!-- navAttributeSelectionAreaOpened || collectOnlyParams -->
  <div fxLayout="column" *ngIf="navAttributeSelectionAreaOpened" >
    <ng-container *ngFor="let param of navFilterAttributes; let i = index; trackBy: trackByFn" >

      <mat-card style="background-color: #efefef; padding-left: .5rem;">
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap=".5rem">
            <div fxLayout="column" fxLayout.xs="row" [fxFlex.gt-xs]="35" fxLayoutAlign="center center">
              <mat-form-field class="fullWidth">
                <mat-label>Parameter Name</mat-label>
                <input matInput [(ngModel)]="param.parameter">
                <mat-hint>Give a custom name to your parameter</mat-hint>
              </mat-form-field>
            </div>

            <div fxLayout.xs="row" [ngClass.xs]="'margin-bottom'" [fxFlex.gt-xs]="20" fxLayoutAlign="center center" fxLayout="column">
              <button
                matTooltip="Parameter type"
                mat-button
                fxFlexFill
                [matMenuTriggerFor]="menuParamTypes"
              >
                <div style="margin-top: 30px;" fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                  <mat-icon style="opacity: 70%;" >{{paramTypeLogoMap[param.paramType].icon}}</mat-icon>
                  <span>{{paramTypeLogoMap[param.paramType].name}}</span>
                  <mat-icon style="opacity: 70%;">arrow_drop_down</mat-icon>
                </div>
              </button>
              <mat-menu #menuParamTypes="matMenu">
                <div *ngFor="let op of parameterTypes; trackBy: trackByFn">
                  <button mat-menu-item (click)="onSelectParamType(param, op)">
                    <mat-icon>{{ op.icon }}</mat-icon> <span>{{ op.name }}</span>
                  </button>
                </div>
              </mat-menu>
            </div>

            <div fxLayout.xs="row" [ngClass.xs]="'margin-bottom'" [fxFlex.gt-xs]="35" fxLayout="column" fxLayoutAlign="center center"  style="margin-bottom: -24px;">
                <div [ngSwitch]="param.paramType">
                  <div *ngIf="param.paramType === 'value'">
                    <mat-form-field class="fullWidth">
                      <mat-label>Value</mat-label>
                      <input  matInput #filterValueInput [(ngModel)]="param.value">
                    </mat-form-field>
                  </div>
                  <div *ngIf="param.paramType === 'attribute' || !param.paramType">
                    <form [formGroup]="linkAttributesFrom" class="fullWidth">
                      <mat-form-field class="fullWidth">
                        <mat-label>Attribute</mat-label>
                        <input
                          #boxInput
                          type="text"
                          aria-label="attribute autocomplete"
                          matInput
                          (focus)="setAttributeList(i)"
                          formControlName="{{'form' + i}}"
                          [matAutocomplete]="auto2"
                          (ngModelChange)="navFilterAttrValue($event, param)"
                        >
                        <button *ngIf="boxInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="linkAttributesFrom.get('form0').patchValue('')">
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFnAttribute"
                        (optionSelected)="navFilterAttrSelected($event, param); ">
                          <mat-option *ngFor="let attr of filteredLinkAttributes | async; trackBy: trackByFn" [value]="attr">
                            <span>{{attr.__id}}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </form>
                  </div>
                </div>
            </div>

            <button mat-icon-button color="warn" fxFlex="5" (click)="removeNavFilterAttr(i)">
              <mat-icon>delete</mat-icon>
            </button>
            <!-- <div class="" fxLayout="row" fxLayoutAlign="end" style="margin-bottom: -10px;margin: -25px -25px -19px 0px;padding-bottom: 12px;">
            </div> -->
          </div>

        </mat-card>
        <div style="height: 5px;"></div>
    </ng-container>


  </div>
  </div>
  <div *ngIf="navAttributeSelectionAreaOpened" fxLayout="column" fxLayoutAlign="start start">
    <button mat-stroked-button color="primary" (click)="addParamItem()">
      <mat-icon>add</mat-icon> Add Parameter
    </button>
    <div style="height: .3rem;"></div>
    <div style="font-size: .8rem; color: #666; font-style: italic;">Choose any contexual value to pass across the navigation. It helps in loading relevant record(s) in destination page.</div>
  </div>

  <div class="detailsPageConfig section" style="width: 95%;" *ngIf="autoCreatePageConfigOpened" fxLayout="column" fxLayoutAlign="center start">
    <div>
      <mat-radio-group color="primary" (change)="detailsBoxTypeChosen($event)">
        <mat-radio-button class="radioItem" value="same" checked="true">Same Object</mat-radio-button>
        <mat-radio-button class="radioItem" value="different">Another Object</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="height: 1rem;"></div>

    <div *ngIf="diffOjectPageCreateConfig" class="diffObjectConfig">
      <app-select-box
        (boxSelectionError)="boxSelectionError($event)"
        (boxInput)="boxSelected($event)"
      >
      </app-select-box>
      <small *ngIf="isBoxConfigError" style="color: red;">{{ boxConfigError.error.message | json }}</small>

      <app-select-box-object
        *ngIf="isBoxSelected && canGetBoxObjects"
        [boxId]="selectedDestBoxId"
        [connectionId]="selectedDestConnectionId"
        (boxObjectInput)="boxObjectSelected($event)"
        (boxObjectSelectionError)="boxObjectSelectionError($event)"
      >
      </app-select-box-object>
      <small *ngIf="isBoxObjectConfigError" style="color: red;">{{ boxObjectConfigError.error.message | json }}</small>
    </div>

    <div *ngIf="isGetOptionsToCollect">
      <div style="height: 1rem;"></div>
      <div class="subHeader">Please provide the following values for <b>{{ boxConfig.boxName }} - {{ boxConfig.boxObjectId }}</b></div>
      <div style="height: .5rem;"></div>
      <div fxLayout fxLayoutAlign="flex-start center">
        <ng-container *ngFor="let option of getFnOptions; let i = index; trackBy: trackByFn">
          <mat-form-field style="width: 100%;">
            <mat-label>{{ option.name }}</mat-label>
            <input matInput (input)="getOptionChanged($event, i)" [required]="option.required" [value]="option.value">
            <mat-hint style="color: #3f51b5;">Input type: {{ option.dataType }}</mat-hint>
          </mat-form-field>
        </ng-container>
      </div>
      <div style="height: 1rem;"></div>
    </div>

    <button (click)="createDetailsPageSwitch()" [disabled]="!isDetailsBoxChosen" mat-raised-button color="primary">
      <span style="margin-right: 1rem;">Create page</span>
      <mat-icon>east</mat-icon>
    </button>

  </div>

  <div class="section" *ngIf="this.navigationType === 'internal' && readyToChoseConn" style="width: 95%;">

    <div class="subHeader" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap=".5rem">
      <span>Select a connection to load data in destination page</span>
      <mat-icon style="opacity: 50%;" matTooltip="we will use this connection to load relevent data after navigating to linked page. For instance, if you are going to 'contacts' page, chose something like 'connection_name : contacts'" matTooltipPosition="above">info</mat-icon>
    </div>

    <div *ngFor="let connection of destConnList; let i = index; trackBy: trackByFn" class="connWrap">
      <mat-checkbox color="primary" [(ngModel)]="connection.selected" (change)="connectionSelected($event, i)">
        <div fxLayout="row" fxLayoutAlign="start center">
          <span class="connItem">
            <img class="example-option-img" matTooltip="{{ connection.boxId }}" [src]="connection.logoUrl" height="25" width="25">
          </span>
          <span class="connItem">{{ connection.boxId }}</span>
          <span class="connItem">{{ connection.boxObjectId }}</span>
          <span class="connItem">{{ "(" + connection.boxName + ")" }}</span>
        </div>
      </mat-checkbox>

      <div *ngIf="connection.selected" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
        <ng-container *ngIf="connection['attrMapping'] && connection['attrMapping'].length">
          <ng-container *ngFor="let map of connection['attrMapping']; let j = index; trackBy: trackByFn">

            <div class="attrMapWrap" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
              <!-- <div class="attrMapItem" fxFlex="30" fxLayout="row" fxLayoutAlign="start center">{{ !connection['selfMap'] ? map.sourceAttr.name : map.linkAttr.name }}</div> -->
              <div class="attrMapItem" fxFlex="30" fxLayout="row" fxLayoutAlign="start center">{{ map.sourceAttr.name }}</div>
              <div class="attrMapItem" fxFlex="10" fxLayout="row" fxLayoutAlign="start center" *ngIf="!connection['selfMap']"><mat-icon>east</mat-icon></div>
              <div class="attrMapItem" fxFlex="30" fxLayout="row" fxLayoutAlign="start center" *ngIf="!connection['selfMap']">{{ map.destAttr.name }}</div>

              <span class="attrMapItem deleteIcon" fxFlex="10" fxLayout="row" fxLayoutAlign="end center"><mat-icon (click)="deleteMapAttr(i, j)">delete</mat-icon></span>
            </div>

          </ng-container>
        </ng-container>

        <!-- show button if connection selected and mapping area is not already open -->
        <button *ngIf="connection.selected && i !== mappingOpenedFor" mat-raised-button (click)="addMapping(i)" fxLayout fxLayoutAlign="space-around center" style="margin-top: .5rem;">
          <mat-icon style="margin: auto;">add</mat-icon>
          <span style="margin: auto;">{{ connection['selfMap'] ? "Add link attribute" : "Add attribute mapping" }}</span>
        </button>
      </div>

      <ng-container *ngIf="i == mappingOpenedFor">
        <app-attribute-mapping
          [sourceAttributes]="attributeList"
          [destAttributes]="destAttrs"
          [selfMap]="connection['selfMap']"
          (mappingSelected)="mappingSelected($event, i)"
          (cancelAdd)="cancelAddMapping($event, i)"
        >
        </app-attribute-mapping>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!bypassSave" class="section" style="width: 95%;" fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button class="actionButton" (click)="cancelNavigation()">Cancel</button>
    <button mat-flat-button class="actionButton" color="primary" [disabled]="!isReadyToSave" (click)="saveNavigationSettings()">Apply</button>
  </div>

</div>

