import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxService } from 'src/app/shared/services/box.service';

@Component({
    selector: 'app-custom-object-dialog',
    templateUrl: './custom-object-dialog.component.html',
    styleUrls: ['./custom-object-dialog.component.scss'],
    standalone: false
})
export class CustomObjectAttributeDialogComponent implements OnInit {
  inputParams:any
  inputMap: any = {object:""};
  executionResponse: any;
  connection: any;
  type: string;
  spinner: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, public dialogRef: MatDialogRef<CustomObjectAttributeDialogComponent>,
  private boxService: BoxService) { 
    console.log("DIALOGREF", dialogData);
    var data = dialogData;
    this.connection = data.connection;
    this.type = data.type;
    if(data.type == 'customAttribute') this.inputMap.object = data.object;
  }

  ngOnInit(): void {
  }

  inputRecevied(event){
    if(event) this.inputParams = event;
  }

  attributesReceived(event){
    if(event) Object.assign(this.inputMap,event);
  }

  async execute(){
    if(this.type == 'customObject'){
      this.spinner = true;
      var payload = null;
      if (this.inputParams) {
        payload = { parameters: {} };
        payload.parameters = this.inputParams;
        console.log('payload', JSON.stringify(payload));
      }
  
      var response = await this.boxService.executeBoxObjectFunction(this.connection._id, this.connection.box_id, 'createobject', payload)
      if(response.data){
        this.executionResponse = response.data;
      }else{
        this.executionResponse = response;
      }
      console.log(this.executionResponse);
      this.spinner = false;
      if(!this.executionResponse.error) {
        this.dialogRef.close(this.executionResponse);
      }
    }

    if(this.type == 'customAttribute'){
      this.spinner = true;
      var payload = null;
      if (this.inputMap) {
        payload = { parameters: {} };
        payload.parameters = this.inputMap;
        console.log('payload', JSON.stringify(payload));
      }
      var response = await this.boxService.executeBoxObjectFunction(this.connection._id, this.connection.box_id, 'createattribute', payload)
      if(response.data){
        this.executionResponse = response.data;
      }else{
        this.executionResponse = response;
      }
      console.log(this.executionResponse);
      this.spinner = false;
      if(!this.executionResponse.error) {
        this.dialogRef.close(this.executionResponse);
      }
    }

  }

  close(){
    this.dialogRef.close();
  }

}
