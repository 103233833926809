import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'multiselect-autocomplete',
    templateUrl: './multiselect-autocomplete.component.html',
    styleUrls: ['./multiselect-autocomplete.component.scss'],
    standalone: false
})
export class MultiselectAutocompleteComponent implements OnInit {

  @Output() result = new EventEmitter<{ key: string, data: Array<string> }>();

  @Input() placeholder: string = 'Select Data';
  @Input() data: Array<any> = [];
  @Input() key: string = '';

  selectControl = new UntypedFormControl();

  rawData: Array<any> = [];
  selectData: Array<any> = [];
  @Input() isSetData: boolean = false;
  @Input() setDataObj: any[] = [];

  filteredData: Observable<Array<any>>;
  filterString: string = '';

  constructor() {
    this.filteredData = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map((value: any) => typeof value === 'string' ? value : value?.name || ''),
      map(filter => this.filter(filter))
    );
  }

  ngOnInit(): void {
    this.isSetData ?
      this.setDataFn() : this.getDataFn();
    console.log(this.selectData);
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  getDataFn() {
    this.data.forEach((item: any) => {
      console.log(item)
      this.rawData.push({ item, selected: false });
    });
  }

  setDataFn() {
    this.data.forEach((item: any) => {
      this.setDataObj.forEach((element) => {
        if (element._id == item._id) {
          this.rawData.push({ item, selected: true });
          this.selectData.push({ item, selected: true });
          return
        }
      })
      this.rawData.push({ item, selected: false });
    });
  }

  filter = (filter: string): Array<any> => {
    this.filterString = filter;
    if (filter.length > 0) {
      return this.rawData.filter(option => {
        return option.item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.rawData.slice();
    }
  };

  displayFn(subject: any) {
    return subject ? subject.name : undefined;
  }

  optionClicked = (event: Event, data: any): void => {
    event.stopPropagation();
    console.log(event, data)
    this.toggleSelection(data);
  };

  toggleSelection = (data: any): void => {
    data.selected = !data.selected;

    if (data.selected === true) {
      this.selectData.push(data);
    } else {
      const i = this.selectData.findIndex(value => value.item === data.item);
      this.selectData.splice(i, 1);
    }

    this.selectControl.setValue(this.selectData);
    this.emitAdjustedData();
  };

  emitAdjustedData = (): void => {
    const results: Array<any> = []
    this.selectData.forEach((data: any) => {
      results.push(data.item);
    });
    this.result.emit({ key: this.key, data: results });
  };

  removeChip = (data: any): void => {
    this.toggleSelection(data);
  };

}
