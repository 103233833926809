<spinner
  [center]="true"
  [selfSpin]="true"
  *ngIf="spinner"
  size="la-2x"
  color="grey"
  type="ball-triangle-path"
></spinner>

<diV>
  <br />
  <div fxLayout="row" *ngIf="authService.loggedIn">
    <app-workspace
      (dataRefresh)="refreshFlow()"
      [parentPage]="'flow'"
    ></app-workspace>
  </div>
  <display-banner
    [logo]="'flow/appiworks-flow-logo.png'"
    [Title]="'Unlock efficiency and seamlessly integrate with Flow'"
    [Tagline]="'Streamline your workflows and amplify productivity'"
  >
  </display-banner>

  <div *ngIf="!authService.loggedIn" fxLayout="row" fxLayoutAlign="center center">
    <div  class="build_button_div" (click)="navigateToSignUp()">
      <div class="build_button" fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="center start">
          <div style="font-size:  large;; font-weight: bold;" fxLayout="row"
          fxLayoutAlign="start center"> Build Now</div>
       </div>
      </div>
    </div>
  </div>

  <div *ngIf="authService.loggedIn" fxLayout="row">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="15">
      <button
        *ngIf="authService.loggedIn"
        mat-button
        fxLayoutAlign="start center"
        routerLink="/home"
        color="primary"
      >
        <mat-icon color="primary">arrow_back</mat-icon>
        <span style="margin-top: 10px; margin-left: 4px">Back to Home</span>
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="65">
      <div fxLayout="row">
        <h1 style="margin-bottom: 0px"><b>List of Flows</b></h1>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center end" fxFlex="15">
      <button
        mat-flat-button
        color="primary"
        style="border-radius: 12px"
        (click)="createNewFlow()"
      >
        Create new Flow
      </button>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </div>
  <br />

  <div *ngIf="authService.loggedIn && flows.length" fxLayout="row">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="90">
      <div *ngIf="flows.length > 0">
        <mat-card appearance="outlined" class="list-card" *ngFor="let flow of flows; let i = index; trackBy: trackByFn">
          <div>
            <div fxLayout="row" style="padding: 10px">
              <div fxLayout="column" fxLayoutAlign="center start" fxFlex="5">
                {{ i + 1 }}
              </div>
              <div fxLayout="column" fxLayoutAlign="center start" fxFlex="20">
                <div fxLayout="row" style="padding: 5px" class="logo-container">
                  <img
                    *ngIf="flow.trigger.box_logo_url"
                    [src]="flow.trigger.box_logo_url"
                    class="image-size"
                  />
                  <mat-icon
                    *ngIf="flow.trigger_type == 'schedule'"
                    class="icon-size-list"
                    >schedule</mat-icon
                  >
                  <mat-icon
                    *ngIf="flow.trigger_type == 'webhook'"
                    class="icon-size-list"
                    >webhook</mat-icon
                  >
                  <ng-container *ngFor="let step of flow.steps; let i = index; trackBy: trackByFn">
                    <!-- Display logos based on the showMoreLogos state -->
                    <ng-container *ngIf="showMoreLogos || i < 4">
                      <mat-icon
                        *ngIf="
                          step.box_logo_url &&
                          !step.box_logo_url.includes('https://')
                        "
                        class="icon-size-list"
                        style="margin-right: 5px"
                      >
                        {{ step.box_logo_url }}
                      </mat-icon>
                      <img
                        *ngIf="step?.box_logo_url?.includes('https://')"
                        [src]="step.box_logo_url"
                        class="image-size"
                        style="margin-right: 5px"
                      />
                    </ng-container>
                    <!-- Display toggle icon after showing all logos -->
                    <ng-container *ngIf="i === flow.steps.length - 1">
                      <!-- <div class="dots">...</div> -->
                      <mat-icon
                        *ngIf="flow.steps.length > 4"
                        class="icon-show-more"
                        matTooltip="{{
                          showMoreLogos ? 'Show Less' : 'Show More'
                        }}"
                        matTooltipPosition="above"
                        (click)="toggleLogos()"
                        style="cursor: pointer"
                      >
                        {{ showMoreLogos ? "remove" : "add" }}
                      </mat-icon>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div fxFlex="5"></div>
              <div
                fxLayout="column"
                fxLayoutAlign="center start"
                fxFlex="50"
                style="font-weight: 700"
              >
                {{ flow.name }}
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10">
                <mat-slide-toggle
                  *ngIf="flow.published_flow_id"
                  matTooltip="{{
                    flow.isActive ? 'Flow Enabled' : 'Flow Disabled'
                  }}"
                  class="active_toggle"
                  (change)="updateActiveState(flow)"
                  [(ngModel)]="flow.isActive"
                ></mat-slide-toggle>
              </div>
              <button fxFlex="5"
                mat-icon-button
                matTooltip="Edit Flow"
                (click)="editFlow(flow._id)"
                aria-label="edit icon"
              >
                <mat-icon class="action-icon">edit</mat-icon>
              </button>
              <!-- <div fxLayout="row" fxLayoutAlign="center" fxFlex="10">
                <div
                  fxFlex="100"
                  fxFlex.lt-md="90"
                  fxFlex.lt-sm="80"
                  fxFlex.lt-xs="70"
                >

              </div> -->
              <button mat-icon-button [matMenuTriggerFor]="menu" fxFlex="5">
                <mat-icon class="action-icon">more_vert</mat-icon>
              </button>
              <!-- </div> -->
              <mat-menu #menu="matMenu">
                <a
                  routerLink="/flow/instance/{{ flow._id }}"
                  mat-menu-item
                  style="text-decoration: none; min-width: 144px"
                  href=""
                  target="_blank"
                >
                  <button mat-button matTooltip="View flow executions">
                    <mat-icon color="primary" style="text-decoration: none"
                      >list</mat-icon
                    >
                    Executions
                  </button>
                </a>
                <div
                  mat-menu-item
                  style="text-decoration: none; min-width: 144px"
                >
                  <button
                    mat-button
                    matTooltip="Share Flow"
                    (click)="shareFlowData(flow)"
                  >
                    <mat-icon color="primary" style="text-decoration: none"
                      >share</mat-icon
                    >
                    Share Flow
                  </button>
                </div>

                <div
                  mat-menu-item
                  style="text-decoration: none; min-width: 144px"
                >
                  <button
                    mat-button
                    matTooltip="Clone Flow"
                    (click)="cloneFlow(flow, i)"
                    aria-label="edit icon"
                  >
                    <mat-icon color="primary" class="action-icon">content_copy</mat-icon>
                    Clone Flow
                  </button>
                </div>

                <div mat-menu-item style="text-decoration: none; min-width: 144px">
                  <button mat-button matTooltip="Delete Flow" aria-label="delete flow" (click)="deleteFlow(flow)">
                    <mat-icon color="warn" class="action-icon">delete</mat-icon>
                    Delete Flow
                  </button>
                </div>

              </mat-menu>
            </div>
            <div fxLayout="row" class="bottom-sec">
              <div fxLayout="column" fxLayoutAlign="center start" fxFlex="40">
                <div fxLayout="row" fxLayoutAlign="start start">
                  Updated &nbsp; <b>{{ formatDate(flow.modified_at) }}</b>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <mat-paginator
          [length]="totalFlows"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          (page)="pageChanged($event)"
        >
        </mat-paginator>
      </div>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </div>

  <diV *ngIf="authService.loggedIn && sharedFlows.length > 0">
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="5"></div>
      <div fxLayout="column" fxLayoutAlign="center start" fxFlex="45">
        <div fxLayout="row">
          <h1 style="margin-bottom: 0px"><b>Shared Flows</b></h1>
        </div>
      </div>
      <div fxLayout="column" fxFlex="5"></div>
    </div>
    <br />

    <div fxLayout="row">
      <div fxLayout="column" fxFlex="5"></div>
      <div fxLayout="column" fxFlex="90">
        <div *ngIf="sharedFlows.length > 0">
          <mat-card
            appearance="outlined"
            class="list-card"
            *ngFor="let flow of sharedFlows; let i = index; trackBy: trackByFn"
          >
            <div>
              <div fxLayout="row" style="padding: 10px">
                <div fxLayout="column" fxLayoutAlign="center start" fxFlex="5">
                  {{ i + 1 }}
                </div>
                <div fxLayout="column" fxLayoutAlign="center start" fxFlex="22">
                  <div
                    fxLayout="row"
                    style="padding: 5px"
                    class="logo-container"
                  >
                    <img
                      *ngIf="flow.trigger.box_logo_url"
                      [src]="flow.trigger.box_logo_url"
                      class="image-size"
                    />
                    <mat-icon
                      *ngIf="flow.trigger_type == 'schedule'"
                      class="icon-size-list"
                      >schedule</mat-icon
                    >
                    <mat-icon
                      *ngIf="flow.trigger_type == 'webhook'"
                      class="icon-size-list"
                      >webhook</mat-icon
                    >
                    <ng-container
                      *ngFor="let step of flow.steps; let i = index; trackBy: trackByFn"
                    >
                      <ng-container *ngIf="showMoreLogos || i < 4">
                        <mat-icon
                          *ngIf="
                            step.box_logo_url &&
                            !step.box_logo_url.includes('https://')
                          "
                          class="icon-size-list"
                          style="margin-right: 5px"
                        >
                          {{ step.box_logo_url }}
                        </mat-icon>
                        <img
                          *ngIf="step?.box_logo_url?.includes('https://')"
                          [src]="step.box_logo_url"
                          class="image-size"
                          style="margin-right: 5px"
                        />
                      </ng-container>
                      <ng-container *ngIf="i === flow.steps.length - 1">
                        <mat-icon
                          *ngIf="flow.steps.length > 4"
                          class="icon-show-more"
                          matTooltip="{{
                            showMoreLogos ? 'Show Less' : 'Show More'
                          }}"
                          matTooltipPosition="above"
                          (click)="toggleLogos()"
                          style="cursor: pointer"
                        >
                          {{ showMoreLogos ? "remove" : "add" }}
                        </mat-icon>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <div fxFlex="5"></div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="50"
                  style="font-weight: 700"
                >
                  {{ flow.name }}
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxFlex="10"
                ></div>
                <div fxLayout="row" fxLayoutAlign="center" >
                  <div
                    fxFlex="100"
                    fxFlex.lt-md="90"
                    fxFlex.lt-sm="80"
                    fxFlex.lt-xs="70"
                  >
                    <button
                      mat-icon-button
                      matTooltip="Clone Flow"
                      (click)="cloneFlow(flow, i)"
                      aria-label="edit icon"
                    >
                      <mat-icon class="action-icon">content_copy</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon class="action-icon">more_vert</mat-icon>
                    </button>
                  </div>
                </div>
                <mat-menu #menu="matMenu">
                  <a
                    routerLink="/flow/instance/{{ flow._id }}"
                    mat-menu-item
                    style="text-decoration: none; min-width: 144px"
                    href=""
                    target="_blank"
                  >
                    <button mat-icon-button matTooltip="View flow executions">
                      <mat-icon color="primary" style="text-decoration: none"
                        >list</mat-icon
                      >
                      Executions
                    </button>
                  </a>
                </mat-menu>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div fxLayout="column" fxFlex="5"></div>
    </div>
  </diV>
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout fxLayoutAlign="start start" fxFlex="45">
      <button
      mat-flat-button
      color="primary"
      style="border-radius: 12px; margin-top: 5px;"
      (click)="createNewFlow()"
    >
      Create new Flow
    </button>
    </div>
  </div>
</diV>
