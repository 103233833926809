<div fxLayout="row" fxLayoutGap="10px" style="width: 1000px;">
  <div *ngFor="let param of parameters; let i = index; trackBy: trackByFn" fxLayoutAlign="start center" style="width: 500px;">
    <!-- <ng-container *ngIf="param.widget.type == 'query'; else elseTemplate">
      <query-inputs-component
        fxFlex="100"
        [componentsToShow]="queryInputs.subComponentsToShow"
        [boxObjectAttributes]="boxObjectAttributes"
        [retainSubComponentOutput]="queryInputs.retainSubComponentOutput"
        [existingConfig]="param.widget.queryInputs"
        [supportApplyButton]="false"
        [fieldListingMap]="fieldListingMap"
        (newConfig)="valueReceived({value: $event.query, subComponentOutput: $event.subComponentOutput}, param)"
      ></query-inputs-component>
    </ng-container> -->

    <!-- <ng-template > -->
      <ng-container *ngIf="param.widget && param.widget.type; else arrTemplate" >
          <ng-container [ngSwitch]="param.widget.type">
            <ng-container *ngSwitchCase="'input'">
              <app-textinput
                style="width: 100%" #widgets
                [widgetMeta]="param.widget"
                [builderMode]="false"
                (userInputReceived)="valueReceived($event, param)"
              >
              </app-textinput>
            </ng-container>

            <ng-container *ngSwitchCase="'divider'">
              <app-separator
              style="width: 100%"
              [builderMode]="false"
              ></app-separator>
            </ng-container>
      
            <ng-container *ngSwitchCase="'textarea'">
              <app-textarea
                style="width: 100%" #widgets
                [widgetMeta]="param.widget"
                [builderMode]="false"
                (userInputReceived)="valueReceived($event, param)">
              </app-textarea>
            </ng-container>
      
            <ng-container *ngSwitchCase="'autocomplete'">
              <app-autocomplete
                style="width: 100%" #widgets
                [widgetMeta]="param.widget"
                [builderMode]="false"
                (userInputReceived)="valueReceived($event, param)">
              </app-autocomplete>
            </ng-container>
      
          </ng-container>
          <app-field-picker *ngIf="param.widget.type == 'input' && fieldListingMap?.sourceFields"
            [inputFieldMap]="{
              list:['sourceFields'],
              sourceFields: {
                id:'sourceFields', 
                displayName: 'SOURCE FIELDS', 
                fields: fieldListingMap?.sourceFields, 
                options:{}
              }
            }"
            (selectedFields)="insertCursor($event, param)"
          ></app-field-picker>
      </ng-container>
    <!-- </ng-template> -->

    <ng-template #arrTemplate>
      <!-- <mat-card fxLayout="column" style="margin: 10px 0;">
        <p>Add {{ param.key }}</p>
        <ng-container *ngFor="let paramWidget of arrayParamWidgets; let i = index; trackBy: trackByFn">
          <div fxLayout="row">
            <app-parameter-widgets [parameters]="paramWidget" [index]="i"></app-parameter-widgets>
            <button mat-mini-fab color="warn" aria-label="Remove" (click)="removeArryInputs(i)">
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </ng-container>
        <div>
          <button mat-mini-fab color="primary" aria-label="Add" (click)="addArrayInputs(param.widgets)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-card> -->
    </ng-template>
  </div>
</div>