<div class="tools-wrap">
  <div>
    <div class="tools-title"> Tools </div>
  </div>
  <div class="expansionBody" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="toolItemWrap" fxFlex="100" cdkDropList (cdkDropListDropped)="dropWidget($event)">
      <button cdkDrag (cdkDragStarted)="dragStarted($event)" [cdkDragData]="dragData['__action__']" mat-stroked-button color="primary" (click)="createNewTool(dragData['__action__'])">
        <mat-icon aria-hidden="false" aria-label="button icon">double_arrow</mat-icon>
        <span class="buttonText">Action</span>
      </button>
    </div>

    <div class="toolItemWrap" fxFlex="100" cdkDropList (cdkDropListDropped)="dropWidget($event)">
      <button cdkDrag (cdkDragStarted)="dragStarted($event)" [cdkDragData]="dragData['__branch__']" mat-stroked-button color="primary" (click)="createNewTool(dragData['__branch__'])">
        <mat-icon aria-hidden="false" aria-label="button icon">account_tree</mat-icon>
        <span class="buttonText">Branch</span>
      </button>
    </div>
  </div>

  <div style="height: 1rem;"></div>
  <mat-divider></mat-divider>
  <div style="height: 1rem;"></div>

  <div *ngIf="appsLoaded">
    <mat-form-field appearance="outline" class="app-search-ac" style="margin-bottom: -1.25em">
      <mat-label>Search apps</mat-label>
      <input matInput type="text" (input)="inputDetected($event.srcElement.value)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <!-- <div style="height: 1rem;"></div> -->

    <div class="app-listing container-with-thin-scrollbar">
      <ng-container *ngFor="let app of filteredApps; trackBy: trackByFn">
        <div fxLayoutAlign="start center" fxLayoutGap=".3rem" cdkDropList (cdkDropListDropped)="dropWidget($event)">
          <div 
            class="draggable-app" fxLayoutAlign="start center" fxLayoutGap=".5rem"
            [cdkDragData]="dragData[app.__id]" cdkDrag (cdkDragStarted)="dragStarted($event)" 
            (click)="createNewTool(dragData[app.__id])"
          >
            <div class="logo-wrap">
              <img [src]="app.logo" alt="" style="height: 100%; width: 100%">
            </div>
            <div class="app-name">{{ app.name }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
