import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { ConnectionDialogComponent } from 'src/app/shared/dialog/connection-dialog/connection.dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
    selector: 'app-privilege-view',
    templateUrl: './privilege-view.component.html',
    styleUrls: ['./privilege-view.component.scss'],
    standalone: false
})
export class PrivilegeViewComponent implements OnInit {

  @Input() resource_type: any;
  @Input() resource_id: any;

  privilegeSearchField: string = '';
  workspaceId: string;
  preAuthenticatedToken: string;
  privileges: any[] = [];

  isCreatePrivilage:boolean = false;
  privilageConfig: any = {};
  listFilter: any = {
    filter: "resource_type=default|string"
  }
  filterResType:any = "Default";
  filterResTypes: any = ["Default"]

  constructor(
    private route: ActivatedRoute, 
    private connectionService: ConnectionService, 
    private spinnerService: SpinnerService, 
    private adminService: AdminService, 
    private dialog: MatDialog
  ) { }

  async ngOnInit() {

    this.route.params.subscribe(async (params) => {
      
      this.workspaceId = params.workspaceid || this.connectionService?.workSpaceId;

      if (!this.workspaceId) {
        this.workspaceId = await this.connectionService.getWorkSpaceId();
      }

      if (!params.authorization) {
        this.preAuthenticatedToken = this.connectionService.preAuthenticatedToken;
      } else {
        this.preAuthenticatedToken = decodeURIComponent(params.authorization);
      }

      // if (params.get('workspaceid') && decodeURIComponent(params.get('authorization')) != 'null') {
      //   this.connectionService.workSpaceId = params.get('workspaceid');
      //   this.connectionService.preAuthenticatedToken = decodeURIComponent(params.get('authorization'));
      // }
    });

    console.log("resource_type", this.resource_type)
    console.log("resource_id", this.resource_id)

    this.privilageConfig = {
      resource_type: this.resource_type,
      resource_id: this.resource_id
    }

    if(this.resource_type == "bloom"){
      this.filterResTypes.push("Bloom");
      this.filterResType = "Bloom"
    }

  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  async ngAfterViewInit() {
    if (this.workspaceId && this.preAuthenticatedToken) {
      this.spinnerService.show();
      await this.listPrivilege();
      this.spinnerService.hide();
    }
  }

  async listPrivilege(){
    this.isCreatePrivilage = false;
    this.spinnerService.show();
    let payload = {
      filter: "resource_type=default|string"
    }

    if(this.filterResType == "Bloom") payload.filter = `resource_type=bloom|string,resource_id=${this.resource_id}|string`;
      var res:any = await this.adminService.listPrivilege(1, 20, this.preAuthenticatedToken, payload)
      this.privileges = res.data;
      this.spinnerService.hide();
  }

  async deletePrivilege(privilegeObj: any) {

    let dialogRef = this.dialog.open(ConnectionDialogComponent, {
      data: { name: privilegeObj.name },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.spinnerService.show();
        await this.adminService.deletePrivilege(this.preAuthenticatedToken, privilegeObj._id)
        await this.listPrivilege();
        this.spinnerService.hide();
      }
    });

  }

  editPrivilege(privilegeObj: any) {
    console.log(privilegeObj)
    
  }

}
