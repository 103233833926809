<div [fxHide]="selectData.length==0">
  <mat-form-field >
  <!-- <mat-chip-listbox #chipList>
    <ng-container *ngFor="let select of selectData; trackBy: trackByFn">
      <mat-chip-option class="cardinal-colors" (click)="removeChip(select)">
        {{ select.item ? select.item?.name : '' }}
        <mat-icon class="mat-chip-remove">cancel</mat-icon>
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox> -->

  <mat-chip-grid #chipGrid aria-label="Fruit selection">
    @for (select of selectData; track select) {
      <mat-chip-row>
        {{ select.item ? select.item?.name : '' }}
        <button matChipRemove [attr.aria-label]="'remove '" (click)="removeChip(select)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    }
  </mat-chip-grid>



  <input matInput type="text"
  [matChipInputFor]="chipGrid"
  [placeholder]="placeholder"
  [matAutocomplete]="auto"
  [formControl]="selectControl">


<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let data of filteredData | async; trackBy: trackByFn" (click)="$event.stopPropagation()">
    <div (click)="optionClicked($event, data)" fxLayout="row" fxLayoutGap="12.5px">
      <pre></pre>
      <mat-checkbox [checked]="data.selected" (change)="toggleSelection(data)" (click)="$event.stopPropagation()">
        <mat-label>{{ data.item ? data.item?.name : '' }}</mat-label>
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>
</mat-form-field>
</div>
