import { Component, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, Inject, LOCALE_ID } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormBuilder, FormGroup, NgModel, UntypedFormControl } from '@angular/forms';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { Subject, Subscription } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { formatDate } from '@angular/common';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';


@Component({
    selector: 'app-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.css'],
    standalone: false
})
export class TimeComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: {};
  form: FormGroup;
  timerValue: string = ""
  hoveredNow: boolean = false;
  validationSubscription: Subscription;
  @ViewChild('timeInput', { static: false }) timeInput: NgModel;

  private destroy:any = new Subject();
  //------------------------------constructor---------------------------------
  constructor(
    public metaService: MetaService, 
    @Inject(LOCALE_ID) private locale: string,
    public formBuilder: FormBuilder,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    private validationService: ValidationService,
    public wmtService: WidgetMetaTransformationService,
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService)
  }

  //---------------------------------ngONInit----------------------------------------
  ngOnInit(): void {

    super.ngOnInit()
    this.initValue() 
    this.form = this.formBuilder.group({
      timer: [''],
    });

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.timeInput.control.markAsTouched()
      }
    })

    this.contextMenuActions = {
      actions: [
        "edit"
      ],
    }
    
    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      // console.log("set value notifier in textarea", widgetMeta)
      this.decompressMetaAndAssign(widgetMeta)
      this.initValue()
    })

    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
        this.initValue()
      }
    })
  }

  initValue(){
    //setting default value if the value doesn't exist
    if ((this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') && this.widgetMeta.config.defaultValue.value) {
      this.widgetMeta.config.value.value = this.widgetMeta.config.defaultValue.value;
    }
    if (this.widgetMeta.config.defaultValue?.dynamic && this.widgetMeta.config?.expressionConfig?.id == '__currentdatetime__') {
      this.widgetMeta.config.value.value = this.formatTimeLocal();
    }
    console.log("value", this.widgetMeta?.config?.value?.value)
    this.emitOutput()
  }

  /**
   * to show the existing value in html input of type time
   * @param inputDate 
   * @returns formatted date string
  */
  formatTimeLocal() {
    // Create a new Date object from the ISO string
    const date = new Date();
    // Get the time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date and time in the required format
    return `${hours}:${minutes}`;
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
    this.validationSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      if(changes.widgetMeta.currentValue.config.value?.value?.includes('AM') || changes.widgetMeta.currentValue.config.value?.value?.includes('PM')) {
        changes.widgetMeta.currentValue.config.value.value = this.convertTo24Hour(changes.widgetMeta.currentValue.config.value.value)
      }
      this.emitOutput();
      this.setContextActions()
    }
  }


  //----------------------------------- FUNCTIONS ---------------------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "appearance",
        "bold",
        "underline",
        "color",
        "backgroundColor",
        "italic",
        "fontSize",
        "fontFamily",
        "edit"
      ],
      appearance: {
        value: this.widgetMeta?.config?.appearance?.value,
        type: this.widgetMeta?.config?.appearance?.type
      },
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
    if (event.actionType == "updateStyles") {
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        if (!event.noEmit) this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
      }
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("time widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  userInputDetected(val: any) {
    console.log("from html", val);
    // console.log("widget value", this.widgetMeta.config.value.value)
    // this.widgetMeta.config.value.value = val
    // this.widgetMeta.config.value.value = this.convertTo12Hour(this.widgetMeta.config.value.value)
    // this.timerValue = value
    // console.log("input change detected", this.timerValue)
    this.emitOutput()
  }

  emitOutput(){
    console.log("time value", this.widgetMeta.config.value.value)
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: ''
    }
    if(this.widgetMeta.config.required?.value){
      userInput.validity = this.timeInput?.valid || false
    }
    if(this.widgetMeta.config.customOutputFormat?.enabled){
      let isoDate = new Date(this.timerValue).toISOString()
      console.log("ISO date", isoDate)
      let formattedTime = formatDate(isoDate, this.widgetMeta.config.customOutputFormat.customTimeFormat, this.locale)
      userInput.value = formattedTime
      console.log("formatted date is", formattedTime)
    }else{
      console.log("no custom output format")
      // userInput.value = `${new Date(this.timerValue).toISOString()}|date`
      userInput.value = this.widgetMeta.config.value.value
    }
    console.log("emitting data", userInput)
    this.userInputReceived.emit(userInput);
  }

  // convertTo12Hour(time) {
  //   let [hours, minutes] = time.split(':');
  //   hours = parseInt(hours);
  //   minutes = parseInt(minutes);
  //   const period = hours >= 12 ? 'PM' : 'AM';
  //   hours = hours % 12 || 12;
  //   return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
  // }

  convertTo24Hour(time) {
    // Extract the parts of the time
    const [timePart, modifier] = time.split(' ');
  
    // Split the time part into hours and minutes
    let [hours, minutes] = timePart.split(':');
  
    // Convert hours to a number
    hours = parseInt(hours, 10);
  
    // If the modifier is PM and hours is less than 12, add 12 to hours
    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    }
  
    // If the modifier is AM and hours is 12, set hours to 0 (midnight)
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
  
    // Ensure hours and minutes are two digits
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.padStart(2, '0');
  
    // Return the formatted time in 24-hour format
    return `${hoursStr}:${minutesStr}`;
  }

}
