<div
  class="wrapper"
  (click)="formCanvasClick($event)"
  (mouseover)="mouseover()"
  (mouseout)="mouseout()"
>
  <div *ngIf="type === 'internal'" (click)="$event.stopPropagation()">
    <app-form-page *ngIf="!formService.isPreviewSuccess" [type]="type"></app-form-page>
    <app-success-page-config *ngIf="formService.isPreviewSuccess"></app-success-page-config>
  </div>
  <div *ngIf="!type || type !== 'internal'" (click)="$event.stopPropagation()">
    <router-outlet></router-outlet>
  </div>
</div>
