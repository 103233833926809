export class TemplateEngine {

  fillAny(template: any, data: Object, isNoRemove?: boolean) {
    if (template && typeof template === "object")
      return this.fillTemplateObject(template, data, isNoRemove);
    else
      return this.fill(template, data, isNoRemove);
  }


  fillTemplateObject(template: any, data: Object, isNoRemove?: boolean) {
    var newObj: any = {};
    var templateKeys = Object.keys(template);
    for (var i = 0; i < templateKeys.length; i++) {
      var currentKeyValue = template[templateKeys[i]];
      if (typeof currentKeyValue == 'object') { // If there is inner object, then recursively form the object
        if (Array.isArray(currentKeyValue)) {
          // newObj[templateKeys[i]] = currentKeyValue;
          var array = []
          //for array type loop through array
          for(var j= 0; j < currentKeyValue.length; j++){
            if (typeof currentKeyValue[j] == 'object') { //if item is object
              array.push(this.fillTemplateObject(currentKeyValue[j], data, isNoRemove))
            } else { //if string
              array.push(this.fill(currentKeyValue[j], data, isNoRemove))
            }
          }
          newObj[templateKeys[i]] = array; //assign replaced array
        } else {
          var innerObj = this.fillTemplateObject(currentKeyValue, data, isNoRemove);
          if (innerObj && Object.keys(innerObj).length > 0) {
            newObj[templateKeys[i]] = innerObj;
          }
        }
      } else if (typeof template == "boolean")  {
        newObj[templateKeys[i]] = currentKeyValue
      } else { // Use template engine to replace all dynamic fields

        var value = this.fill(currentKeyValue, data, isNoRemove);
        if (value && value != "") {
          newObj[templateKeys[i]] = value
        }

        //Template engine will remove the object/field if value is empty
        //In some cases we need the value as empty
        //So we are making here to the keyword "${#EMPTY-STRING}" will no remove the field
        //TODO - need to make this generic as more keywords can come
        if (value == "" && currentKeyValue == "${#EMPTY-STRING}") {
          newObj[templateKeys[i]] = value
        }

        // if (currentKeyValue == "#rawData") {
        //   newObj[templateKeys[i]] = data
        // }
      }
    }

    return newObj;
  }

  fill(template: string, data: any, isNoRemove?: boolean, isInnerReplace?: boolean) {
    if (typeof template == "boolean" || typeof template == "number") {
      // variable is a boolean
      return template;
    }

    var templateVars: any

    if(!isInnerReplace){
      var innerTemplates = template.match(/\$\[[^\]]+\]/g);
      if(innerTemplates && innerTemplates.length > 0) {
        template = TemplateEngine.checkInnerTemplateAndReplaceValue(template, data, isNoRemove)
      }
    }

    if(isInnerReplace) templateVars = template.match(/\$\[[^\]]+\]/g);
    else templateVars = template.match(/\$\{[^\}]+\}/g);

    // var templateVars = template.match(/\$\{[^\}]+\}/g);//this.templateVars;
    if (templateVars == null)
      return template;


    // Replace variables from the template with the actual values from the data object.
    // If no value is available, replace with the empty string.
    for (var i = 0; i < templateVars.length; ++i) {
      var name = templateVars[i];
      name = name.substring(2, name.length - 1); // remove ${};


      var isObject =  false;
      var nameArr = name.split('|') || "";
      var result = TemplateEngine.getObjectValue(nameArr[0], data);

      //if template have custom formating/mappiing
      if(nameArr[1]) result = TemplateEngine.fillParamMapping(result, nameArr[1], data);

      // console.log("---name", name, "re", result);
      if ((result !== null) && (typeof result === 'object')){
          //check if value type object and it is a only regex
          if(template.startsWith("$") && template.endsWith("}")) {
            isObject = true;
          }
          result = JSON.stringify(result);
      }
      // template = template.replace(templateVars[i], result || "");
      if(isNoRemove){
        if(result){
          template = template.replace(templateVars[i], result || "");
        } else { //keep the template if not replaced
          template = template;
        }
      } else {
        template = template.replace(templateVars[i], result ?? "");
      }

      if(isObject){ // if value type is Object parse it(to avoid stringified values)
        template = JSON.parse(template);
      }
    }
    // console.log("Template Data : %s Result : %s", JSON.stringify(data), template);


    return template;
  }

  static getObjectValue(string: any, data: any) {
    var nameArr = string.split('|');
    var result = null;
    var name = nameArr[0];

    // //if name is rawData assign data as value
    // if(name == "#rawData"){
    //   return data;
    // }

    // if there is a dot, then drill down to the object
    var dotIndex = name.indexOf('.');
    while (dotIndex != -1) {
      var parent = name.substring(0, dotIndex);
      name = name.substring(dotIndex + 1);
      data = data[parent];
      if (data == null)
        break;
      else
        dotIndex = name.indexOf('.');
    }

    if (data != null) {
      var contructedValue = data[name];
    }
    result = contructedValue;

    return result;
  }

  static checkInnerTemplateAndReplaceValue(templateVar: any, data: any, isNoRemove?: boolean){
    var te = new TemplateEngine();
    templateVar = te.fill(templateVar, data, isNoRemove, true);
    return templateVar;
  };

  /**
 * Replaces a mapped value based on a predefined template system.
 *
 * - It allows dynamic field values to be transformed based on a specific mapping system.
 * - If some values need to be constructed using a predefined structure, such as `systemObjectSemantic`.
 *
 * 1. Splits the `template` string by `:` to extract the mapping type (e.g., `systemObjectSemantic`).
 * 2. If the type matches `systemObjectSemantic`, it constructs a new template string dynamically.
 * 3. Uses `fillAny` to process the constructed template and replace values accordingly.
 * 4. Returns the transformed value.
 *
 * @param value - The original value to be mapped.
 * @param template - The template definition that specifies how the value should be formatted.
 * @param data - The complete data object used for dynamic replacements.
 * @returns The transformed value after applying the mapping.
 */
  static fillParamMapping(value, template, data){
    let te = new TemplateEngine();
    let templateArr = template.split(":");  // Split the template string by ":"
    let systemType = templateArr[0];
    if(systemType == "SystemObject"){
      // Construct a new template string based on the system object format
      let templateValue = "${" + `${templateArr.join(".")}.${value}}`; //construct the template string
      let tempValue = te.fillAny(templateValue, data);
      if(tempValue) value = tempValue;
    }
    // TODO: Extend support for more mapping types in the future
    return value;
  }

}
