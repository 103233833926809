import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";


export class Button extends Widget {
  type = 'button';
  gridX = 6
  minGridX = 1
  config = {
    props: [
      "targetUrl",
      "buttonType",
      "buttonColorType",
      "buttonIcon",
    ],
    targetUrl: {
      displayName: "Target URL",
      value: "",
      type: "text"
    },
    buttonType: {
      displayName: "Button Type",
      value: "Raised",
      type: "select",
    },
    buttonColorType: {
      displayName: "Button Color Type",
      value: "primary",
      customValue: "",
      type: "buttonColor",
      toPersist: ['value', 'customValue']
    },
    buttonIcon: {
      displayName: "Add Icon Prefix?",
      value: false,
      type: "iconPicker",
      iconName: {
        displayName: 'Material Icon Name',
        value: 'emoji_emotions'
      },
      hideText: {
        displayName: 'Hide Button Text',
        value: false,
        type: 'boolean'
      },
      toPersist: ['value', 'hideText', 'iconName']
    },
  }

  //text formatting properties
  textFormat = undefined

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['value'].displayName = 'Button Text';
    this.config['value'].value = "default button"
    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }

  // public getEvents(){
  //     let events = [
  //       {
  //         id: "click",
  //         name: "Click",
  //         function: "click"
  //       }
  //     ]

  //     return events;
  // }

  public getMappingConfig() {
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.value.value",
      staticValue: true,
      effect: {
        styles: ["background-color"],
        "background-color": {
          id: "background-color",
          name: "Background Color",
          inputType: "color-palette"
        }
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }
}
