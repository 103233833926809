import { Component, OnInit } from '@angular/core';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnInit {

  constructor(
    public clientPlatformService: ClientPlatformService,
  ) { }

  environment = environment;
  ngOnInit(): void {
  }

}
