import { Component, Input, OnInit, ViewChild, Injector, Output, EventEmitter, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { FormControl, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';


@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
    standalone: false
})
export class SelectComponent extends BaseWidgetComponent implements OnInit, OnChanges, DoCheck {

  @Output() selectionChanged = new EventEmitter<any>();

  contextMenuActions: any = [];
  // selectedOption: any = '';
  hoveredNow: boolean = false;
  styles: any;
  availableOptions: any[] = []
  nestedPath: string = ''
  fc = new FormControl('')

  oldValue = ""

  @ViewChild('menuTrigger') selectMenuTrigger: MatMenuTrigger;
  private destroy:any = new Subject();

  validationSubscription: any

  constructor(
    public pageService: PageService,
    private widgetUtilityService: WidgetUtilityService,
    private _snackBar: MatSnackBar,
    public metaService: MetaService,
    public validationService: ValidationService,
    public resourcePermissionService: ResourcePermissionService,
    public wmtService: WidgetMetaTransformationService,
    public injector: Injector
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService, injector)
  }

  private getExpressionUtility(): ExpressionUtility {
    return this.injector.get(ExpressionUtility);
  }

  ngOnInit(): void {
    super.ngOnInit()
    // console.log("select meta", JSON.parse(JSON.stringify(this.widgetMeta)))
    // if(!this.widgetMeta?.config?.value) this.widgetMeta.config.value = {
    //   value: ''
    // }
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      // console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })

    //set the value if default expression enabled
    if (this.widgetMeta.config?.expressionConfig?.id && !this.widgetMeta.config?.value?.value) {
      let value = this.getExpressionUtility().resolveExpression(this.widgetMeta.config?.expressionConfig);
      this.widgetMeta.config.value.value = value;
    }

    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      // console.log("set value notifier in select", widgetMeta)
      this.decompressMetaAndAssign(widgetMeta)
      this.initForm()
      setTimeout(() => {
        this.userInputDetected()
      }, 200);
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    if(changes.widgetMeta && changes.widgetMeta.currentValue){
      // console.log("onChanges select meta", JSON.parse(JSON.stringify(changes.widgetMeta.currentValue)))
      this.widgetInit()
      // if(JSON.stringify(changes.widgetMeta) !== JSON.stringify(this.widgetMeta)){
      // }
    }

    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.selectMenuTrigger && this.selectMenuTrigger.menuOpen) {
        this.selectMenuTrigger?.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  ngDoCheck(): void {
    // if(this.oldValue != this.widgetMeta?.config?.value?.value){
    //   this.oldValue = this.widgetMeta?.config?.value?.value || ""
    //   // console.log("SELECT doCheck", this.oldValue)
    //   this.initForm()
    //   // console.log("calling from doCheck", this.oldValue, this.widgetMeta?.config?.value?.value)
    //   setTimeout(() => {
    //     this.userInputDetected()
    //   }, 200);
    // }
  }

  //----------------------------METHODS--------------------------

  async widgetInit(){
    // console.log("widget init", JSON.parse(JSON.stringify(this.widgetMeta)))
    await this.generateAvailableOptions()
    this.initForm()
    setTimeout(() => {
      this.userInputDetected()
    }, 200);
  }

  initForm(){
    // console.log("initForm hit", JSON.parse(JSON.stringify(this.widgetMeta?.config?.value?.value)))
    if(this.widgetMeta?.config?.required?.value){
      this.fc.addValidators(Validators.required);
    }
    this.fc.patchValue(this.widgetMeta?.config?.value?.value || '')
    this.fc.updateValueAndValidity()
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "addOption",
        "edit",
      ],
    }

    if(this.widgetMeta.config.appearance){
      this.contextMenuActions.actions.unshift('appearance')
      this.contextMenuActions['appearance'] = {
        value: this.widgetMeta?.config.appearance.value,
        type: this.widgetMeta?.config.appearance.type
      }
    }
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    // console.log("In select component, action is", JSON.parse(JSON.stringify(event)))
    if (event.actionType == "delete") {
      this.onDelete()
    }else if (event.actionType == "newOption") {
      // console.log("need to add option", event.newOption, "to widget", event.data)
      this.widgetMeta = event.data
      if(event.newOption && typeof event.newOption == 'string'){
        this.addOption(event.newOption)
      }

      // this.selectMenuTrigger?.closeMenu();

    }else if (event.actionType == "removeOption") {
      let temp: any = []
      this.widgetMeta.config.availableOptions.staticOptions.forEach(option => {
        if (option !== event.removeOption) {
          temp.push(option)
        }
      });
      this.widgetMeta.config.availableOptions.staticOptions = temp

      // console.log("option removed", this.widgetMeta.config.availableOptions.staticOptions)
      this.newWidgetMeta.emit(this.widgetMeta)
      // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
      this.selectMenuTrigger?.closeMenu();

    }else if(event.actionType == 'settingsChanged' || 'updateStyles'){
      this.widgetMeta = event.data
      console.log("action")
      this.resetValue()
      super.generateStyles();
      this.generateAvailableOptions()
      this.newWidgetMeta.next(this.widgetMeta)
      // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
    }
  }


  resetValue(){
    this.widgetMeta.config.value.value = ""
  }


  onClick(event) {
    if (!this.builderMode) {
      return
    }
    console.log("select clicked", event)
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.selectMenuTrigger?.closeMenu();
  }

  selectionHandler(event: any) {
    console.log("Change detected", event.value)
    console.log("fc before delay", JSON.parse(JSON.stringify(this.fc.valid)))

    setTimeout(() => {
      console.log("fc after delay", JSON.parse(JSON.stringify(this.fc.valid)))
      if(event.value == "__reset__"){
        this.widgetMeta.config.value.value = ""
      }
      let userInput: any = {
        sourceWidget: this.widgetMeta.id,
        widgetType: this.widgetMeta.type,
        data: this.widgetMeta.config?.value?.value || ""
      }

      //this only applies when using select Component for widget configuration
      if (this.widgetMeta.hasOwnProperty('propName')) {
        userInput.propName = this.widgetMeta.propName
      }
      //emit the newly selected data
      this.selectionChanged.emit(userInput);
      this.userInputDetected();
    }, 200);
  }

  userInputDetected() {
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config?.value?.value || "",
      validity: this.fc.valid
    }
    // console.log("emitting", userInput)
    this.userInputReceived.emit(userInput);
  }

  onOuterClick(event) {
    console.log("selectBox outer click")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  async generateAvailableOptions(noRefetch: boolean = false){
    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableOptions.filter(opt => opt.type == 'dynamic')
      console.log("preserved dynamic options", dynamicOptionItems)
    }else{
      if(this.widgetMeta.config.availableOptions.dynamicOptions.enabled){
        if(!this.widgetMeta.config.availableOptions.dynamicOptions?.userData){
          rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode);
        } else {
          rawDynamicOptions = await this.widgetUtilityService.getBloomUsers();
        }
        dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
      }
    }

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    this.availableOptions = []
    this.availableOptions = this.availableOptions.concat(staticOptions)
    this.availableOptions = this.availableOptions.concat(dynamicOptionItems)

    // add reset option
    this.availableOptions.unshift({
      default: false,
      name: "-- Reset selection --",
      type: "static",
      value: "__reset__"
    })

    if(this.widgetMeta.config.value.value !== undefined && this.widgetMeta.config.value.value !== null && this.widgetMeta.config.value.value !== ''){
      staticOptions.forEach(o => o.default = false)
      let stOpt = staticOptions.find(opt => opt.value == this.widgetMeta.config.value.value)
      if(stOpt) {
        stOpt.default = true
      }
    }

    for (let i = 0; i < staticOptions.length; i++) {
      const element = staticOptions[i];
      if(element.default == true){
        this.widgetMeta.config.value.value = element.value
        // console.log("calling from generate available options")
        this.userInputDetected()
      }
    }
  }

  // async fetchDynamicOptions(){
  //   console.log("handleDynamicOptions hit")
  //   let dynamicOptions = this.widgetMeta.config.availableOptions.dynamicOptions
  //   if(!dynamicOptions || !dynamicOptions.enabled || !dynamicOptions.connectionId || !dynamicOptions.boxObjectId) {
  //     console.log("dynamic options not enabled or invalid")
  //     return []
  //   }
  //   let dataBindSetup: any = {
  //     boxId: dynamicOptions.boxId,
  //     boxObject: dynamicOptions.boxObjectId,
  //     connectionId: dynamicOptions.connectionId,
  //     pageNumber: 1,
  //     pageSize: 20,
  //     getFnOptions: dynamicOptions.getFnOptions || [],
  //     attributes: [dynamicOptions.nameAttribute.__id, dynamicOptions.valueAttribute.__id]
  //   }
  //   console.log("dataBindSetup", dataBindSetup)
  //   let res: any
  //   try{
  //     res = await this.boxService.getAny(dataBindSetup)
  //   }catch(e){
  //     console.log("could not fetch dynamic options", e)
  //   }
  //   console.log("dynamic options fetched", res)
  //   return res.data
  // }

  // processDynamicOptions(dynamicOptionItems: any[], widgetMeta: any){
  //   let options = []
  //   let valuePath: string = widgetMeta.config.availableOptions.dynamicOptions.valueAttribute.__id + (this.widgetMeta.config.availableOptions.dynamicOptions.valuePath ? `/${this.widgetMeta.config.availableOptions.dynamicOptions.valuePath}` : '')
  //   let namePath: string = widgetMeta.config.availableOptions.dynamicOptions.nameAttribute.__id + (this.widgetMeta.config.availableOptions.dynamicOptions.namePath ? `/${this.widgetMeta.config.availableOptions.dynamicOptions.namePath}` : '')

  //   dynamicOptionItems.forEach((opt: any) => {
  //     let optionObject = {
  //       name: this.getDeepObjectValue(opt, namePath),
  //       value: this.getDeepObjectValue(opt, valuePath)
  //     }
  //     // console.log("optionObject prepared", optionObject)
  //     options.push(optionObject)
  //   })
  //   console.log("prepared dynamic options", options)
  //   return options
  // }

  private addOption(option: string){
    let optionObject = {
      name: option,
      value: option
    }
    this.widgetMeta.config.availableOptions.staticOptions.push(optionObject)
    this.generateAvailableOptions(true)
    // this.newWidgetMeta.next(this.widgetMeta)
    // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
    this._snackBar.open("option added", '', {duration: 1500})
    this.newWidgetMeta.emit(this.widgetMeta)
  }


  // /**
  //  * https://stackoverflow.com/a/11433067/7849490
  //  */
  // private createNestedObject( base, names, value ) {
  //   // If a value is given, remove the last name and keep it for later:
  //   var lastName = arguments.length === 3 ? names.pop() : false;

  //   // Walk the hierarchy, creating new objects where needed.
  //   // If the lastName was removed, then the last object is not set yet:
  //   for( var i = 0; i < names.length; i++ ) {
  //       base = base[ names[i] ] = base[ names[i] ] || {};
  //   }

  //   // If a value was given, set it to the last name:
  //   if( lastName ) base = base[ lastName ] = value;

  //   // Return the last object in the hierarchy:
  //   return base;
  // };

  // private getDeepObjectValue(obj, path){
  //   // console.log("getDeepObjectValue hit", obj, path)
  //   if(typeof obj !== 'object' || Array.isArray(obj) || !path) return obj
  //   let parts = path.split('.')
  //   let objTemp: any = obj
  //   let len = parts.length
  //   for(let j = 0; j < len; j++){
  //     objTemp = objTemp[parts[j]]

  //     if(objTemp == undefined) break
  //     if(j !== (len - 1) && (typeof objTemp !== 'object' || Array.isArray(objTemp))){
  //       console.log("broken path")
  //       objTemp = undefined
  //       break
  //     }
  //   }
  //   // console.log("returning", objTemp)
  //   return objTemp
  // }

  removeOption(i: number){
    this.widgetMeta.config.availableOptions.staticOptions.splice(i, 1)
    console.log("option removed")
    this.generateAvailableOptions(true)
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }
}
