import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { StarchService } from '../services/starch.service';
import { BaseSelectionPopupComponent } from './base-selection-popup/base-selection-popup.component';

@Component({
    selector: 'base-selection',
    templateUrl: './base-selection.component.html',
    styleUrls: ['./base-selection.component.scss'],
    standalone: false
})
export class BaseSelectionComponent implements OnInit {
  bases: any;
  base: any;

  constructor(
    public starchService: StarchService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService
  ) { }

  spinner: boolean = false;

  @Input() "existingBase": any;
  @Output() "selectedBase" = new EventEmitter<any>();

  baseListingACFrom = new UntypedFormControl();
  baseListingOptions: Observable<any[]>;
  @Input() onlyDisplay: boolean = false;

  isEdit:boolean = false;

  connections: any = [];

  async ngOnInit() {

    this.spinner = true;

    if(this.existingBase && typeof this.existingBase === "string"){
      console.log("[BASE SELECTION] string input", this.existingBase)
      //if () { //TODO - get from cache if not there load
      this.base = await this.starchService.getStarchBase(this.existingBase);
    } else if(this.existingBase && Object instanceof this.existingBase){
      this.base = this.existingBase;
    }

    if(this.base) {
      this.selectedBase.emit(this.base);
      this.baseListingACFrom.setValue(this.base);
    }
    this.spinner = false;
  }

  async ngAfterViewInit(){
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  async addBasePopup(){
    var dialog = this.dialog.open(BaseSelectionPopupComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px'
    });
    var diologResult = await dialog.afterClosed().toPromise();
    // if(!diologResult) return;
    this.getAllBases()
    this.spinner = false;
  }

  async getAllBases(){
    console.log("getting all bases")
    this.spinnerService.show();
    this.bases = await this.starchService.getStarchBases(1, 500);
    console.log("starch list", this.bases);
    this.spinnerService.hide();
  }

  async ngOnChanges(changes: SimpleChanges) {
  }


  async setFilterOptions() {
    console.log("all bases")
    if(!this.isEdit){
      await this.getAllBases();
    }
    this.isEdit = true;
    this.baseListingOptions = this.getFilteredOptions(this.baseListingACFrom)
  }

  getFilteredOptions(form: UntypedFormControl){
    return form.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  displayFunction(subject) {
    if(!subject) return undefined;
    return subject.name;
  }

  async baseSelected(base: any){
    console.log("selected", base);
    this.selectedBase.emit(base);
  }


  private _filter(value: any): string[] {
    value = value ? value : ""
    if(typeof value != "string") return;
    const filterValue = value.toLowerCase();
    return this.bases.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  clearBase(e) {
    e.stopPropagation()
    this.baseListingACFrom.patchValue('')
    this.selectedBase.emit('');
  }
}
