// src/app/screenshot.service.ts
import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {

  constructor() { }

  // Method to capture screenshot
  async captureScreen(element: HTMLElement = document.body, fileName, isLimitSize = false) {
    try {
      let result:any =await this.capture(fileName, element);

      // If isLimitSize is true, create a resized version at 900x600
      if (isLimitSize) {
        result.resizedCanvas = await this.capture(fileName + "_resized", element, true);
      }

      return result;
    } catch (error) {
      console.error('Error capturing screenshot:', error);
      throw error;
    }
  }

  // Method to capture screenshot
  async capture(fileName, element, isLimitSize = false){
    let canvas = await html2canvas(element);
    // If isLimitSize is true, resize the canvas
    if(isLimitSize) {
      canvas = this.resizeCanvas(canvas, 900, 600);
    }
      // Create result object with original image
    const originalBlob = await this.canvasToBlob(canvas);
    const buffer = await this.blobToArrayBuffer(originalBlob);
    var nodeBuffer = []
    let UintBuffer = new Uint8Array(buffer);
    for (var i = 0; i < UintBuffer.byteLength; i++) {
      nodeBuffer.push(UintBuffer[i])
    }

      // Convert ArrayBuffer to Uint8Array to get the buffer in [255, 216, 255, ...] format
    let result:any = {
        payload: {
          buffer: nodeBuffer,
          size: originalBlob.size,
          mimetype: originalBlob.type
        },
        url: await this.blobToDataURL(originalBlob)
    }

    if(fileName) {
      result.payload.filename = fileName + "." + this.getFileExtension(originalBlob.type);
    }

    return result;
  }

  // Method to resize canvas to specified dimensions
  private resizeCanvas(sourceCanvas: HTMLCanvasElement, targetWidth: number, targetHeight: number): HTMLCanvasElement {
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = targetWidth;
    resizedCanvas.height = targetHeight;

    const ctx = resizedCanvas.getContext('2d');
    if (ctx) {
      // Use drawImage to resize the canvas content
      ctx.drawImage(sourceCanvas, 0, 0, sourceCanvas.width, sourceCanvas.height, 0, 0, targetWidth, targetHeight);
    }
    return resizedCanvas;
  }

  private blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Blob to Data URL conversion failed'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  // Method to convert canvas to Blob
  private canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas to Blob conversion failed'));
        }
      }, 'image/png');
    });
  }

  // Method to convert Blob to ArrayBuffer
  private blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as ArrayBuffer);
        } else {
          reject(new Error('Blob to ArrayBuffer conversion failed'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  private getFileExtension(mimeType: string): string {
    const mimeTypesToExtensions: { [key: string]: string } = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/webp': 'webp',
    };
    return mimeTypesToExtensions[mimeType] || 'png';
  }
}