
<mat-dialog-actions class="fixed-top-right" align="end">
  <button mat-button mat-dialog-close (click)="spinner.hide();onClose()">
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-actions>

<div align="center" style="margin-bottom: 1rem;">
  <span class="button-toggle-flex">
    <div class="fixed-toolbar">
      <mat-button-toggle-group (change)="buttonToggleChange($event)" [value]="screenSizeButtonToggleValue">
        <mat-button-toggle [matTooltip]="item?.tooltip" *ngFor="let item of screenSizeArray; trackBy: trackByFn" [value]="item">
          <mat-icon>{{item.icon}}</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button mat-icon-button (click)="screenOrientationChange()" matTooltip="Orientation"
        *ngIf="screenSizeButtonToggleValue?.icon=='phone_iphone'||screenSizeButtonToggleValue?.icon=='tablet_mac'||screenSizeButtonToggleValue?.icon=='tablet_android'||selectedDevice?.icon=='phone_iphone'||selectedDevice?.icon=='phone_android'||selectedDevice?.icon=='tablet_mac'||selectedDevice?.icon=='tablet_android'">
        <mat-icon>screen_rotation</mat-icon>
      </button>
    </div>
    <span class="height-width" *ngIf="screenSizeButtonToggleValue.icon=='devices_other'">
      <mat-form-field class="device-form-field-margin">
        <mat-label>Device Name</mat-label>
        <mat-select [(ngModel)]="selectedDevice" (ngModelChange)="deviceSelectChange()">
          <mat-select-trigger>
            <mat-icon [inline]="true" class="device-icon">{{selectedDevice.icon}}</mat-icon>
            <span>{{selectedDevice.name}}</span>
          </mat-select-trigger>
          <mat-option [value]="item" *ngFor="let item of deviceArray; trackBy: trackByFn">
            <mat-icon>{{item.icon}}</mat-icon> <span>{{item.name}}</span>
            <div></div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" placeholder="width" [disabled]="selectedDevice.name!='Responive'"
          [(ngModel)]="width">
        <mat-label>Height</mat-label>
      </mat-form-field>
      <mat-icon class="close-icon" [inline]="true">close</mat-icon>
      <mat-form-field>
        <input matInput type="number" placeholder="height" [disabled]="selectedDevice.name!='Responive'"
          [(ngModel)]="height">
        <mat-label>Width</mat-label>
      </mat-form-field>
    </span>
    <br>
  </span>

  <div [style.height.px]="height" [style.width.px]="width" class="frame"
    [style.--right]="this.selectedDevice == deviceArray[0]?this.screenSizeButtonToggleValue?.landscape:this.selectedDevice?.landscape"
    [class.smartphone_portrait]="screenOrientation=='portrait' && (deviceType=='phone_iphone'||deviceType=='phone_android')"
    [class.smartphone_landscape]="screenOrientation=='landscape' && (deviceType=='phone_iphone'||deviceType=='phone_android')"
    [class.tablet_portrait]="screenOrientation=='portrait' && (deviceType=='tablet_mac'||deviceType=='tablet_android')"
    [class.tablet_landscape]="screenOrientation=='landscape' && (deviceType=='tablet_mac'||deviceType=='tablet_android')"
    [class.laptop]="deviceType=='laptop_mac'||deviceType=='laptop_windows' ||deviceType=='laptop_mac_big'">
    <!-- <div class="content" *ngIf="data?.parentType != 'form'">
      <iframe frameBorder="0" [height]="height" [width]="width" style="border: none" [src]="safeUrl"></iframe>
    </div> -->
    <div class="content overflow" *ngIf="data?.parentType != 'form'">
        <app-viewer-canvas [url]="data?.url" [type]="'internal'"> </app-viewer-canvas>
    </div>
    <div class="content" *ngIf="data?.parentType === 'form'">
      <app-form-viewer-canvas [type]="'internal'"></app-form-viewer-canvas>
    </div>
    <div [class.keyboard]="deviceType=='laptop_mac'"></div>
    <div [class.keyboard_small]="deviceType=='laptop_windows'" ></div>
    <div [class.keyboard_big]="deviceType=='laptop_mac_big'"></div>
    <div [class.topkeyboard]="deviceType=='laptop_mac'||deviceType=='laptop_windows' ||deviceType=='laptop_mac_big'"></div>
  </div>
</div>
