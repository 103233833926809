import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ExpressionManager } from 'src/app/shared/built-in-expression/ExpressionManager';


@Component({
    selector: 'app-expressions-selection',
    templateUrl: './expressions-selection.component.html',
    styleUrls: ['./expressions-selection.component.scss'],
    standalone: false
})
export class ExpressionsSelectionComponent implements OnInit {

  @Input() widgetExpressionConfig: any
  @Input() widgetType: any
  @Output() expressionSelected = new EventEmitter<any>();

  expListingACForm = new UntypedFormControl();

  selectedExpression: any;
  // expressionConfig: any = {}
  expressions: any[]
  filteredExpressions: any
  isExpressionSelected: boolean = false

  // expressionManager = new ExpressionManager()
  constructor(private expressionManager: ExpressionManager) { }

  ngOnInit(): void {
    // console.log("expressionData", this.expressionData)
    this.expressions = this.expressionManager.getAllExpressionDetails(this.widgetType)
    console.log("expressions list", this.expressions)
    // this.expressions.forEach(exp => {
    //   console.log(exp.getExpressionDetail())
    // })
    console.log("widgetExpressionConfig", this.widgetExpressionConfig)
    if(this.widgetExpressionConfig){
      this.selectedExpression = this.expressions.find(exp => exp.id == this.widgetExpressionConfig.id)
      console.log("saved expression", this.selectedExpression)
      this.isExpressionSelected = true

      this.emitExpressionData()
      this.expListingACForm.setValue(this.selectedExpression);
    } else this.widgetExpressionConfig = {};

    if(!this.widgetExpressionConfig.hasOwnProperty("onCreate")) this.widgetExpressionConfig.onCreate = true;
    if(!this.widgetExpressionConfig.hasOwnProperty("onEdit")) this.widgetExpressionConfig.onEdit = true;

    this.filteredExpressions = this.expListingACForm.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.id)),
      map(value => this._expressionsFilter(value))
    );
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  private _expressionsFilter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.expressions.filter(exp => exp.name.toLowerCase().includes(filterValue));
  }

  displayFunction(exp) {
    if(!exp) return undefined;
    return exp.name;
  }

  setFilteredExpressions(){
    this.filteredExpressions = this.expListingACForm
      .valueChanges
      .pipe(startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._expressionsFilter(value))
    );
  }

  // -------------------------------------------------------------------

  expressionSelectionHandler(exp){
    this.selectedExpression = exp
    console.log("selected expression", exp)
    this.isExpressionSelected = true

    // emit selection if selectedExpression id present
    if(this.selectedExpression.id){
      this.widgetExpressionConfig.id = this.selectedExpression.id;
      this.emitExpressionData()
    }
  }

  clearSelection(){
    this.isExpressionSelected = false
    this.expListingACForm.patchValue('');
    this.expListingACForm.disable();
    this.expListingACForm.enable();
    this.setFilteredExpressions();
  }

  expressionConfigReceived(data){
    this.widgetExpressionConfig = data
    this.emitExpressionData()
  }

  emitExpressionData(){
    console.log("this.widgetExpressionConfig", this.widgetExpressionConfig)
    this.expressionSelected.emit(this.widgetExpressionConfig);
  }
}
