import { BaseValidator } from "./BaseValidator";

export class urlValidator extends BaseValidator{
    id = "url"
    icon =  ""
    name = "URL"
    config = {
        inputParams: {
            params: ['customErrorMessage'],
            customErrorMessage: {
                value : "Please Enter a Valid URL",
                displayName: "Custom Error Message",
                type: 'string'
            }
        }
    }

    public validate(val:string){
        const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*\/?(\?.*)?(#.*)?$/;
        return urlRegex.test(val);
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}