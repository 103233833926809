import { ThemeService } from './../services/theme.service';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    standalone: false
})
export class SnackbarComponent implements OnInit {
  customCollapsedHeight: string = '100px';
  customExpandedHeight: string = '120px';


  private _isError: boolean = false;
  get isError() { return this._isError; }
  set isError(v) { this._isError = v };

  private _panelOpenState: boolean = true;
  get panelOpenState() { return this._panelOpenState; }
  set panelOpenState(v) { this._panelOpenState = v }

  get snackBarRef() { return this._snackBarRef; }

  get data() { return this._data; }

  get themeService() { return this._themeService };

  constructor(@Inject(MAT_SNACK_BAR_DATA) private _data: any, private _snackBarRef: MatSnackBarRef<SnackbarComponent>, private _themeService: ThemeService) {
    this.data?.isError ? this.isError = true : this.isError = false;
  }

  ngOnInit(): void {
  }

  HTMLDetailsElementToogle($event) {
    $event.stopPropagation();
  }

  isDarkTheme() {
    let isDarkTheme = false;
    if (this.themeService.isDarkTheme === true) {
      isDarkTheme = true;
    }
    return isDarkTheme;
  }

}
