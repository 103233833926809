import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';

import { MagnifierDialogComponent } from './magnifier-dialog.component';

@NgModule({
  declarations: [
    MagnifierDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: []
})
export class MagnifierModule { } 