import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'box-filter-config',
    templateUrl: './app-filter-config.component.html',
    styleUrls: ['./app-filter-config.component.scss'],
    standalone: false
})
export class AppFilterConfigComponent implements OnInit {

  @Input() widgetMeta: any;
  @Output() filterChanged = new EventEmitter<any>();

  filterType: any;
  listCtrl = new UntypedFormControl();
  resources: any
  lists: any = []
  separatorKeysCodes: number[] = [ENTER, COMMA];

  filteredList: any;
  constructor(
   private http:  HttpClient,
   private sanitizer: DomSanitizer,
   public cs: ConnectionService,
  ) { }

  async ngOnInit() {
    console.log("widgetMeta", this.widgetMeta);
    this.filterType = this.widgetMeta.config?.predefinedFilters?.filterType; 
    if(this.filterType != "any") await this.getList(this.filterType);
    this.filteredList = this.listCtrl.valueChanges.pipe(
      startWith(''),
      map(value => {
        //the whole attribute object comes here as value
        return typeof value === 'string' ? value : value.name
      }),
      map(value => this.listFilter(value))
    );
    this.filterChanged.emit(this.widgetMeta);
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  async getList(type){
    let res:any;
    switch (type) {
      case "categories":
        res = await this.cs.getBoxCategory();
        break;
      
      case "apps":
        res = await this.cs.getBoxes();
        break;
    
      default:
        break;
    }
    let resources = res?.data || [];
    this.resources = [];
    for (let index = 0; index < resources.length; index++) {
      const element = resources[index];
      let obj = { __id: element.__id, name: element.name};
      this.resources.push(obj);
    }
    this.lists = this.widgetMeta.config?.predefinedFilters?.value || [];
    console.log("this.resources", this.resources);
  }

  selectedList(e){
    console.log("selectedList", e);
    this.lists.push(e.option.value);
    console.log("this.lists", this.lists);
    this.widgetMeta.config.predefinedFilters.value = this.lists;
    this.listCtrl.setValue('');
    this.filterChanged.emit(this.widgetMeta);
  }

  removeList(list:any){
    console.log("removeList", list)
    const index = this.lists.indexOf(list);
    if (index >= 0) {
      this.lists.splice(index, 1);
    }
    this.filterChanged.emit(this.widgetMeta);
  }

  addList(event: MatChipInputEvent): void {
    console.log("add fired", event)
    // if (event.value) {
    //   this.searchAttributes.push(event.value);
    // }

    // Clear the input value
    this.filterChanged.emit(this.widgetMeta);
    event.chipInput!.clear();
    this.listCtrl.setValue('');
  }

  private listFilter(value: any): string[] {
    console.log("value from search attribute input", value)
    if (!this.resources) return []
    const filterValue = value.toLowerCase();
    return this.resources.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  async onTypeChange(){
    console.log("widgetMeta filterType", this.filterType);
    this.widgetMeta.config.predefinedFilters.filterType = this.filterType;
    if(this.filterType != "any") await this.getList(this.filterType);
    this.lists = [];
    this.filterChanged.emit(this.widgetMeta);
  }

}

