import { Widget } from "./baseWidget";
import { AvailableOptions } from './optionsConfig'
import { TextFormatting } from "./textFormat";

export class Select extends Widget {
  type = 'select';
  gridX = 6
  minGridX = 3
  config = {
    props: [
      "availableOptions",
      'floatLabel',
      "value",
      'appearance',
    ],
    availableOptions: JSON.parse(JSON.stringify(AvailableOptions)),
    value: {
      displayName: "",
      value: '',
      resetValue: '',
      type: 'text',
    },
    floatLabel: {
      displayName: 'Floating label',
      type: 'radio',
      value: 'auto',
      valueOptions: ['auto','always']
    },
    appearance: {
      displayName: " input appearance",
      value: "standard",
      type: "select"
    },
  };
  textFormat = undefined

  constructor(id: number, name: string) {
    super(id, name)
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    
    this.attachCommonConfig(this)
    this.config['label'].value = "select an option"
    this.config['title'].value = "Select an option"
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return true
  }

  public setOptions(values){
    console.log("select: setOptions()", this.config, "values", values)
    if(!values){
      this.config.value.value = '';
      // this.config.availableOptions.staticOptions.forEach(opt => {
      //   if(opt.default) opt.default = false
      // });
    } else if (typeof values == 'string'){ 
      this.config.value.value = values
    }
    return values;
  }
}
