import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { BoxService } from '../../services/box.service';
import { WidgetRegistry } from 'src/app/bloom/models/WidgetRegistry';

@Component({
    selector: 'object-parameter-inputs',
    templateUrl: './object-parameter-inputs.component.html',
    styleUrls: ['./object-parameter-inputs.component.scss'],
    standalone: false
})
export class ObjectInputsComponent implements OnInit {
 

  constructor(
    public con: ConnectionService,
    private dialog: MatDialog,
    public boxService: BoxService,
    private spin: SpinnerService
  ) { }

  inputMap: any = {object: ""};

  @Input() object: any;
  @Input() existingInputs:any;
  @Output() "inputParameter" = new EventEmitter<any>();
  // @Output() "enableExecute" = new EventEmitter<any>();

  async ngOnInit() {
    console.log("[PARAMETER-INTPUTS] init action", this.object);
    this.spin.hide();
  }

  objectInputReceived(){
    this.inputParameter.emit(this.inputMap)
  }

  attributesReceived(event){
    Object.assign(this.inputMap, event);
    this.inputParameter.emit(this.inputMap)
  }

}
