import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { Router, RouterModule, Routes } from '@angular/router';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { ListingComponent } from './listing/listing.component';
import { FlowRoutingModule } from './flow-routing.module';
import { SetupComponent } from './builder/setup/setup.component';
import { AppListingModule } from './../shared/app-listing/app-listing.module';
import { ClientPlatformModule } from '../client-platform/common-components/client-platform.module';
// import { ConnectionDialogComponent } from '../shared/dialog/connection-dialog/connection.dialog';
// import { TriggerWebhookComponent } from './builder/trigger-webhook/trigger-webhook.component';
import { ConnectionSelectionModule } from '../shared/connection-selection/connection-selection.module';
import { AppSelectionModule } from '../shared/app-selection/app-selection.module';
import { AppsPopupModule } from '../shared/apps-popup/apps.popup.module';
import { ActionSelectionModule } from '../shared/action-selection/action-selection.module';
import { MappingSetupModule } from '../shared/mapping-setup/mapping-setup.module';
// import { TestWebhookComponent } from './builder/trigger-webhook/test-webhook/test-webhook.component';
import { workspaceModule } from '../shared/workspace/workspace.module';
import { TriggerSchedulerModule } from '../shared/trigger/trigger-scheduler/trigger-scheduler.module';
import { TriggerWebhookModule } from '../shared/trigger/trigger-webhook/trigger-webhook.module';
import { StepActionModule } from '../shared/flow-step/step-action/step-action.module';
import { StepControlModule } from '../shared/flow-step/step-control/step-control.module';
import { StepTitleBarComponent } from './builder/common/step-title-bar/step-title-bar.component';
import { ListPanelModule } from '../bloom/specialized-panels/list-panel/list-panel.module';
import { DisplayBannerModule } from '../shared/display-banner/display-banner.module';
import { FlowCollaborationComponent } from './flow-collaboration/flow-collaboration.component';
import { CommonMemberModule } from '../shared/common-member/common-member.module';
import { ResourceCollaborationModule } from '../shared/resource-collaboration/resource-collaboration.module';
import { FlowInstanceModule } from './flow-instance/flow-instance.module';
import { DeleteDialogModule } from '../shared/delete-dialog/delete-dialog.module';
import { BuilderToolbarModule } from '../bloom/builder/builder-toolbar/builder-toolbar.module';
import { FlowPublishDialogModule } from './builder/flow-publish-dialog/flow-publish-dialog.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FlowBuilderToolsModule } from './builder/flow-builder-tools/flow-builder-tools.module';
import { FlowExecutionComponent } from './builder/common/flow-execution/flow-execution.component';
import { WebSocketService } from '../core/services/SocketService';
import { JsonViewModule } from 'nxt-json-view';
import { TitlecasePipe } from '../core/pipes/Titlecase.pipe';
import { StepProcessorModule } from '../shared/flow-step/step-processor/step-processor.module';
import { FlowConfigurationComponent } from './builder/common/flow-configuration/flow-configuration.component';
import { FlowNameConfigComponent } from './builder/common/flow-name-config/flow-name-config.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FlowTriggerConfigComponent } from './builder/common/flow-trigger-config/flow-trigger-config.component';
import { TriggerEventModule } from '../shared/trigger/trigger-event/trigger-event.module';
import { CreationComponent } from './builder/creation/creation.component';
import { SpecializedPanelsModule } from '../bloom/specialized-panels/specialized-panels.module';
import { FlowTemplateComponent } from './builder/flow-template/flow-template.component';
import { CreateFlowDetailComponent } from './builder/create-flow-detail/create-flow-detail.component';
import {MatChipsModule} from '@angular/material/chips';
import { FlowHomeComponent } from './home/home.component';

@NgModule({
    declarations: [
        ListingComponent,
        SetupComponent,
        StepTitleBarComponent,
        FlowCollaborationComponent,
        FlowExecutionComponent,
        FlowConfigurationComponent,
        TitlecasePipe,
        FlowNameConfigComponent,
        FlowTriggerConfigComponent,
        CreationComponent,
        FlowTemplateComponent,
        CreateFlowDetailComponent,
        FlowHomeComponent
        // TriggerWebhookComponent,
        // TestWebhookComponent,
    ],
    imports: [
        workspaceModule,
        CommonModule,
        SharedModule,
        RouterModule,
        SpinnerModule,
        FlowRoutingModule,
        FlowInstanceModule,
        AppListingModule,
        ClientPlatformModule,
        ConnectionSelectionModule,
        AppSelectionModule,
        AppsPopupModule,
        ActionSelectionModule,
        MappingSetupModule,
        TriggerSchedulerModule,
        TriggerWebhookModule,
        StepActionModule,
        StepControlModule,
        ListPanelModule,
        DisplayBannerModule,
        CommonMemberModule,
        ResourceCollaborationModule,
        DeleteDialogModule,
        BuilderToolbarModule,
        FlowPublishDialogModule,
        FlowBuilderToolsModule,
        JsonViewModule,
        StepProcessorModule,
        MatFormFieldModule,
        TriggerEventModule,
        SpecializedPanelsModule,
        MatChipsModule
        // MatSlideToggleModule
    ],
    providers: [WebSocketService],
    exports: [
        ListingComponent
    ]
})
export class FlowModule { }
