<div class="outerCover" *ngIf="builderMode" [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick()"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    <!-- <mat-form-field>
      <mcc-timer-picker mccConnectedTimerPicker [mccConnectedTimerPickerOrigin]="inputTrigger"></mcc-timer-picker>
      <input matInput autocomplete="off" mccTimerPickerOrigin
        placeholder="{{ widgetMeta.config.placeholder.value }}" #inputTrigger="mccTimerPickerOrigin"
        formControlName="timer" />
      <button mat-icon-button matSuffix>
        <mat-icon>schedule</mat-icon>
      </button>
    </mat-form-field> -->
    <!-- <div class="ngx-timepicker-field-example">
      <ngx-timepicker-field [defaultTime]="'09:00 am'" [disabled]="true"></ngx-timepicker-field>
    </div> -->

    <!-- <mat-form-field [appearance]="widgetMeta.config.appearance?.value || 'outline'">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input type="time" disabled matInput [name]="widgetMeta.name"/>
    </mat-form-field> -->
    <mat-form-field [appearance]="widgetMeta.config.appearance?.value || 'outline'">
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
      <input matInput type="time" [required]="widgetMeta?.config?.required?.value" [name]="widgetMeta.name">
  </mat-form-field>
  </div>
</div>


<div class="outerCover" *ngIf="!builderMode && !widgetMeta.config?.hidden?.value" (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly?.value">
    <!-- <form (ngSubmit)="userInputDetected(form)" [formGroup]="form">
      <mat-form-field *ngIf="!widgetMeta.config.viewOnly?.value">
        <mcc-timer-picker mccConnectedTimerPicker [mccConnectedTimerPickerOrigin]="inputTrigger"></mcc-timer-picker>
        <input matInput autocomplete="off" placeholder="{{ widgetMeta.config.placeholder.value }}"
          formControlName="timer" />
        <button mat-icon-button matSuffix mccTimerPickerOrigin #inputTrigger="mccTimerPickerOrigin">
          <mat-icon>schedule</mat-icon>
        </button>
      </mat-form-field>
    </form> -->
    <!-- <div class="ngx-timepicker-field-example">
      <ngx-timepicker-field [defaultTime]="'09:00 am'" (timeChanged)="userInputDetected($event)"></ngx-timepicker-field>
    </div> -->

    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <form action="#">
      <mat-form-field [appearance]="widgetMeta.config.appearance?.value || 'outline'">
        <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
        <input type="time"
          onfocus="this.showPicker()"
          (ngModelChange)="widgetMeta.config?.value.value = $event"
          [required]="widgetMeta?.config?.required?.value" 
          [ngModel]="widgetMeta.config?.value?.value"
          (change)="userInputDetected($event)" matInput [name]="widgetMeta.name"
          #timeInput="ngModel"
        />
        <!-- Error & Note handling -->
        <mat-error *ngIf="timeInput.invalid && timeInput.hasError('required')">Time is required</mat-error>
        <mat-hint *ngIf="widgetMeta.note">
          <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
          <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
        </mat-hint>
      </mat-form-field>
        <!-- (ngModelChange)="widgetMeta.config?.value.value = $event"  -->
    </form>

  </div>
  <div *ngIf="widgetMeta.config.viewOnly?.value"
    class="innerCover label-z-index content"
    [ngStyle]="styles"
  >
    {{ this.widgetMeta?.config?.value?.value }}
  </div>
</div>
