import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { ConnectionService } from './modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { filter, map, mergeMap, pairwise } from 'rxjs/operators';
import { PageService } from './bloom/services/page-service.service';
import { SeoService } from './core/services/SeoService';
import { BoxCache } from './core/boxInstances/BoxCache';
import { TransferState, makeStateKey } from '@angular/core';
import { UIConfigService } from './shared/services/UIConfigService';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'AppiWorks';
  routerSubscription: any;
  isBrowser: any;

  constructor(
    public themeService: ThemeService,
    private translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    public orgService: ConnectionService,
    public pageService: PageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    public seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    public uic: UIConfigService,
    private transferState: TransferState
  ) {
    translate.addLangs(['en', 'fr']);
    this.isBrowser = isPlatformBrowser(platformId);
    // if(!this.isBrowser) return;

    this.routerSubscription = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urls = event.urlAfterRedirects.split("?");
        if (orgService.userProfile?._id) {
          const trackMap: any = { type: "page" };
          trackMap.path = urls.length > 1 ? urls[1] : undefined;
        }
      }
    });

    router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        console.log('previous url', events[0].urlAfterRedirects);
        console.log('current url', events[1].urlAfterRedirects);
        pageService.previousUrl = events[0].urlAfterRedirects;
      });

    console.log("Hydration Check: TransferState keys");
  }

  ngOnDestroy() {
    // Unsubscribe from router events to prevent memory leaks
    this.routerSubscription?.unsubscribe();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      console.log("Hydration Check: ");
    }, 1000); // ✅ Delay check
  }

  ngOnInit() {
    if(this.isBrowser){
      let url = window.location.href;
      this.seoService.updateTitleAndMeta();
      this.loadBoxData();

      //check if the url is in the prerendered files structure
      if(window?.location?.href?.includes("/p/")) {
        // Only mark as prerendered if the URL is in the prerendered files structure
        // This checks if the current URL path matches the pattern of prerendered pages
        const urlPath = new URL(window.location.href).pathname;
        const pathSegments = urlPath.split('/');

        // Check if the URL follows the pattern /p/{code}
        if (pathSegments.length >= 3 && pathSegments[1] === 'p' && pathSegments[2]) {
          // Extract the code from the URL
          const code = pathSegments[2];

          // Get valid prerendered paths by checking the directory structure
          // This dynamically determines which paths are available without hardcoding
          const validPrerenderedPaths = this.getValidPrerenderedPaths();

          if (validPrerenderedPaths.includes(code)) {
            this.pageService.isPrerendered = true;
            console.log(`This URL was pre-rendered (verified path /p/${code} exists in prerendered files).`);

            // Reset isPrerendered flag after 30 seconds to enable client-side behavior
            // setTimeout(() => {
            //   this.pageService.isPrerendered = false;
            //   console.log('Switched from pre-rendered to client-side mode after timeout');
            // }, 30000);
          } else {
            console.log(`This URL follows prerendered pattern but no matching prerendered file found for /p/${code}.`);
          }
        }
      }
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(data => {
      console.log("[data] ac", data)
      this.uic.subProduct = data['subProduct']
    });
  }

  private async loadBoxData() {
      await BoxCache.loadBoxes();
  }

  private getValidPrerenderedPaths(): string[] {
    // In a browser environment, we can't directly access the file system
    // So we need to determine this based on what we know about the deployment

    // This approach dynamically determines prerendered paths by checking what's available
    // in the dist/browser/p directory at runtime

    try {
      // In production, we can fetch this information from a generated JSON file
      // that's created during the build process
        // Try to fetch from a configuration file that lists all prerendered paths
        // This file would be generated during the build process
        const prerenderedPathsConfig = (window as any).__PRERENDERED_PATHS__;
        if (prerenderedPathsConfig && Array.isArray(prerenderedPathsConfig)) {
          return prerenderedPathsConfig;
        }

        // Fallback: try to load from assets if available
        // This could be implemented by having the build process generate a JSON file
        // with the list of prerendered paths
        try {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', '/assets/prerendered-paths.json', false); // Synchronous request
          xhr.send(null);
          if (xhr.status === 200) {
            const paths = JSON.parse(xhr.responseText);
            if (Array.isArray(paths)) {
              return paths;
            }
          }
        } catch (e) {
          console.warn('Failed to load prerendered paths from assets:', e);
        }

      // If we can't get the paths dynamically, use what we know exists
      // This is determined by examining the dist/browser/p directory structure
      // We can see from the directory listing that 'puffy-religion' exists
      const knownPaths = [];
      return knownPaths;
    } catch (error) {
      console.error('Error determining prerendered paths:', error);
      // Fallback to known paths if there's an error
      return [];
    }
  }
}
