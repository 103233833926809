import { Injectable} from '@angular/core';
import { WidgetManager } from '../models/WidgetManager';
import { WidgetRegistry } from '../models/WidgetRegistry';
import { TextFormatting } from '../models/widgetClasses/textFormat';


@Injectable({
  providedIn: 'root'
})
export class WidgetMetaTransformationService {

  // persistenceProperties: any; 

  constructor() { 
    this.populateStaticDataStructures()
  }

  standardProperties: string[] = [] // exhaustive list of all properties of all widgets that goes inside config.props (collected from scanning through all widgets, one time on load of application)
  toPerisistProperties = {  // keeping all properties that has multiple dynamic fields to save in it's config.
    props: []
  }  


  /**
   * scans through all widgets and generates standardProperties and toPersistProperties
   * triggered once during application init
   * @returns 
   */
  populateStaticDataStructures () {
    let allWidgetTypes = WidgetRegistry.getAllWidgetTypes()
    console.log("all widget types", allWidgetTypes)
    for (let i = 0; i < allWidgetTypes.length; i++) {
      const widType = allWidgetTypes[i];
      
      let wid = WidgetRegistry.getWidget(widType)
      // console.log("wid", wid)
      wid.config.props.forEach(prop => {
        // if prop is not already included in standard props array, add it now
        if (!this.standardProperties.includes(prop)) this.standardProperties.push(prop)

        // check for toPersist properties
        if (wid.config[prop].toPersist?.length) {
          if (!this.toPerisistProperties.props.includes(prop)) {
            this.toPerisistProperties.props.push(prop)
            this.toPerisistProperties[prop] = wid.config[prop]
          }
        }
      })
    }
    console.log("persistence properties", this.toPerisistProperties)
    console.log("standard properties", this.standardProperties)
  }

  /**
   * given a meta in minified form, it converts into a meta object same as the widget class instance
   * @param decompressedMeta 
   * @returns transformed widget meta as a widget class instance
   */
  decompressWidgetMeta(compressedMeta: any) {
    // console.log("came for decompress", JSON.parse(JSON.stringify(compressedMeta || "")))
    if (!compressedMeta) return compressedMeta
    if (compressedMeta?.config) return compressedMeta //returning because already decompressed
    let widget = WidgetManager.getWidget(compressedMeta.type || 'label', compressedMeta.id, compressedMeta.name)
    if (!widget) throw new Error (`widget type ${compressedMeta.type} not found`)
    
    // copy top level properties as it is
    widget.gridX = compressedMeta['gridX'];
    widget.name = compressedMeta['name'];
    widget.id = compressedMeta['id'];
    widget.type = compressedMeta['type'];
    widget.validatorConfig = compressedMeta['validatorConfig'];
    widget.actionConfig = compressedMeta['actionConfig'];

    // copy config properties into newly created widget meta instance
    widget.config?.props?.forEach((prop: any) => {
      // standard props
      if (this.isStandardProp(prop)) {
        if (compressedMeta.hasOwnProperty(prop) && compressedMeta[prop] !== null && compressedMeta[prop] !== undefined) {
          
          // if composite property, copy all keys except isComposite, because isComposite is a marker added during compression process (not a widget property)
          if (compressedMeta[prop]?.['isComposite']) {
            for (const key in compressedMeta[prop]) {
              if (key !== "isComposite") {
                widget.config[prop][key] = compressedMeta[prop][key];
              }
            }
          } else {
            widget.config[prop].value = compressedMeta[prop]
          }
        }
      }
    })

    // copy text format properties
    if (widget.textFormat) {      
      Object.keys(widget.textFormat).forEach(prop => {
        if (compressedMeta.hasOwnProperty(prop) && compressedMeta[prop] !== null && compressedMeta[prop] !== undefined) {
          widget.textFormat[prop].value = compressedMeta[prop]
        }
      })
    }
    // console.log("widget after decompression", widget)
    return widget
  }

  /**
   * given an object similar to widget class instance, it converts into a simple object having all properties in the top level
   * @param widgetMeta 
   * @returns 
   */
  compressWidgetMeta(widgetMeta: any) {
    // console.log("compress", widgetMeta)
    if (!widgetMeta?.config) return widgetMeta  // already compressed
    let compressedMeta = {}

    for (let i = 0; i < widgetMeta.config.props.length; i++) {
      const prop = widgetMeta.config.props[i];

      // for value, always keep it, irrespective of whether it has changed or not
      if (prop === 'value') {
        compressedMeta[prop] = widgetMeta.config[prop].value;
        continue;
      }
    
      // store property value if it is not undefined and not null, consider empty string and false as valid values
      if (widgetMeta.config[prop].hasOwnProperty('value') && (widgetMeta.config[prop].value == null || widgetMeta.config[prop].value == undefined)) continue;
      // if (widgetMeta.config[prop].defaultValue && widgetMeta.config[prop].value === widgetMeta.config[prop].defaultValue) continue;
    
      if (widgetMeta.config[prop].toPersist?.length) {
        compressedMeta[prop] = this.compressPersistenceProperty(widgetMeta, prop)

      } else if (this.isPersistenceProperty(prop)) {  
        // this block is for backward compatibility. 
        // When old widget meta is being migrated that does not have toPersist array in the property configuration, toPersist is checked here

        widgetMeta.config[prop].toPersist = this.toPerisistProperties[prop].toPersist
        compressedMeta[prop] = this.compressPersistenceProperty(widgetMeta, prop)

      } else {
        compressedMeta[prop] = widgetMeta.config[prop].value;
      }
    }

    Object.keys(widgetMeta.textFormat || {}).forEach(prop => {
      let textFormat = TextFormatting.textFormatting()
      
      // if it is changed from defaultValue, then save
      if (widgetMeta.textFormat[prop].value !== textFormat[prop]?.value) {
        compressedMeta[prop] = widgetMeta.textFormat[prop].value
      }
    })

    compressedMeta['gridX'] = widgetMeta.gridX
    compressedMeta['name'] = widgetMeta.name
    compressedMeta['id'] = widgetMeta.id
    compressedMeta['type'] = widgetMeta.type
    compressedMeta['validatorConfig'] = widgetMeta.validatorConfig
    compressedMeta['actionConfig'] = widgetMeta.actionConfig
  
    console.log("compressed meta", compressedMeta)

    // console.log("compressed meta", compressedMeta)
    return compressedMeta
  }


  /**
   * compress widget property which is marked with toPersist
   * multiple sub properties are packed into an object and returned
   * @param widgetMeta 
   * @param prop 
   */
  compressPersistenceProperty (widgetMeta: any, prop: any) {
    // 'isComposite' flag indicates this object should NOT be treated as property value, rather need to be expanded during decompression process 
    let valObj: any = { isComposite: true } 

    // if toPersist array has only element "*", then persist all keys
    if (widgetMeta.config[prop].toPersist[0] == '*') {
      Object.keys(widgetMeta.config[prop]).forEach(key => {
        valObj[key] = widgetMeta.config[prop][key]
      })
    } else {
      for (let j = 0; j < widgetMeta.config[prop].toPersist.length; j++) {
        const key = widgetMeta.config[prop].toPersist[j];
        valObj[key] = widgetMeta.config[prop][key]
      }
    }
    return valObj
  }

  
  /**
   * checks if a given property is a persistence property, i.e., multiple parts in it's configuration (other than just value) needs to be saved
   * @param prop 
   * @returns true if given property is a persistence property, false otherwise
   */
  isPersistenceProperty (prop) {
    // console.log("persistence properties", this.toPerisistProperties)
    if (this.toPerisistProperties.props.includes(prop) && this.toPerisistProperties[prop]?.toPersist?.length) {
      return true
    } else return false
  }


  /**
   * Determines if a given property is related to text formatting.
   * @param prop - The property to check.
   * @returns true if the property is a text format property such as 'color', 'underline', 'bold', 'fontFamily', 'fontSize', 'italic', or 'class'; false otherwise.
   */

  isTextFormatProperty(prop: any) {
    if (['color', 'underline', 'bold', 'fontFamily', 'fontSize', 'italic', 'class'].includes(prop)) return true
    else return false
  }

  /**
   * checks if a given property is a standard property having single dynamic part, available at config[prop].value
   * @param prop 
   */
  isStandardProp (prop: any) {
    if (this.standardProperties.includes(prop)) return true
    else return false
  }

}
