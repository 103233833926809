<div>

  <div *ngIf="step.control_type">

    <button matTooltip="Change Control Type" fxLayout="row" fxLayoutAlign="start center"  mat-button [matMenuTriggerFor]="controltypemenu">
      <mat-icon aria-hidden="false">{{controlTypeMap[step.control_type].icon}}</mat-icon> 
      <span> {{controlTypeMap[step.control_type].name}}</span>
    </button>
    
    <mat-menu #controltypemenu="matMenu" >
      <div mat-menu-item *ngFor="let control of controlTypes; let i = index; trackBy: trackByFn">

        <div (click)="setControl(control)"  fxLayout="row" fxLayoutAlign="center center" >
          <mat-icon aria-hidden="false">{{control.icon}}</mat-icon> <span>{{control.name}}</span>
        </div>
      </div>
    </mat-menu>
  </div>

  <div class="" fxLayout="column" *ngIf="step.control.conditions.length" >
    Conditions
  </div>
  <div class="" fxLayout="column" *ngIf="step.control.conditions.length" >
    <div *ngFor="let condition of step.control.conditions; let i = index; trackBy: trackByFn" >
    <mat-card appearance="outlined" fxLayout="row" style="padding: 10px;">
      <div fxFlex="100">
        <div fxLayout="column"  fxLayoutAlign="end end">
          <button mat-icon-button color="warn" (click)="removeFilter(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      <div *ngIf="selectFilterAttributeForm.get('form0')" 
        fxLayout="column" fxLayoutAlign="start center">
        <form [formGroup]="selectFilterAttributeForm" class="full-width">
        <mat-form-field class="full-width paddingForm" style="margin-bottom: -19px;">
          <mat-label>Field</mat-label>
          <input
            #boxInput
            type="text"
            aria-label="field autocomplete"
            matInput
            (focus)="setFilterList(i)"
            formControlName="{{'form' + i}}"
            [matAutocomplete]="auto2"
            (input)="onInputChange($event.target.value, condition, i)"
          >
          <button *ngIf="boxInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectFilterAttributeForm.get('form0').patchValue('')">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFn" 
          (optionSelected)="filterAttrSelected($event, condition, i); ">
            <mat-option *ngFor="let attr of attributesForFilter | async; trackBy: trackByFn" [value]="attr">
              <span>{{attr.__id}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="start center">
        <mat-form-field class="full-width">
          <mat-label>Operator</mat-label>
          <mat-select [(value)]="condition.operator">
            <mat-option [value]="op.value" *ngFor="let op of availableOperators; trackBy: trackByFn">{{ op.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayoutAlign="start center" fxLayout="column">
        <button
            matTooltip="Condition type"
            mat-button
            fxFlexFill
            [matMenuTriggerFor]="menuFilterTypes"
          >
            <div style="margin-top: 30px;" fxLayout fxLayoutAlign="start center"
             class="smallText" fxFlexFill>
              <mat-icon style="opacity: 70%;" >{{conditionTypeLogoMap[condition.conditionType].logo}}</mat-icon>
              <span>{{conditionTypeLogoMap[condition.conditionType].name}}</span>
              <mat-icon style="opacity: 70%;">arrow_drop_down</mat-icon>
            </div>
          </button>
        <mat-menu #menuFilterTypes="matMenu">
          <div *ngFor="let op of conditionTypes; trackBy: trackByFn">
            <button mat-menu-item (click)="onSelectConditionType(condition, op, i)">
              <mat-icon>{{ op.icon }}</mat-icon> <span>{{ op.name }}</span>
            </button>
          </div>
        </mat-menu>
      </div>

      <div fxLayout="column" fxLayoutAlign="start center" >
          <div [ngSwitch]="condition.conditionType">
            <div *ngSwitchCase="'custom'">
              <mat-form-field class="full-width">
                <mat-label>Value</mat-label>
                <input matInput #filterValueInput [(ngModel)]="condition.value">
              </mat-form-field>
            </div>
            <div *ngSwitchCase="'step_result'">
              <!-- fxFlex="25" fxLayout="column" fxLayoutAlign="center center" -->
              <div fxLayout.xs="row" *ngIf="selectStepResultsForm.get('form0')" >
                <form [formGroup]="selectStepResultsForm" class="full-width">
                <mat-form-field class="full-width paddingForm" style="margin-bottom: -19px;">
                  <mat-label>Field</mat-label>
                  <input
                    #boxInput
                    type="text"
                    aria-label="field autocomplete"
                    matInput
                    (focus)="setStepResultList(i)"
                    formControlName="{{'form' + i}}"
                    [matAutocomplete]="autoresult"
                  >
                  <button *ngIf="boxInput.value" matSuffix mat-icon-button aria-label="Clear"
                   (click)="selectStepResultsForm.get('form0').patchValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-autocomplete autoActiveFirstOption #autoresult="matAutocomplete" 
                  [displayWith]="displayFn" 
                  (optionSelected)="stepResultSelected($event, condition, i); ">
                    <mat-option *ngFor="let attr of attributesForFilter | async; trackBy: trackByFn" [value]="attr">
                      <span>{{attr.__id}}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                </form>
              </div>
            </div>
          </div>
      </div>
    </div>
    </mat-card >
  </div>
  <div  fxLayout="row" fxLayoutAlign="start center">
    <button  mat-stroked-button color="primary" (click)="addConditionItem()">
      <mat-icon>add</mat-icon> Add Condition
    </button>
  </div>

  <!-- <div fxLayout="row" fxLayoutAlign="center center">
    <div  fxLayout="column" style="margin-bottom:-15px;font-weight: bold;color:#3f51b7" fxFlex="50"
     fxLayoutAlign="end center">True</div>
    <div  fxLayout="column" style="margin-bottom: -15px ;font-weight: bold;color:#f44336" fxFlex="50"
     fxLayoutAlign="end center">False</div>
  </div> -->

</div>
