import { Component, Input, OnInit } from '@angular/core';
import { PageService } from '../../services/page-service.service';

@Component({
    selector: 'widget-drag-handle',
    templateUrl: './widget-drag-handle.component.html',
    styleUrls: ['./widget-drag-handle.component.scss'],
    standalone: false
})
export class WidgetDragHandleComponent implements OnInit {

  @Input() widgetMeta: any = {}
  constructor(
    public pageService: PageService
  ) { }

  ngOnInit(): void {
  }



}
