<ng-container >
  <ng-container *ngIf="(isBloomConfigLoading || hasPermission || isLoginRequired || isLandingPageLogin)">
    <app-bloom-toolbar
    [bloomMeta]="bloomMeta"
    [pages]="pages"
    [currentBloomCode]="currentBloomCode"
    [isLoggedIn]="isLoggedIn"
    [version]="version"
    (bloomLogin)="bloomLogin()"
    (bloomLogout)="bloomLogout()"
    (pageChange)="hideFooter($event)"
    (previewPageChange)="previewPageChange($event)"
  > </app-bloom-toolbar>
  </ng-container>
  <spinner [center]="true"  [selfSpin]="spinner" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
  <ng-container  *ngIf="bloomMeta && (hasPermission || isLoginRequired || isLandingPageLogin)">
    <div *ngIf="ready">
      <router-outlet *ngIf="type != 'internal'"  (activate)="onActivate($event)"></router-outlet>
      <app-page *ngIf="type == 'internal'" [changedPageCode]="changedPageCode" [_builderMode]="false"></app-page>
      <div class="footer-view" *ngIf="showFooter">
        <mat-divider></mat-divider>
        <app-page [footerConfig]="{ 'isFooterPage': true, 'changeContext': false }" [_builderMode]="false"></app-page>
      </div>
    </div>


    <div *ngIf="isLoginRequired">
      <app-bloom-login [isDialog]="" (loggedIn)="loggedIn($event)" ></app-bloom-login>
    </div>

    <!-- <app-page *ngIf="!pageCodeExists"></app-page> -->

    <div *ngIf="terminationError" class="terminationError">
      Requested URL is invalid. (Bloom code not present)
    </div>
    <div *ngIf="versionError" class="versionError">
      Requested URL is invalid. (Invalid version given)
    </div>
  </ng-container>
</ng-container>
