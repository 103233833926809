import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
    selector: 'app-role-create',
    templateUrl: './role-create.component.html',
    styleUrls: ['./role-create.component.scss'],
    standalone: false
})
export class RoleCreateComponent implements OnInit {
  @Input() role: any;
  @Input() roleConfig: any;
  @Output() "listRole" = new EventEmitter<any>();

  constructor(
    private route: ActivatedRoute, 
    private adminService: AdminService, 
    private spinnerService: SpinnerService,
    private connectionService: ConnectionService, 
    private metaService: MetaService, 
  ) { }

  roleFormGroup: UntypedFormGroup = new UntypedFormGroup({
    _id: new UntypedFormControl(''),
    created_at: new UntypedFormControl(''),
    created_by: new UntypedFormControl(''),
    modified_at: new UntypedFormControl(''),
    modified_by: new UntypedFormControl(''),
    code: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    description: new UntypedFormControl('', Validators.required),
    privileges: new UntypedFormControl([]),
    resource_type: new UntypedFormControl(''),
    resource_id: new UntypedFormControl('')
  });

  newEditTitle: string = 'New';
  workspaceId: string;
  preAuthenticatedToken: string;
  isNewRole: boolean = true;
  showSelectPriviege: boolean = false;
  setPriviligeObj: any[] = [];
  cardValue = {
    options: []
  };
  privilegeOptions:any;
  privileges: any = []

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.workspaceId = params.workspaceid || this.connectionService?.workSpaceId ;
      this.preAuthenticatedToken = params.authorization ? decodeURIComponent(params.authorization) : this.connectionService?.preAuthenticatedToken;
      this.isNewRole = this.role ? false : true;
    });

    // var res:any = await this.adminService.listPrivilege(1, 500, this.preAuthenticatedToken)
    // this.privileges = res.data;

    let defaultPayload = {filter: `resource_type=default|string,resource_id=default|string`};
    let bloomPayload = {filter: `resource_type=bloom|string,resource_id=${this.metaService.bloomMeta.code}|string`};
    var defaultRes:any = await this.adminService.listPrivilege(1, 500, this.connectionService.preAuthenticatedToken, defaultPayload)
    var bloomRes:any = await this.adminService.listPrivilege(1, 500, this.connectionService.preAuthenticatedToken, bloomPayload)
    let defaultPrivliages = defaultRes?.data || [];
    let bloomPrivliages = bloomRes?.data || [];
    this.privileges = defaultPrivliages.concat(bloomPrivliages);
    console.log("privileges", this.privileges);
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  setFilterOptions(){
    this.privilegeOptions = this.roleFormGroup.get('privileges').valueChanges.pipe(startWith(""),
      map((value) => this._filter(value, this.privileges))
    );
  }

  private _filter(value: string, array?:any): string[] {
    console.log("value", value)
    value = value ? value : ""
    if(typeof value != "string") return;
    const filterValue = value.toLowerCase();
    return array.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  getPriviegeId() {
    console.log(this.roleFormGroup.get('privileges').value);
    this.roleFormGroup.get('privileges').value.forEach((element: any) => {
      this.setPriviligeObj.push(this.adminService.privilegeTableSource.data.find((i: any) => {
        if (i._id == element)
          return i
      }))
    })
    this.showSelectPriviege = true;
  }


  backToRoleListing() {
    this.listRole.emit();
  }

  displayFunction(subject) {
    if(!subject) return undefined;
    return subject.name;
  }

  async createRole() {

    var resourceOption = {
      resource_type: "default", 
      resource_id: "default"
    }

    if(this.roleConfig){
      resourceOption = {
        resource_type: this.roleConfig.resource_type, 
        resource_id: this.roleConfig.resource_id
      }
    }
    this.roleFormGroup.patchValue(resourceOption)

    console.log("inside create", this.roleFormGroup.value)
    // this.roleFormGroup.controls.privileges.markAsTouched();
    // if (this.roleFormGroup.valid && this.roleFormGroup.get('privileges').value.length > 0) {
      
      this.spinnerService.show();
      var res = await this.adminService.createRole(this.preAuthenticatedToken, this.roleFormGroup.value)
      this.spinnerService.hide();
      this.backToRoleListing(); 
    // } else { console.log("inside create else") }
  }

  async updateRole() {
    this.roleFormGroup.controls.privilege.markAsTouched();
    if (this.roleFormGroup.valid && this.roleFormGroup.get('privileges').value.length > 0) {
      this.spinnerService.show();
      var res = await this.adminService.updateRole(this.preAuthenticatedToken, this.roleFormGroup.value);
      this.backToRoleListing();
      this.spinnerService.hide();
    }
  }

  selectChange(event){
    console.log("--->", event)
    var code = event.code;
    let value = this.roleFormGroup.get('privileges').value

    if(!value.includes(code)){
      value.push(event.code);
      this.roleFormGroup.get('privileges').setValue(value);
    }
  }

  removePrivilege(privilege: string): void {
    let value = this.roleFormGroup.get('privileges').value
    const index = value.indexOf(privilege);

    if (index >= 0) {
      value.splice(index, 1);
    }
    this.roleFormGroup.get('privileges').setValue(value);
  }

}
