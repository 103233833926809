<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path" *ngIf="spinner"></spinner>

<div *ngIf="onlyDisplay && base" fxLayout="row">
  <div fxLayout="column" fxFlex="5">
    <mat-icon color="primary" matPrefix>contactless</mat-icon> </div>
  <div fxLayout="column" fxFlex="5">
  </div>
  <div fxLayout="column" fxFlex="80">
     {{base?.name}}
  </div>
  <div fxLayout="column" fxFlex="10"></div>
</div>

<div fxLayout="row" *ngIf="!onlyDisplay">
    <mat-form-field style="width: 80%;">
        <mat-icon color="primary" matPrefix>contactless</mat-icon>
        <mat-label>
          <span>&nbsp;Choose Base</span>
        </mat-label>
        <input matInput
        [formControl]="baseListingACFrom"
        (focus)="setFilterOptions()"
        [matAutocomplete]="auto" />
        <button matTooltip="Clear selected Base" *ngIf="baseListingACFrom.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="clearBase($event)" >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFunction"
          (optionSelected)="baseSelected($event.option.value)">
          <mat-option *ngFor="let option of baseListingOptions | async; trackBy: trackByFn" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>
      <div>
        <button mat-icon-button color="primary" (click)="addBasePopup()" matTooltip="Create Connection" aria-label="View Available Apps">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
      </div>
      <!-- <div>
        <button *ngIf="connectionId && baseListingACFrom.value" mat-icon-button color="primary" (click)="connectionPopup('edit')" matTooltip="Edit Connection" aria-label="View Available Apps">
            <mat-icon>edit</mat-icon>
          </button>
      </div> -->
      <mat-spinner
        *ngIf="spinner"
        color="warn"
        [diameter]="20"
      ></mat-spinner>
</div>
