import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from './auth-service.service';
import { environment } from 'src/environments/environment';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';

const generateName = require("project-name-generator");
const uuid = require('uuid');

@Injectable({
  providedIn: 'root'
})
export class StarchService {
  baseLength: any;

  constructor(
    public token: TokenUtil,
    public cs: ConnectionService,
    public http: HttpClient,
    public authService: AuthServiceService
  ) { }

  async getStarchBases(page:any = 1, limit:any = 10, filter?:any, workspaceId?:any){
    if(!workspaceId) workspaceId = this.cs.workSpaceId;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.cs.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/starch/list/${workspaceId}?page=${page}&limit=${limit}`;
    if(filter) url = url + "&filter=" + filter;
    try {
      console.log("get starch base url", url);
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get response ---->", response);
      this.baseLength = response?.totalCount || 0;
      return response?.result || []
    } catch (e) {
      console.error("[BOX-HTTP] Error on get:", e)
      throw e;
    }
  }

  async createStarchBase(baseMap: any){
    console.log("baseMap for creation", baseMap)
    var url = `${environment.SERVER_BASE_URL}/starch`
    try {

      baseMap.workspace_id = this.cs.workSpaceId;
      baseMap.created_by = this.authService.profile.email;
      baseMap.modified_by = this.authService.profile.email;

      let token = this.cs.preAuthenticatedToken;
      var options = { headers: { Authorization: `PreAuthenticatedToken ${token}` } }

      let response: any = await this.http.post(url, baseMap, options).toPromise();
      console.log("createStarchBase response ---->", response);
      return response?.result || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on create base:", e)
      throw e;
    }
  }

  async updateStarchBase(baseMap: any){
    var url = `${environment.SERVER_BASE_URL}/starch/${baseMap._id}`
    try {

      if(!baseMap.workspace_id) return;
      baseMap.modified_by = this.authService.profile.email;

      let token = this.cs.preAuthenticatedToken;
      var options = { headers: { Authorization: `PreAuthenticatedToken ${token}` } }

      let response: any = await this.http.put(url,baseMap, options).toPromise();
      console.log("update StarchBase response ---->", response);
      return response?.result || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on update base by id:", e)
      throw e;
    }
  }

  async deleteStarchBase(baseMap: any){
    var url = `${environment.SERVER_BASE_URL}/starch/${baseMap._id}/${baseMap.storage_base_code}`;
    try {

      let token = this.cs.preAuthenticatedToken;
      var options = { headers: { Authorization: `PreAuthenticatedToken ${token}` } }

      let response: any = await this.http.delete(url, options).toPromise();
      console.log("delete StarchBase response ---->", response);
      return response?.result || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on delete base by id:", e)
      throw e;
    }
  }

  async getStarchBase(id: string){
    let token = this.cs.preAuthenticatedToken;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${token}` } }

    var url = `${environment.SERVER_BASE_URL}/starch/${id}`;
    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get base by ID, response ---->", response);
      return response?.result || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get base by id:", e)
      throw e;
    }
  }

  async getBaseByCode(code: any) {
    let token = this.cs.preAuthenticatedToken;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${token}` } }

    let filter = `code=${code}|string`;

    var url = `${environment.SERVER_BASE_URL}/starch/list/${this.cs.workSpaceId}?filter=${filter}`
    try {
      console.log("hitting URL", url)
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get base by code, response ---->", response);
      return response?.result[0] || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get base by id:", e)
      throw e;
    }
  }

  async getBaseAttributes(baseMap: any, payload: any){
    console.log("[get base attributes]", payload)
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/getattributes`;
    let body = {
      parameters: payload
    }
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] get attributes response:", response);
      return response?.result || [];
    } catch (e) {
      console.error("[BOX-HTTP] Error on get attributes:", e)
      throw e;
    }
  }

  async updateBaseAttributes(baseMap: any, payload: any){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/updateattribute`;
    let body = {
      parameters: payload
    }
    console.log("[BOX-HTTP] update attributes body:", body);
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] update attributes response:", response);
      return response?.result || [];
    } catch (e) {
      console.error("[BOX-HTTP] Error on update attributes:", e)
      throw e;
    }
  }

  async createBaseAttributes(baseMap: any, payload: any){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/createattribute`;
    let body = {
      parameters: payload
    }
    console.log("[BOX-HTTP] create attributes body:", body);
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] create attributes response:", response);
      return response?.result || [];
    } catch (e) {
      console.error("[BOX-HTTP] Error on create attributes:", e)
      throw e;
    }
  }

  async createBaseObject(baseMap: any, payload: any){
    console.log("[create base object] baseMap", baseMap)
    console.log("[create base object] payload", payload)
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/createobject`;
    let body = {
      parameters: payload
    }
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] create objects response:", response);
      return response?.result?.[0] || {};
    } catch (e) {
      console.error("[BOX-HTTP] Error on create objects:", e)
      throw e;
    }
  }

  async deleteBaseObject(baseMap: any, object: any){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/deleteobject`;
    let body = {
      parameters: {
        object: object
      }
    }
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] delete object response:", response);
      return response?.result?.[0] || {};
    } catch (e) {
      console.error("[BOX-HTTP] Error on delete objects:", e)
      throw e;
    }
  }


  async deleteStarchRelation(baseMap, id){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/starch_relationship/deleteById`;
    let body = {
      parameters: {
        id: id
      }
    }
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] delete Starch Relation response:", response);
      return response?.result?.[0] || {};
    } catch (e) {
      console.error("[BOX-HTTP] Error on delete Starch Relation:", e)
      throw e;
    }
  }

  async getStarchRelation(baseMap, id){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/starch_relationship/get`;
    let body = {
      parameters: {
        query: {
          filter: `_id=${id}|string`,
          page: "1|100|100"
        }
      }
    }
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] get Starch Relation response:", response);
      return response?.result?.data[0] || {};
    } catch (e) {
      console.error("[BOX-HTTP] Error on get Starch Relation:", e)
      throw e;
    }
  }

  async createStarchRelation(baseMap, payload){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/starch_relationship/create`;
    payload.modified_at = new Date().toISOString() + "|date";
    payload.created_at = new Date().toISOString() + "|date";
    let body = {
      parameters: {
        data: [payload]
      }
    }
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] create Starch Relation response:", response);
      return response?.result?.[0] || {};
    } catch (e) {
      console.error("[BOX-HTTP] Error on create Starch Relation:", e)
      throw e;
    }
  }

  async updateStarchRelation(baseMap, payload){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/starch_relationship/update`;
    payload.modified_at = new Date().toISOString() + "|date";
    let body = {
      parameters: {
        data: [payload]
      }
    }
    try {
      let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
      console.log("[BOX-HTTP] create Starch Relation response:", response);
      return response?.result?.[0] || {};
    } catch (e) {
      console.error("[BOX-HTTP] Error on create Starch Relation:", e)
      throw e;
    }
  }

  // async deleteBaseObject(baseMap: any, payload: any){
  //   var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/createobject`;
  //   let body = {
  //     parameters: payload
  //   }
  //   try {
  //     let response: any = await this.execute(boxUrl, body, "post",  baseMap.storage_token);
  //     console.log("[BOX-HTTP] create objects response:", response);
  //     return response?.result?.[0] || {};
  //   } catch (e) {
  //     console.error("[BOX-HTTP] Error on create objects:", e)
  //     throw e;
  //   }
  // }

  async getBoxActions(baseMap: any) {
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/functions`;
    let boxToken = baseMap.storage_token;
    try {
      let response: any = await this.execute(boxUrl, null, "get", boxToken);
      console.log("[BOX-HTTP] get actions response:", response);
      return;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get actions:", e)
      throw e;
    }
  }

  async getBaseObjects(baseMap: any){
    var boxUrl = `${environment.BOX_URL}/${baseMap.storage_box}/objects`;
    let result = [];
    let boxToken = baseMap.storage_token;
    console.log("[BOX-HTTP] get boxToken:",baseMap, boxToken);
    try {
      let options = {
        headers: {
          Authorization: `Bearer ${await this.token.getStatelessToken()}`,
          boxconfigToken: JSON.parse(JSON.stringify(boxToken)),
          "Cache-Control": 'no-cache'
        }
      }
      var response: any = await this.http.get(boxUrl, options).toPromise();
      // let response: any = await this.execute(boxUrl, null, "get", boxToken);
      console.log("[BOX-HTTP] get objects response:", response);
      response?.objects.forEach(element => {
        if(element.__id != "starch_relationship") result.push(element);
      });
      console.log("[BOX-HTTP] get objects result:", result);
      return result;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get objects:", e)
      throw e;
    }
  }


  async execute(url: string, payload?: any, method?: string, boxToken?: any) {

    let options = {
      headers: {
        Authorization: `Bearer ${await this.token.getStatelessToken()}`,
        boxconfigToken: JSON.parse(JSON.stringify(boxToken)),
        "Cache-Control": 'no-cache'
      }
    }

    let response: any;
    try {
      if (method == "get") {
        console.log("[BOX-HTTP] execute get: ", url, options);
        response = await this.http.get(url, options).toPromise();
        console.log("[BOX-HTTP] response: ", response);
      } else {
        response = await this.http.post(url, payload, options).toPromise();
      }
      console.log("[BOX-HTTP] execution response: ", response);
      return response
    } catch (e) {
      console.log("[BOX-HTTP] execution: ", e)
      throw e;
    }
  }

  async checkAndGetRandomCode(code?:any){
    // this.spinner = true;
    let randomCode
    if(!code){
      randomCode = uuid.v4().substring(0, 4);
      if(!randomCode){
        randomCode = Date.now()
      }
      randomCode = `${generateName.generate({ words: 1}).dashed}-${randomCode}`;
      randomCode = this.trimRandomCode(randomCode);
    } else randomCode = code;

    console.log("randomCode", randomCode)
    let result = await this.getBaseByCode(randomCode);
    console.log("result", result);
    if(result) return this.checkAndGetRandomCode();
    else {
      // this.spinner = false;
      // this.isNameExists = false;
      // this.sugestBaseCode = randomCode;
      // this.formGroup.get('code').setValue(this.sugestBaseCode);
      return randomCode;
    }
  }

  //trim random code into 11 char as database wont accept above 12
  trimRandomCode(input: string): string {
    if (input.length <= 11) {
      return input;
    }

    const parts = input.split('-'); // Split by '-'
    if (parts.length > 1) {
      const firstPart = parts[0].slice(0, 6); // Keep first 6 chars
      const lastPart = parts[1].slice(0, 4);  // Keep first 4 chars of second part
      return `${firstPart}-${lastPart}`;
    }
    return input.slice(0, 11); // Fallback if no '-'
  }



}
