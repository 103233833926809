<div fxLayout="row">
    <div fxLayout="column" fxFlex="2"> </div>
    <div fxLayout="column" fxFlex="96">

        <mat-accordion multi>
            <mat-expansion-panel [expanded]="meta.securityConfig?.securities?.length === i+1" *ngFor="let security of meta.securityConfig?.securities;let i = index; trackBy: trackByFn">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Security #{{i + 1}}
                  </mat-panel-title>
                  <mat-panel-description>
                    
                  </mat-panel-description>
                </mat-expansion-panel-header>
                
                <mat-card appearance="outlined" >
                  <div>
                    <div fxLayout="column" fxFlex="95"></div>
                    <div fxLayout="column" fxFlex="5"> 
                      <mat-icon style="cursor: pointer;" color="warn" 
                      (click)="removeSecurity(meta.securityConfig.securities, i)">
                      delete
                      </mat-icon>
                    </div>
                  </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <div fxLayout="column" fxLayout.xs="row" fxFlex="25" *ngIf="!isNoEffect">
                    <div> Apply effect </div>
                  </div>
                  <div fxLayout="column" fxFlex="25" fxLayout.xs="row" *ngIf="effects && effects.length > 0">
                    <div fxLayout="row"    >
                    <mat-icon style="margin-top: 15px;" matTooltip="Effect" color="primary"> settings </mat-icon>
                    <mat-form-field  style="width: 90%;">
                      <mat-select [(ngModel)] = "security.effect"
                      >
                        <mat-option [value]="effect.code" *ngFor="let effect of effects; trackBy: trackByFn">
                          {{ effect.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  </div>
                  <div fxLayout="column" fxLayout.xs="row" fxFlex="25"> &nbsp;when user</div>
            
                  <div fxLayout="column" fxFlex="25" fxLayout.xs="row">
                    <div fxLayout="row" >
                      <mat-icon style="margin-top: 15px;" matTooltip="Condition" color="primary">filter_alt</mat-icon>
                    <mat-form-field style="width: 90%" >
                      <mat-select [(ngModel)] = "security.condition">
                        <mat-option [value]="condition.code" *ngFor="let condition of conditions; trackBy: trackByFn">
                          {{ condition.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="column" fxFlex="20">
                      <div> privilege </div>
                    </div>
                    <div fxLayout="column" fxFlex="5"></div>
                    <div fxLayout="column" fxFlex="75">
                        <mat-form-field  *ngIf="privileges">
                            <mat-label>Privileges</mat-label>
                            <mat-chip-grid #chipList aria-label="">
                              <mat-chip-row *ngFor="let privilege of security.privileges; trackBy: trackByFn"
                              (removed)="removePrivilege(privilege, security.privileges)">
                                {{privilege}}
                                <button matChipRemove>
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip-row>
                              <input
                                placeholder="Add privilege"
                                #fruitInput
                                [formControl]="filteredCtrl"
                                (focus)="setFilterOptions()"
                                [matAutocomplete]="autoBoxEvent"
                                [matChipInputFor]="chipList"
                                >
                            </mat-chip-grid>
                            <mat-autocomplete 
                              #autoBoxEvent="matAutocomplete" 
                              [displayWith]="displayFunction"
                              (optionSelected)="selectChange($event.option.value, security.privileges)">
                              <mat-option *ngFor="let option of privilegeOptions | async; trackBy: trackByFn" [value]="option">
                                {{ option.code }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                      </div>
                
                
                </div>
        

              </mat-card>
              </mat-expansion-panel>

        </mat-accordion>

    </div>
    <div fxLayout="column" fxFlex="2"> </div>

</div>

<div fxLayout="row">
    <div fxLayout="column" fxFlex="5"> </div>
    <div fxLayout="column" fxFlex="90">
      <div fxLayout="row" fxLayoutAlign="start center"> 
        <button style="cursor: pointer;" (click)="addNewSecurity()"  color="primary" mat-stroked-button matTooltip="Add new security">
            <mat-icon >
                add_circle_outline
             </mat-icon>
             Add security
        </button>
      </div>
    </div>
    <div fxLayout="column" fxFlex="5"> </div>
  </div>


<!-- <pre> {{meta.securityConfig | json}}</pre> -->

